export default {
  read: {
    title: 'Lees je documenten',
    loading_docs_to_read:
      'We zijn jouw documenten aan het genereren. Dit kan tot 1 minuut duren.',
    info:
      'Neem onderstaande documenten goed door en geef aan dat je ze gelezen hebt. Hiermee geef je ook aan dat je akkoord gaat met de inhoud. Heb je nog vragen? Neem gerust contact op met onze juristen via 020 - 303 1043.',
    subtitle: 'Lees de volgende documenten aandachtig',
    checkbox: 'Gelezen',
    download: 'Download',
    info_omzetting_with_added_value:
      'Je hebt gekozen voor een geruisloze of ruisende omzetting  van een eenmanszaak of vof naar een BV. De werkzaamheden van de notaris nemen hierdoor meer tijd in beslag. Dit is gemiddeld 3 dagen extra.\
      <br><br> Neem onderstaande documenten goed door en geef aan dat je ze gelezen hebt. Hiermee geef je ook aan dat je akkoord gaat met de inhoud. Heb je nog vragen? Neem gerust contact op met onze juristen via 020-303 1043.',
    info_when_high_capital:
      'Bij een hoog aandelenkapitaal, moet de notaris meer werkzaamheden verrichten. Hou rekening met gemiddeld 3 dagen extra aan tijd. Zo heeft een notaris zelfs een wettelijke onderzoeksplicht bij een aandelenkapitaal vanaf €10.000 naar de herkomst van het vermogen. Daarnaast is het mogelijk dat de notaris extra kosten in rekening brengt. Voor meer informatie kun je met ons contact opnemen via 020-3031043. \
      <br><br>Neem onderstaande documenten goed door en geef aan dat je ze gelezen hebt. Hiermee geef je ook aan dat je akkoord gaat met de inhoud. Heb je nog vragen? Neem gerust contact op met onze juristen via 020-303 1043.',
    info_omzetting_with_added_value_and_high_capital:
      'Je hebt gekozen voor een geruisloze of ruisende omzetting  van een eenmanszaak of vof naar een BV. De werkzaamheden van de notaris nemen hierdoor meer tijd in beslag. Dit is gemiddeld 3 dagen extra.\
      <br><br> Bij een hoog aandelenkapitaal, moet de notaris meer werkzaamheden verrichten. Hou rekening met gemiddeld 3 dagen extra aan tijd. Zo heeft een notaris zelfs een wettelijke onderzoeksplicht bij een aandelenkapitaal vanaf €10.000 naar de herkomst van het vermogen. Daarnaast is het mogelijk dat de notaris extra kosten in rekening brengt. Voor meer informatie kun je met ons contact opnemen via 020-3031043. \
      <br><br>Neem onderstaande documenten goed door en geef aan dat je ze gelezen hebt. Hiermee geef je ook aan dat je akkoord gaat met de inhoud. Heb je nog vragen? Neem gerust contact op met onze juristen via 020-303 1043.'
  },
  upload: {
    title: 'Upload documenten',
    label: 'Upload',
    spaar_info: `De notaris die jouw BV opricht, zit in <strong>{notary_location}</strong>. Tijdens het proces is het nodig om een aantal van de documenten te legaliseren. Hiervoor heb je twee verschillende opties: <br><br> 1. Je kunt naar <strong>{notary_location}</strong> gaan en daar je documenten ondertekenen. <br><br> 2. Je kunt ook je documenten meenemen naar en notaris bij jou in de buurt en deze daar laten legaliseren. Je kunt ze dan per post opsturen naar de notaris. <br><br> Neem vooral contact op met ons team voor meer informatie en gedetailleerde begeleiding.`,
    info: {
      all_docs_ok:
        'Na versturing, controleert de notaris jouw documenten. De notaris kan om aanvullende documenten vragen. Zorg ervoor dat de documenten goed leesbaar zijn en correct zijn ingevuld om vertraging te voorkomen. Gebruik alleen .docx en pdf documenten die niet groter zijn dan 15 Mb.',
      residential_address:
        'Als je het woonadres van een van de aandeelhouders heeft gekozen als bedrijfsadres, dan kan je een blanco pagina uploaden met de tekst ‘Verrekeningsadres is hetzelfde als woonadres‘ in het gedeelte huurovereenkomst.'
    },
    foreign_info: {
      residential_address:
        'Na deze stap zal de notaris je documenten beoordelen en aanvullende documenten vragen als dat nodig is. Als je het woonadres van een van de aandeelhouders hebt gekozen als bedrijfsadres, dan kan je een blanco pagina uploaden met de tekst ‘Verrekeningsadres is hetzelfde als woonadres‘ in het gedeelte huurovereenkomst.',
      next_step:
        'Heb je nog vragen? Neem contact met op ons via online chat voor snelle begeleiding.'
    },
    warning_foreign_info: {
      paragraph: `Sommige documenten van de bij de oprichting betrokken buitenlandse aandeelhouder(s) moeten worden gelegaliseerd en/of voorzien van een apostille.' Neem contact op met ons team via de chat voor directe begeleiding en ondersteuning.<br><br>Zorg ervoor dat zodra de documenten klaar zijn, je de originele documenten via de post opstuurt naar het postadres van jouw Ligo notaris. Upload daarnaast de kopiën hier in je dashboard.`,
      question: 'Niet bekend met legalisatie en/of apostille?',
      link: 'Lees dan het volgende artikel'
    },
    uploaded: 'Geüpload',
    change: 'Wijzig',
    success: 'Het document is succesvol geüpload',
    error: 'Er is een fout opgetreden bij het uploaden van het document'
  },
  send_notary: 'Verstuur naar de notaris',
  cant_send_message: `Deze knop wordt geactiveerd nadat alle <br> vereiste documenten zijn geüpload`,
  busy_notary_banner:
    'Op dit moment hebben alle notariskantoren in Nederland het erg druk, waardoor het wat langer kan duren om uw zaak af te ronden.',
  watch_video: 'Bekijk video',
  process_video: 'Bekijk de uitlegvideo'
};
