


























import { defineComponent } from '@vue/composition-api';
import { APrimaryBtn } from '../../app/buttons';

export default defineComponent({
  name: 'SQEmpty',
  components: { APrimaryBtn },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    },
    btnLabel: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: true
    },
    iconSize: {
      type: String,
      default: '148px'
    }
  },
  setup() {
    return {};
  }
});
