










import { defineComponent } from '@vue/composition-api';
import { BaseModal } from '.';

const i18nContent = {
  title: 'e_signature.sign_document.modals.decline.title',
  text: 'e_signature.sign_document.modals.decline.text'
};

const ctas = [
  {
    label: 'e_signature.sign_document.modals.decline.cancel',
    textColor: 'default',
    class: 'e-sign-bordered-btn',
    color: 'white',
    emit: 'cancel'
  },
  {
    label: 'e_signature.sign_document.modals.decline.decline',
    color: 'negative',
    emit: 'decline'
  }
];

export default defineComponent({
  name: 'DeclineModal',
  components: { BaseModal },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return { ctas, i18nContent };
  }
});
