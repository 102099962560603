export default {
  upload: {
    title: 'Kies het document',
    placeholder: 'Document bestand',
    send: 'Upload',
    success: 'Het document is succesvol geüpload',
    error: 'Er is een fout opgetreden bij het uploaden van het document',
    required: 'Documentbestand is required',
    format: 'Ongeldig document. Het moet pdf zijn, niet groter dan 15 MB',
    name: 'Document Naam'
  },
  new: {
    tittle: 'Nieuw document',
    upload: 'Klik hier om een contract te uploaden',
    library: 'Selecteer een contract uit de Ligo bibliotheek',
    signed: {
      part1: 'Is je',
      part2: 'al getekend?',
      yes: 'Ja',
      no: 'Nee'
    }
  },
  editor: {
    discardModal: {
      title: 'Verwijder alle niet-opgeslagen wijzigingen',
      subtitle:
        'Als je jouw wijzigingen niet opslaat, verwijder je de laatste wijzigingen die je hebt aangebracht.',
      buttonContinue: 'Doorgaan met bewerken',
      buttonDiscard: 'Verwijder'
    },
    banner: {
      title: 'Bewerken',
      subtitle: 'Laatste wijziging',
      buttonDiscard: 'Annuleer',
      buttonSave: 'Opslaan',
      unsaved: 'Er zijn niet opgeslagen wijzigingen'
    },
    loader: {
      loadingEditor: 'Het document laadt. Even geduld alstublieft...',
      loadingDocument: 'De editor laadt. Even geduld alstublieft...'
    }
  },
  add_document: {
    title: 'Maak een nieuw document of upload een bestaand document',
    new_document: 'Maak een nieuw document',
    upload_document: 'Upload een bestaand document'
  },
  free_banner: {
    hello: 'Welkom bij Ligo,',
    title1: 'Je hebt een',
    title2:
      ' legal plan. Dit is wat je krijgt: | account. Dit is wat je krijgt:',
    item1:
      'Onbeperkt templates van juridisch documenten | {count} contract uit de contractenbibliotheek | {count} contracten  uit de contractenbibliotheek',
    item2:
      'Onbeperkt digitale handtekeningen | {count} juridisch geldige digitale ondertekeningen | {count} juridisch geldige digitale ondertekeningen',
    item3: 'Veilige opslag van een onbeperkt aantal contracten',
    footer: 'Je kan je plan wijzigen via je ',
    footer_link: 'accountinstellingen',
    ok: 'OK'
  },
  last_updated: 'Laatst gewijzigd op {date}'
};
