































import { defineComponent, computed } from '@vue/composition-api';
import { SQMainHeader, StepsBanner } from '../base';
import { useSignatureHome } from './signature-home.hook';
import APrimaryBtn from '../base/app/buttons/APrimaryBtn.vue';
import { SignatureHomePageInformation } from '../../components/utils/useCustomerPageInformation.hooks';

interface Step {
  title: string;
  subtitle: string;
  icon: string;
}

interface UploadArea {
  title: string;
  subtitle: string;
  icon: string;
  btn: string;
}

export default defineComponent({
  name: 'SignatureHomeHandler',
  components: { SQMainHeader, APrimaryBtn, StepsBanner },
  setup() {
    const {
      fileInput,
      selectedFiles,
      dropHandler,
      onUploadFiles
    } = useSignatureHome();

    const { t, isReady, load } = SignatureHomePageInformation;
    load();

    const steps = computed(() => {
      if (isReady()) return t('steps') as Step[];
      return [];
    });

    const uploadArea = computed(() => {
      if (isReady) return t('upload_area') as UploadArea;
      return {} as UploadArea;
    });

    return {
      fileInput,
      selectedFiles,
      steps,
      uploadArea,
      dropHandler,
      onUploadFiles,
      t,
      isReady
    };
  }
});
