









































import { defineComponent } from '@vue/composition-api';
import { useAwaitDOMNode, getPropertyClassBased } from '@ligo/shared/utils';

const HEX = /^#([0-9a-f]{3}){1,2}$/i;

export default defineComponent({
  name: 'ABtn',
  components: {},
  props: {
    leftIcon: String,
    leftIconColor: String,
    rightIcon: String,
    rightIconColor: String,
    label: String,
    textColor: {
      type: String,
      default: 'white'
    },
    bgColor: String,
    padding: {
      type: String,
      default: '8px 16px'
    },
    bgDisable: {
      type: String,
      default: '#f4f5f8'
    }
  },
  setup(props) {
    const disableColor = HEX.test(props.bgDisable)
      ? props.bgDisable
      : getPropertyClassBased(props.bgDisable, 'background-color');
    useAwaitDOMNode(['.application-button.q-btn.disabled']).then(([node]) => {
      const styleDisable = (node as HTMLElement)?.style;
      styleDisable?.setProperty('--a-btn-disable-bg', `${disableColor}`);
    });
    return {};
  }
});
