import { ApiService, Dictionary } from '@ligo/shared/utils';
import { RESOURCES } from '../resources';
import { BankingRequest } from '@ligo/dashboard/store';

export const BankingService = {
  requests(query: Dictionary = {}) {
    return ApiService.get<BankingRequest[]>(RESOURCES.BANKING_REQUESTS, {
      ...query
    });
  },
  account_request(notaryProductUuid: string) {
    return ApiService.post(RESOURCES.BANKING_REQUESTS, {
      notary_product_uuid: notaryProductUuid
    });
  }
};
