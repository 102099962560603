export default {
  Home: 'Dashboard',
  Account: 'Mijn account',
  Settings: 'Account instellingen',
  AddMember: 'Add members',
  DirectorsShareholders: 'Directors & shareholders',
  Discounts: 'Kortingen',
  NotaryProductDetail: 'Mijn diensten',
  Incorporation: 'Incorporation',
  NotarialDeeds: 'Notarial deeds',
  OtherServices: 'Other services',
  MyDocuments: 'My documents',
  CreateDocument: 'Contracten Bibliotheek',
  CreateTemplate: 'Create template',
  ListQuotes: 'Quote Requests',
  RequestQuote: 'Quote Requests',
  QuotesDetails: 'Quote details',
  NotaryProducts: 'Mijn diensten',
  ActiveContracts: 'Mijn Documenten',
  Companies: 'My Companies',
  NewQuote: 'Nieuwe offerte',
  Billing: 'Factuurgegevens',
  Checkout: 'Bestellingsoverzicht',
  AdyenPayment: 'Betaling',
  ContractPreview: 'Juridische documenten',
  notary_products: {
    new: 'Nieuwe dienst',
    title: 'Diensten',
    table: {
      columns: {
        name: 'Naam',
        status: 'Status',
        type: 'Type',
        last_modified: 'Laatst gewijzigd'
      },
      filters: {
        search: 'Zoek op naam',
        status: 'Status',
        types: {
          label: 'Dienst',
          labelSelected: ''
        },
        last_modified: 'Laatst gewijzigd'
      }
    }
  },
  contracts: {
    title: 'Maak een juridisch document',
    subtitle: 'Onderteken en bewaar jouw juridische documenten digitaal'
  },
  discount: {
    title: 'Kortingen',
    subtitle:
      'Krijg toegang tot speciale voordelen en unieke kortingen van onze partners'
  },
  templates: {
    title: 'Contracten bibliotheek'
  },
  contract_reminders: {
    title: 'Taken',
    subtitle:
      'Jouw juridische assistent die je automatisch op het juiste moment aan fiscale en juridische verplichtingen herinnert.',
    empty_banner: {
      title: 'Dit is waar je jouw juridisch taken beheert',
      subtitle:
        'Automatiseer juridische taken en word herinnerd op het juiste moment om actie te ondernemen, zodat je compliant blijft en kan focussen op het uitbouwen van je bedrijf',
      btn: 'Nieuwe taak'
    },
    editor_placeholder: 'Schrijf hier een bericht'
  },
  user: {
    title: 'Instellingen'
  }
};
