










































































































import { defineComponent } from '@vue/composition-api';
import { usePaymentData } from './payment.hooks';
import BaseButton from '../../components/global/BaseButton.vue';
import { Phone } from '@ligo/dashboard/components';

const BTW_KEY = 'btw';
const ITEMS_KEY = 'lineItems';
const TOTAL_KEY = 'total';
const CUSTOMER_KEY = 'customer';
const BTW_TAX_AMOUNT = ' ( 21% )';
const CARDS_IMAGE = '/dashboard/images/cards2.png';

export default defineComponent({
  name: 'Checkout',
  components: {
    BaseButton,
    Phone
  },
  props: {
    paymentId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const {
      loading,
      paymentData,
      keysOrder,
      ratingBanner,
      customerService,
      pay
    } = usePaymentData(props.paymentId);
    return {
      loading,
      paymentData,
      keysOrder,
      ratingBanner,
      customerService,
      pay,
      BTW_KEY,
      BTW_TAX_AMOUNT,
      ITEMS_KEY,
      TOTAL_KEY,
      CUSTOMER_KEY,
      CARDS_IMAGE
    };
  }
});
