export default {
  title: 'Meeting with the notary',
  banner: {
    text_send_email_and_call_video:
      'Please send an email to the notary to schedule a meeting at a time that is convenient for you. The meeting will take place via a video-call. Please have with you the ID(s) that you used for the application.',
    text_contact:
      'The notary will contact you by phone to corroborate your details and send you an email after the call to verify your identity and sign the power of attorney via a digital identification tool called Evidos. By signing the power of attorney, you authorise the civil-law notary to register your B.V. on your behalf. <b>Please do not forget to also click on the second link in the email. Here you will have to make a picture of yourself and your identification to identify yourself.</b>',
    text_send_email_and_go_notary:
      "Please send an email to the notary to schedule an appointment at a time convenient for you. The meeting with the notary will be at the notary's office. If you choose the notary in Rotterdam, you do not have to do anything at the moment. You will receive an e-mail from them. Please make sure you will bring your identification, the one that you used for your application."
  },
  contact: 'Contact Information',
  email: 'E-mail',
  phone: 'Telephone number',
  address: 'Address',
  check_map: 'Check the map',
  document_folder: 'Document folder',
  read_and_print: {
    title: 'Print and bring these documents to the meeting',
    banner: {
      text:
        'Please print out the power of attorney and the personal datacard. You can not sign the documents yet, since you will do that during the meeting with the notary.'
    }
  },
  upload: 'Choose',
  change: 'Change',
  documents: 'Documents you uploaded',
  templates: 'Ligo forms and templates'
};
