import { Ref } from '@vue/composition-api';

export enum LinkType {
  Route,
  Logout,
  OldDashboard,
  OpenIntercom
}

export interface NavItem {
  route: string;
  icon?: string;
  title: string;
  subtitle?: string;
  badgeNumber?: number;
  children?: NavItem[];
  newItem?: boolean;
  closed?: Ref<boolean>;
}

export interface UserMenuItem {
  title: string;
  icon?: string;
  link?: string;
  type?: LinkType;
}
