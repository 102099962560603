



























import { defineComponent, PropType } from '@vue/composition-api';
import { NotaryProductDocument } from '@ligo/dashboard/store';
import DocumentNameClickable from '../../global/DocumentNameClickable.vue';

export default defineComponent({
  name: 'ItemTemplate',
  components: { DocumentNameClickable },
  props: {
    item: {
      type: Object as PropType<NotaryProductDocument>,
      required: true
    }
  }
});
