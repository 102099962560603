import { ComputedRef, Ref, watch, onBeforeUnmount } from '@vue/composition-api';

const beforeunloadListner = (event) => {
  event.preventDefault();
  return (event.returnValue = '');
};

export function useOnbeforeunload(flag: Ref<boolean> | ComputedRef<boolean>) {
  onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', beforeunloadListner, {
      capture: true
    });
  });
  watch(flag, (newVal) => {
    if (newVal) {
      window.addEventListener('beforeunload', beforeunloadListner, {
        capture: true
      });
    } else {
      window.removeEventListener('beforeunload', beforeunloadListner, {
        capture: true
      });
    }
  });
}
