export default {
  table: {
    columns: {
      service: 'Service',
      status: 'Status',
      year: 'Year',
      paid_on: 'Paid on'
    },
    status: {
      paid: 'Paid',
      open: 'Pending'
    },
    actions: {
      pay: 'Pay'
    },
    empty: {
      title: 'Your DGA services will show here',
      subtitle:
        'Get professional advice from our tax and payroll specialists and handle over all the work to ensure you save money'
    }
  }
};
