


























































import { defineComponent } from '@vue/composition-api';
import { ABtn, APrimaryBtn, ASecondaryBtn } from '../../../base/app/buttons';

export default defineComponent({
  name: 'ESignDrawer',
  components: { ABtn, APrimaryBtn, ASecondaryBtn },
  props: {
    value: {
      type: Boolean,
      default: true
    },
    continueDisabled: {
      type: Boolean,
      default: true
    },
    continueI18n: {
      type: String,
      default: 'e_signature.global.drawer.actions.continue'
    },
    showBackCta: {
      type: Boolean,
      default: true
    },
    showDeclineCta: {
      type: Boolean,
      default: false
    },
    showContinueCta: {
      type: Boolean,
      default: true
    },
    showActions: {
      type: Boolean,
      default: true
    }
  }
});
