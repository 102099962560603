import { TranslateResult } from 'vue-i18n';

export interface PreviewDocumentMenuItem {
  icon?: string;
  label: string;
  emit: string;
  separator?: boolean;
}

export enum ctaTypes {
  default = 'default',
  basic = 'basic',
  custom = 'custom'
}

type SignCardCta = {
  type: 'basic' | 'default' | 'custom';
  icon?: string;
  label?: string | TranslateResult;
  bgColor?: string;
  textColor?: string;
  borderColor?: string;
  emit?: string;
};

export interface Signature {
  email: string;
  color: string;
  icon?: string;
  message: string | TranslateResult;
  signer_uuid?: string;
  session_uuid?: string;
  signed?: boolean;
}

export interface Footer {
  title: string | TranslateResult;
  subtitle: string | TranslateResult;
  ctaText: string | TranslateResult;
  ctaEvent: string;
}

export interface SignCard {
  topIcon?: string;
  title?: string | TranslateResult;
  text?: string | TranslateResult;
  requestNotification?: string | TranslateResult;
  badge?: {
    textColor?: string;
    backgroundColor?: string;
    text?: string | TranslateResult;
  };
  ctas?: SignCardCta[];
  signatures?: Signature[];
  footer?: Footer;
}
