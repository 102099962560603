import { ref, Ref } from '@vue/composition-api';
import { RESOURCES } from '@ligo/dashboard/customer/store';
import { ApiService } from '@ligo/shared/utils';
import { BillingData } from '../../../models';

export const formSections = [
  [{ name: 'name', required: true }],
  [{ name: 'vat_number', required: false }],
  [
    { name: 'street', required: true },
    { name: 'house_number', required: true }
  ],
  [
    { name: 'zip', required: true },
    { name: 'city', required: true }
  ],
  [{ name: 'phone_number', required: true }]
];

export function useBilling() {
  const loading = ref(true);
  const billingData: Ref<BillingData> = ref(null);
  const showSaved = ref(false);

  async function load() {
    ApiService.get(RESOURCES.BILLING)
      .then(({ data }) => {
        billingData.value = (data || {}) as BillingData;
        if (data) {
          showSaved.value = true;
        }
      })
      .catch((e) => {
        console.log('There was an error retriving the billing form data : ', e);
      })
      .finally(() => {
        loading.value = false;
      });
  }
  load();

  async function saveBilling(paymentUuid?: string) {
    await ApiService.patch(RESOURCES.BILLING, {
      payment_uuid: paymentUuid,
      ...billingData.value
    });
  }

  return { loading, billingData, saveBilling, showSaved, load };
}
