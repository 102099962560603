























import { defineComponent, PropType } from '@vue/composition-api';
import { Dictionary } from '@ligo/shared/utils';
import LabelValue from './LabelValue.vue';

export default defineComponent({
  name: 'InfoBanner',
  components: { LabelValue },
  props: {
    title: {
      type: String
    },
    img: {
      type: String,
      default: '../images/image-placeholder.svg'
    },
    data: {
      type: Object as PropType<Dictionary<string>>,
      default: () => ({})
    },
    locale: {
      type: String,
      default: 'account.notary'
    }
  },
  setup() {
    return {};
  }
});
