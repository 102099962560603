

































import { defineComponent, PropType } from '@vue/composition-api';
import { ModalCta } from '../../../../../models';
import { ABtn } from '../../../../base/app/buttons';

export default defineComponent({
  name: 'BaseModal',
  components: { ABtn },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: String,
    text: String,
    ctas: {
      type: Array as PropType<ModalCta[]>,
      default: () => []
    }
  }
});
