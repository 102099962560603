import { useTemplateList } from '../templates/template-list.hook';
import { ContractsPreview, ContractType } from '@ligo/dashboard/customer/store';
import { watch, Ref, ref } from '@vue/composition-api';

const headers = [
  {
    title: 'Name',
    field: 'name'
  },
  {
    title: 'Status',
    field: 'status'
  },
  {
    title: 'Last Modified',
    field: 'lastModified'
  },
  {
    title: '',
    field: 'action'
  }
];

const rows = [
  {
    name: 'Cookiebeleid Engels',
    subtitle: 'Added from the library',
    status: {
      text: 'Signed',
      textColor: 'green',
      background: 'green-light'
    },
    lastModified: 'Jun 9, 2022'
  },
  {
    name: 'Aandeelhoudersbesluit benoeming bestuurder',
    subtitle: '',
    status: {
      text: '0 / 3 signed',
      textColor: 'yellow',
      background: 'yellow-light'
    },
    lastModified: 'Jun 9, 2022'
  },
  {
    name: 'Brief verzoek tot verlaging salaris DGA Engels',
    subtitle: 'Added from the library',
    status: {
      text: 'Draft',
      textColor: 'subdued',
      background: 'grey-light'
    },
    lastModified: 'Jun 9, 2022'
  }
];

export function usePanel() {
  const contractsPreview: Ref<ContractsPreview> = ref({
    title: 'Contracts Preview',
    subtitle: 'Contracts Preview',
    items: []
  });

  const { loading, items, onStart } = useTemplateList();

  function open(contractTemplate: ContractType) {
    onStart(contractTemplate);
  }

  watch(loading, (newValue) => {
    if (!newValue) contractsPreview.value.items = items.value;
  });

  return {
    loading,
    contractsPreview,
    headers,
    rows,
    open
  };
}
