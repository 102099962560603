import { ref, Ref } from '@vue/composition-api';
import { ApiService } from '@ligo/shared/utils';
import { RESOURCES } from '@ligo/dashboard/customer/store';
import { Payment, PaymentView, PaymentRatingBanner } from '../../models';
import Vue from 'vue';

function paymentToPaymentView(data: Payment) {
  return <PaymentView>{
    uuid: data.uuid,
    upsellPackageName: data.payable_name,
    lineItems: data.line_items.map((item) => ({
      ...item,
      price: item.price / 100.0
    })),
    total: data.payment_amount,
    subtotal: data.payable_price,
    btw: data.payment_amount - data.payable_price
  };
}

export function usePaymentData(paymentId: string) {
  const loading = ref(true);
  const paymentData: Ref<PaymentView> = ref(null);
  const customerService = {
    message: 'payment.message'
  };
  const keysOrder = ['lineItems', 'subtotal', 'btw', 'total'];
  const ratingBanner: PaymentRatingBanner = {
    order: ['customer', 'kyioh', 'google'],
    customer: {
      total: '100,000+',
      icon: 'img:/dashboard/icons/customer.png',
      text: 'payment.rating_banner.customer.text'
    },
    kyioh: {
      text: 'payment.rating_banner.kyioh.text',
      icon: 'img:/dashboard/icons/kyioh.png',
      rating: 9.2 / 2,
      details: 'payment.rating_banner.kyioh.details'
    },
    google: {
      text: 'payment.rating_banner.google.text',
      icon: 'img:/dashboard/icons/google.png',
      rating: 4.4,
      details: 'payment.rating_banner.google.details'
    }
  };
  ApiService.get<Payment>(RESOURCES.PAYMENT(paymentId))
    .then(({ data }) => {
      console.log('RESPONSE : ', data);
      paymentData.value = paymentToPaymentView(data);
    })
    .catch((e) => {
      console.log('There was an error fetching payment data : ', e);
    })
    .finally(() => {
      loading.value = false;
    });
  function pay() {
    Vue['Router'].push({
      name: 'AdyenPayment',
      params: {
        uuid: paymentData.value.uuid
      }
    });
  }
  return {
    loading,
    paymentData,
    keysOrder,
    ratingBanner,
    customerService,
    pay
  };
}
