import { Notify } from 'quasar';

type NotifyOpts = Parameters<Notify['create']>[0];

const defaultSuccess = {
  type: 'positive',
  color: 'default',
  icon: 'fas fa-circle-check',
  classes: 'success-toast',
  timeout: 0,
  position: 'top'
};
const defaultError = {
  type: 'negative',
  color: 'error',
  icon: 'fas fa-circle-exclamation',
  position: 'top'
};

export function notifyAsync(
  promise: Promise<any>,
  success?: NotifyOpts,
  error?: NotifyOpts,
  successOptions?: any,
  errorOptions?: any
) {
  const finalSuccess = successOptions || defaultSuccess;
  const finalError = errorOptions || defaultError;
  promise
    .then(() => {
      const opts = typeof success === 'string' ? { message: success } : success;
      Notify.create({ ...finalSuccess, ...opts } as NotifyOpts);
    })
    .catch(() => {
      const opts = typeof error === 'string' ? { message: error } : error;
      Notify.create({ ...finalError, ...opts } as NotifyOpts);
    });
}

export function simpleNotification(success?: NotifyOpts, error?: NotifyOpts) {
  if (success) {
    const opts = typeof success === 'string' ? { message: success } : success;
    Notify.create({ ...defaultSuccess, ...opts } as NotifyOpts);
  } else if (error) {
    const opts = typeof error === 'string' ? { message: error } : error;
    Notify.create({ ...defaultError, ...opts } as NotifyOpts);
  }
}
