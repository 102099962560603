





































































import { defineComponent, PropType, computed } from '@vue/composition-api';
import SignCardComponent from './base/SignCard.vue';
import { SignCard, ctaTypes } from '../../../../models';
import SignaturesList from './SignaturesList.vue';
import { Contract } from '@ligo/dashboard/customer/store';
import WrappReminder from '../../../../pages/reminders/WrappReminder.vue';
import { ABtn, ASecondaryBtn } from '../../../base/app/buttons';

export default defineComponent({
  name: 'WrappSignCard',
  components: {
    SignCardComponent,
    SignaturesList,
    WrappReminder,
    ABtn,
    ASecondaryBtn
  },
  props: {
    card: Object as PropType<SignCard>,
    loading: {
      type: Boolean,
      default: false
    },
    contract: {
      type: Object as PropType<Contract>
    }
  },
  setup(props, { emit }) {
    const badge = computed(() => {
      return {
        textColor: props.card.badge?.textColor,
        backgroundColor: props.card.badge?.backgroundColor,
        text: props.card.badge?.text
      };
    });

    function onClick(ctaIndex: number) {
      if (props.card.ctas[ctaIndex].emit) {
        emit(props.card.ctas[ctaIndex].emit);
      } else {
        emit('click');
      }
    }
    return { badge, ctaTypes, onClick };
  }
});
