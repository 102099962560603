export function binarySearch(
  min: number,
  max: number,
  condition: (value: number) => boolean
): number {
  let i = min;
  let j = max;
  let upperBound = max + 1;
  while (i <= j) {
    const mid = Math.floor((i + j) / 2);
    if (condition(mid)) {
      i = mid + 1;
    } else {
      j = mid - 1;
      upperBound = mid;
    }
  }
  if (upperBound > min) return upperBound - 1;
  return -1;
}
