





















































import { PropType, defineComponent, ref } from '@vue/composition-api';
import { PaymentFlowSteps } from '../../models';
import BillingStep from './billing/BillingStep.vue';
import CheckoutStep from './checkout/CheckoutStep.vue';
import EnjoyStep from './modal-components/EnjoyStep.vue';
import { useStore, useRouter } from '../../composables';
import { PaymentService } from '@ligo/dashboard/customer/store';
import { PaymentQuery } from '@ligo/shared/models';

export default defineComponent({
  name: 'PaymentModal',
  components: {
    BillingStep,
    CheckoutStep,
    EnjoyStep
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    paymentUuid: {
      type: String,
      required: true
    },
    modalStep: {
      type: String
    },
    adyenData: {
      type: Object as PropType<PaymentQuery>
    },
    persistent: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const nextDisable = ref(true);
    const store = useStore();
    const router = useRouter();
    const loading = ref(true);
    const payment = ref();
    const title = ref('');
    const currentStep = ref(props.modalStep || PaymentFlowSteps.BILLING);

    const originUrl = router.currentRoute.path;

    async function load() {
      loading.value = true;
      const response = await PaymentService.getById(props.paymentUuid);
      payment.value = response.data;
      loading.value = false;
    }

    load();

    async function onPaymentCompleted() {
      await store.dispatch('authentication/loadUserInfo');
      currentStep.value = PaymentFlowSteps.SUCCESS_PURCHASE;
    }

    function clear() {
      currentStep.value = PaymentFlowSteps.BILLING;
      payment.value = null;
    }

    return {
      clear,
      load,
      title,
      nextDisable,
      currentStep,
      PaymentFlowSteps,
      payment,
      loading,
      originUrl,
      onPaymentCompleted
    };
  }
});
