































import {
  defineComponent,
  getCurrentInstance,
  onMounted
} from '@vue/composition-api';
export default defineComponent({
  name: 'NotificationBanner',
  components: {},
  props: {
    borderColor: {
      type: String,
      default: '#E4E8F1'
    },
    backgroundColor: {
      type: String,
      default: '#DDE5F7'
    },
    title: String,
    titleColor: String,
    subtitle: String,
    subtitleColor: String,
    icon: String
  },
  setup(props) {
    const instance = (getCurrentInstance() as any)._uid;

    onMounted(() => {
      const styleNotificationBanner = (document.querySelector(
        `#notification-banner-${instance}`
      ) as HTMLElement).style;

      styleNotificationBanner.setProperty('--border-color', props.borderColor);
      styleNotificationBanner.setProperty(
        '--background-color',
        props.backgroundColor
      );
    });

    return { instance };
  }
});
