const peopleFieldLayout = [
  'financial_year_end',
  'full_time_work',
  'part_time_work',
  'representation',
  'employees_loan',
  'activities'
];

const activitiesFieldLayout = [
  'offer_type',
  'sell_products',
  'where_are_sold',
  'export_products',
  'import_products'
];

export const extraFieldLayout = ['headquarters'];

export const addressFieldLayout = [
  'current_address_equals_home',
  'office_address',
  'postal_code',
  'place_of_business',
  'notary_city_id',
  'intermediary'
];

export const bvLayout = [
  ['company_name', ...activitiesFieldLayout, ...peopleFieldLayout],
  [...extraFieldLayout, ...addressFieldLayout]
];

export const holdingLayout = [
  ['company_name', 'financial_year_end', ...extraFieldLayout],
  addressFieldLayout
];

export const stichtingLayout = [
  ['company_name', ...peopleFieldLayout, ...extraFieldLayout],
  addressFieldLayout
];

export const defaultFieldLayout = [['company_name'], addressFieldLayout];
