





























































import { PropType } from 'vue';
import { Navigation } from './models/navigation';
import { ApiService } from '@ligo/shared/utils';

export default {
  name: 'UserMenu',
  props: {
    username: String,
    avatar: String,
    links: {
      type: (Array as unknown) as PropType<Navigation>
    }
  },
  setup(_, { root }) {
    async function onGoToLink(external: boolean, path: string, post: boolean) {
      if (post) {
        const response = await ApiService.auth_redirection(path);
        window.location.assign(response.request.responseURL);
      } else if (external) {
        window.location.replace(path);
      } else {
        root.$router.push({ name: path }).catch((e) => e);
      }
    }
    return { onGoToLink };
  }
};
