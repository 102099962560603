import { ApiService, RootContext, useStoreValue } from '@ligo/shared/utils';
import {
  CustomerDashboardState,
  RESOURCES
} from '@ligo/dashboard/customer/store';
import { NotaryProductDocumentKind } from '@ligo/dashboard/store';

import { getDocumentsBy, onDownload } from '../documents.hooks';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useReadDocuments(root: RootContext) {
  const notaryProductId = useStoreValue<string, CustomerDashboardState>(
    root.$store
  )('notaryProduct/getNotaryProductId');
  const { items, loading } = getDocumentsBy(
    notaryProductId,
    {
      kinds: [
        NotaryProductDocumentKind.GENERATED,
        NotaryProductDocumentKind.FIXED,
        NotaryProductDocumentKind.LIGO_UPLOADED
      ]
    },
    true
  );

  function onToggleRead(doc: any, index: number) {
    const status = !doc.status;
    items.value[index]['status'] = status;

    ApiService.patch(
      `${RESOURCES.NOTARY_PRODUCT_DOCUMENT(notaryProductId.value)}/${doc.uuid}`,
      { status }
    );
  }

  return {
    items,
    loading,
    onToggleRead,
    onDownload
  };
}
