












































































































import { EmptyListHandler } from '@ligo/dashboard/customer/store';
import { defineComponent } from '@vue/composition-api';
import { SQTable, SQMainHeader } from '../base';
import { useActiveContracts } from './active-contracts-list';
import { useContractTableConfig } from './contracts-table/contract-table.hook';
import MakeCopyModal from '../../components/documents/contract-preview/modals/MakeCopyModal.vue';
import Panel from './panel/Panel.handler.vue';
import { ASecondaryBtn } from '../base/app/buttons';

const emptyListHandler: EmptyListHandler = {
  title: 'contract.empty_banner.title',
  subtitle: 'contract.empty_banner.subtitle',
  icon: 'img:/dashboard/images/empty_docs.svg',
  avatarSize: '150px',
  iconSize: '120px'
};

export default defineComponent({
  name: 'ActiveContractsList',
  components: {
    Panel,
    MakeCopyModal,
    SQTable,
    SQMainHeader,
    ASecondaryBtn
  },
  props: {
    height: {
      type: String,
      default: '750px'
    },
    mobileHeight: {
      type: String,
      default: '500px'
    }
  },
  setup(_, { root }) {
    const {
      columns,
      rows,
      showMakeCopy,
      showDelete,
      contractToCopy,
      loading,
      pagination,
      filters,
      actions,
      itemsTotal,
      signatureWidget,
      getContracts,
      onItemClicked,
      search,
      onShowDelete,
      onDelete,
      onDownload,
      onContinue,
      onGoToDetails,
      onGoToPayment,
      onShowMakeCopy
    } = useActiveContracts(root);

    const { headers } = useContractTableConfig();

    return {
      columns,
      rows,
      showDelete,
      emptyListHandler,
      headers,
      showMakeCopy,
      contractToCopy,
      loading,
      pagination,
      filters,
      actions,
      itemsTotal,
      signatureWidget,
      getContracts,
      onItemClicked,
      search,
      onShowMakeCopy,
      onShowDelete,
      onDelete,
      onDownload,
      onContinue,
      onGoToDetails,
      onGoToPayment
    };
  }
});
