


















































import { defineComponent, ref, computed, PropType } from '@vue/composition-api';
import { useMembershipInfo } from '../utils/membership-info';
import { useCloseBanner } from '../utils/user-chache-config';
import ASecondaryBtn from '../base/app/buttons/ASecondaryBtn.vue';
import { useStore } from '../../composables';
import { BannerInformation } from './home.hook';

const FREE_CONTRACT_BANNER_KEY = 'user_has_close_free_contract_banner';

export default defineComponent({
  name: 'FreeContractBanner',
  components: { ASecondaryBtn },
  props: {
    information: {
      type: Object as PropType<BannerInformation>,
      required: true
    }
  },
  setup() {
    const store = useStore();
    const username = computed(() => store.getters['authentication/firstname']);
    const { closeBanner, getShowBanner } = useCloseBanner(
      FREE_CONTRACT_BANNER_KEY
    );
    const showBanner = ref(getShowBanner());
    function onCloseBanner() {
      showBanner.value = false;
      closeBanner();
    }
    const {
      planSignatures,
      planTemplates,
      membershipName,
      hasMembership
    } = useMembershipInfo();

    const hasUnlimitedTemplates = computed(() => planTemplates.value != -1);
    const hasUnlimitedSignatures = computed(() => planSignatures.value != -1);

    return {
      showBanner,
      planSignatures,
      planTemplates,
      membershipName,
      hasUnlimitedTemplates,
      hasUnlimitedSignatures,
      username,
      hasMembership,
      onCloseBanner
    };
  }
});
