




import { defineComponent, inject } from '@vue/composition-api';
import { BrandedResetPassword } from '@ligo/shared/auth';
import { brandInformation, brandRouteName } from './auth'
import { RESOURCES } from '@ligo/dashboard/customer/store';

export default defineComponent({
  name: 'ResetPassword',
  components: { BrandedResetPassword },
  setup() {
    const brand: string = inject('brand', 'ligo');
    const brandData = brandInformation(brand)

    return {
      brand,
      brandData,
      brandRouteName,
      base: RESOURCES.AUTH
    };
  }
});
