



































import { defineComponent, PropType } from '@vue/composition-api';
import { Signer } from '../../../../../../models';
import { getSignerColor } from '../../../utils/signer';

export default defineComponent({
  name: 'MenuSelect',
  props: {
    signers: {
      type: Array as PropType<Signer[]>,
      required: true
    },
    value: {
      type: Object as PropType<Signer>
    }
  },
  setup(props) {
    return {
      getSignerColor
    };
  }
});
