export default {
  upload: {
    title: 'Choose the document',
    placeholder: 'Document file',
    send: 'Upload',
    success: 'The document has been successfully uploaded',
    error: 'There has been an error while uploading the document',
    required: 'Document file is required',
    format: 'Invalid document. It should be pdf or word, no greater than 15 MB',
    name: 'Document name'
  },
  new: {
    tittle: 'New document',
    upload: 'Click to upload',
    library: 'Select from Ligo library',
    signed: {
      part1: 'Is your',
      part2: 'already signed?',
      yes: 'Yes',
      no: 'No'
    }
  },
  editor: {
    discardModal: {
      title: 'Discard all unsaved changes',
      subtitle:
        'If you discard your changes, you’ll delete any edits you made since you last saved.',
      buttonContinue: 'Continue Editing',
      buttonDiscard: 'Discard'
    },
    banner: {
      title: 'Edit',
      subtitle: 'Last modified',
      buttonDiscard: 'Cancel',
      buttonSave: 'Save',
      unsaved: 'You have unsaved changes'
    },
    loader: {
      loadingEditor: 'Loading Editor. Please wait...',
      loadingDocument: 'Loading Document. Please wait...'
    }
  },
  add_document: {
    title: 'Create a new document or upload an existing one',
    new_document: 'Create new document',
    upload_document: 'Upload existing document'
  },
  free_banner: {
    hello: 'Welcome to Ligo,',
    title1: 'You are subscribed to the ',
    title2: '  plan which includes: | account which includes: ',
    item1:
      'Unlimited contracts from the library | {count} contract from the library | {count} contracts from the library',
    item2:
      'Unlimited legally binding e-signatures | {count} legally binding e-signature  | {count} legally binding e-signatures',
    item3: 'Unlimited safe storage of legal documents',
    footer:
      'If you want to make any changes to your subscription, you can visit your',
    footer_link: 'account settings',
    ok: 'Got it'
  },
  last_updated: 'Last updated on {date}'
};
