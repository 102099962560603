import {
  ResponseDialogModel,
  ResponseType,
  PaymentObject
} from '@ligo/dashboard/customer/store';
import { RootContext } from '@ligo/shared/utils';
import { ref, Ref, watch } from '@vue/composition-api';

export function useResetUrl(root: RootContext) {
  root.$router.replace(root.$route.path);
}

export function usePaymentResponse(root: RootContext) {
  function getText(
    productType: string,
    bodyParam: Ref<string>,
    customTitle = null
  ) {
    const prefix = `payment_messages.${productType}`;
    return {
      title: customTitle
        ? root.$t(customTitle, { productName: bodyParam.value })
        : root.$t(`${prefix}.title`, { productName: bodyParam.value }),
      message: root.$t(`${prefix}.description`),
      btnLabel: root.$t(`${prefix}.btn_label`)
    };
  }

  function AcceptNp(
    titleParam: Ref<string>,
    customTitle = null
  ): ResponseDialogModel {
    return {
      ...getText('notary_product', titleParam, customTitle),
      image: '/dashboard/images/day_rocket.svg',
      bodyPadding: 'sm'
    };
  }
  function AcceptedMember(titleParam: Ref<string>): ResponseDialogModel {
    return {
      ...getText('membership', titleParam),
      image: '/dashboard/images/day_rocket.svg',
      bodyPadding: 'sm'
    };
  }
  function AcceptedContract(bodyParam: Ref<string>): ResponseDialogModel {
    const textData = getText('contract', bodyParam);
    return {
      message: textData.title,
      btnLabel: textData.btnLabel,
      image: '/dashboard/images/free_contract.svg'
    };
  }
  const RejectedPayment: ResponseDialogModel = {
    title: root.$t('payment_messages.all_fails.title'),
    message: root.$t('payment_messages.all_fails.description'),
    image: '/dashboard/images/night_rocket.svg'
  };

  return {
    AcceptedMember,
    AcceptNp,
    RejectedPayment,
    AcceptedContract
  };
}

export function useBuildPaymentDialog(
  root: RootContext,
  paymentRespnse: string,
  paidObjName: Ref<string>,
  paidObj: PaymentObject,
  sameDialog,
  customTitle = null
) {
  const paymentDialog = ref<ResponseDialogModel>();
  const showErrorDialog = ref(false);
  const showSuccessDialog = ref(false);
  const showDialog = ref(false);
  if (paymentRespnse) {
    watch(paidObjName, () => {
      const {
        AcceptedContract,
        AcceptedMember,
        AcceptNp,
        RejectedPayment
      } = usePaymentResponse(root);

      const getKindOfPayment = () => {
        switch (paidObj) {
          case PaymentObject.CONTRACT:
            return AcceptedContract(ref(paidObjName.value));
          case PaymentObject.MEMBERSHIP:
            return AcceptedMember(ref(paidObjName.value));
          default:
            return AcceptNp(ref(paidObjName.value), customTitle.value);
        }
      };

      if (paymentRespnse === ResponseType.SUCCESS) {
        paymentDialog.value = getKindOfPayment();
        sameDialog
          ? (showDialog.value = true)
          : (showSuccessDialog.value = true);
      } else if (paymentRespnse === ResponseType.ERROR) {
        paymentDialog.value = RejectedPayment;
        sameDialog ? (showDialog.value = true) : (showErrorDialog.value = true);
      }
      useResetUrl(root);
    });
  }

  return {
    paymentDialog,
    showErrorDialog,
    showSuccessDialog,
    showDialog
  };
}
