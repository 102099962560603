
























import { computed, defineComponent } from '@vue/composition-api';
import { useStore } from '../../../composables';
import { useMembershipInfo } from '../../utils/membership-info';
import { APrimaryBtn } from '../../base/app/buttons';
import StepHeader from '../modal-components/StepHeader.vue';
import NeedHelp from '../modal-components/NeedHelp.vue';

export default defineComponent({
  name: 'SuccessStep',
  components: { StepHeader, NeedHelp, APrimaryBtn },
  setup() {
    const store = useStore();
    const username = computed(() => store.getters['authentication/username']);
    const { membershipName, nextBillingDate } = useMembershipInfo();
    return { username, membershipName, nextBillingDate };
  }
});
