import { ApiService } from '@ligo/shared/utils';
import { EXTERNAL_RESOURCES } from '../resources';

export const WordEditorService = {
  async sfdtToDocx(file) {
    const formData: FormData = new FormData();
    formData.append('files', file);
    const data = await ApiService.post(
      EXTERNAL_RESOURCES.WORD_EDITOR_SERVICE + '/Import',
      formData,
      true
    );
    return data.data;
  }
};
