









































































import { defineComponent, PropType } from '@vue/composition-api';
import { BasicYouTubeVideo } from '@ligo/shared/components';
import APrimaryBtn from '../app/buttons/APrimaryBtn.vue';

interface Service {
  text: string;
  icon?: string;
}

interface Award {
  img: string;
  text: string;
}

export default defineComponent({
  name: 'HeroBanner',
  components: { BasicYouTubeVideo, APrimaryBtn },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    videoUrl: {
      type: String
    },
    img: {
      type: String
    },
    features: { type: Array as PropType<Array<Service>> },
    awards: { type: Array as PropType<Array<Award>> },
    cta: {
      type: String
    }
  },
  setup() {
    return {};
  }
});
