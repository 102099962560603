











































import { defineComponent } from '@vue/composition-api';
import {
  APrimaryBtn,
  ASecondaryBtn
} from 'libs/dashboard/customer/components/src/lib/components/base/app/buttons';

export default defineComponent({
  name: 'ConfirmationDialog',
  components: {
    APrimaryBtn,
    ASecondaryBtn
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    message: {
      type: String
    },
    confirmMsg: {
      type: String,
      default: 'yes'
    },
    cancelMsg: {
      type: String,
      default: 'no'
    },
    topImage: {
      type: String,
      required: false
    },
    isPersistent: {
      type: Boolean,
      required: false
    },
    btnFixedSize: {
      type: String,
      required: false
    }
  },
  setup(props, { emit }) {
    const cancel = () => {
      emit('cancel');
      closeDialog();
    };
    const closeDialog = () => {
      emit('exit');
      emit('input', false);
    };
    const confirm = () => {
      emit('confirm');
      emit('input', false);
    };
    return { closeDialog, confirm, cancel };
  }
});
