import { IVueI18n } from 'vue-i18n';
import { baseText } from './baseNotaryStepText';
import { Notary } from '@ligo/dashboard/store';
import { Dictionary, accessWithDot, nully } from '@ligo/shared/utils';

const AVAILABLE_LOCALES = {
  en: 'english_information',
  nl: 'dutch_information'
};

export const useDynamicText = (i18n: IVueI18n, notary: Notary) => {
  const baseTextObj = baseText;

  const notaryTextObj = {
    en: notary ? notary[AVAILABLE_LOCALES.en] || {} : {},
    nl: notary ? notary[AVAILABLE_LOCALES.nl] || {} : {}
  };

  const getLocale = () => (i18n.locale == 'en-us' ? 'en' : 'nl');

  const nte = (text: string) => {
    const locale = getLocale();
    const result = accessWithDot(notaryTextObj[locale], text);
    return !nully(result);
  };

  const nt = (text: string, context: Dictionary = {}) => {
    if (nte(text)) {
      const locale = getLocale();
      let result: string = accessWithDot(notaryTextObj[locale], text);
      if (context) {
        Object.entries(context).map((entry) => {
          const [key, value] = entry;
          result = result.replace(`{${key}}`, value);
        });
        return result;
      }
      return result;
    }
    const key = accessWithDot(baseTextObj, text);
    if (typeof key !== 'string') return key;
    if (context) return i18n.t(key, context);
    return i18n.t(key);
  };

  return {
    nt
  };
};
