import { ActionItem, CompanyService } from '@ligo/dashboard/customer/store';
import { Company } from '@ligo/dashboard/store';
import { usePaginated } from '@ligo/shared/utils';
import { computed, watch } from '@vue/composition-api';
import { AxiosResponse } from 'axios';
import { useRouter, useStore } from '../composables';

export const DEFAULT_PER_PAGE = 30;

export function useCompanies() {
  const store = useStore();
  const router = useRouter();

  const selectedCompany = computed(() => store.getters['company/getCompanyId']);

  function afterLoadCompanies(data: AxiosResponse<Company[]>) {
    store.dispatch('company/setCompaniesAmount', data.data.length);
    return data;
  }

  const { items, onNextPage, loading, load } = usePaginated(
    (params) => CompanyService.list(params).then(afterLoadCompanies),
    DEFAULT_PER_PAGE
  );

  const setCompany = async (availableCompanies: Company[]) => {
    // If there is not selected company, or the selected one doesn't belong to the current user, the first Company in the list is selected.
    // Else if the selected company exists but is not in the current list (because is in another page), the company is added to the companies options (for the selector).
    let company: Company;
    if (selectedCompany.value) {
      try {
        const response = await CompanyService.getById(selectedCompany.value);
        company = response.data;
      } catch (e) {
        console.log('The company saved does not exists in the current user');
      }
    }

    if (
      (!selectedCompany.value || !company) &&
      availableCompanies.length &&
      availableCompanies[0].uuid
    ) {
      await store.dispatch('company/select', availableCompanies[0].uuid);
    } else if (
      company &&
      items.value.findIndex((comp) => comp.uuid == company.uuid) == -1
    ) {
      items.value.push(company);
    }
  };

  watch(items, (newItems) => {
    setCompany(newItems);
  });

  const companies = computed(() => {
    const itemsWithoutDuplicates: Company[] = [];
    items.value.forEach((company) => {
      if (!itemsWithoutDuplicates.find((comp) => comp.uuid == company.uuid)) {
        itemsWithoutDuplicates.push(company);
      }
    });
    return itemsWithoutDuplicates;
  });

  const onSelectCompany = async (uuid: string): Promise<void> => {
    await store.dispatch('company/select', uuid);
    await router.push({ name: 'Home' }).catch((e) => e);
    router.go(undefined);
  };

  const company = computed(() => {
    return companies.value.find((comp) => comp.uuid == selectedCompany.value);
  });

  return {
    list: companies,
    loading,
    load,
    selectedCompany,
    onSelectCompany,
    onScroll: onNextPage,
    company
  };
}
