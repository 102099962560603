





































































import { defineComponent } from '@vue/composition-api';
import { useServiceTypes } from './service-types.hook';
import ASecondaryBtn from '../base/app/buttons/ASecondaryBtn.vue';
import APrimaryBtn from '../base/app/buttons/APrimaryBtn.vue';
import { SQMainHeader } from '../../components/base';
import { ExtraServiceService } from '@ligo/dashboard/customer/store';
import { useRouter } from '../../composables';

export default defineComponent({
  name: 'ServicesTypesHandler',
  components: { APrimaryBtn, ASecondaryBtn, SQMainHeader },
  setup() {
    const router = useRouter();

    const {
      serviceTypes,
      loadingServiceTypes,
      features,
      trackServiceTypeAction,
      loadServiceTypes
    } = useServiceTypes();

    loadServiceTypes();

    function onGoToPayment(service) {
      trackServiceTypeAction('extra_service_getservice', service);
      ExtraServiceService.create(service.slug, router.currentRoute);
    }

    function onGoToDetails(service) {
      trackServiceTypeAction('extra_service_learnmore', service);
      router.push({
        name: 'ServiceTypeDetails',
        params: { slug: service.slug }
      });
    }

    function truncate(price) {
      return Math.trunc(price);
    }

    return {
      loadingServiceTypes,
      serviceTypes,
      truncate,
      features,
      onGoToDetails,
      onGoToPayment
    };
  }
});
