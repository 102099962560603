













import { defineComponent, ref } from '@vue/composition-api';
import PaymentsHandler from '../../components/account/Payments.handler.vue';
import BasePage from '../../components/base/BasePage.vue';
import UserPageSelector from './UserPageSelector.vue';
import PlanSection from '../../components/account/billing-and-payment/PlanSection.vue';
import BillingSection from '../../components/account/billing-and-payment/BillingSection.vue';
import CancelModal from '../../components/payment/cancel-modal/CancelModal.vue';

export default defineComponent({
  components: {
    PaymentsHandler,
    BasePage,
    UserPageSelector,
    PlanSection,
    BillingSection,
    CancelModal
  },
  setup() {
    const showCancelModal = ref(false);

    return { showCancelModal };
  }
});
