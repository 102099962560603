














import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'Phone',
  props: {
    message: String,
    phone: {
      type: String,
      default: '020 303 1043'
    },
    padding: {
      type: String,
      default: 'sm'
    }
  },
  setup(props) {
    function onClickPhone() {
      window.open('tel:' + props.phone, '_self');
    }
    return { onClickPhone };
  }
});
