







































































import { defineComponent } from '@vue/composition-api';
import { useMembershipInfo } from '../../utils/membership-info';
import { APrimaryBtn, ASecondaryBtn } from '../../base/app/buttons';

export default defineComponent({
  name: 'PlanSection',
  components: { APrimaryBtn, ASecondaryBtn },
  setup() {
    const {
      membershipName,
      hasMembership,
      nextBillingDate,
      downgradeDate
    } = useMembershipInfo();

    const title = 'account.plan.title';
    return {
      membershipName,
      hasMembership,
      downgradeDate,
      nextBillingDate,
      title
    };
  }
});
