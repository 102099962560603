








































































































import {
  defineComponent,
  PropType,
  computed,
  ref,
  watch
} from '@vue/composition-api';
import { Signer, fieldTypeIcons } from '../../../../../models';
import { SignerInputField, SignerBox } from './base';
import { getUser } from '../../utils/user';
import { getSignerColor } from '../../utils/signer';

const i18nContent = {
  title: 'e_signature.prepare_document.board.title',
  click_field_hint: 'e_signature.prepare_document.board.click_field_hint',
  no_fields: {
    paragraph_1: 'e_signature.prepare_document.board.no_fields.paragraph_1',
    paragraph_2: 'e_signature.prepare_document.board.no_fields.paragraph_2',
    message: 'e_signature.prepare_document.board.no_fields.message'
  },
  fields: {
    description: 'e_signature.prepare_document.board.fields.description',
    edit_signer: 'e_signature.prepare_document.board.fields.edit_signer',
    field: {
      types: {
        signature: 'e_signature.prepare_document.board.fields.types.signature',
        text: 'e_signature.prepare_document.board.fields.types.text',
        date: 'e_signature.prepare_document.board.fields.types.date',
        checkbox: 'e_signature.prepare_document.board.fields.types.checkbox'
      }
    },
    add_myself: 'e_signature.prepare_document.board.fields.add_myself',
    add_new_signer: 'e_signature.prepare_document.board.signers.add'
  },
  mail_placeholder:
    'e_signature.prepare_document.board.signers.mail_placeholder'
};

export default defineComponent({
  name: 'AddFieldsBoard',
  components: {
    SignerInputField,
    SignerBox
  },
  props: {
    title: String,
    subtitle: String,
    contractUuid: {
      type: String,
      required: true
    },
    signers: {
      type: Array as PropType<Signer[]>,
      default: () => [] as Signer[]
    }
  },
  setup(props, { emit }) {
    const newSigners = ref(props.signers.length ? [] : [{ email: '' }]);
    const atLeastOneField = computed(() => {
      return props.signers.some((signer) => signer.fields.length);
    });

    const showAddMyself = computed(() => {
      const { user } = getUser();
      return !props.signers.some((signer) => signer.email === user.value.email);
    });

    function removeWhenSignersNotEmpty(index: number) {
      const promise = new Promise((resolve) => {
        watch(
          () => props.signers,
          (value) => {
            if (value.length > 0) {
              resolve(0);
            }
          },
          { immediate: true }
        );
      });
      promise.then(() => {
        newSigners.value.splice(index, 1);
      });
    }

    function addSigner(newEmail: string) {
      emit('on-add-signer', newEmail);
    }

    function onSave(newEmail: string, index: number) {
      if (newEmail) {
        addSigner(newEmail);
        removeWhenSignersNotEmpty(index);
      }
    }

    function onRemoveSigner(index: number) {
      newSigners.value.splice(index, 1);
    }

    function addEmptyField() {
      newSigners.value.push({ email: '' });
    }

    function pendingFields() {
      return newSigners.value.length;
    }

    function addMySelf(index: number) {
      emit('add-myself');
      removeWhenSignersNotEmpty(index);
    }

    watch(
      () => [...props.signers, ...newSigners.value],
      (value) => {
        if (value.length === 0) {
          newSigners.value.push({ email: '' });
        }
      }
    );

    return {
      focus,
      newSigners,
      atLeastOneField,
      i18nContent,
      fieldTypeIcons,
      showAddMyself,
      addMySelf,
      addSigner,
      getSignerColor,
      onSave,
      onRemoveSigner,
      addEmptyField,
      pendingFields
    };
  }
});
