




























import { defineComponent } from '@vue/composition-api';
import ABtn from '../../../components/base/app/buttons/ABtn.vue';

const IMAGE = '/dashboard/icons/memo_group.svg';

export default defineComponent({
  name: 'PaidTaskBanner',
  components: { ABtn },
  setup() {
    return {
      IMAGE
    };
  }
});
