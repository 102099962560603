




























































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ImageSideDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    btnLabel: {
      type: String
    },
    width: {
      type: String,
      default: '700px'
    },
    bodyPadding: {
      type: String,
      default: 'none'
    }
  },
  setup(_, { emit }) {
    function onCloseDialog() {
      emit('on-close');
    }

    function onDialogAction() {
      emit('on-confirm');
    }

    return { onCloseDialog, onDialogAction };
  }
});
