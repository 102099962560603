





















import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'BusyNotary',
  props: {
    text: {
      type: String,
      default: 'read_docs.busy_notary_banner'
    },
    useI18n: {
      type: Boolean,
      default: true
    }
  }
});
