





















































































































































import { defineComponent, ref, computed, PropType } from '@vue/composition-api';
import {
  ConfirmationDialog,
  RequestInfoDialog
} from '@ligo/dashboard/components';
import { useDeedDocument } from './deed-document.hook';
import {
  Notary,
  NotaryProductDocumentAcceptance,
  NotaryProductDocumentKind,
  NotaryProductDocumentType
} from '@ligo/dashboard/store';
import DocumentNameClickable from '../../global/DocumentNameClickable.vue';
import DocumentsFolder from '../DocumentsFolder.vue';
import { CustomerDashboardState } from '@ligo/dashboard/customer/store';
import { useStoreValue } from '@ligo/shared/utils';
import { useI18n, useStore } from '../../../composables';
import ExpansionCard from '../../global/ExpansionCard.vue';
import { useDynamicText } from '../hooks/dynamicText.hooks';
import { APrimaryBtn, ASecondaryBtn } from '../../base/app/buttons';
import { getLastUpdatedDocument, getDocumentsBy } from '../documents.hooks';

const document_types = [
  NotaryProductDocumentType.REGULAR,
  NotaryProductDocumentType.DRAFT_DEED
];
const document_kinds = [
  NotaryProductDocumentKind.USER_UPLOADED,
  NotaryProductDocumentKind.NOTARY_UPLOADED,
  NotaryProductDocumentKind.LIGO_UPLOADED
];

export default defineComponent({
  name: 'DeedDocumentsHandler',
  components: {
    ConfirmationDialog,
    RequestInfoDialog,
    DocumentNameClickable,
    DocumentsFolder,
    ExpansionCard,
    APrimaryBtn,
    ASecondaryBtn
  },
  props: {
    notaryProductUuid: {
      type: String,
      required: true
    },
    notary: {
      type: Object as PropType<Notary>
    }
  },
  setup(props, { root, emit }) {
    emit('stop-loading');
    const selectedDoc = ref('');
    const requestDialog = ref(false);
    const showRequestDialog = (uuid: string) => {
      selectedDoc.value = uuid;
      requestDialog.value = true;
    };
    const doneConfirmDialog = ref(false);
    const showDoneConfirmDialog = (uuid: string) => {
      selectedDoc.value = uuid;
      doneConfirmDialog.value = true;
    };
    const {
      existsDeed,
      loading,
      items,
      cancellingAmendment,
      onAmmendRequest,
      onApproveDeed,
      onDownload,
      onGlobalApproval,
      onCancelAmendment
    } = useDeedDocument(root, emit);

    const store = useStore();

    const notaryProductId = useStoreValue<string, CustomerDashboardState>(
      store
    )('notaryProduct/getNotaryProductId');

    const {
      items: folderDocuments,
      load: folderDocumentsLoad
    } = getDocumentsBy(notaryProductId, {
      kinds: document_kinds ?? [],
      document_types: document_types ?? []
    });

    const lastUpdatedFolderDocs = computed(() => {
      if (folderDocuments.value && folderDocuments.value.length) {
        return getLastUpdatedDocument(folderDocuments.value);
      }
      return '';
    });

    const globalApproval = computed(
      () =>
        items.value.length &&
        items.value.every(
          (item) => item.acceptance == NotaryProductDocumentAcceptance.ACCEPTED
        )
    );

    const i18n = useI18n();
    const { nt } = useDynamicText(i18n, props.notary);

    return {
      items,
      existsDeed,
      loading,
      doneConfirmDialog,
      requestDialog,
      selectedDoc,
      NotaryProductDocumentAcceptance,
      globalApproval,
      document_types,
      document_kinds,
      folderDocuments,
      folderDocumentsLoad,
      lastUpdatedFolderDocs,
      notaryProductId,
      cancellingAmendment,
      nt,
      onApproveDeed,
      onAmmendRequest,
      showRequestDialog,
      showDoneConfirmDialog,
      onDownload,
      onGlobalApproval,
      onCancelAmendment
    };
  }
});
