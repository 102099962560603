













import { Contract } from '@ligo/dashboard/customer/store';
import { defineComponent, PropType, computed } from '@vue/composition-api';
import { useGetContract } from './drawer-definitions/get-contract.hook';
import { useSignContract } from './drawer-definitions/sign-contract.hook';
import { WrappSignCard } from './sign-card';

export default defineComponent({
  name: 'ContractDrawer',
  components: { WrappSignCard },
  props: {
    contract: {
      type: Object as PropType<Contract>
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const contractObject = computed(() => props.contract);

    const { card: getContractCard } = useGetContract(props.contract);
    const { card: signContractCard } = useSignContract(contractObject);

    return { getContractCard, signContractCard };
  }
});
