import {
  getLabelForStringOption,
  getLabelOptionalForStringOption,
  transformStringCheckboxOptions,
  transformStringOptions
} from '../base/SimpleForm/hooks/utils';
import { SimpleField } from '../base/SimpleForm/models/simpleForm.models';
import {
  companyStructureOptions,
  employeeCountOptions,
  incorporatedTimeOptions,
  industryTypeOptions,
  useLigoOptions
} from './onboardingOptions.hooks';

export const onboardingFields = (
  t: (key: string) => string,
  te: (key: string) => boolean
): SimpleField[] => [
  {
    key: 'company_structure',
    type: 'select',
    rules: ['required'],
    selectable: {
      useCheckbox: true,
      label: (value) =>
        getLabelForStringOption('form', 'company_structure', value, t),
      options: () => transformStringOptions(companyStructureOptions)
    }
  },
  {
    key: 'company_name',
    type: 'text',
    rules: ['required']
  },
  {
    key: 'industry',
    type: 'select',
    rules: ['required'],
    selectable: {
      label: (value) => getLabelForStringOption('form', 'industry', value, t),
      options: () => transformStringOptions(industryTypeOptions)
    }
  },
  {
    key: 'employee_count',
    type: 'select',
    selectable: {
      label: (value) =>
        getLabelForStringOption('form', 'employee_count', value, t),
      options: () => transformStringOptions(employeeCountOptions)
    },
    rules: ['required']
  },
  {
    key: 'incorporated_time',
    rules: ['required'],
    type: 'select',
    selectable: {
      label: (value) =>
        getLabelOptionalForStringOption(
          'form',
          'incorporated_time',
          value,
          t,
          te
        ),
      options: () => transformStringOptions(incorporatedTimeOptions)
    }
  },
  {
    key: 'use_ligo',
    type: 'select',
    selectable: {
      multiple: true,
      useCheckbox: true,
      options: () =>
        transformStringCheckboxOptions(useLigoOptions, 'form', 'use_ligo', t)
    }
  },
  {
    key: 'preferred_language',
    type: 'language',

    transform: (value) => {
      if (value == 'en-us') return 'en';
      return value;
    }
  }
];
