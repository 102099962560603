export default {
  step: 'Step of the questionnaire',
  minutes: '10 minutes ago',
  on_delete_dialog: {
    title: 'Are you sure you want to delete this service?',
    subtitle:
      'This action can not be undone and you will lose all your improvement',
    delete_cta: 'Delete'
  },
  on_delete:
    'Are you sure you want to delete this service? This action can not be undone and you will lose all your improvement',
  add_new_service: 'Add new Service',
  payment: 'Payment',
  finished: 'finished',
  state: {
    extra_questions: 'Extra questions',
    read_upload: 'Read & Upload documents',
    check_doc: "Documents' check",
    deed: 'Final deed check',
    meeting: 'Meeting with notary',
    finished: 'Finished'
  },
  in_progress: 'In progress',
  to_do: 'To do',
  completed: 'Completed',
  no_data: 'There are no documents to show',
  document_file: 'Document file',
  document_file_required: 'Document file is required',
  sent_success: 'The documents has been successfully sent to the notary',
  sent_error: 'There has been an error while sending the documents'
};
