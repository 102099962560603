import { ref, watch } from '@vue/composition-api';
import { useStoreValue } from '@ligo/shared/utils';
import Vue from 'vue';

export function getUser() {
  const loading = ref(true);
  const user = useStoreValue<{
    email: string;
  }>(Vue['Store'])('authentication/user');
  watch(
    user,
    (newVal) => {
      if (newVal) {
        loading.value = false;
      }
    },
    { immediate: true }
  );
  return { user, loading };
}
