import moment from 'moment';

export function timeSince(date: Date) {
  const cur_date = new Date(date);
  const seconds = Math.floor(
    (new Date().getTime() - cur_date.getTime()) / 1000
  );
  const timeline = [
    { time: 31536000, label: 'years_ago' },
    { time: 2592000, label: 'months_ago' },
    { time: 86400, label: 'days_ago' },
    { time: 3600, label: 'hours_ago' },
    { time: 60, label: 'minutes_ago' },
    { time: 1, label: 'seconds_ago' }
  ];
  let res: { integer: string; label: string };
  for (let i = 0; i < timeline.length; ++i) {
    const x = timeline[i];
    const interval = seconds / x.time;
    if (interval >= 1) {
      res = { integer: `${Math.floor(interval)} `, label: x.label };
      break;
    }
  }
  return res;
}

export function stringToDateAndTime(date: string) {
  return moment(date).format('DD/MM/YYYY | hh:mm');
}

export function incrementYear(currentDate: string, increment = 1) {
  const date = new Date(currentDate);
  date.setFullYear(date.getFullYear() + increment);
  return date;
}

export function incrementMonth(currentDate: string, increment = 1) {
  const date = new Date(currentDate);
  date.setMonth(date.getMonth() + increment);
  return date;
}

export function incrementWeek(currentDate: string, increment = 1) {
  const date = new Date(currentDate);
  date.setDate(date.getDate() + increment * 7);
  return date;
}
