import { Module } from 'vuex'
import { CustomerDashboardState } from '../state'
import state, { LayoutState } from "./state"
import getters from './getters'
import mutations from './mutations'
import actions from './actions'


export const layoutStore: Module<
  LayoutState,
  CustomerDashboardState
> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}