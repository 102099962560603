export default {
  lawyers: {
    title: 'Meet the lawyers on our platform',
    view: 'view profile',
    placeholder: 'Lawyer'
  },
  how_it_works: {
    title: 'How it works',
    card_1_title: 'Submit your request',
    card_1_text:
      'Tell us about your case and indicate the legal are to what it refers',
    card_2_title: 'Discuss your legal matter',
    card_2_text:
      'We will pre-select a list of attorneys that are the best for your case',
    card_3_title: 'Chose your attorney',
    card_3_text: 'Chose the attorney you want to work with and get things done'
  },
  actions: {
    consult: 'Request a phone consultation',
    quote: 'Request a free quote',
    doc_review: 'Request a document review'
  },
  consult: {
    step_1: 'Send your question to the <br /> lawyers',
    step_2: 'Receive an invitation for <br /> consultation within 48hs',
    step_3: 'Join the call and get answers <br /> to your questions',
    form: {
      title: 'Ask your question',
      text_placeholder: 'Write your question',
      text_required: 'Please write your question',
      max_length: 'Please ask a question of less than 1000 characters',
      selection_required: 'Please select at least one option',
      option_title: 'Choose which subjects match your question',
      option_1: 'Labor',
      option_2: 'Company',
      option_3: 'Fiscal',
      option_4: 'Financial',
      option_5: 'Intellectual property',
      submit: 'Submit your question',
      request_success: 'Your question have been submitted successfully',
      request_error: 'There has been an error while submitting your question'
    },
    kinds: {
      title: 'What kind of questions can you ask (Examples)?',
      establishment: {
        title: 'Establishment',
        example_1:
          '“My BV has not yet been fully established, but I have already incurred costs. Am I personally liable for the costs incurred and can I declare these costs to the BV?"',
        example_2:
          '“I want to leave the VOF that I set up together with my business partner and then set up my own BV. Can I take customers from the VOF to the BV?"',
        example_3:
          '“I currently have a sole proprietorship, but given its growth I would like to convert the sole proprietorship into a BV. What is involved?"'
      },
      staff: {
        title: 'Staff',
        example_1:
          '"I am dissatisfied with my employee\'s performance. When can I proceed with dismissal?"',
        example_2:
          '"I recently set up a BV and I now want to hire employees. What insurance do I need for that?"',
        example_3:
          '"An ex-employee has recently started working for a competitor, while his employment contract includes a non-competition clause. What can I do about this?"'
      },
      collection: {
        title: 'Collection',
        example_1:
          '"I have a customer who does not want to pay his outstanding invoices. What can I do to still get my money?"',
        example_2:
          '"The goods I ordered for my company have not been delivered even though I have already paid. I want to cancel the agreement and get my money back. How can I best go about this?"',
        example_3:
          '"My company is in financial trouble. As a result, I have outstanding invoices that I cannot pay. Can my company be deferred?"'
      },
      privacy: {
        title: 'Privacy',
        example_1:
          '"My company will soon have to comply with the requirements of the GDPR. How will this affect the personal data I have already collected in the past?"',
        example_2:
          '"My company\'s computers were recently stolen. Should I notify my customers of a potential data breach?"',
        example_3:
          '"I have concluded a processing agreement with a third party in the past, but I now want to terminate the agreement. Is this possible?"'
      },
      trademark: {
        title: 'Trademark protection',
        example_1:
          '"Recently, a company with almost the same trade name has established itself in the street where my company is also located. This is causing confusion among the customers. What can I do about this?"',
        example_2:
          '"I believe I have invented a new product. I would like to protect this product so that others cannot enjoy my invention. How can I enjoy these protections?"',
        example_3:
          '"Photos that I have published on my website have been taken over by another website without my permission. What legal action can I take against this?"'
      },
      other: {
        title: 'Other',
        example_1:
          '"My BV is located in the Netherlands, but mainly focuses on providing services abroad. In which country does my BV have to pay tax?"',
        example_2:
          '"I would like to use a mystery shopper to know how my competitors operate. Can I do this?"',
        example_3:
          '"I expect that I will not finish my annual accounts on time. What are the consequences?"'
      }
    }
  }
};
