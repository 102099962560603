










import { defineComponent, PropType } from '@vue/composition-api';
import { PaymentQuery } from '@ligo/shared/models';
import { RESOURCES } from '@ligo/dashboard/customer/store';
import BasePage from '../../components/base/BasePage.vue';

export default defineComponent({
  name: 'AdyenPayment',
  components: { BasePage },
  props: {
    uuid: {
      type: String,
      required: true
    },
    originUrl: {
      type: String,
      required: true
    },
    query: Object as PropType<PaymentQuery>
  },
  setup() {
    return { RESOURCES };
  }
});
