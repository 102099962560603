import { LegalCheckupsService } from '@ligo/dashboard/customer/store';
import { ref } from '@vue/composition-api';

export function useLegalCheckup() {
  const checkups = ref();

  async function load() {
    const legalCheckupsResponse = await LegalCheckupsService.list();
    const legalCheckupTypesResponse = await LegalCheckupsService.listTypes();
    checkups.value = legalCheckupsResponse.data;
    return {
      checkups: legalCheckupsResponse.data,
      checkupTypes: legalCheckupTypesResponse.data.filter(
        (checkupType) => checkupType.slug != 'calculator'
      )
    };
  }

  function getLastCheckupFromType(slug: string) {
    return checkups.value
      .filter((item) => item.legal_checkup_type.slug === slug)
      .sort()[0];
  }

  return { load, getLastCheckupFromType };
}
