import { ActionTableHeader } from '../../../models/global/action-table';
import { useI18n } from '../../../composables';

export function useContractTableConfig() {
  const i18n = useI18n();

  const actionsBtn = {
    icon: 'img:/dashboard/icons/three_dots_circle.svg'
  };

  const headers: ActionTableHeader[] = [
    {
      title: i18n.t('contract.actives.table.name'),
      width: '50%'
    },
    {
      title: i18n.t('contract.actives.table.status'),
      width: '20%'
    },
    {
      title: i18n.t('contract.actives.table.last_modified'),
      width: '20%'
    },
    {
      title: '',
      width: '10%'
    }
  ];

  const statusBadge = {
    editing: {
      text: 'subdued',
      background: 'grey-light'
    },
    sent_for_signing: {
      text: 'yellow',
      background: 'yellow-light'
    },
    active: {
      text: 'green',
      background: 'green-light'
    },
    declined: {
      text: 'red',
      background: 'red-light'
    }
  };

  return { actionsBtn, headers, statusBadge };
}
