



































import { defineComponent, PropType, computed } from '@vue/composition-api';
import { useBuildPaymentDialog } from '../../components/payment-response.hook';
import { ImageSideDialog } from '@ligo/dashboard/components';
import DashboardDialog from 'libs/dashboard/components/src/lib/dialogs/DashboardDialog.vue';

import { Contract, PaymentObject } from '@ligo/dashboard/customer/store';

export default defineComponent({
  name: 'ContractPaymentMessage',
  components: {
    ImageSideDialog,
    DashboardDialog
  },
  props: {
    uuid: {
      type: String,
      required: true
    },
    dialog: {
      type: String
    },
    contract: {
      type: Object as PropType<Contract>
    }
  },
  setup(props, { root }) {
    const contractReady = computed(() => props.contract?.name);

    const {
      showErrorDialog,
      showSuccessDialog,
      paymentDialog
    } = useBuildPaymentDialog(
      root,
      props.dialog,
      contractReady,
      PaymentObject.CONTRACT,
      false
    );

    return {
      showErrorDialog,
      showSuccessDialog,
      paymentDialog
    };
  }
});
