














import {
  computed,
  ComputedRef,
  defineComponent,
  inject
} from '@vue/composition-api';
import {
  socialAuth,
  providerLinks,
  brandRouteName,
  brandInformation,
  brandSocialBtn
} from './auth';
import { BrandedRegister } from '@ligo/shared/auth';
import { AuthError } from '@ligo/bv-flow/store';
import { useInit } from '@ligo/dashboard/customer/components';
import {
  ContractService,
  ExtraServiceService,
  MembershipService
} from '@ligo/dashboard/customer/store';
import { useRouter } from '../../composables';

export default defineComponent({
  name: 'Register',
  components: { BrandedRegister },
  props: {
    redirectUrl: String,
    legalCheckupUuid: String,
    contractUuid: String,
    extraServiceTypeSlug: String,
    planId: String
  },
  setup(props, { root }) {
    const router = useRouter();
    const loginError: ComputedRef<AuthError> = computed(() => {
      return root.$store.state.authentication.authenticationError;
    });
    const { init } = useInit(root);

    if (props.contractUuid) {
      ContractService.saveSelection(props.contractUuid);
    }
    if (props.extraServiceTypeSlug) {
      ExtraServiceService.saveSelection(props.extraServiceTypeSlug);
    }

    if (props.planId) {
      MembershipService.saveSelection(props.planId);
    }

    function onRegister(data) {
      const payload = props.legalCheckupUuid
        ? { legal_checkup_uuid: props.legalCheckupUuid, ...data }
        : data;
      root.$store
        .dispatch('authentication/auth', {
          data: {
            ...payload,
            locale: root.$i18n.locale == 'nl' ? 'nl' : 'en'
          },
          registration: true
        })
        .then((result) => {
          if (result) {
            init();
            router.push({
              name: 'Onboarding',
              query: { ...router.currentRoute.query }
            });
          }
        });
    }
    const brand: string = inject('brand', 'ligo');
    const brandData = brandInformation(brand);

    function goToLogin() {
      const route = { name: brandRouteName(brand, 'Login') };
      route['query'] = {
        redirect_url: props.redirectUrl,
        legal_checkup_uuid: props.legalCheckupUuid,
        contract_uuid: props.contractUuid,
        extraServiceTypeSlug: props.extraServiceTypeSlug,
        plan_id: props.planId
      };
      root.$router.push(route);
    }

    const socialBtns = brandSocialBtn(brand);

    return {
      brand,
      goToLogin,
      onRegister,
      socialBtns,
      providerLinks,
      loginError,
      socialAuth,
      brandData
    };
  }
});
