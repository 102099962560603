














import { defineComponent, ref, watch } from '@vue/composition-api';
import { BaseModal } from './';
import { BaseInput } from '../base/';
import { useModalCtas } from './base';

export default defineComponent({
  name: 'FillTextModal',
  components: { BaseModal, BaseInput },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: String
  },
  setup(props) {
    const text = ref('');
    const { saveBtn, ctasList: ctas } = useModalCtas();

    function changeText(newValue: string) {
      text.value = newValue;
      saveBtn.value.disabled = !Boolean(newValue);
    }

    watch(
      () => props.value,
      (newValue) => {
        if (newValue) {
          text.value = '';
          saveBtn.value.disabled = true;
        }
      },
      { immediate: true }
    );

    return { text, ctas, changeText };
  }
});
