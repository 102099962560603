export default {
  name: 'Bedrijfsnaam',
  street: 'Straat',
  house_number: 'Huisnummer',
  zip: 'Postcode',
  city: 'Plaats',
  vat_number: 'BTW nummer (optioneel)',
  phone_number: 'Telefoonnummer',
  message: 'Voor vragen kun je ons bellen',
  next: 'Ga verder',
  rules: {
    required: 'Dit veld is verplicht'
  },
  address: 'Adres',
  billing_details: 'Factuurgegevens',
  edit: 'Wijzig',
  empty: 'No details added yet',
  save_success: 'Je factuuradres is aangepast'
};
