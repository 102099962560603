
















































import { defineComponent, ref } from '@vue/composition-api';
import { useBilling, SavedBilling } from '../../payment';
import { APrimaryBtn, ASecondaryBtn } from '../../base/app/buttons';
import { notifyAsync } from '@ligo/shared/utils';
import { useI18n } from '../../../composables';
import { SimpleField } from '../../base/SimpleForm/models/simpleForm.models';
import BaseSimpleField from '../../base/SimpleForm/BaseSimpleField.vue';

const formSections: SimpleField[] = [
  { key: 'name', rules: ['required'] },
  { key: 'street', rules: ['required'] },
  { key: 'house_number', rules: ['required'] },
  { key: 'zip', rules: ['required'] },
  { key: 'city', rules: ['required'] },
  { key: 'phone_number', rules: ['required'], type: 'phone-number' },
  { key: 'vat_number' }
];

export default defineComponent({
  name: 'BillingSection',
  components: {
    SavedBilling,
    BaseSimpleField,
    APrimaryBtn,
    ASecondaryBtn
  },
  setup() {
    const i18n = useI18n();
    const { billingData, saveBilling, load, loading } = useBilling();
    const showEdit = ref(false);
    const form = ref();

    function required(v) {
      return !!v || i18n.t('billing.rules.required');
    }

    async function onSave() {
      if (await form.value.validate()) {
        await notifyAsync(
          saveBilling().then(load),
          i18n.t('billing.save_success'),
          i18n.t('billing.save_error'),
          {
            color: 'dark',
            position: 'top',
            icon: 'img:/dashboard/icons/circle-check.svg'
          }
        );
        showEdit.value = false;
      }
    }
    async function onCancel() {
      load();
      showEdit.value = false;
    }

    return {
      billingData,
      showEdit,
      formSections,
      onSave,
      form,
      onCancel,
      required,
      loading
    };
  }
});
