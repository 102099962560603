

































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'EditionSidebar',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  }
});
