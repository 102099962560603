export default {
  title: 'De notaris controleert momenteel je documenten',
  info_banner:
    'Hou er rekening mee dat de notaris later nog om aanvullende documenten kan vragen. Denk hierbij aan een ondernemingsplan. Dit is gangbaar. Wanneer de notaris al je documenten heeft goedgekeurd, zal hij of zij overgaan tot het opstellen van de finale conceptakte. Op het moment dat de notaris deze akte voor je uploadt, ontvang je een melding.',
  request_header: 'De notaris heeft aanvullende documenten van je nodig',
  reject_header: 'Afgewezen document(en)',
  accepted_header: 'Goedgekeurd door de notaris',
  view: 'Bekijk toelichting',
  upload: 'Upload',
  uploaded: 'Geüpload',
  change: 'Wijzig',
  send: 'Verstuur alle documenten naar de notaris',
  modal: {
    title: 'Toelichting van de notaris',
    view: {
      title: 'Reden van afkeuring',
      subtitle: 'Beschrijving van wat je moet doen'
    },
    ok: 'Ok'
  },
  pending: 'In afwachting',
  accepted: 'Geaccepteerd',
  documents: 'Al uw documenten',
  templates: 'Sjablonen',
  documents_approved: '{used}/{total} goedgekeurde documenten',
  edit_application: 'Wijzig je aanvraag',
  menu: {
    company_name: 'Wijzig bedrijfsnaam',
    company_address: 'Wijzig bedrijfsadres',
    update_shareholder: 'Wijzig gegevens aandeelhouders',
    remove_shareholders: 'Verwijder/Voeg toe aandeelhouders',
    business_activities: 'Wijzig bedrijfsactiviteiten',
    share_capital: 'Wijzig aandelenkapitaal',
    other: 'Overige wijzigingen',
    cancel: 'Annuleer aanvraag'
  }
};
