import { GetterTree } from 'vuex';
import { LayoutState } from './state';
import { CustomerDashboardState } from '../state';

const PAGES = ['NotaryProductDetail'];

const getters: GetterTree<LayoutState, CustomerDashboardState> = {
  getDashboardTitle: (state) => ($router, translation) => {
    if (PAGES.includes($router.currentRoute.name))
      return state.dashboardTitle || translation;
    return translation;
  },
  getDrawerBreakpoint(state) {
    return state.drawerBreakpoint;
  },
  getPaymentUuid(state) {
    return state.paymentUuid;
  },
  getPaymentModal(state) {
    return state.openPaymentModal;
  }
};

export default getters;
