export function basicResize(event: any) {
  const target = event.target;
  const styles = window.getComputedStyle(target);

  target.style.width = `${event.rect.width}px`;
  target.style.height = `${event.rect.height}px`;

  target.style.top = `${parseFloat(styles.top) + event.deltaRect.top}px`;
  target.style.left = `${parseFloat(styles.left) + event.deltaRect.left}px`;
}

export function basicMove(event: any) {
  const target = event.target;
  const styles = window.getComputedStyle(target);

  target.style.top = `${parseFloat(styles.top) + event.dy}px`;
  target.style.left = `${parseFloat(styles.left) + event.dx}px`;
}
