































































import { computed, defineComponent, ref, PropType } from '@vue/composition-api';
import { useStore } from '../../../composables';
import { PaymentQuery } from '@ligo/shared/models';
import { Payment, PaymentFlowSteps } from '../../../models';
import APrimaryBtn from '../../base/app/buttons/APrimaryBtn.vue';
import ASecondaryBtn from '../../base/app/buttons/ASecondaryBtn.vue';
import StepHeader from '../modal-components/StepHeader.vue';
import PaymentSummary from './PaymentSummary.vue';
import AdyenPayment from './AdyenPayment.vue';
import ErrorPaymentBanner from './ErrorPaymentBanner.vue';
import { paymentToPaymentView } from './checkout.hook';
import { PaymentService } from '@ligo/dashboard/customer/store';
import { simpleNotification } from '@ligo/shared/utils';

export default defineComponent({
  name: 'CheckoutStep',
  components: {
    StepHeader,
    PaymentSummary,
    AdyenPayment,
    ErrorPaymentBanner,
    ASecondaryBtn,
    APrimaryBtn
  },
  props: {
    payment: {
      type: Object as PropType<Payment>
    },
    adyenData: {
      type: Object as PropType<PaymentQuery>
    },
    originUrl: {
      type: String
    },
    loading: {
      type: Boolean
    }
  },
  setup(props, { emit, root }) {
    const store = useStore();
    const paymentRef = computed(() => props.payment);
    const url = `${props.originUrl}?step=${PaymentFlowSteps.CHECKOUT}`;
    const coupon = ref('');
    const username = computed(() => store.getters['authentication/firstname']);
    const paymentData = computed(() => paymentToPaymentView(paymentRef.value));

    const paymentError = ref(false);
    const errorMessage = ref();

    function onPaymentError(error) {
      paymentError.value = true;
      errorMessage.value = `${root.$t(
        'payment.stepper.error_message'
      )}: ${error}`;
    }

    async function applyCoupon() {
      const message = await PaymentService.applyCoupon(
        paymentRef.value.uuid,
        coupon.value
      );
      if (message == 'success') {
        coupon.value = '';
        simpleNotification({
          message: root.$t('payment.stepper.coupon.success') as string,
          timeout: 5000
        });
        emit('reload');
      } else {
        paymentError.value = true;
        errorMessage.value = root.$t(`payment.stepper.coupon.${message}`);
      }
    }

    async function removeCoupon() {
      await PaymentService.removeCoupon(paymentRef.value.uuid);
      emit('reload');
    }

    return {
      username,
      paymentData,
      paymentRef,
      url,
      coupon,
      onPaymentError,
      applyCoupon,
      removeCoupon,
      paymentError,
      errorMessage
    };
  }
});
