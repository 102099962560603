export default {
  documenteditorcontainer: {
    Download: 'Downloaden',
    New: 'Nieuw',
    Open: 'Open',
    Undo: 'Ongedaan maken',
    Redo: 'Opnieuw doen',
    Image: 'Beeld',
    Table: 'Tabel',
    Link: 'Koppeling',
    Bookmark: 'Bladwijzer',
    'Table of Contents': 'Inhoudsopgave',
    'HEADING - - - - 1': 'KOPTEKST 1',
    'HEADING - - - - 2': 'RUBRIEK - - - - 2',
    'HEADING - - - - 3': 'RUBRIEK - - - - 3',
    Header: 'Koptekst',
    Footer: 'Voettekst',
    'Page Setup': 'Pagina instellingen',
    'Page Number': 'Paginanummer',
    Break: 'Breken',
    Find: 'Zoeken',
    'Local Clipboard': 'Lokaal klembord',
    'Restrict Editing': 'Bewerking beperken',
    'Upload from computer': 'Upload van computer',
    'By URL': 'Op URL',
    'Page Break': 'Pagina-einde',
    'Section Break': 'Sectie Break',
    'Header And Footer': 'Kop- en voettekst',
    Options: 'Opties',
    Levels: 'levels',
    'Different First Page': 'Afwijkende eerste pagina',
    'Different header and footer for odd and even pages':
      "Verschillende kop- en voettekst voor oneven en even pagina's.",
    'Different Odd And Even Pages': "Afwijkende Even en oneven pagina's",
    'Different header and footer for first page':
      'Afwijkende kop- en voettekst voor de eerste pagina.',
    Position: 'Positie',
    'Header from Top': 'Koptekst vanaf bovenkant',
    'Footer from Bottom': 'Voettekst vanaf onderkant',
    'Distance from top of the page to top of the header':
      'Afstand van de bovenkant van de pagina tot de bovenkant van de koptekst.',
    'Distance from bottom of the page to bottom of the footer':
      'Afstand van de onderkant van de pagina tot de onderkant van de voettekst.',
    'Aspect ratio': 'Beeldverhouding',
    W: 'w',
    H: 'H',
    Width: 'Breedte',
    Height: 'Hoogte',
    Text: 'Tekst',
    Paragraph: 'paragraaf',
    Fill: 'Vullen',
    'Fill color': 'Opvulkleur',
    'Border Style': 'Randstijl',
    'Outside borders': 'Buiten grenzen',
    'All borders': 'Alle grenzen',
    'Inside borders': 'Binnen grenzen',
    'Left border': 'Linker rand',
    'Inside vertical border': 'Binnen verticale rand',
    'Right border': 'Rechter rand',
    'Top border': 'Bovenrand',
    'Inside horizontal border': 'Binnen horizontale rand',
    'Bottom border': 'Onderrand',
    'Border color': 'Rand kleur',
    'Border width': 'Grensbreedte',
    Cell: 'Cel',
    'Merge cells': 'Cellen samenvoegen',
    'Insert Or Delete': 'Invoegen / Verwijder',
    'Insert columns to the left': 'Kolommen links invoegen',
    'Insert columns to the right': 'Kolommen rechts invoegen',
    'Insert rows above': 'Voeg rijen hierboven in',
    'Insert rows below': 'Voeg rijen hieronder in',
    'Delete rows': 'Rijen Verwijder',
    'Delete columns': 'Kolommen Verwijder',
    'Cell Margin': 'Mobiele marge',
    Top: 'Top',
    Bottom: 'Bodem',
    Left: 'Links',
    Right: 'Rechtsaf',
    'Align Text': 'Tekst uitlijnen',
    'Align top': 'Boven uitlijnen',
    'Align bottom': 'Lijn de onderkant uit',
    'Align center': 'Tekst in het midden uitlijnen',
    'Number of heading or outline levels to be shown in table of contents':
      'Aantal kop- of overzichtsniveaus dat moet worden weergegeven in de inhoudsopgave.',
    'Show page numbers': 'Toon paginanummers',
    'Show page numbers in table of contents':
      'Paginanummers weergeven in de inhoudsopgave.',
    'Right align page numbers': 'Paginanummers rechts uitlijnen',
    'Right align page numbers in table of contents':
      'Lijn de paginanummers in de inhoudsopgave rechts uit.',
    'Use hyperlinks': 'Gebruik hyperlinks',
    'Use hyperlinks instead of page numbers':
      'Gebruik hyperlinks in plaats van paginanummers.',
    Font: 'Lettertype',
    'Font Size': 'Lettertypegrootte',
    'Font color': 'Letterkleur',
    'Text highlight color': 'Tekst markeer kleur',
    'Clear all formatting': 'Wis alle opmaak',
    'Bold Tooltip': 'Vet (Ctrl + B)',
    'Italic Tooltip': 'Cursief (Ctrl + I)',
    'Underline Tooltip': 'Onderstrepen (Ctrl + U)',
    Strikethrough: 'doorhalen',
    'Superscript Tooltip': 'Superscript (Ctrl + Shift ++)',
    'Subscript Tooltip': 'Subscript (Ctrl + =)',
    'Align left Tooltip': 'Links uitlijnen (Ctrl + L)',
    'Center Tooltip': 'Midden (Ctrl + E)',
    'Align right Tooltip': 'Rechts uitlijnen (Ctrl + R)',
    'Justify Tooltip': 'Rechtvaardigen (Ctrl + J)',
    'Decrease indent': 'Inspringen verminderen',
    'Increase indent': 'Inspringen vergroten',
    'Line spacing': 'Regelafstand',
    Bullets: 'kogels',
    Numbering: 'nummering',
    Styles: 'stijlen',
    'Manage Styles': 'Stijlen beheren',
    Page: 'Bladzijde',
    of: 'van',
    'Fit one page': 'Plaats één pagina',
    'Spell Check': 'Spellingscontrole',
    'Underline errors': 'Onderstreep fouten',
    'Fit page width': 'Pas paginabreedte aan',
    Update: 'Bijwerken',
    Cancel: 'Annuleren',
    Insert: 'invoegen',
    'No Border': 'Geen grens',
    'Create a new document': 'Maak een nieuw document.',
    'Open a document': 'Open een document.',
    'Undo Tooltip': 'Maak de laatste bewerking ongedaan (Ctrl + Z).',
    'Redo Tooltip': 'Voer de laatste bewerking opnieuw uit (Ctrl + Y).',
    'Insert inline picture from a file':
      'Inline-afbeelding uit een bestand invoegen.',
    'Insert a table into the document': 'Voeg een tabel in het document in',
    'Create Hyperlink':
      "Maak een link in uw document voor snelle toegang tot webpagina's en bestanden (Ctrl + K).",
    'Insert a bookmark in a specific place in this document':
      'Voeg een bladwijzer in op een specifieke plaats in dit document.',
    'Provide an overview of your document by adding a table of contents':
      'Geef een overzicht van uw document door een inhoudsopgave toe te voegen.',
    'Add or edit the header': 'Voeg de kop toe of bewerk deze.',
    'Add or edit the footer': 'Voettekst toevoegen of bewerken.',
    'Open the page setup dialog': 'Open het dialoogvenster Pagina-instelling.',
    'Add page numbers': 'Voeg paginanummers toe.',
    'Find Text': 'Zoek tekst in het document (Ctrl + F).',
    'Toggle between the internal clipboard and system clipboard':
      'Schakelen tussen het interne klembord en het systeemklembord. </br> Toegang tot het systeemklembord via script wordt geweigerd vanwege het beveiligingsbeleid van browsers. In plaats daarvan </br> 1. U kunt het interne klembord laten knippen, kopiëren en plakken in de component. </br> 2. U kunt de sneltoetsen (Ctrl + X, Ctrl + C en Ctrl + V) gebruiken om te knippen , kopiëren en plakken met systeemklembord.',
    'Current Page Number':
      'Het huidige paginanummer in het document. Klik of tik om naar een specifieke pagina te navigeren.',
    'Read only': 'Alleen lezen',
    Protections: 'Protections',
    'Error in establishing connection with web server':
      'Fout bij het tot stand brengen van verbinding met webserver',
    Single: 'single',
    Double: 'Dubbele',
    'New comment': 'Nieuwe reactie',
    Comments: 'Opmerkingen',
    'Print layout': 'Afdrukopmaak',
    'Web layout': 'web layout',
    'Text Form': 'Tekst vorm',
    'Check Box': 'Selectievakje',
    DropDown: 'Laten vallen',
    'Update Fields': 'Velden bijwerken',
    'Update cross reference fields': 'Kruisverwijzingsvelden bijwerken',
    'Hide properties pane': 'Verberg eigenschappenvenster',
    'Show properties pane': 'Toon eigenschappenvenster',
    'Form Fields': 'Formuliervelden',
    'Track Changes': 'Houd de wijzigingen in het document bij',
    TrackChanges: 'Spoorwissel',
    AllCaps: 'In hoofdletters',
    'Change case Tooltip': 'Verander de zaak',
    'Insert Footnote': 'Voetnoot invoegen',
    'Insert Endnote': 'Eindnoot invoegen',
    'Footnote Tooltip': 'Voetnoot invoegen (Alt + Ctrl + F).',
    'Endnote Tooltip': 'Eindnoot invoegen (Alt + Ctrl + D).',
    UPPERCASE: 'HOOFDLETTERS',
    'No color': 'Geen kleur',
    'Top margin': 'Bovenmarge',
    'Bottom margin': 'De ondergrens',
    'Left margin': 'Linkermarge',
    'Right margin': 'Rechtermarge',
    Normal: 'normaal',
    Heading: 'rubriek',
    'Heading 1': 'Koptekst 1',
    'Heading 2': 'Kop 2',
    'Heading 3': 'Kop 3',
    'Heading 4': 'Rubriek 4',
    'Heading 5': 'Kop 5',
    'Heading 6': 'Kop 6',
    ZoomLevelTooltip: 'Zoomniveau. Klik of tik om de zoomopties te openen.',
    None: 'Geen',
    Borders: 'borders'
  },
  DocumentEditor: {
    Table: 'Tabel',
    Row: 'Rij',
    Cell: 'Cel',
    Ok: 'OK',
    Cancel: 'Annuleren',
    Size: 'Grootte',
    'Preferred Width': 'Gewenste breedte',
    Points: 'punten',
    Percent: 'procent',
    'Measure in': 'Meet in',
    Alignment: 'opstelling',
    Left: 'Links',
    Center: 'Centreren',
    Right: 'Rechts',
    Justify: 'Uitvullen',
    'Indent from left': 'Inspringen van links',
    'Borders and Shading': 'Randen en schaduw',
    Options: 'opties',
    'Specify height': 'Geef de hoogte op',
    'At least': 'Minstens',
    Exactly: 'Precies',
    'Row height is': 'Rijhoogte is',
    'Allow row to break across pages': "Laat rij over pagina's breken",
    'Repeat as header row at the top of each page':
      'Herhaal dit als kopregel bovenaan elke pagina',
    'Vertical alignment': 'Verticale uitlijning',
    Top: 'Boven',
    Bottom: 'Onder',
    'Default cell margins': 'Standaard celmarges',
    'Default cell spacing': 'Standaard celafstand',
    'Allow spacing between cells': 'Ruimte tussen cellen toestaan',
    'Cell margins': 'Celmarges',
    'Same as the whole table': 'Hetzelfde als de hele tafel',
    Borders: 'borders',
    None: 'Geen',
    Single: 'Enkel',
    Dot: 'Punt',
    DashSmallGap: 'DashSmallGap',
    DashLargeGap: 'DashLargeGap',
    DashDot: 'DashDot',
    DashDotDot: 'DashDotStip',
    Double: 'Dubbele',
    Triple: 'Verdrievoudigen',
    ThinThickSmallGap: 'DunDikKleine Gap',
    ThickThinSmallGap: 'DikDunSmallGap',
    ThinThickThinSmallGap: 'DunDikDunKleinGap',
    ThinThickMediumGap: 'DunDikMediumKloof',
    ThickThinMediumGap: 'DikDunMediumKloof',
    ThinThickThinMediumGap: 'DunDikDunMediumKloof',
    ThinThickLargeGap: 'DunDikGrote Gap',
    ThickThinLargeGap: 'Dik DunLargeGap',
    ThinThickThinLargeGap: 'DunDikDunGroteKloof',
    SingleWavy: 'SingleGolvend',
    DoubleWavy: 'Dubbel Golvend',
    DashDotStroked: 'DashDotStroked',
    Emboss3D: 'Emboss3D',
    Engrave3D: 'Graveren3D',
    Outset: 'Begin',
    Inset: 'inzet',
    Thick: 'Dik',
    Style: 'Stijl',
    Width: 'Breedte',
    Height: 'Hoogte',
    Letter: 'Brief',
    Tabloid: 'tabloid',
    Legal: 'wettelijk',
    Statement: 'Uitspraak',
    Executive: 'uitvoerend',
    A3: 'A3',
    A4: 'A4',
    A5: 'A5',
    B4: 'B4',
    B5: 'B5',
    'Custom Size': 'Aangepast formaat',
    'Different odd and even': 'Anders even en oneven',
    'Different first page': 'Verschillende eerste pagina',
    'From edge': 'Van rand',
    Header: 'Koptekst',
    Footer: 'Voettekst',
    Margin: 'marges',
    Paper: 'Papier',
    Layout: 'lay-out',
    Orientation: 'Oriëntering',
    Landscape: 'Landschap',
    Portrait: 'Portret',
    'Table Of Contents': 'Inhoudsopgave',
    'Show page numbers': 'Toon paginanummers',
    'Right align page numbers': 'Paginanummers rechts uitlijnen',
    Nothing: 'Niets',
    'Tab leader': 'Tab leider',
    'Show levels': 'Toon niveaus',
    'Use hyperlinks instead of page numbers':
      'Gebruik hyperlinks in plaats van paginanummers',
    'Build table of contents from': 'Bouw een inhoudsopgave van',
    Styles: 'stijlen',
    'Available styles': 'Beschikbare stijlen',
    'TOC level': 'TOC-niveau',
    Heading: 'Heading',
    'List Paragraph': 'Lijst Alinea',
    Normal: 'normaal',
    'Outline levels': 'Overzichtsniveaus',
    'Table entry fields': 'Velden voor tabelinvoer',
    Modify: 'Aanpassen',
    Color: 'Kleur',
    Setting: 'omgeving',
    Box: 'Doos',
    All: 'Allemaal',
    Custom: 'Op maat',
    Preview: 'Voorbeeld',
    Shading: 'shading',
    Fill: 'Vullen',
    'Apply To': 'Van toepassing op',
    'Table Properties': 'Tabeleigenschappen',
    'Cell Options': 'Celopties',
    'Table Options': 'Tabel opties',
    'Insert Table': 'Tabel invoegen',
    'Number of columns': 'Aantal columns',
    'Number of rows': 'Aantal rijen',
    'Text to display': 'Weer te geven tekst',
    Address: 'Adres',
    'Insert Hyperlink': 'Koppeling invoegen',
    'Edit Hyperlink': 'Hyperlink bewerken',
    Insert: 'invoegen',
    General: 'Algemeen',
    Indentation: 'deuk',
    'Before text': 'Voor tekst',
    Special: 'speciaal',
    'First line': 'Eerste lijn',
    Hanging: 'hangende',
    'After text': 'Na tekst',
    By: 'Door',
    Before: 'Voordat',
    'Line Spacing': 'Regelafstand',
    After: 'Na',
    At: 'Bij',
    Multiple: 'Meerdere',
    Spacing: 'Spatiëring',
    'Define new Multilevel list': 'Definieer nieuwe lijst op meerdere niveaus',
    'List level': 'Lijstniveau',
    'Choose level to modify': 'Kies niveau om te wijzigen',
    Level: 'Niveau',
    'Number format': 'Nummer formaat',
    'Number style for this level': 'Nummerstijl voor dit niveau',
    'Enter formatting for number': 'Voer de opmaak voor het nummer in',
    'Start at': 'Begin bij',
    'Restart list after': 'Lijst opnieuw starten na',
    Position: 'Positie',
    'Text indent at': 'Tekst inspringen om',
    'Aligned at': 'Uitgelijnd op',
    'Follow number with': 'Volg nummer met',
    'Tab character': 'Tab-teken',
    Space: 'Ruimte',
    Arabic: 'Arabisch',
    UpRoman: 'UpRoman',
    LowRoman: 'LowRoman',
    UpLetter: 'UpLetter',
    LowLetter: 'LowLetter',
    Number: 'Aantal',
    'Leading zero': 'Voorloopnul',
    Bullet: 'Kogel',
    Ordinal: 'rangtelwoord',
    'Ordinal Text': 'Oorspronkelijke tekst',
    'For East': 'Voor oost',
    'No Restart': 'Geen herstart',
    Font: 'Lettertype',
    'Font style': 'Lettertype',
    'Underline style': 'Stijl onderstrepen',
    'Font color': 'Letterkleur',
    Effects: 'Effecten',
    Strikethrough: 'doorhalen',
    Superscript: 'superscript',
    Subscript: 'Onderschrift',
    'Double strikethrough': 'Dubbel doorgehaald',
    Regular: 'regelmatig',
    Bold: 'Vet',
    Italic: 'Cursief',
    Cut: 'Knippen',
    Copy: 'Kopiëren',
    Paste: 'Plakken',
    Hyperlink: 'hyperlink',
    'Open Hyperlink': 'Open Hyperlink',
    'Copy Hyperlink': 'Hyperlink kopiëren',
    'Remove Hyperlink': 'Hyperlink verwijderen',
    Paragraph: 'paragraaf',
    'Merge Cells': 'Cellen samenvoegen',
    'Insert Above': 'Boven invoegen',
    'Insert Below': 'Hieronder invoegen',
    'Insert Left': 'Links invoegen',
    'Insert Right': 'Rechts invoegen',
    Delete: 'Verwijder',
    'Delete Table': 'Tabel Verwijder',
    'Delete Row': 'Verwijder rij',
    'Delete Column': 'Kolom Verwijder',
    'File Name': 'Bestandsnaam',
    'Format Type': 'Type opmaak',
    Save: 'Opslaan',
    Navigation: 'Navigatie',
    Results: 'resultaten',
    Replace: 'Vervangen',
    'Replace All': 'Vervang alles',
    'We replaced all': 'We hebben alles vervangen',
    Find: 'Zoeken',
    'No matches': 'Geen overeenkomsten',
    'All Done': 'Helemaal klaar',
    Result: 'Resultaat',
    of: 'van',
    instances: 'instanties',
    with: 'met',
    'Click to follow link': 'Klik om de link te volgen',
    'Continue Numbering': 'Doorgaan met nummeren',
    'Bookmark name': 'Bladwijzernaam',
    Close: 'Sluiten',
    'Restart At': 'Herstart om',
    Properties: 'Eigendommen',
    Name: 'Naam',
    'Style type': 'Stijltype',
    'Style based on': 'Stijl gebaseerd op',
    'Style for following paragraph': 'Stijl voor de volgende paragraaf',
    Formatting: 'formatteren',
    'Numbering and Bullets': 'Nummering en opsommingstekens',
    Numbering: 'nummering',
    'Update Field': 'Werk veld bij',
    'Edit Field': 'Veld bewerken',
    Bookmark: 'Bladwijzer',
    'Page Setup': 'Pagina instellingen',
    'No bookmarks found': 'Geen bladwijzers gevonden',
    'Number format tooltip information':
      'Nummernotatie op één niveau: </br> [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Bijvoorbeeld hoofdstuk% 1. geeft nummering weer zoals </br> hoofdstuk 1. Item </br> hoofdstuk 2. Item </br> ... </br> hoofdstuk N. Item </br> </br> Multilevel getalnotatie: </ br > [PREFIX]% [LEVELNUMBER] [SUFFIX] + [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Bijvoorbeeld% 1.% 2. zal nummering weergeven zoals </br> 1.1. Item </br> 1.2. Item </br> ... </br> 1.N. Item',
    Format: 'Formaat',
    'Create New Style': 'Maak een nieuwe stijl',
    'Modify Style': 'Stijl wijzigen',
    New: 'Nieuw',
    Bullets: 'kogels',
    'Use bookmarks': 'Gebruik bladwijzers',
    'Table of Contents': 'Inhoudsopgave'
  }
};
