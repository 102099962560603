import { ExtraService } from './../../../../../store/src/lib/models/extra-service';
export default {
  subtotal: 'Subtotal',
  btw: 'VAT',
  total: 'Total',
  pay: 'Proceed to payment',
  message: 'In case you have any questions please give us a call',
  secure: '100% Secure payment',
  rating_banner: {
    customer: {
      text: 'Satisfied customers'
    },
    kyioh: {
      text: 'Kyioh review score',
      details: '9.2 Ratings'
    },
    google: {
      text: 'Google review score',
      details: '4.4 Ratings'
    }
  },

  stepper: {
    header: 'Change Plan',
    select_step: 'Choose your new plan',
    billing_step: 'Add billing details',
    pay_step: 'Pay and enjoy ',
    enterprise_step: 'Book a call with our team',
    cancel: 'Cancel',
    error_message: 'There was an error',
    types: {
      ExtraService: 'Fill In Information'
    },
    dropin_header: {
      text: `By continuing, you agree to Ligo's {url} and its partners.`,
      link: 'Terms and Conditions'
    },
    coupon: {
      apply: 'Apply',
      generic: 'Invalid Coupon',
      success: 'Coupon applied successfully',
      remove: 'Remove Coupon',
      no_product:
        'There are no products in your order to which this coupon can be applied'
    }
  },
  footer: {
    support: 'Need help?',
    support_link: 'Chat with us',
    next: 'Next',
    next_tooltip: 'You need to pick a plan to continue'
  },
  switcher: {
    monthly: 'Monthly',
    yearly: 'Yearly',
    save: 'Save Up to 40%'
  },
  plan: {
    header: 'Hi {name}, I am Lisa, the support manager of Ligo.',
    subtitle: 'You can change your plan in 1 minute.',
    choose_billing: 'Every when would you like to pay?',
    choose_plan: 'Which plan would you like to upgrade to?',
    change: 'Change to this plan',
    current_plan: 'Current plan',
    upon_request: 'Upon request'
  },
  billing: {
    title: 'What are your billing details?',
    subtitle: 'You can always change these later from your settings if needed.'
  },
  checkout: {
    title: 'Last step {name}! How would you like to pay?',
    subtitle:
      'Once you complete your payment you’ll get full access to your  plan 🙌',
    vat: 'VAT (21%)',
    total: 'Total'
  },
  enjoy: {
    title: 'You’re all set {name}!',
    subtitle: 'You can find your invoice in your Account overview',
    done: 'Done',
    close: 'Close'
  },
  enterprise_schedule: {
    title: 'That’s exciting, {name}!',
    subtitle: 'We can’t wait to discuss more about it with you.',
    card:
      'Below you can request a call with Matias, the Head of Product at Ligo. You’ll discuss together how the enterprise plan works and how it can help you take your business legal operations to the next level.',
    cta: 'Request a call with Matias',
    footer: 'You’ll receive an e-mail from Matias within 24 hours'
  },
  enterprise_contacted: {
    title: 'We’re on it! Matias will get back to you within 24 hours.',
    subtitle:
      'In the meantime feel free to reach out to our team via the chat if you need any help.',
    ok: 'Ok'
  },
  downgrade_warn: {
    title: 'Are you sure you want to downgrade to {plan} plan?',
    subtitle:
      'By downgrading to {toPlan} plan, you’ll lose access to some of the features that are included in your current {fromPlan} plan. Are you sure you want to continue?',
    cta: 'Yes, continue'
  },
  cancel: {
    header: 'Cancel and downgrade to the free plan',
    warn: {
      title: 'Hey {name}, It’s Lisa the support lead at Ligo.',
      subtitle:
        'Downgrading to the FREE plan means you’ll lose access to some features that are included in your {planName} plan. Are you sure you want to continue?',
      cta: 'Yes, continue'
    },
    goodbye: {
      title: 'We hope to have you back again in the future, {name}',
      subtitle:
        'You’ve successfuly canceled your {plan} plan. You’ll keep access to it until {date}, the end of your billing cycle, and you’ll be downgraded to the Free plan after that.',
      cta: 'Done'
    },
    form: {
      title: 'One quick question before you finish',
      subtitle:
        'Before you downgrade we’d love your quick feedback to understand how to make Ligo better for everyone. It’s been a pleasure having you as a customer, and we would love to learn from you as much as we can. 🙂',
      q1: '1. What are your reasons for downgrading?',
      a1_1: 'Missing features',
      a1_1_placeholder: 'Let us know which features are missing...',
      a1_2: 'Upgraded by mistake',
      a1_3: 'Too difficult to use',
      a1_4: 'Using an alternative',
      a1_5: 'Security or compliance requirements',
      a1_6: 'Company is shuttong down or making cuts',
      a1_7: 'Not using it enough',
      a1_8: 'Too expensive',
      a1_9: 'Other',
      a1_9_placeholder: 'Tell us more...',
      q2: '2. What tools are you using instead?',
      a2_1: 'DocuSign',
      a2_2: 'DocuSign CLM',
      a2_3: 'SignRequest',
      a2_4: 'Juro',
      a2_5: 'Oneflow',
      a2_6: 'Concord',
      a2_7: 'Contractbook',
      a2_8: 'Precisely',
      a2_9: 'Ironclad',
      a2_10: 'Other',
      a2_10_placeholder: 'Tell us more...',
      q3: '3. Any other thoughts you would like to share with us?',
      a3_placeholder:
        'Our team will review your feedback to make Ligo better...',
      cta: 'Cancel and downgrade to free'
    }
  },
  pay_contract: {
    header: 'Buy contract',
    stepper: {
      billing: 'Add billing details',
      checkout: 'Pay'
    },
    billing: {
      title: 'What are the billing details we should use in your invoice?',
      subtitle: 'You can always change these later in your settings if needed.'
    },
    checkout: {
      title: 'How would you like to pay?',
      subtitle:
        'Once you complete your payment you’ll get full access to this contract 🙌',
      success:
        'Your payment was successful. You can now access and edit this contract.'
    }
  },
  line_items: {
    yearly_discount: 'Yearly discount',
    new_subscription_discount: 'New subscription discount',
    new_membership_discount: 'Settlement months already paid for'
  }
};
