






































































import { defineComponent, PropType } from '@vue/composition-api';
import { useDgaTable } from '../dga-services.hook';
import { SQTable } from '../../base';
import {
  SQTableRow,
  SQTableEmptySate,
  SQTablePagination,
  SearchEngine
} from '../../../components/base/tables/s-q-table.hook';

export default defineComponent({
  name: 'ServicesTable',
  components: { SQTable },
  props: {
    services: {
      type: Array as PropType<SQTableRow[]>,
      required: true
    },
    loading: {
      type: Boolean,
      default: true
    },
    pagination: {
      type: Object as PropType<SQTablePagination>,
      default: () => null
    },
    searchEngine: {
      type: Function as PropType<SearchEngine>,
      default: () => null
    },
    empty: {
      type: Object as PropType<SQTableEmptySate>,
      default: () => null
    }
  },
  setup() {
    const { columns } = useDgaTable();
    return { columns };
  }
});
