import { Contract } from '@ligo/dashboard/customer/store';
import print from 'print-js';

export function hasPdfDocument(contract: Contract): boolean {
  return !!contract.last_revision?.pdf_document;
}

export function hasWordDocument(contract: Contract): boolean {
  return !!contract.last_revision?.word_document;
}

export function downloadContract(url, type: 'word' | 'pdf') {
  if (type == 'word') {
    window.location.href = url;
  } else {
    window.open(url, 'blank');
  }
}

export function printPdf(url) {
  print({ printable: url });
}
