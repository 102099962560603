import { MutationTree } from 'vuex';
import { AuthState } from './state';

const mutation: MutationTree<AuthState> = {
  authRequest(state) {
    state.authenticating = true;
    state.authenticationError = null;
  },

  authSuccess(state, accessToken) {
    state.accessToken = accessToken;
    state.authenticating = false;
  },

  confirmSuccess(state) {
    state.authenticating = false;
  },

  authError(state, { errorCode, errorMessage, errorData }) {
    state.authenticating = false;
    state.authenticationError = null;
    state.authenticationError = {
      code: errorCode,
      message: errorMessage,
      data: errorData
    };
    localStorage.removeItem('user');
  },
  cleanErrors(state) {
    state.authenticationError = null;
  },
  openLogin(state) {
    state.authMethod = false;
    state.openDialog = true;
  },
  openRegister(state) {
    state.authMethod = true;
    state.openDialog = true;
  },
  closeDialog(state) {
    state.openDialog = false;
  },
  logoutSuccess(state) {
    state.accessToken = null;
  },
  setUser(state, user) {
    state.user = user;
  },
  unsetUser(state) {
    state.user = null;
  },
  updateUserLocale(state, locale) {
    if (state.user) {
      state.user.locale = locale;
    }
  },
  restrictFromFreeContract(state) {
    state.user.did_receive_contract_for_free = true;
  }
};

export default mutation;
