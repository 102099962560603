import { Module } from 'vuex';
import { CustomerDashboardState } from '../state';
import state, { NotaryProductState } from './state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export * from './state';

export const notaryProduct: Module<
  NotaryProductState,
  CustomerDashboardState
> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
};
