
















































































































































import {
  computed,
  defineComponent,
  PropType,
  watch
} from '@vue/composition-api';
import { useReminderManagerConfig } from './reminder-modals-config.hook';
import { Event } from '@ligo/dashboard/customer/store';
import { ABtn, ASecondaryBtn } from '../../../components/base/app/buttons';
import { WYSIWYGEditor } from '@ligo/shared/components';

import moment from 'moment';

export default defineComponent({
  name: 'UpdateModal',
  components: {
    WYSIWYGEditor,
    ABtn,
    ASecondaryBtn
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    reminder: {
      type: Object as PropType<Event>,
      required: true
    }
  },
  setup(props) {
    const {
      reminderName,
      reminderDate,
      remindMeOn,
      reminderMessage,
      canCreate,
      getOptionDate
    } = useReminderManagerConfig();

    function updateModal() {
      if (props.reminder) {
        reminderName.value = props.reminder.event_name;
        reminderDate.value = props.reminder.event_date;
        remindMeOn.value = props.reminder.remind_me_on;
        reminderMessage.value = props.reminder.message || '';
      }
    }

    function updateMessage(newValue: string) {
      reminderMessage.value = newValue;
    }

    function getFormattedDate(date: any) {
      if (moment(date).isValid()) return moment(date).format('DD-MM-YYYY');
      else return date;
    }

    const reminderEvent = computed(() => {
      return {
        id: props.reminder?.id,
        event_name: reminderName.value,
        event_date: reminderDate.value,
        message: reminderMessage.value,
        remind_me_on: remindMeOn.value
      };
    });

    watch(
      () => props.reminder,
      () => {
        updateModal();
      },
      {
        immediate: true
      }
    );
    return {
      canCreate,
      reminderName,
      reminderDate,
      reminderEvent,
      reminderMessage,
      remindMeOn,
      getFormattedDate,
      updateMessage,
      updateModal,
      getOptionDate
    };
  }
});
