import { ref, computed, ComputedRef, watch } from '@vue/composition-api';
import { AssistantPageInformation } from '../../../../components/utils/useCustomerPageInformation.hooks';
import { AssistantState } from '@ligo/shared/components';
import { useStore } from '../../../../composables';
import { useStoreValue } from '@ligo/shared/utils';

export function useDocumentPreparationAssistant(assistantConditions: {
  [key: string]: ComputedRef;
}) {
  const store = useStore();
  const nextClick = ref(false);
  const dismissClick = ref(false);
  const assistantStates: AssistantState[] = [];
  const hiddenAssistant = ref(false);
  const isActive = useStoreValue(store)(
    'assistant/getPrepareDocumentAssistantState'
  );

  const allConditions = {
    next: computed(() => nextClick.value),
    dismiss: computed(() => dismissClick.value),
    ...assistantConditions
  };

  const clearableConditions = {
    next: clearNextClick
  };

  const {
    t,
    load,
    useConfig: useLigoAssistantConfig,
    isReady: isAssistantReady
  } = AssistantPageInformation;

  load();

  function handleNextClick() {
    nextClick.value = true;
  }

  function handleDismissClick() {
    hideAssistant();
  }

  function clearNextClick() {
    nextClick.value = false;
  }

  function activateAssistant() {
    store.dispatch('assistant/activatePrepareDocumentAssistant');
  }

  function deactivateAssistant() {
    store.dispatch('assistant/deactivatePrepareDocumentAssistant');
  }

  function hideAssistant() {
    hiddenAssistant.value = true;
  }

  function finalizeAssistant(state: AssistantState) {
    if (state.dontShowAgain?.value) {
      deactivateAssistant();
    }
  }

  watch(isAssistantReady, (ready) => {
    if (ready) {
      try {
        const assistantConfig = useLigoAssistantConfig()[
          'document_preparation_tour'
        ];
        Object.keys(assistantConfig.steps).forEach((key, index) => {
          const step = assistantConfig.steps[key];
          const conditions = Object.keys(step.transition).map((condition) => ({
            condition: allConditions[condition],
            to: step.transition[condition],
            clear: clearableConditions[condition]
          }));
          assistantStates.push({
            title: t(step.title),
            subtitle: step.subtitle && t(step.subtitle),
            info: step.info && t(step.info),
            targetHtmlSelector: step.targetHtmlSelector,
            transition: computed(() => {
              for (let i = 0; i < conditions.length; i++) {
                if (conditions[i].condition.value) {
                  conditions[i].clear && conditions[i].clear();
                  return conditions[i].to;
                }
              }
              return index;
            }),
            alignment: {
              right: step.alignment?.right ? 1 : 0,
              bottom: step.alignment?.bottom ? 1 : 0
            },
            offset: {
              x: step.offset?.x || 0,
              y: step.offset?.y || 0
            },
            arrowDirection: step.arrowDirection,
            actions: step.actions,
            image: step.image,
            dontShowAgain: step.dont_show_again && {
              value: false,
              label: t(step.dont_show_again.label)
            }
          });
        });
      } catch (e) {
        console.error('There was an error defining the assistance : ', e);
      }
    }
  });

  return {
    assistantStates,
    isActive,
    hiddenAssistant,
    finalizeAssistant,
    hideAssistant,
    activateAssistant,
    deactivateAssistant,
    handleNextClick,
    handleDismissClick
  };
}
