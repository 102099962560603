















































































































































































import {
  Notary,
  NotaryCapacityStatus,
  NotaryProductEvent
} from '@ligo/dashboard/store';
import { defineComponent, PropType, ref, computed } from '@vue/composition-api';
import { useReadDocuments } from './read-documents.hook';
import ReadDocumentsHandler from './ReadDocuments.handler.vue';
import UploadDocumentsHandler from './UploadDocuments.handler.vue';
import { BusyNotary } from './banners';
import { useI18n, useStore } from '../../../composables';
import { NotaryProductService } from '@ligo/dashboard/customer/store';
import BaseSimpleForm from '../../base/SimpleForm/BaseSimpleForm.vue';
import {
  DIALOG_WITHOUT_NOTARY_KEY,
  DialogPageInformation,
  useCustomerNotarySelection
} from '../extra-questions/extra-questions.hooks';
import { NotaryProductSlug } from '@ligo/bv-flow/store';
import { useSimpleFormConfig } from '../../base/SimpleForm/hooks/simpleForm.hooks';
import AlertModal from '../../documents/base/AlertModal.vue';
import { APrimaryBtn, ASecondaryBtn } from '../../base/app/buttons';
import { useDynamicText } from '../hooks/dynamicText.hooks';
const LOADER_TIMER = 2000;

const AVAILABILITY_INFO_KEY = 'availability_status';

export default defineComponent({
  name: 'ReadUploadStep',
  components: {
    ReadDocumentsHandler,
    UploadDocumentsHandler,
    BusyNotary,
    BaseSimpleForm,
    AlertModal,
    APrimaryBtn,
    ASecondaryBtn
  },
  props: {
    notary: {
      type: Object as PropType<Notary>
    },
    productType: {
      type: String as PropType<NotaryProductSlug>
    },
    notaryId: {
      type: Number
    },
    productData: {
      type: Object
    }
  },
  setup(props, { root, emit }) {
    const allDocsUploaded = ref(false);
    const loading = ref(false);

    emit('continue-loading');

    const onAllDocsUploaded = (val: boolean) => {
      allDocsUploaded.value = val;
    };

    const i18n = useI18n();

    const { nt } = useDynamicText(i18n, props.notary);

    const onSend = () => {
      emit('event', NotaryProductEvent.CHECK_DOCUMENTS);
    };

    const busyNotaryBanner = ref(nt('read_docs.enable_busy_banner'));

    const { ...readDocuments } = useReadDocuments(root);

    const onAllDocsRead = computed(() =>
      readDocuments.items.value.every((item) => item.status)
    );

    const readDocsElements = computed(() => ({
      loadingDocs: readDocuments.loading.value,
      docList: readDocuments.items.value,
      readDoc: readDocuments.onToggleRead,
      downloadDoc: readDocuments.onDownload
    }));

    const cantMoveOn = computed(() => {
      return !onAllDocsRead.value || !allDocsUploaded.value;
    });

    const docsLoaded = ref(false);

    const store = useStore();

    const notaryProduct = store.getters['notaryProduct/getNotaryProductId'];

    let interval = null;

    function loadNotaryProduct() {
      NotaryProductService.getById(notaryProduct).then((response) => {
        if (!response.data.waiting_for_documents) {
          docsLoaded.value = true;
          emit('stop-loading');
          clearInterval(interval);
        } else if (!interval) {
          interval = setInterval(() => {
            loadNotaryProduct();
          }, LOADER_TIMER);
        }
      });
    }

    loadNotaryProduct();

    const {
      formConfig,
      onSendInfo,
      loadPageInformation,
      t,
      te
    } = useCustomerNotarySelection(
      notaryProduct,
      props.productType,
      props.notaryId,
      () => cantMoveOn.value
    );

    const { formValue, getFormValueReducer } = useSimpleFormConfig(
      formConfig,
      props.productData
    );

    loadPageInformation();

    const getAvailabilityInfo = computed(() => {
      return t<{ [key in NotaryCapacityStatus]: string }>(
        AVAILABILITY_INFO_KEY
      );
    });

    const showConfirmDialog = ref(false);

    const getDialogInfo = computed(() =>
      t<DialogPageInformation>(DIALOG_WITHOUT_NOTARY_KEY)
    );

    const showToolip = ref(false);

    return {
      allDocsUploaded,
      onAllDocsRead,
      busyNotaryBanner,
      onAllDocsUploaded,
      onSend,
      cantMoveOn,
      readDocsElements,
      docsLoaded,
      // Notary Selection Form
      loading,
      formValue,
      getAvailabilityInfo,
      formConfig,
      t,
      te,
      showToolip,
      getDialogInfo,
      showConfirmDialog,
      nt,
      onMoveNext() {
        loading.value = true;
        onSendInfo(getFormValueReducer())
          .then(() => {
            onSend();
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };
  }
});
