




import { defineComponent } from '@vue/composition-api';
import ErrorLayout from './ErrorLayout.vue';

const i18nContent = {
  title: 'e_signature.errors.declined.title',
  text: 'e_signature.errors.declined.text'
};

export default defineComponent({
  name: 'ErrorDeclined',
  components: {
    ErrorLayout
  },
  setup() {
    return {
      i18nContent
    };
  }
});
