import { computed, ref } from '@vue/composition-api';
import { useAsyncResult } from '@ligo/shared/utils';
import { CardPlan } from '../../../models';
import { useI18n, useStore } from '../../../composables';
import { useMembershipInfo } from '../../utils/membership-info';
import { MembershipType } from '@ligo/shared/auth';
import {
  MembershipService,
  MembershipSlug
} from '@ligo/dashboard/customer/store';

function isValidJSON(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

function isValidMembership(membership: MembershipType) {
  return (
    membership.description &&
    membership.price &&
    membership.slug &&
    membership.name &&
    membership.billing_interval &&
    isValidJSON(membership.description)
  );
}

function membershipToCardPlan(membership: MembershipType): CardPlan {
  const localeMapper = { 'en-us': 'en', nl: 'nl' };
  const lang = localeMapper[useI18n().locale];
  const description = JSON.parse(membership.description)[lang];
  const price = Number(membership.price);
  return {
    id: membership.id,
    name: membership.name.trim().split(' ').slice(0, -1).join(' '),
    billingInterval: membership.billing_interval,
    items: description.features,
    price: price,
    subtitle: description.subtitle,
    slug: membership.slug,
    title_description: description.title_description,
    billing_description: description.billing_description,
    border: description.border,
    title: description.title,
    cta: description.cta
  };
}

export function usePlans() {
  const store = useStore();
  store.dispatch('authentication/loadUserInfo');

  const { state, loading } = useAsyncResult(MembershipService.list);

  const { membership, hasMembership } = useMembershipInfo();

  const currentPlan = computed(
    () => membership.value?.membership_type?.slug || MembershipSlug.FREE
  );

  const currentPlanCard = computed(() => {
    const current = membership.value?.membership_type;
    return current && isValidMembership(current)
      ? membershipToCardPlan(current)
      : undefined;
  });

  const showLegacyBanner = ref(currentPlan.value == MembershipSlug.FREE);

  const plans = computed(() =>
    state.value
      .map((p) => {
        if (p.slug == currentPlan.value) {
          showLegacyBanner.value = false;
        }
        return p;
      })
      .filter((v) => !!v.description && v.slug != currentPlan.value)
      .map(membershipToCardPlan)
  );

  const activePlan = ref('');

  function displayPlan(plan: CardPlan) {
    if (plan.slug == MembershipSlug.FREE) {
      if (!hasMembership.value) return true;
      return false;
    }
    return true;
  }

  const visiblePlans = computed(() => {
    const tail = plans.value.filter((p) => displayPlan(p));
    if (currentPlanCard.value) return [currentPlanCard.value, ...tail];
    return tail;
  });

  return {
    loading,
    plans,
    visiblePlans,
    currentPlan,
    activePlan,
    showLegacyBanner
  };
}
