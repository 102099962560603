import { EditorTools, EditorBanner, EditorLoader } from '../../../models';
import Vue from 'vue';

export function getDefinitions() {
  const items: EditorTools = [
    'Separator',
    'Undo',
    'Redo',
    'Separator',
    'Image',
    'Hyperlink',
    'TableOfContents',
    'Separator',
    'Header',
    'Footer',
    'PageSetup',
    'PageNumber',
    'Break',
    'InsertFootnote',
    'Separator',
    'Find',
    'Separator'
  ];

  const loadingText: EditorLoader = {
    loadingEditor: Vue['i18n'].t('documents.editor.loader.loadingEditor'),
    LoadingDocument: Vue['i18n'].t('documents.editor.loader.loadingDocument')
  };

  const banner: EditorBanner = {
    title: 'documents.editor.banner.title',
    subtitle: 'documents.editor.banner.subtitle',
    buttonDiscard: 'documents.editor.banner.buttonDiscard',
    buttonSave: 'documents.editor.banner.buttonSave'
  };

  return { items, loadingText, banner };
}
