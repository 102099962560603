






































import { defineComponent, Ref, ref, watch } from '@vue/composition-api';
import { QFile } from 'quasar';
export default defineComponent({
  name: 'ImgPicker',
  props: {
    value: String,
    placeholder: {
      type: String,
      default: '/images/image-placeholder.png'
    }
  },
  setup(_, { emit }) {
    const fileRef: Ref<QFile> = ref();
    const file = ref();

    function onClear() {
      emit('change', null);
    }

    function onChange() {
      fileRef.value.pickFiles();
    }

    watch(file, (f) => {
      emit('change', f);
    });

    return { fileRef, file, onChange, onClear };
  }
});
