





























import { defineComponent, computed, ref } from '@vue/composition-api';
import { Toolbar } from '@syncfusion/ej2-vue-documenteditor';
import { useCleanEditor } from '../../components/documents/word-editor/clean-word-editor.hook';
import {
  WordEditorBanner,
  DiscardModal
} from '../../components/documents/word-editor/';
import { EXTERNAL_RESOURCES } from '@ligo/dashboard/customer/store';

export default defineComponent({
  name: 'CleanWordEditor',
  components: {
    WordEditorBanner,
    DiscardModal
  },
  provide: {
    DocumentEditorContainer: [Toolbar]
  },
  setup() {
    const bannerContainer = ref();
    const editorHeight = computed(() => {
      if (bannerContainer.value) {
        return `${
          window.innerHeight - bannerContainer.value.$el.offsetHeight
        }px`;
      }
      return '100vh';
    });
    const editorComponent = ref();
    const showDiscardModal = ref(false);
    const {
      toolbarItems,
      onCreateDocument,
      onDiscard,
      fileName,
      documentChanged,
      onDocumentChanged
    } = useCleanEditor(editorComponent);

    return {
      toolbarItems,
      editorComponent,
      onCreateDocument,
      onDiscard,
      fileName,
      showDiscardModal,
      documentChanged,
      editorHeight,
      bannerContainer,
      serviceUrl: `${EXTERNAL_RESOURCES.WORD_EDITOR_SERVICE}/`,
      onDocumentChanged
    };
  }
});
