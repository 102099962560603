import { boot } from 'quasar/wrappers';
import { ApiService } from '@ligo/shared/utils';
import { RESOURCES } from '@ligo/dashboard/customer/store';
import { loadCompanyFromStorage } from '@ligo/dashboard/customer/components';

export default boot(({ Vue }) => {
  console.log('______ Init API Service ____');
  ApiService.init(RESOURCES.BASE);
  loadCompanyFromStorage();
  Vue.prototype.$api = ApiService;
});
