import Vue from 'vue';

export function useTooltipsInteractions() {
  function mouseMoveEvent(
    event,
    isFieldShowingMenu: boolean,
    showingMenu: boolean
  ) {
    removeTooltip();
    if (!isFieldShowingMenu && !showingMenu) {
      const tooltip = document.createElement('div');
      tooltip.id = 'e-sign-page-tooltip';
      tooltip.style.top = `${event.pageY + 20}px`;
      tooltip.style.left = `${event.pageX + 20}px`;
      tooltip.classList.add('font-12');
      tooltip.classList.add('w-400');
      tooltip.textContent = Vue['i18n'].t(
        'e_signature.prepare_document.page.tooltip'
      );

      document.body.appendChild(tooltip);
    }
  }

  function removeTooltip() {
    const tooltip = document.getElementById('e-sign-page-tooltip');
    if (tooltip) {
      document.body.removeChild(tooltip);
    }
  }

  document.addEventListener('scroll', removeTooltip);

  return { mouseMoveEvent, removeTooltip };
}
