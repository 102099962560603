export default {
  quote: {
    id: 'Quote ID : ',
    content: 'Your quote :',
    note: 'Answer to your quote',
    add_quote: 'Request new quote'
  },
  add_quote_banner: {
    card_1_title: 'Bespreek uw juridische kwestie',
    card_1_text:
      'Vertel ons over uw zaak en geef aan op welk rechtsgebied het betrekking heeft',
    card_2_title: 'Dien uw aanvraag in',
    card_2_text:
      'We zullen vooraf een lijst met advocaten selecteren die het beste bij uw zaak passen',
    card_3_title: 'Kies uw advocaat',
    card_3_text:
      'Kies de advocaat waarmee u wilt werken en zorg dat u dingen voor elkaar krijgt'
  },
  submit_quote: 'Offerte indienen',
  upload_file: 'Een bestand uploaden',
  form: {
    title: 'Beschrijf hier het werk dat gedaan moet worden',
    textarea_placeholder:
      'bijv. : Ik heb een advocaat nodig om algemene voorwaarden op te stellen. Wij zijn een bedrijf dat software ontwikkelt voor Amerikaanse klanten. Daarnaast willen we graag advies over eventuele gegevensbeschermingsregels waar we het meest rekening mee houden.',
    file_picker_title:
      'Sleep uw bestand naar dit gebied of klik om een ​​bestand te uploaden'
  },
  msg: {
    load: {
      success: 'Quote loaded successfully',
      error: 'An error occurred while the quote was loading'
    },
    delete: {
      success: 'The quote was remove successfully',
      error: 'An error occurred while the quote was removing'
    }
  }
};
