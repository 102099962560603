import { computed, Ref } from '@vue/composition-api';
import { notifyAsync, RootContext } from '@ligo/shared/utils';
import { initWebSocket, getBaseSocketURL } from '@ligo/shared/utils';
import {
  NotaryProduct,
  NotaryProductEvent,
  NotaryProductStage,
  NotaryProductTypeSlug
} from '@ligo/dashboard/store';
import { NotaryProductService } from '@ligo/dashboard/customer/store';
import { AxiosPromise } from 'axios';

const stages = [
  NotaryProductStage.EXTRA_QUESTIONS,
  NotaryProductStage.SUBMITTED,
  NotaryProductStage.CHECKING_DOCUMENTS,
  NotaryProductStage.UPLOADING_FCA,
  NotaryProductStage.SCHEDULING_MEETING,
  NotaryProductStage.EXECUTING_DEED,
  NotaryProductStage.EXTRACTING_KVK,
  NotaryProductStage.FINALIZED,
  NotaryProductStage.CANCELLED
];

const STEPPER_SIZE = 5;

function StageNumber(stage: NotaryProductStage): number {
  // To the user, the product stays in the checking documents stage whe ls is checking their documents
  if (stage == NotaryProductStage.LS_CHECKING_DOCUMENTS) return 2;

  const idx = stages.indexOf(stage);
  return Math.min(idx, STEPPER_SIZE);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useNotaryProduct(
  root: RootContext,
  notaryProductId: Ref<string>
) {
  function updateStore(notaryProductData) {
    root.$store.commit('layout/setDashboardTitle', notaryProductData.name);
    root.$store.dispatch('notaryProduct/saveNotaryProduct', notaryProductData);
  }

  const { url, channelIdentifier } = getBaseSocketURL(
    notaryProductId.value,
    'NotaryProductsChannel'
  );

  const { state, loading, load } = initWebSocket<
    NotaryProduct,
    AxiosPromise<NotaryProduct>
  >(
    () => NotaryProductService.getById(notaryProductId.value),
    undefined,
    url,
    channelIdentifier,
    (wsState: Ref<NotaryProduct>, response: { data: NotaryProduct }) => {
      const notaryProduct = response.data;
      wsState.value = notaryProduct;
      updateStore(notaryProduct);
    }
  );

  const onNotaryEvent = (
    event: NotaryProductEvent,
    triggerNotificationToaster = true
  ) => {
    if (triggerNotificationToaster) {
      notifyAsync(
        NotaryProductService.changeStage(state.value.uuid, event).then(() => {
          load();
        }),
        root.$t('product.sent_success'),
        root.$t('product.sent_error')
      );
    } else {
      NotaryProductService.changeStage(state.value.uuid, event).then(() => {
        load();
      });
    }
  };

  const name = computed((): string => state.value?.name);
  const status = computed((): string => state.value?.status);
  const stage = computed(() => state.value?.stage);
  const stageNumber = computed((): number => StageNumber(state.value?.stage));
  const productType = computed(
    (): NotaryProductTypeSlug => state.value?.notary_product_type?.slug
  );
  const notary = computed(() => state.value?.notary);
  const productData = computed(() => state.value?.product_data);
  const notaryId = computed(
    () => notary.value?.id || productData.value?.['notary_id']
  );
  const vidyardTutorialId = computed(
    () => state.value?.notary_product_type?.vidyard_tutorial_id
  );

  return {
    name,
    status,
    stage,
    loading,
    productType,
    stageNumber,
    notary,
    load,
    onNotaryEvent,
    productData,
    notaryId,
    vidyardTutorialId
  };
}
