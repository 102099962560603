





















import { defineComponent } from '@vue/composition-api';
import StepHeader from '../modal-components/StepHeader.vue';
import BillingForm from './BillingForm.vue';
import NeedHelp from '../modal-components/NeedHelp.vue';

export default defineComponent({
  name: 'BillingStep',
  components: { StepHeader, BillingForm, NeedHelp },
  props: {
    paymentUuid: {
      type: String
    }
  }
});
