export default {
  notary: {
    title: 'Kies je notaris',
    availability: {
      low: 'Lage beschikbaarheid',
      medium: 'Middelmatige beschikbaarheid',
      high: 'Hoge beschikbaarheid'
    }
  },
  next: 'Volgende',
  cancel: 'Annuleer',
  other: 'Ander adres',
  addr: 'Woonadres',
  ai_placeholder:
    'Beschrijf in eigen woorden de activiteiten en laat AI het verbeteren voor je',
  ai_next: 'Verbeter met AI',
  select_dialog: {
    title: 'Bevestig uw notaris',
    prefix: 'Je hebt onze notaris',
    suffix:
      ' geselecteerd 👍. Bevestig jouw notaris. Als je later van notaris wilt veranderen, kan je dit doen door contact op te nemen met ons ondersteuningsteam via de chat voordat je alle vereiste documenten indient.'
  },
  gpt_error:
    'Probeer opnieuw. De verbinding van externe AI tool (OpenAI) mislukt soms.'
};
