export const shareholderOptions = ['p4', 'p3', 'p2', 'p1'];

export const representationOptions = ['one_signature', 'two_signatures'];

export const offerTypeOptions = ['service', 'product'];

export const sellProductOptions = [
  'consumers',
  'companies',
  'consumers_and_businesses',
  'none'
];

export const whereAreSoldOptions = [
  'store_or_kiosk',
  'market',
  'street_trade',
  'internet',
  'from_home',
  'postorder',
  'not_applicable'
];

export const headquartersList = [
  'Aa en Hunze',
  'Aalsmeer',
  'Aalten',
  'Achtkarspelen',
  'Alblasserdam',
  'Albrandswaard',
  'Alkmaar',
  'Almelo',
  'Almere',
  'Alphen aan den Rijn',
  'Alphen-Chaam',
  'Altena',
  'Ameland',
  'Amersfoort',
  'Amstelveen',
  'Amsterdam',
  'Apeldoorn',
  'Arnhem',
  'Assen',
  'Asten',
  'Baarle-Nassau',
  'Baarn',
  'Barendrecht',
  'Barneveld',
  'Beek',
  'Beekdaelen',
  'Beemster',
  'Beesel',
  'Berg en Dal',
  'Bergeijk',
  'Bergen',
  'Bergen',
  'Bergen op Zoom',
  'Berkelland',
  'Bernheze',
  'Best',
  'Beuningen',
  'Beverwijk',
  'Bladel',
  'Blaricum',
  'Bloemendaal',
  'Bodegraven-Reeuwijk',
  'Boekel',
  'Borger-Odoorn',
  'Borne',
  'Borsele',
  'Boxmeer',
  'Boxtel',
  'Breda',
  'Brielle',
  'Bronckhorst',
  'Brummen',
  'Brunssum',
  'Bunnik',
  'Bunschoten',
  'Buren',
  'Capelle aan den IJssel',
  'Castricum',
  'Coevorden',
  'Cranendonck',
  'Cuijk',
  'Culemborg',
  'Dalfsen',
  'Dantumadeel',
  'De Bilt',
  'De Friese Meren',
  'De Ronde Venen',
  'De Wolden',
  'Delft',
  'Den Haag (s-Gravenhage)',
  'Den Helder',
  'Deurne',
  'Deventer',
  'Diemen',
  'Dinkelland',
  'Doesburg',
  'Doetinchem',
  'Dongen',
  'Dordrecht',
  'Drechterland',
  'Drimmelen',
  'Dronten',
  'Druten',
  'Duiven',
  'Echt-Susteren',
  'Edam-Volendam',
  'Ede',
  'Eemnes',
  'Eemsdelta',
  'Eersel',
  'Eijsden-Margraten',
  'Eindhoven',
  'Elburg',
  'Emmen',
  'Enkhuizen',
  'Enschede',
  'Epe',
  'Ermelo',
  'Etten-Leur',
  'Geertruidenberg',
  'Geldrop-Mierlo',
  'Gemert-Bakel',
  'Gennep',
  'Gilze en Rijen',
  'Goeree-Overflakkee',
  'Goes',
  'Goirle',
  'Gooise Meren',
  'Gorinchem',
  'Gouda',
  'Grave',
  'Groningen',
  'Gulpen-Wittem',
  'Haaksbergen',
  'Haarlem',
  'Haarlemmermeer',
  'Halderberge',
  'Hardenberg',
  'Harderwijk',
  'Hardinxveld-Giessendam',
  'Harlingen',
  'Hattem',
  'Heemskerk',
  'Heemstede',
  'Heerde',
  'Heerenveen',
  'Heerhugowaard',
  'Heerlen',
  'Heeze-Leende',
  'Heiloo',
  'Hellendoorn',
  'Hellevoetsluis',
  'Helmond',
  'Hendrik-Ido-Ambacht',
  'Hengelo',
  's-Hertogenbosch',
  'Het Hogeland',
  'Heumen',
  'Heusden',
  'Hillegom',
  'Hilvarenbeek',
  'Hilversum',
  'Hoeksche Waard',
  'Hof van Twente',
  'Hollands Kroon',
  'Hoogeveen',
  'Hoorn',
  'Horst aan de Maas',
  'Houten',
  'Huizen',
  'Hulst',
  'IJsselstein',
  'Kaag en Braassem',
  'Kampen',
  'Kapelle',
  'Katwijk',
  'Kerkrade',
  'Koggenland',
  'Krimpen aan den IJssel',
  'Krimpenerwaard',
  'Laarbeek',
  'Landerd',
  'Landgraaf',
  'Landsmeer',
  'Langedijk',
  'Lansingerland',
  'Laren',
  'Leeuwarden',
  'Leiden',
  'Leiderdorp',
  'Leidschendam-Voorburg',
  'Lelystad',
  'Leudal',
  'Leusden',
  'Lingewaard',
  'Lisse',
  'Lochem',
  'Loon op Zand',
  'Lopik',
  'Losser',
  'Maasdriel',
  'Maasgouw',
  'Maassluis',
  'Maastricht',
  'Medemblik',
  'Meerssen',
  'Meierijstad',
  'Meppel',
  'Middelburg',
  'Midden-Delfland',
  'Midden-Drenthe',
  'Midden-Groningen',
  'Mill en Sint Hubert',
  'Moerdijk',
  'Molenlanden',
  'Montferland',
  'Montfoort',
  'Mook en Middelaar',
  'Neder-Betuwe',
  'Nederweert',
  'Nieuwegein',
  'Nieuwkoop',
  'Nijkerk',
  'Nijmegen',
  'Nissewaard',
  'Noardeast-Fryslân',
  'Noord-Beveland',
  'Noordenveld',
  'Noordoostpolder',
  'Noordwijk',
  'Nuenen c.a.',
  'Nunspeet',
  'Oegstgeest',
  'Oirschot',
  'Oisterwijk',
  'Oldambt',
  'Oldebroek',
  'Oldenzaal',
  'Olst-Wijhe',
  'Ommen',
  'Oost Gelre',
  'Oosterhout',
  'Ooststellingwerf',
  'Oostzaan',
  'Opmeer',
  'Opsterland',
  'Oss',
  'Oude IJsselstreek',
  'Ouder-Amstel',
  'Oudewater',
  'Overbetuwe',
  'Papendrecht',
  'Peel en Maas',
  'Pekela',
  'Pijnacker-Nootdorp',
  'Purmerend',
  'Putten',
  'Raalte',
  'Reimerswaal',
  'Renkum',
  'Renswoude',
  'Reusel-De Mierden',
  'Rheden',
  'Rhenen',
  'Ridderkerk',
  'Rijssen-Holten',
  'Rijswijk',
  'Roerdalen',
  'Roermond',
  'Roosendaal',
  'Rotterdam',
  'Rozendaal',
  'Rucphen',
  'Schagen',
  'Scherpenzeel',
  'Schiedam',
  'Schiermonnikoog',
  'Schouwen-Duiveland',
  'Simpelveld',
  'Sint Anthonis',
  'Sint-Michielsgestel',
  'Sittard-Geleen',
  'Sliedrecht',
  'Sluis',
  'Smallingerland',
  'Soest',
  'Someren',
  'Son en Breugel',
  'Stadskanaal',
  'Staphorst',
  'Stede Broec',
  'Steenbergen',
  'Steenwijkerland',
  'Stein',
  'Stichtse Vecht',
  'Súdwest-Fryslân',
  'Terneuzen',
  'Terschelling',
  'Texel',
  'Teylingen',
  'Tholen',
  'Tiel',
  'Tilburg',
  'Tubbergen',
  'Twenterand',
  'Tynaarlo',
  'Tietjerksteradeel',
  'Uden',
  'Uitgeest',
  'Uithoorn',
  'Urk',
  'Utrecht',
  'Utrechtse Heuvelrug',
  'Vaals',
  'Valkenburg aan de Geul',
  'Valkenswaard',
  'Veendam',
  'Veenendaal',
  'Veere',
  'Veldhoven',
  'Velsen',
  'Venlo',
  'Venray',
  'Vijfheerenlanden',
  'Vlaardingen',
  'Vlieland',
  'Vlissingen',
  'Voerendaal',
  'Voorschoten',
  'Voorst',
  'Vught',
  'Waadhoeke',
  'Waalre',
  'Waalwijk',
  'Waddinxveen',
  'Wageningen',
  'Wassenaar',
  'Waterland',
  'Weert',
  'Weesp',
  'West Betuwe',
  'West Maas en Waal',
  'Westerkwartier',
  'Westerveld',
  'Westervoort',
  'Westerwolde',
  'Westland',
  'Weststellingwerf',
  'Westvoorne',
  'Wierden',
  'Wijchen',
  'Wijdemeren',
  'Wijk bij Duurstede',
  'Winterswijk',
  'Woensdrecht',
  'Woerden',
  'Wormerland',
  'Woudenberg',
  'Zaanstad',
  'Zaltbommel',
  'Zandvoort',
  'Zeewolde',
  'Zeist',
  'Zevenaar',
  'Zoetermeer',
  'Zoeterwoude',
  'Zuidplas',
  'Zundert',
  'Zutphen',
  'Zwartewaterland',
  'Zwijndrecht',
  'Zwolle'
];
