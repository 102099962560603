
































import { defineComponent } from '@vue/composition-api';
import { ABtn } from '../app/buttons';
export default defineComponent({
  name: 'AlertModal',
  components: {
    ABtn
  },
  props: {
    title: String,
    subtitle: String,
    cancelLabel: {
      type: String,
      required: true
    },
    confirmLabel: {
      type: String,
      required: true
    },

    cancelColor: {
      type: String,
      default: 'subdued'
    },
    confirmColor: {
      type: String,
      default: 'np-red-1000'
    },

    value: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    return {};
  }
});
