import { ref, computed } from '@vue/composition-api';
import { useAsyncResult, useSingleAsyncResult } from '@ligo/shared/utils';
import {
  LawyerService,
  LawyerApi,
  LawyerCard,
  LawyerProfile
} from '@ligo/dashboard/customer/store';
import { useI18n, useRouter } from '../../composables';

function lawyerToCard(lawyer: LawyerApi): LawyerCard {
  const i18n = useI18n();
  return {
    image: lawyer.avatar_image,
    phone: lawyer.phone,
    fee: i18n.locale !== 'nl' ? lawyer.en.fee : lawyer.nl.fee,
    description:
      i18n.locale !== 'nl'
        ? lawyer.en.short_description
        : lawyer.nl.short_description,
    email: lawyer.email,
    name: `${lawyer.firstname} ${lawyer.lastname}`,
    tags: lawyer.law_practices.map((lawPractice) =>
      i18n.locale !== 'nl' ? lawPractice.name_en : lawPractice.name_nl
    ),
    slug: lawyer.slug,
    firm: lawyer.firm,
    role: i18n.locale !== 'nl' ? lawyer.en.role : lawyer.nl.role
  };
}

function lawyerToProfile(lawyer: LawyerApi): LawyerProfile {
  const i18n = useI18n();
  return {
    firstName: lawyer.firstname,
    lastName: lawyer.lastname,
    description:
      i18n.locale !== 'nl' ? lawyer.en.description : lawyer.nl.description,
    firm: lawyer.firm,
    card: lawyerToCard(lawyer)
  };
}

export function useLawyers() {
  const router = useRouter();
  const lawyerSlug = ref();

  const {
    state: lawyersCards,
    load: loadLawyersCards,
    loading: loadingLawyersCards
  } = useAsyncResult(
    LawyerService.lawyers,
    undefined,
    lawyerToCard,
    undefined,
    undefined,
    false
  );

  const {
    state: lawyerProfile,
    load: loadLawyerProfile,
    loading: loadingLawyerProfile
  } = useSingleAsyncResult(
    () => LawyerService.lawyer(lawyerSlug.value),
    [lawyerSlug],
    lawyerToProfile,
    undefined,
    (e: Error) => {
      if (e.message.includes('404')) {
        router.push({ name: 'Error404' });
      }
      return e;
    },
    false
  );

  const lawyerFullName = computed(() => {
    return [lawyerProfile.value.firstName, lawyerProfile.value.lastName].join(
      ' '
    );
  });

  const breadcrumbPath = computed(() => {
    const path: {
      name?: string;
      key?: string;
      link: string;
      query?: { [key: string]: string };
      disable?: boolean;
    }[] = [
      {
        key: 'lawyers.page.title',
        link: 'Lawyers',
        disable: router.currentRoute.name === 'Lawyers'
      }
    ];
    if (router.currentRoute.name === 'LawyerDetails') {
      path.push({
        name: lawyerFullName.value,
        link: 'LawyerDetails',
        disable: true
      });
    }
    return path;
  });

  function setLawyerSlug(slug: string) {
    lawyerSlug.value = slug;
  }

  return {
    lawyersCards,
    loadingLawyersCards,
    lawyerProfile,
    loadingLawyerProfile,
    lawyerFullName,
    breadcrumbPath,
    loadLawyersCards,
    loadLawyerProfile,
    setLawyerSlug
  };
}
