





























































































































































































import {
  computed,
  defineComponent,
  PropType,
  Ref,
  ref,
  watch
} from '@vue/composition-api';
import { useUploadDocuments } from './upload-documents.hook';
import State, { ActionState } from '../../global/ActionState.vue';
import {
  BvType,
  Notary,
  NotaryProductDocument,
  NotaryProductDocumentKind,
  NotaryProductNotaries,
  NotaryProductTypeSlug
} from '@ligo/dashboard/store';
import UploadDialog from '../../global/UploadDialog.vue';
import DocumentNameClickable from '../../global/DocumentNameClickable.vue';
import {
  QMarkdown,
  TooltipContent,
  VidyardVideoModal
} from '@ligo/shared/components';
import { useI18n, useStore } from '../../../composables';
import { getLastUpdatedDocument } from '../documents.hooks';
import { useDynamicText } from '../hooks/dynamicText.hooks';
import { APrimaryBtn } from '../../base/app/buttons';

const HELP_LIGO =
  'https://help.ligo.nl/nl/articles/6290633-what-is-legalisation-and-how-does-it-work';

const WARNING_ICON = 'img:/dashboard/icons/warning_info.svg';

export default defineComponent({
  name: 'UploadDocumentsHandler',
  components: {
    State,
    UploadDialog,
    DocumentNameClickable,
    QMarkdown,
    TooltipContent,
    VidyardVideoModal,
    APrimaryBtn
  },
  props: {
    productType: {
      type: String as PropType<NotaryProductTypeSlug>
    },
    notary: {
      type: Object as PropType<Notary>
    }
  },
  setup(props, { root, emit }) {
    const i18n = useI18n();
    const store = useStore();

    const {
      items,
      loading,
      form,
      file,
      notaryProductId,
      load
    } = useUploadDocuments(root);

    const { nt } = useDynamicText(i18n, props.notary);

    const fileRules = [
      (value: any) => !!value || i18n.t('documents.document_file_required')
    ];

    const state = computed<ActionState | string>(() => {
      if (items.value && items.value.length) {
        const count = items.value.filter((item) => item.file_url).length;

        if (count === 0) return 'todo';
        else if (count === items.value.length) return 'completed';
        else return 'in_progress';
      }
      return undefined;
    });

    const lastUpdated = computed(() => {
      return getLastUpdatedDocument(items.value);
    });

    const selectedDoc: Ref<NotaryProductDocument> = ref();
    const showVideoExplanation = ref(false);

    const onShowUpload = (doc: NotaryProductDocument) => {
      selectedDoc.value = doc;
      showUpload.value = true;
    };
    const showUpload = ref(false);

    watch(items, () => {
      if (items.value) {
        emit(
          'completed',
          items.value.every(
            (item) => item.kind == NotaryProductDocumentKind.USER_UPLOADED
          )
        );
      }
    });

    const notaryProduct = store.getters['notaryProduct/getNotaryProduct'];

    function getAddr() {
      return `${notaryProduct.notary?.street} ${notaryProduct.notary?.house_number}, ${notaryProduct.notary?.zip}, ${notaryProduct.notary?.city}`;
    }

    const isSpaarBV =
      notaryProduct.product_data.bv_type == BvType.PRIVATE_SAVINGS;

    const isInVgNotary = NotaryProductNotaries.VG == notaryProduct.notary?.slug;

    function onShowVideoExplanation(doc: NotaryProductDocument) {
      selectedDoc.value = doc;
      showVideoExplanation.value = true;
    }

    const currentAddressIsSameAsOneShareholder =
      notaryProduct.product_data.current_address_equals_home;

    return {
      items,
      loading,
      form,
      state,
      file,
      fileRules,
      notaryProductId,
      showUpload,
      selectedDoc,
      HELP_LIGO,
      isSpaarBV,
      WARNING_ICON,
      isInVgNotary,
      showVideoExplanation,
      lastUpdated,
      currentAddressIsSameAsOneShareholder,
      onShowVideoExplanation,
      onShowUpload,
      load,
      getAddr,
      nt
    };
  }
});
