





























































































































import { defineComponent, computed } from '@vue/composition-api';
import EditionSidebar from './EditionSidebar.vue';
import { useReminderManagerConfig } from './reminder-modals-config.hook';
import {
  ABtn,
  APrimaryBtn,
  ASecondaryBtn
} from '../../../components/base/app/buttons';
import { WYSIWYGEditor } from '@ligo/shared/components';

import moment from 'moment';
export default defineComponent({
  name: 'ESidebarCreate',
  components: {
    WYSIWYGEditor,
    EditionSidebar,
    ABtn,
    APrimaryBtn,
    ASecondaryBtn
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const {
      reminderName,
      reminderDate,
      reminderMessage,
      remindMeOn,
      canCreate,
      getOptionDate
    } = useReminderManagerConfig();

    function resetModal() {
      reminderName.value = '';
      reminderDate.value = '';
      reminderMessage.value = '';
      remindMeOn.value = '';
    }

    function updateMessage(newValue: string) {
      reminderMessage.value = newValue;
    }

    function getFormattedDate(date: any) {
      if (moment(date).isValid()) return moment(date).format('DD-MM-YYYY');
      else return date;
    }

    const reminderEvent = computed(() => {
      return {
        event_name: reminderName.value,
        event_date: reminderDate.value,
        message: reminderMessage.value,
        remind_me_on: remindMeOn.value
      };
    });

    return {
      reminderEvent,
      reminderName,
      reminderDate,
      remindMeOn,
      reminderMessage,
      canCreate,
      getFormattedDate,
      updateMessage,
      resetModal,
      getOptionDate
    };
  }
});
