import { Contract } from '@ligo/dashboard/customer/store';
import { computed, ref, Ref } from '@vue/composition-api';
import { EditorEvent } from '../../../analytics/analytic.service';
import { analyticGenericEditorEvent } from '../../../analytics/events';
import { useI18n, useRouter, useStore } from '../../../composables';

export function useContractPropsForHeaders(contract: Contract) {
  const router = useRouter();
  const i18 = useI18n();
  const store = useStore();
  function onEdit() {
    analyticGenericEditorEvent(
      EditorEvent.OPEN_EDITOR,
      contract,
      store.getters['authentication/user']
    );
    router.push({
      name: 'WordEditor',
      params: { contractUuid: contract.uuid }
    });
  }

  const showEdit = computed(
    () =>
      !contract.signed && !contract.signrequest_sent_at && contract.act_as_paid
  );

  const showUpload = ref(false);

  const uploadTooltip = computed(() =>
    contract.signed
      ? i18.t('contract.preview.cant_upload_been_signed')
      : contract.signrequest_sent_at
      ? i18.t('contract.preview.cant_upload_cancel_signing')
      : !contract.act_as_paid
      ? i18.t('contract.preview.cant_upload_need_upgrade')
      : ''
  );

  const editTooltip = computed(() =>
    contract.signed
      ? i18.t('contract.preview.cant_edit_been_signed')
      : contract.signrequest_sent_at
      ? i18.t('contract.preview.cant_edit_cancel_signing')
      : !contract.act_as_paid
      ? i18.t('contract.preview.cant_edit_need_upgrade')
      : ''
  );

  return {
    onEdit,
    showEdit,
    showUpload,
    uploadTooltip,
    editTooltip
  };
}
