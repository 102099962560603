import { store } from 'quasar/wrappers';
import Vuex from 'vuex';
import Vue from 'vue';
import { AuthActionHandlers, authModuleFactory } from '@ligo/shared/auth';
import {
  CustomerDashboardState,
  company,
  notaryProduct,
  layoutStore,
  RESOURCES,
  assistantStore
} from '@ligo/dashboard/customer/store';
import { Router } from '../router';
import { i18n } from '../boot/i18n';
import { pageModuleFactory } from '@ligo/shared/utils';

const pageInformationStore = pageModuleFactory(RESOURCES, i18n);

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

Vue.use(Vuex);

const authActionHandlers: AuthActionHandlers = {
  onAuth(dispatch, commit, userService) {
    userService.me().then((user) => {
      commit('setUser', user);
    });
  }
};

const authentication = authModuleFactory(
  RESOURCES,
  i18n,
  Router,
  authActionHandlers
);

export const Store = new Vuex.Store<CustomerDashboardState>({
  modules: {
    authentication,
    company,
    notaryProduct,
    layout: layoutStore,
    pageInformation: pageInformationStore,
    assistant: assistantStore
  },

  // enable strict mode (adds overhead!)
  // for dev mode only
  strict: !!process.env.DEBUGGING
});

export default store(function ({ Vue }) {
  Vue.use(Vuex);
  return Store;
});
