





















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ExpansionCard',
  props: {
    title: {
      type: String
    },
    defaultOpened: {
      type: Boolean,
      default: false
    },
    sideInformation: {
      type: String
    }
  },
  setup() {
    return {};
  }
});
