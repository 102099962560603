import {
  Contract,
  ContractBadgeStatus,
  SignerAASMState,
  Signer
} from '@ligo/dashboard/customer/store';
import { Ref, computed, ComputedRef } from '@vue/composition-api';
import moment from 'moment';
import { useI18n, useStore } from '../../../../composables';
import { ctaTypes, SignCard } from '../../../../models';
import { useMembershipInfo } from '../../../utils/membership-info';

export function useSignContract(contract: Ref<Contract>) {
  const i18n = useI18n();
  const store = useStore();

  const {
    availableSignatures,
    planSignatures,
    membershipName
  } = useMembershipInfo();
  const username = computed(() => store.getters['authentication/firstname']);
  const upgradeCTA = {
    type: ctaTypes.basic,
    label: i18n.t('contract.preview.upgrade'),
    emit: 'upgrade'
  };
  const singCTA = {
    type: ctaTypes.custom,
    label: i18n.t('contract.preview.sign_btn'),
    bgColor: 'primary',
    emit: 'go-to-signing'
  };
  const viewSigningLogCTA = {
    type: ctaTypes.default,
    label: i18n.t('contract.preview.download_log'),
    emit: 'download-log'
  };
  const revokeSingningCTA = {
    type: ctaTypes.custom,
    label: i18n.t('contract.preview.revoke_sign'),
    icon: 'img:/dashboard/icons/trash-can-xmark.svg',
    bgColor: 'red-light',
    textColor: 'red',
    borderColor: 'red-marker-light',
    emit: 'revoke-sign'
  };

  const signedBadge = {
    textColor: 'green',
    backgroundColor: 'green-light',
    text: i18n.t('contract.preview.signed')
  };
  const expiredBadge = {
    textColor: 'red',
    backgroundColor: 'red-light',
    text: i18n.t('contract.preview.expired')
  };
  const outForSigningBadge = {
    textColor: 'yellow',
    backgroundColor: 'yellow-light',
    text: i18n.t('contract.preview.out_for_signing')
  };
  const declinedBadge = {
    textColor: 'default',
    backgroundColor: 'red-light',
    text: i18n.t('contract.preview.declined')
  };

  const sign: SignCard = {
    title: i18n.t('contract.preview.sign_digitally.title'),
    text: i18n.t('contract.preview.sign_digitally.subtitle'),
    ctas: [singCTA]
  };

  const signaturesExceeded: SignCard = {
    topIcon: 'img:/dashboard/icons/sign_circled.svg',
    title: i18n.t('contract.preview.signatures_exceeded.title'),
    text: i18n.t('contract.preview.signatures_exceeded.subtitle', {
      name: username.value,
      amount: planSignatures.value,
      plan: membershipName.value
    }),
    ctas: [upgradeCTA]
  };
  const customUpload: SignCard = {
    title: i18n.t('contract.preview.signed_manually.title'),
    text: i18n.t('contract.preview.signed_manually.subtitle'),
    badge: signedBadge
  };

  function getRequestNotification() {
    const dateMessage = 'contract.preview.expires_on';
    const date = contract.value.signing_expiration_date;
    return date && !isContractDeclined.value
      ? `${i18n.t(dateMessage)}: ${moment(date).format('DD/MM/YYYY')}`
      : '';
  }

  const isContractDeclined = computed(() => {
    return contract.value.contract_status === ContractBadgeStatus.DECLINED;
  });

  const signersBadge = computed(() => {
    if (contract.value.signing_expired) {
      return expiredBadge;
    }
    if (contract.value.signed) {
      return signedBadge;
    }
    if (contract.value.contract_status === ContractBadgeStatus.DECLINED) {
      return declinedBadge;
    }
    return outForSigningBadge;
  });

  function signerMapper(s: Signer) {
    const signer = {
      email: s.email,
      icon: '',
      message: '',
      color: '',
      signer_uuid: s.uuid,
      session_uuid: s.signing_session.uuid,
      signed: false
    };
    if (s.aasm_state === SignerAASMState.DECLINED) {
      signer.message = `${i18n.t('contract.preview.decline_on')}: ${moment(
        s.declined_at
      ).format('DD/MM/YYYY HH:mm')}`;
      signer.color = 'red';
      signer.icon = 'mdi-close-circle';
    } else if (contract.value.signing_expired && !s.signed_at) {
      signer.message = i18n.t('contract.preview.not_signed').toString();
      signer.color = 'red';
    } else if (s.signed_at) {
      signer.message = `${i18n.t('contract.preview.signed_on')}: ${moment(
        s.signed_at
      ).format('DD/MM/YYYY HH:mm')}`;
      signer.color = 'green';
      signer.icon = 'mdi-check-circle';
      signer.signed = true;
    } else {
      signer.message = i18n.t('contract.preview.signed_yet').toString();
      signer.color = 'orange';
    }
    return signer;
  }

  const signatures = computed(() => contract.value.signers.map(signerMapper));

  const signaturesCard: ComputedRef<SignCard> = computed(() => ({
    title: i18n.t('contract.preview.sign_digitally.title'),
    badge: signersBadge.value,
    signatures: signatures.value,
    ctas:
      contract.value.signed || isContractDeclined.value
        ? [viewSigningLogCTA]
        : [revokeSingningCTA],
    requestNotification: getRequestNotification()
  }));

  const areSignaturesExceeded = computed(
    () => contract.value.uploaded && availableSignatures.value == 0
  );

  const card = computed(() => {
    if (areSignaturesExceeded.value) {
      return signaturesExceeded;
    } else if (
      contract.value.act_as_paid &&
      contract.value.signrequest_sent_at
    ) {
      return signaturesCard.value;
    } else if (contract.value.signed && contract.value.uploaded) {
      return customUpload;
    }
    return sign;
  });
  return { card };
}
