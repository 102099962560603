import { en_auth } from '@ligo/shared/auth';
import { en_customer_dashboard } from '@ligo/dashboard/customer/components';
import validations from './validations';
import notifications from './notifications';
import alerts from './alerts';
import table from './table';
import inactivity_modal from './inactivity_modal';

export default {
  en: 'English',
  nl: 'Dutch',
  go_back: 'Go Back',
  yes: 'Yes',
  no: 'No',
  next: 'Next',
  welcome: 'Welcome',
  back: 'back',
  close: 'Close',
  ask_ai: 'Ask AI',
  loading: 'Loading',
  welcome_to_ligo: 'Welcome to Ligo!',
  kind_reject: 'No, thanks',
  upgrade: 'Upgrade',
  upgrade_membership:
    'Upgrade to access unlimited 30min phone consultations with lawyers',
  upgrade_membership_banner: {
    title: 'Unlimited access to 100+ documents for your business',
    check1: 'Unlimited E-signing',
    check2: 'Unlimited 30min phone consultations with lawyers',
    check3: 'Templates in English and Dutch'
  },
  free: 'FREE',
  free_doc_text:
    'You have the option to select one free document once. Start the free document flow by selecting the one you want. Enjoy!',
  validations,
  alerts,
  table,
  notifications,
  inactivity_modal,
  ...en_auth,
  ...en_customer_dashboard,
  payment_messages: {
    notary_product: {
      title: `Yay! {productName} is nearly a reality.`,
      description:
        'In the next steps you will be asked to upload the required documents.',
      btn_label: `Continue`,
      title_transfer_np: `Yes! the transfer of shares {productName} is nearly a reality`,
      title_statuten_np: `Yes! the amendment of the articles of association of {productName} is nearly a reality`
    },
    contract: {
      title_prefix: 'Your',
      title_suffix:
        'has been created successfully. Amazing! Make it binding with E-signature.',
      btn_label: 'View document'
    },
    membership: {
      title_prefix: 'Congratulations! You now have a',
      title_suffix: 'plan.',
      description:
        'Start now with creating, adjusting and signing your important legal documents!',
      btn_label: 'Great! thanks'
    },
    all_fails: {
      title: 'Oh, oh... something went wrong',
      description:
        'Don’t worry, we’ll save your information. You can contact us at: <span style="color:#37CFB1;font-size:16px">020 303 1043</span>. We will help you out,'
    }
  },
  not_confirmed_user: {
    confirmation: 'Confirmation email',
    text:
      'Please confirm your email address before proceeding. You do this by clicking on the link in the email you received. This is necessary to maintain access to your account.',
    resend: 'Resend confirmation'
  },
  go_to_results: 'Go to results',
  custom_ad_hoc_date: {
    days: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
    daysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
    months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split(
      '_'
    ),
    monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
    firstDayOfWeek: 1, // 0-6, 0 - Sunday, 1 Monday, ...
    format24h: true,
    pluralDay: 'days'
  }
};
