import {
  ContractType,
  ContractService,
  EXTERNAL_RESOURCES
} from '@ligo/dashboard/customer/store';
import { ApiService, usePaginated, Dictionary } from '@ligo/shared/utils';
import { ref, onMounted } from '@vue/composition-api';
import { CategoryItem } from '../../../models';

const PER_PAGE = 10;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useTemplateList() {
  const search = ref('');
  const category = ref();

  const categoryLabels = ref<CategoryItem[]>();

  const { items, loading } = usePaginated<ContractType>(
    (params: Dictionary) => {
      if (category.value) {
        return ContractService.templates({
          ...params,
          contract_category_ids: [category.value]
        });
      }
      return ContractService.templates(params);
    },
    PER_PAGE,
    [],
    search
  );

  async function onStart(item: ContractType) {
    loading.value = true;
    const newContract = await ContractService.createFromTemplate(item.id);
    const url = EXTERNAL_RESOURCES.CONTRACTS(
      newContract.contract_type.slug,
      newContract.uuid
    );
    const path = EXTERNAL_RESOURCES.AUTHENTICATED_REDIRECTION(url);
    const response = await ApiService.auth_redirection(path);
    window.location.assign(response.request.responseURL);
  }

  async function getCategoriesToLabels() {
    const categories = await ContractService.categories();

    categoryLabels.value = categories.data.map((category) => {
      const categoryItem: CategoryItem = {
        id: category.id,
        name: category.name
      };
      return categoryItem;
    });
  }

  onMounted(() => {
    getCategoriesToLabels();
  });

  return {
    items,
    onStart,
    loading
  };
}
