import { computed, ComputedRef, ref, Ref } from '@vue/composition-api';
import { RootContext } from '@ligo/shared/utils';
import { NotaryProductService } from '@ligo/dashboard/customer/store';
import {
  DeprecatedServices,
  NotaryProduct,
  NotaryProductTypeSlug,
  UpsellPackageNotaryProductSlug
} from '@ligo/dashboard/store';
import {
  classifyProducts,
  NotaryProductClassification,
  NotaryProductTypes
} from './notary-product.hook';
import moment from 'moment';
import {
  SQTableRow,
  SQTableColumn,
  SQTableAction,
  SQTableFilter,
  SQTablePagination
} from './base/tables/s-q-table.hook';

export enum NPKinds {
  INCORPORATION = 'incorporation',
  MODIFICATION = 'modification'
}

const NPTSlugs = {
  ...NotaryProductTypeSlug,
  ...UpsellPackageNotaryProductSlug,
  ...DeprecatedServices
};
const INCORPORATIONS = [
  NPTSlugs.DUTCH_BV,
  NPTSlugs.ENGLISH_BV,
  NPTSlugs.ENGLISH_HOLDING,
  NPTSlugs.OMZETTING,
  NPTSlugs.STITCHING,
  NPTSlugs.ANBI_STITCHING,
  NPTSlugs.HOLDING,
  NPTSlugs.UPSELL_HOLDING,
  NPTSlugs.UPSELL_INCORPORATE_HOLDING,
  NPTSlugs.OLD_HOLDING,
  NPTSlugs.HOLDING_OPZETTEN
] as (
  | NotaryProductTypeSlug
  | UpsellPackageNotaryProductSlug
  | DeprecatedServices
)[];
const MODIFICATIONS = Object.values(NPTSlugs).filter(
  (slug) => !INCORPORATIONS.includes(slug)
);

function getNotaryProductTypeSlugs(type: string) {
  return type === NPKinds.INCORPORATION ? INCORPORATIONS : MODIFICATIONS;
}

export function useNotaryProductsTable(
  root: RootContext,
  emit: (event: string, ...args: any[]) => void,
  kind: NPKinds
) {
  const items = ref<NotaryProduct[]>([]);
  const itemsTotal = ref(0);
  const loading = ref(true);
  const currentKind = ref(kind);

  const actionItems = computed(() => {
    return items.value.map(itemsToRows);
  });

  const NPTypesSelection = computed(() =>
    Object.fromEntries(
      Object.entries(NotaryProductTypes).filter(([key]) => {
        return currentKind.value === NPKinds.INCORPORATION
          ? INCORPORATIONS.includes(key as NotaryProductTypeSlug)
          : MODIFICATIONS.includes(key as NotaryProductTypeSlug);
      })
    )
  );

  const columns: SQTableColumn[] = [
    {
      name: 'name',
      label: root.$t('page.notary_products.table.columns.name'),
      align: 'left',
      field: 'name',
      sortable: true
    },
    {
      name: 'status',
      label: root.$t('page.notary_products.table.columns.status'),
      align: 'left',
      field: 'status'
    },
    {
      name: 'type',
      label: root.$t('page.notary_products.table.columns.type'),
      align: 'left',
      field: 'type'
    },
    {
      name: 'due_date',
      label: root.$t('page.notary_products.table.columns.last_modified'),
      align: 'left',
      field: 'due_date'
    }
  ];

  const actions: SQTableAction[] = [
    {
      label: 'Delete',
      icon: 'mdi-delete',
      color: 'subdued',
      emit: 'delete',
      conditional: (row) => row.deletable
    }
  ];

  const filters: ComputedRef<SQTableFilter[]> = computed(() => [
    {
      type: 'query',
      name: 'query',
      label: root.$t('page.notary_products.table.filters.search'),
      model: ''
    },
    {
      type: 'single-select',
      name: 'status',
      label: root.$t('page.notary_products.table.filters.status'),
      model: '',
      options: Object.keys(NotaryProductClassification).map((key) => ({
        value: NotaryProductClassification[key].stages || [key],
        key: key,
        label: root.$t(`home.${key}`)
      })),
      optionClass: (opt) => {
        return `
        bg-${NotaryProductClassification[opt.key].color}
        text-${NotaryProductClassification[opt.key].textColor}
        q-px-sm
        q-py-xs
        border-radius-10
        text-capitalize
        `;
      },
      selectedClass:
        'q-px-sm q-py-xs border-radius-10 bg-surface-default text-default'
    },
    {
      type: 'multi-select',
      name: 'type',
      label: `${root.$t('page.notary_products.table.filters.types.label')}`,
      labelSelected: `${root.$t(
        'page.notary_products.table.filters.types.labelSelected'
      )}`,
      model: [],
      options: Object.keys(NPTypesSelection.value).map((key) => ({
        value: key,
        label: NPTypesSelection.value[key]
      })),
      optionClass: `
        bg-grey-light
        text-subdued
        q-px-sm
        q-py-xs
        border-radius-10
        text-capitalize
        `,
      selectedClass:
        'q-px-sm q-py-xs border-radius-10 bg-surface-default text-default'
    },
    {
      type: 'date-range',
      name: 'due_date',
      label: root.$t('page.notary_products.table.filters.last_modified'),
      model: {
        from: '',
        to: ''
      }
    }
  ]);

  const pagination: Ref<SQTablePagination> = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
  });

  const query = ref({
    query: undefined,
    stages: undefined,
    'last_modified_date_filter[gte]': undefined,
    'last_modified_date_filter[lte]': undefined,
    page: pagination.value.page,
    per_page: pagination.value.rowsPerPage,
    notary_product_types: getNotaryProductTypeSlugs(currentKind.value)
  });

  function getProduct(id: string) {
    const item = items.value.find(
      (product: NotaryProduct) => product.uuid == id
    );
    return item;
  }

  function onGoToQuestionare(id: string) {
    const url = getProduct(id).continue_link;
    if (url) {
      window.open(url, '_blank');
    }
  }

  async function onGoToDetails(uuid: string) {
    root.$router.push({
      name: 'NotaryProductDetail',
      params: { uuid: uuid }
    });
  }

  function onGoToPay(id: string) {
    window.location.assign(getProduct(id).payment_link);
  }

  async function deleteProduct(id: string): Promise<void> {
    loading.value = true;
    await NotaryProductService.delete(id);
    getItems();
  }

  function getTotal() {
    NotaryProductService.list(query.value).then((response) => {
      itemsTotal.value = parseInt(response.headers['x-total']);
    });
  }

  function getItems() {
    loading.value = true;
    NotaryProductService.list(query.value)
      .then((response) => {
        pagination.value.rowsNumber = response.headers['x-total'];
        emit('change-total', response.headers['x-total']);
        items.value = response.data;
      })
      .finally(() => {
        loading.value = false;
      })
      .catch((e) => {
        console.log('There was an error fetching recent activities', e);
      });
  }

  function search(filter, page, rowsPerPage, sortBy, descending) {
    query.value.query = filter['query'].model || undefined;
    query.value.stages =
      (filter['status'].model && filter['status'].model.value) || undefined;
    query.value.notary_product_types =
      filter['type'].model.map((type) => type.value) || undefined;

    if (query.value.notary_product_types.includes(NPTSlugs.ENGLISH_BV)) {
      query.value.notary_product_types.push(
        NPTSlugs.UPSELL_INCORPORATE_HOLDING
      );
    }

    if (query.value.notary_product_types.includes(NPTSlugs.HOLDING)) {
      query.value.notary_product_types.push(NPTSlugs.UPSELL_HOLDING);
    }

    if (!query.value.notary_product_types.length)
      query.value.notary_product_types = getNotaryProductTypeSlugs(
        currentKind.value
      );

    const from =
      filter['due_date']?.model &&
      new Date(filter['due_date']?.model?.from || filter['due_date']?.model);
    const to = new Date(filter['due_date']?.model?.to);

    const isValidFrom = from && !isNaN(from.getTime());
    const isValidTo = to && !isNaN(to.getTime());

    isValidTo && to.setDate(to.getDate() + 1);

    query.value['last_modified_date_filter[gte]'] =
      (isValidFrom && moment(from).format('YYYY-MM-DD')) || undefined;
    query.value['last_modified_date_filter[lte]'] =
      (isValidTo && moment(to).format('YYYY-MM-DD')) || undefined;

    query.value.page = page;
    query.value['per_page'] = rowsPerPage;

    pagination.value.sortBy = sortBy;
    pagination.value.descending = descending;
    pagination.value.page = page;
    pagination.value.rowsPerPage = rowsPerPage;

    getItems();
  }

  function itemsToRows(item: any): SQTableRow {
    const classification = classifyProducts(item);
    return {
      id: item.uuid,
      name: item.name,
      type: item.notary_product_type.name,
      ...classification,
      status: {
        value: root.$t(`home.${item.stage}`),
        textColor: classification.status.textColor,
        color: classification.status.color,
        radius: 10
      },
      due_date: item.recent_activity
        ? moment(item.recent_activity.last_viewed).format('DD MMM YYYY')
        : '-'
    };
  }

  function changeNotaryProductKind(kind: NPKinds) {
    currentKind.value = kind;

    pagination.value = {
      sortBy: 'desc',
      descending: false,
      page: 1,
      rowsPerPage: 10,
      rowsNumber: 10
    };

    query.value = {
      query: undefined,
      stages: undefined,
      'last_modified_date_filter[gte]': undefined,
      'last_modified_date_filter[lte]': undefined,
      page: pagination.value.page,
      per_page: pagination.value.rowsPerPage,
      notary_product_types: getNotaryProductTypeSlugs(currentKind.value)
    };

    getItems();
  }

  getItems();
  getTotal();

  return {
    actionItems,
    columns,
    loading,
    pagination,
    filters,
    actions,
    itemsTotal,
    changeNotaryProductKind,
    search,
    onGoToDetails,
    onGoToQuestionare,
    onGoToPay,
    deleteProduct
  };
}
