export default {
  login: {
    success: 'We are glad to have you back',
    error: 'There has been some trouble with your credentials'
  },
  register: {
    success: 'Welcome to Ligo',
    error: 'There has been a problem with your data'
  },
  confirmation: {
    success: 'Your account has been confirmed!!',
    error: 'There has been some trouble with your credentials'
  },
  general: {
    error: 'Action failed',
    success: 'Action was successful'
  }
};
