export default {
  popular: 'BV diensten',
  services_card: 'Dienst voor mijn onderneming',
  documents_card: 'Contracten voor mijn onderneming',
  create_document: 'Maak een contract',
  holding: 'Holding oprichten',
  bv: 'BV oprichten',
  all_services: 'Bekijk alle diensten',

  incorporate: 'BV oprichten',
  ask_lawyer: 'Ask a lawyer',
  transfer_shares: 'Transfer of shares',
  quote_request: 'Quotes requests',
  workflow: 'My workflows',

  name: 'Naam',
  service_type: 'Type dienst',
  status: 'Status',
  last_viewed: 'Laatst bekeken',

  checkup: {
    header_avg: 'AVG Privacy Scan',
    header_legal: 'Legal Compliance Scan',
    msg: 'Here you will know how your B.V. is doing regarding legal matters.',
    btn: 'Perform new checkup',
    free: 'Free',
    comming: 'Binnenkort'
  },

  information: {
    title: 'Welcome to the new Dashboard Beta!',
    text: `We're excited to show you our new and refreshed design.`,
    btn: 'Go to the old dashboard'
  },

  in_progress: 'Bewerking',
  open_mix: 'Vragenlijst',
  extra_questions: 'Extra vragen',
  submitted_mix: 'Documenten uploaden',
  checking_documents: 'Documenten controleren',
  legal_support_checking_documents: 'Documenten controleren',
  uploading_fca: 'Goedkeuring van FCA',
  scheduling_meeting: 'Identiteitsverificatie',
  executing_deed: 'Laatste stap',
  extracting_kvk: 'Wachten op KVK-nummer',
  on_hold: 'On hold',
  step: 'Stap',
  paid: 'Betaald',
  waiting_payment: 'Wacht op betaling',
  finalized: 'Afgerond',
  submitted: 'Verstuurd',
  add_new_service: 'Add new Service',
  canceled: 'Geannuleerd'
};
