import { Event } from '@ligo/dashboard/customer/store';
import { ref, computed } from '@vue/composition-api';
import { useReminderManager } from '../reminder-manager.hook';

export function useReminderManagerConfig() {
  //#region variables
  const reminderName = ref('');
  const reminderDate = ref('');
  const remindMeOn = ref('');
  const reminderMessage = ref('');
  const showCreateDialog = ref(false);
  const showListDialog = ref(false);
  const showUpdateDoalog = ref(false);
  const selectedReminder = ref();
  const {
    reminderList,
    completeReminderList,
    loadingReminders,
    loadReminders,
    createReminder,
    eventUpdate,
    eventComplete,
    eventDelete
  } = useReminderManager();

  //#endregion

  //#region computed functions

  const canCreate = computed(() => {
    return reminderName.value && reminderDate.value;
  });

  //#endregion

  //#region create reminder
  function onShowCreateDialog() {
    showCreateDialog.value = true;
  }

  function onCloseCreateDialog() {
    showCreateDialog.value = false;
  }

  function onCreateReminder(contractUuid: string, reminder: any) {
    showCreateDialog.value = false;
    reminder.contract_uuid = contractUuid;

    createReminder(contractUuid, reminder);
  }
  //#endregion

  //#region update reminder
  function onShowUpdateDialog(reminder: Event) {
    selectedReminder.value = reminder;
    showUpdateDoalog.value = true;
  }

  function onCloseUpdateDialog() {
    showUpdateDoalog.value = false;
  }

  function onUpdateReminder(contract_uuid: string, reminder: any) {
    showUpdateDoalog.value = false;
    eventUpdate(reminder, () => loadReminders(contract_uuid));
  }

  function onToggleCompleteReminder(contract_uuid: string, reminder: any) {
    showUpdateDoalog.value = false;
    eventComplete(reminder.id, () => loadReminders(contract_uuid));
  }
  //#endregion

  //#region delete reminder
  function onDeleteReminder(contract_uuid: string, reminder_id: number) {
    showUpdateDoalog.value = false;
    eventDelete(reminder_id, () => loadReminders(contract_uuid));
  }
  //#endregion

  function getOptionDate() {
    const date = new Date().toISOString();
    return date.substring(0, date.indexOf('T')).toString().replace(/-/g, '/');
  }

  return {
    reminderName,
    reminderDate,
    remindMeOn,
    canCreate,
    completeReminderList,
    loadingReminders,
    reminderList,
    showCreateDialog,
    showListDialog,
    showUpdateDoalog,
    selectedReminder,
    reminderMessage,
    loadReminders,
    onShowCreateDialog,
    onCloseCreateDialog,
    onCreateReminder,
    onShowUpdateDialog,
    onCloseUpdateDialog,
    onUpdateReminder,
    onDeleteReminder,
    onToggleCompleteReminder,
    getOptionDate
  };
}
