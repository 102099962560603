var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.signatures),function(sig,i){return _c('div',{key:("sig-" + i),staticClass:"font-12 w-400"},[_c('div',{class:("q-mt-" + (i > 0 && 'md'))},[_c('p',{staticClass:"q-mb-xs"},[_vm._v(" "+_vm._s(sig.email)+" ")])]),_c('div',{class:("row text-" + (sig.color))},[(sig.icon)?_c('div',{staticClass:"col-1"},[_c('q-icon',{attrs:{"color":sig.color,"name":sig.icon}})],1):_vm._e(),_c('div',{staticClass:"col-11"},[_vm._v(" "+_vm._s(sig.message)+" ")]),(sig.email == _vm.loggedUserEmail && !sig.signed)?_c('p',{staticClass:"text-primary cursor-pointer",on:{"click":function($event){return _vm.$router.push({
            name: 'SigningDocument',
            params: {
              contractUuid: _vm.contract.uuid,
              signerUuid: sig.signer_uuid,
              sessionUuid: sig.session_uuid
            },
            query: {
              origin: 'true'
            }
          })}}},[_vm._v(" "+_vm._s(_vm.$t('contract.preview.sign_now'))+" ")]):_vm._e()])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }