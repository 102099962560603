import {
  Contract,
  ContractService,
  WordEditorService
} from '@ligo/dashboard/customer/store';
import {
  Ref,
  ref,
  onBeforeMount,
  onMounted,
  watch,
  computed
} from '@vue/composition-api';
import { L10n } from '@syncfusion/ej2-base';
import Vue from 'vue';
import { Loading } from 'quasar';
import { en, nl } from './editor-18n';
import { getDefinitions } from './definitions';
import { useOnbeforeunload } from '@ligo/shared/utils';
import { useI18n, useStore } from '../../../composables';
import moment from 'moment';
import { analyticGenericEditorEvent } from '../../../analytics/events';
import { EditorEvent } from '../../../analytics/analytic.service';

export function useSfdtEditor(editor: Ref<any>, contract: Contract) {
  const sfdt = ref();
  const loading = ref(true);

  if (contract) {
    fetch(contract.last_revision.word_document)
      .then((res) => res.blob())
      .then(async (blob) => {
        sfdt.value = await WordEditorService.sfdtToDocx(blob);
        updateEditorSfdt();
      })
      .catch((e) => {
        console.log('There was a problem mounting the WordEditor : ', e);
      })
      .finally(() => {
        loading.value = false;
      });
  }
  function updateEditorSfdt() {
    editor.value.ej2Instances.documentEditor.open(sfdt.value);
  }

  async function getBlob() {
    return await editor.value.ej2Instances.documentEditor.saveAsBlob('Docx');
  }

  async function saveToContract(newName?: string): Promise<void> {
    const blob = await getBlob();
    const formData: FormData = new FormData();
    formData.append('file', blob, contract.name);
    ContractService.uploadContract(contract.uuid, formData);
    if (newName) {
      ContractService.update(contract.uuid, { name: newName });
    }
  }

  async function saveToContractAndExport() {
    await saveToContract();
    editor.value.ej2Instances.documentEditor.save('sample', 'Docx');
  }

  return { saveToContractAndExport, saveToContract, loading };
}

export function setupEditor(contractUuid: string) {
  const { items, loadingText, banner: bannerDefinition } = getDefinitions();
  const i18n = useI18n();
  const store = useStore();
  const banner = ref(bannerDefinition);

  L10n.load({
    nl: nl,
    'en-us': en
  });

  const contract = ref();
  const editorComponent = ref();
  const editor = ref();
  const documentChanged = ref(false);
  const showDiscardModal = ref(false);
  const REDIRECTION = {
    name: 'ContractPreview',
    params: { uuid: contractUuid }
  };

  function onContentChange() {
    documentChanged.value = true;
  }

  function onToolbarClick(args) {
    switch (args.item.id) {
      case 'Download':
        editor.value.saveToContractAndExport();
        break;
    }
  }

  function onSave() {
    if (documentChanged.value) {
      editor.value.saveToContract(contract.value.name).then(() => {
        documentChanged.value = false;
        analyticGenericEditorEvent(
          EditorEvent.SAVE_IN_EDITOR,
          contract.value,
          store.getters['authentication/user']
        );
        Vue['Router'].push(REDIRECTION);
      });
    }
  }

  function onDiscard() {
    if (documentChanged.value) {
      showDiscardModal.value = true;
    } else {
      onDiscardConfirm();
    }
  }

  function onDiscardConfirm() {
    analyticGenericEditorEvent(
      EditorEvent.CANCEL_EDITION,
      contract.value,
      store.getters['authentication/user']
    );
    Vue['Router'].push(REDIRECTION);
  }

  function onShowLoader(loadingText: string) {
    Loading.show({
      message: loadingText,
      customClass: 'text-white',
      spinnerColor: 'primary'
    });
  }

  function onHideLoader() {
    Loading.hide();
  }

  const locale = computed(() => {
    return Vue['i18n'].locale;
  });

  onBeforeMount(() => {
    onShowLoader(loadingText.loadingEditor);
  });

  onMounted(() => {
    ContractService.getById(contractUuid).then((response) => {
      onShowLoader(loadingText.LoadingDocument);
      const document = response.data;
      contract.value = document;
      const {
        loading,
        saveToContract,
        saveToContractAndExport
      } = useSfdtEditor(editorComponent, document);
      banner.value.subtitle = `${i18n.t(banner.value.subtitle)} ${moment(
        document.updated_at
      ).fromNow()}`;
      editor.value = { saveToContract, saveToContractAndExport };
      watch(loading, (newValue) => {
        if (!newValue) {
          onHideLoader();
        }
      });
    });
  });

  function onCreated() {
    // Set default past option
    editorComponent.value.ej2Instances.documentEditor.defaultPasteOption =
      'KeepTextOnly';
  }

  const contractName = computed(() => {
    return contract.value?.name;
  });

  watch(editorComponent, (newVal) => {
    if (newVal) {
      onCreated();
    }
  });

  useOnbeforeunload(documentChanged);

  return {
    items,
    editorComponent,
    documentChanged,
    showDiscardModal,
    banner,
    locale,
    contract,
    contractName,
    onDiscardConfirm,
    onToolbarClick,
    onSave,
    onDiscard,
    onContentChange
  };
}
