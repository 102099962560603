import { NotaryProductService } from '@ligo/dashboard/customer/store';
import { Dictionary } from '@ligo/shared/utils';
import { ref } from '@vue/composition-api';
import {
  SimpleField,
  SimpleForm
} from '../../../base/SimpleForm/models/simpleForm.models';
import { ExtraChangesPageInformation } from '../../../utils/useCustomerPageInformation.hooks';

export const useFormConfig = (
  uuid: string,
  fields: SimpleField[],
  key: string
) => {
  const loading = ref(false);

  const onSendInfo = (payload: Dictionary) => {
    return NotaryProductService.updateNotaryProduct(uuid, {
      product_data: {
        ...payload
      }
    });
  };

  const onNext = async (payload: Dictionary) => {
    loading.value = true;
    onSendInfo(payload).finally(() => {
      loading.value = false;
    });
  };

  const { t, te } = ExtraChangesPageInformation;

  const formConfig: SimpleForm = {
    translateT: t,
    translateTe: te,
    fields: fields,
    key: key,
    design: {
      titleClass:
        'q-py-md text-default font-20 w-600 text-dark row justify-around items-center',
      fieldContentClass: 'col-12 q-py-sm',
      actionsClass: 'full-width row justify-end q-pt-md',
      labelClass: 'font-14 q-mb-sm text-dark',
      transition: false
    }
  };

  return {
    t,
    loading,
    formConfig,
    onSendInfo,
    onNext
  };
};
