



















































import { defineComponent } from '@vue/composition-api';
import { ASecondaryBtn } from '../../../base/app/buttons';
import DocumentBanner from '../../base/DocumentBanner.vue';
import CustomerLangSelect from '../../../../layouts/CustomerLangSelect.vue';

export default defineComponent({
  name: 'ESignBanner',
  components: { DocumentBanner, CustomerLangSelect, ASecondaryBtn },
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    goBack: {
      type: Boolean,
      default: false
    },
    useMobileActions: {
      type: Boolean,
      default: false
    },
    pendingSignatures: {
      type: Number,
      default: 0
    }
  }
});
