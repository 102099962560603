





































import { defineComponent, ref } from '@vue/composition-api';
import { PropType } from 'vue';
import { SimpleField } from '../models/simpleForm.models';

const TEXT_AREA_HEIGHT = '230px';

export default defineComponent({
  name: 'BaseSimpleTextField',
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {},
    rules: {
      type: Array
    },
    loading: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    readonly: {
      type: Boolean
    },
    disable: {
      type: Boolean
    },
    hint: {
      type: String
    },
    formValue: {
      type: Object
    },
    config: {
      type: Object as PropType<SimpleField>
    }
  },
  setup(props, { emit }) {
    const field = ref(null);
    return {
      TEXT_AREA_HEIGHT,
      field,
      onInput(value: any) {
        if (field.value?.hasError) {
          field.value?.resetValidation();
        }
        if (props.config?.transform)
          emit('input', props.config?.transform(value));
        else emit('input', value);
      },
      getLength(value: any) {
        return value?.toString().length || 0;
      },
      onResetValidation() {
        field.value?.resetValidation();
      }
    };
  }
});
