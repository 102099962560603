export default {
  login: {
    success: 'We zijn blij je terug te hebben',
    error: 'Er zijn problemen opgetreden met uw inloggegevens'
  },
  register: {
    success: 'Welkom bij Ligo',
    error: 'Er is een probleem opgetreden met uw gegevens'
  },
  confirmation: {
    success: 'Uw account is bevestigd !!',
    error: 'Er zijn problemen opgetreden met uw inloggegevens'
  },
  general: {
    error: 'Actie: mislukt',
    success: 'Actie was succesvol'
  }
};
