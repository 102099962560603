

























import { defineComponent, ref } from '@vue/composition-api';
export default defineComponent({
  name: 'ScrollArea',
  props: {
    maxHeight: String
  },
  setup() {
    const scrollContainer = ref();
    return { scrollContainer };
  }
});
