import interact from 'interactjs';
import { basicMove } from './tools';

export function toDraggable(
  restriction = 'parent',
  callback?: (event: any) => void
) {
  return {
    listeners: {
      move(event) {
        basicMove(event);
        callback && callback(event);
      }
    },
    inertia: true,
    modifiers: [
      interact.modifiers.restrictRect({
        restriction: restriction,
        endOnly: true
      })
    ]
  };
}
