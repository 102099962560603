export default {
  notary: {
    title: 'Select your notary',
    availability: {
      low: 'Low availability',
      medium: 'Medium availability',
      high: 'High availability'
    }
  },
  next: 'Next',
  cancel: 'Cancel',
  other: 'Other address',
  addr: 'Residential address',
  ai_placeholder:
    ' Describe in your own words the activities and let AI improve it for you',
  ai_next: 'Improve with AI',
  select_dialog: {
    title: 'Confirm your notary',
    prefix: 'You have selected our notary',
    suffix:
      '👍. Please confirm your notary. If you want to change your notary later, you can do this by contacting our support team via the chat before submitting all required documents.'
  },
  gpt_error:
    'Please try again. The connection of the external AI tool (OpenAI) fails sometimes.'
};
