
































































































































import {
  computed,
  defineComponent,
  PropType,
  Ref,
  ref
} from '@vue/composition-api';
import State, { ActionState } from '../../global/ActionState.vue';
import {
  Notary,
  NotaryProductDocument,
  NotaryProductReadDocsInterface,
  NotaryProductTypeSlug
} from '@ligo/dashboard/store';
import { conditionsReadDocs } from './conditional-translations';
import { useTranslateNotaryProduct } from '../translation-utility';
import { VidyardVideoModal } from '@ligo/shared/components';
import { getLastUpdatedDocument } from '../documents.hooks';
import { useI18n } from '../../../composables';
import { useDynamicText } from '../hooks/dynamicText.hooks';
import { APrimaryBtn } from '../../base/app/buttons';

export default defineComponent({
  name: 'ReadDocumentsHandler',
  components: { VidyardVideoModal, State, APrimaryBtn },
  props: {
    productType: {
      type: String as PropType<NotaryProductTypeSlug>
    },
    readDocsAttrs: {
      type: Object as PropType<NotaryProductReadDocsInterface>,
      required: true
    },
    notary: {
      type: Object as PropType<Notary>
    }
  },
  setup(props) {
    const { tNPKey } = useTranslateNotaryProduct(conditionsReadDocs);

    const items = computed(() => props.readDocsAttrs.docList);
    const loading = computed(() => props.readDocsAttrs.loadingDocs);
    const onToggleRead = props.readDocsAttrs.readDoc;
    const onDownload = props.readDocsAttrs.downloadDoc;
    const selectedDoc: Ref<NotaryProductDocument> = ref();
    const showVideoExplanation = ref(false);

    const lastUpdated = computed(() => {
      return getLastUpdatedDocument(items.value);
    });
    const i18n = useI18n();
    const { nt } = useDynamicText(i18n, props.notary);

    const state = computed<ActionState | string>(() => {
      if (items.value && items.value.length) {
        const count = items.value.filter((item) => item.status).length;

        if (count === 0) return 'todo';
        else if (count === items.value.length) {
          return 'completed';
        } else return 'in_progress';
      }
      return undefined;
    });

    function onShowVideoExplanation(doc: NotaryProductDocument) {
      selectedDoc.value = doc;
      showVideoExplanation.value = true;
    }

    return {
      items,
      loading,
      selectedDoc,
      showVideoExplanation,
      state,
      lastUpdated,
      onToggleRead,
      onShowVideoExplanation,
      onDownload,
      tNPKey,
      nt
    };
  }
});
