import { nl_customer_dashboard } from '@ligo/dashboard/customer/components';
import { nl_auth } from '@ligo/shared/auth';
import validations from './validations';
import notifications from './notifications';
import alerts from './alerts';
import table from './table';
import inactivity_modal from './inactivity_modal';

export default {
  en: 'Engels',
  nl: 'Nederlands',
  go_back: 'Ga terug',
  yes: 'Ja',
  no: 'Nee',
  next: 'Volgende',
  welcome: 'Welcome',
  back: 'back',
  close: 'Close',
  ask_ai: 'Vraag AI',
  loading: 'Bezig met laden',
  welcome_to_ligo: 'Welkom bij Ligo!',
  kind_reject: 'Nee bedankt',
  upgrade: 'Upgrade',
  upgrade_membership:
    'Upgrade om toegang te krijgen tot onbeperkte telefonische consults van 30 minuten met advocaten',
  upgrade_membership_banner: {
    title: 'Krijg onbeperkte toegang tot 100+ documenten voor je bedrijf',
    check1: 'Onbeperkt digitale handtekeningen',
    check2: 'Onbeperkt 30-min telefonische consults met advocaten',
    check3: 'Templates in het Engels en Nederlands'
  },
  free: 'Gratis',
  free_doc_text: 'Je kan een van deze documenten gratis krijgen!',
  validations,
  alerts,
  table,
  notifications,
  inactivity_modal,
  ...nl_customer_dashboard,
  ...nl_auth,
  payment_messages: {
    notary_product: {
      title: `Yes! {productName} is bijna een feit.`,
      description:
        'In de volgende stappen word je gevraagd de vereiste documenten te uploaden.',
      btn_label: 'Doorgaan',
      title_transfer_np: `Ja! de aandelenoverdracht {productName} is bijna een feit`,
      title_statuten_np: `Ja! de statutenwijziging {productName} is bijna een feit`
    },
    contract: {
      title_prefix: 'Je',
      title_suffix:
        'is succesvol opgesteld. Geweldig! Maak het bindend met online ondertekenen.',
      btn_label: 'Bekijk document'
    },
    membership: {
      title_prefix: 'Hoera! Je hebt nu een',
      title_suffix: 'plan.',
      description:
        'Begin nu met het maken, aanpassen én ondertekenen van jouw belangrijke juridische documenten!',
      btn_label: 'Geweldig, bedankt!'
    },
    all_fails: {
      title: 'Oh, oh… er is iets fout gegaan',
      description:
        'Geen zorgen, we bewaren je gegevens. Je kunt contact met ons opnemen via: <span style="color:#37CFB1;font-size:16px">020 303 1043</span>. We zullen je verder helpen.'
    }
  },
  not_confirmed_user: {
    confirmation: 'Bevestigingsmail',
    text:
      'Bevestig je e-mailadres voordat je verder gaat. Dit doe je door op de link the klikken in de email die je hebt ontvangen. Dit is noodzakelijk om de toegang tot je account te behouden.',
    resend: 'Bevestigingsmail verzenden'
  },
  download: 'Downloaden',
  go_to_results: 'Ga naar resultaten',
  custom_ad_hoc_date: {
    days: 'Zondag_Maandag_Dinsdag_Woensdag_Donderdag_Vrijdag_Zaterdag'.split(
      '_'
    ),
    daysShort: 'Zo_Ma_Di_Wo_Do_Vr_Za'.split('_'),
    months: 'Januari_Februari_Maart_April_Mei_Juni_Juli_Augustus_September_Oktober_November_December'.split(
      '_'
    ),
    monthsShort: 'Jan_Feb_Mrt_Apr_Mei_Jun_Jul_Aug_Sep_Okt_Nov_Dec'.split('_'),
    firstDayOfWeek: 1, // 0-6, 0 - Sunday, 1 Monday, ...
    format24h: true,
    pluralDay: 'dagen'
  }
};
