






import { defineComponent } from '@vue/composition-api';
import ServiceTypesHandler from '../../components/service-types/ServiceTypes.handler.vue';
import BasePage from '../../components/base/BasePage.vue';

export default defineComponent({
  name: 'ServicesTypes',
  components: { ServiceTypesHandler, BasePage },
  setup() {
    return {};
  }
});
