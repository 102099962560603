




























import { defineComponent, PropType } from '@vue/composition-api';
import { ESignPage, ESignField, Signer } from '../../../../models';
import Field from './Field.vue';

export default defineComponent({
  name: 'ESignPage',
  components: {
    Field
  },
  props: {
    page: {
      type: Object as PropType<ESignPage>,
      required: true
    },
    fields: {
      type: Array as PropType<ESignField[]>,
      required: true
    },
    signers: {
      type: Array as PropType<Signer[]>,
      required: true
    }
  }
});
