







































import { Contract } from '@ligo/dashboard/customer/store';
import { defineComponent, PropType } from '@vue/composition-api';
import { useStore } from '../../../../composables';
import { Signature } from '../../../../models';

export default defineComponent({
  name: 'SignaturesList',
  props: {
    signatures: {
      type: Array as PropType<Signature[]>,
      requred: true
    },
    contract: {
      type: Object as PropType<Contract>,
      required: true
    }
  },
  setup() {
    const store = useStore();
    const loggedUserEmail = store.getters['authentication/userEmail'];
    return {
      loggedUserEmail
    };
  }
});
