import { computed, Ref, ref, watch } from '@vue/composition-api';

import { useAsyncResult, useAsyncState } from '@ligo/shared/utils';
import { NotificationService } from '@ligo/dashboard/customer/store';
import {
  Notification,
  notificationWithClickableOption
} from '@ligo/dashboard/store';
import { useRouter, useStore } from '../composables';
import { NavItem, LinkType, UserMenuItem } from '../models';

export const TOKEN_FOR_SEPARATOR = '--';

export function useNavItems() {
  const navItems: Ref<NavItem[]> = ref([
    {
      route: 'Home',
      icon: 'fas fa-grid-2',
      title: 'nav.home',
      subtitle: ''
    },
    {
      route: 'Reminders',
      icon: 'fas fa-list-check',
      title: 'nav.contract_reminders',
      subtitle: 'contract.reminders.no_items'
    },
    {
      route: 'Incorporations',
      icon: 'fas fa-building',
      title: 'nav.incorporations.title',
      subtitle: 'nav.incorporations.subtitle'
    },
    {
      route: 'Modifications',
      icon: 'fas fa-rotate',
      title: 'nav.modifications.title',
      subtitle: 'nav.modifications.subtitle'
    },
    {
      route: 'ActiveContracts',
      icon: 'fas fa-file-contract',
      title: 'nav.contracts_title',
      subtitle: 'nav.contracts_subtitle',
      closed: ref(true),
      children: [
        {
          route: 'DocumentsLibrary',
          title: 'nav.documents_library',
          icon: 'far fa-file-spreadsheet'
        },
        {
          route: 'SignatureHome',
          title: 'nav.signature_home',
          icon: 'far fa-signature',
          newItem: true
        }
      ]
    },
    {
      route: 'DgaServices',
      icon: 'fas fa-user',
      title: 'nav.dga_services'
    },
    {
      route: 'TaxServices',
      icon: 'fas fa-calculator',
      title: 'nav.tax_services'
    },
    {
      route: 'Banking',
      icon: 'fas fa-landmark',
      title: 'nav.banking'
    },
    {
      route: 'ServiceTypes',
      icon: 'fas fa-wrench',
      title: 'nav.discounts',
      subtitle: 'nav.discounts_subtitle'
    },
    {
      route: 'Lawyers',
      icon: 'fas fa-scale-balanced',
      title: 'nav.lawyers',
      subtitle: 'nav.lawyers_subtitle'
    }
  ]);

  return { navItems };
}

export function useUserMenuItems() {
  const store = useStore();
  const menuItems = computed(() => {
    const items: UserMenuItem[] = [
      {
        title: 'nav.settings',
        icon: 'img:/dashboard/icons/user.svg',
        link: 'Settings',
        type: LinkType.Route
      },
      {
        title: 'nav.account',
        icon: 'img:/dashboard/icons/credit-card.svg',
        link: 'Account',
        type: LinkType.Route
      },
      {
        title: 'nav.help',
        icon: 'img:/dashboard/icons/comment-question.svg',
        link: '',
        type: LinkType.OpenIntercom
      },
      { title: TOKEN_FOR_SEPARATOR },
      {
        title: 'nav.logout',
        icon: 'img:/dashboard/icons/right-to-bracket.svg',
        type: LinkType.Logout
      }
    ];

    return items;
  });

  // Hybrid users have an extra option to switch to old dashboard
  watch(
    () => store.getters['authentication/isHybrid'],
    () => {
      if (store.getters['authentication/isHybrid']) {
        menuItems.value.splice(4, 0, {
          title: TOKEN_FOR_SEPARATOR
        });
        menuItems.value.splice(5, 0, {
          title: 'Old Dashboard',
          icon: 'img:/dashboard/icons/redirect.svg',
          type: LinkType.OldDashboard
        });
      }
    }
  );

  const logout = menuItems.value.slice(-1)[0];

  return { menuItems, logout };
}

export function useNotifications() {
  const router = useRouter();
  const page = ref(1);
  let done = undefined;
  const items = ref([]);

  const { state, loading, last, load } = useAsyncResult(
    () => NotificationService.list({ page: page.value }),
    [page]
  );

  const { state: unseenCount, load: loadUnseen } = useAsyncState(
    () =>
      NotificationService.list({ unseen: true }).then((response) => {
        return parseInt(response.headers['x-total']);
      }),
    0
  );

  function onMarkAsSeen(ids: string[]) {
    loading.value = true;
    NotificationService.markAsSeen(ids).then(() => {
      page.value = 1;
      loadUnseen();
      load();
    });
  }

  function onScroll(event: () => void) {
    if (last.value) return;
    if (!done) done = event;
    page.value++;
  }

  function onSeeNotification(notification: Notification) {
    const npNotableType = 'NotaryProduct';
    const contractNotableType = 'Contract';
    const membershipType = 'Membership';

    if (!notification.seen_at) {
      onMarkAsSeen([notification.id]);
    }
    if (notification.notable_type == npNotableType)
      onNavigateToProduct(notification);
    else if (notification.notable_type == contractNotableType)
      onNavigateToContract(notification);
    else if (notification.notable_type == membershipType) goToAccount();
  }

  function goToAccount() {
    router.push({
      name: 'Account'
    });
  }

  function onNavigateToContract(notification: Notification) {
    router.push({
      name: 'ContractPreview',
      params: { uuid: notification.notable_uuid }
    });
  }

  function onNavigateToProduct(notification: Notification): void {
    if (
      router.currentRoute.name != 'NotaryProductDetail' ||
      router.currentRoute.params?.uuid != notification.notable_uuid
    ) {
      router.push({
        name: 'NotaryProductDetail',
        params: { uuid: notification.notable_uuid }
      });
    }
  }

  watch(state, (newItems) => {
    if (page.value > 1) {
      items.value = [...items.value, ...newItems];
    } else {
      items.value = newItems;
    }
    done && done();
  });

  const notifications = computed(() =>
    items.value.map(notificationWithClickableOption)
  );

  return {
    items: notifications,
    unseenCount,
    loadingNotifications: loading,
    lastNotifications: last,
    onScroll,
    onMarkAsSeen,
    onSeeNotification
  };
}
