export default {
  offers: {
    page_title: 'Get special benefits with our partners',
    redeem: 'Redeem offer',
    redeemed: 'Redeemed',
    upgrade: 'Upgrade to redeem',
    disclaimer:
      "If you click on 'Redeem Offer', you give consent to sharing your contact details with this partner."
  },
  msg: {
    part_1: 'Congratulations!',
    part_2:
      'Congratulations!! Our partner will contact you in one working day via email, so you can redeem your offer.'
  },
  ok: 'Ok',
  banner: {
    title: 'Get access to exclusive discounts from our partners',
    subtitle:
      'Find insurance for your company, an accountant to take care of your payroll and VAT submissions, and more, at a competitive price by using Ligo’s exclusive discounts.',
    btn: 'View discounts'
  }
};
