


































































import { defineComponent, computed } from '@vue/composition-api';

import { SQTable, StepsBanner } from '../base';
import ASecondaryBtn from '../base/app/buttons/ASecondaryBtn.vue';
import { useBanking } from './banking.hook';

export default defineComponent({
  name: 'BankingAccountsHandler',
  components: { StepsBanner, SQTable, ASecondaryBtn },
  setup() {
    const {
      columns,
      rows,
      pagination,
      loading,
      emptyState,
      t,
      isPageInformationReady,
      search
    } = useBanking();

    const usp_cards = computed(() => {
      if (isPageInformationReady()) return t('usp_cards');
      return [];
    });

    return {
      columns,
      rows,
      pagination,
      usp_cards,
      loading,
      emptyState,
      search,
      t,
      isPageInformationReady
    };
  }
});
