import {
  DocumentEditorContainerPlugin,
  DocumentEditorPlugin
} from '@syncfusion/ej2-vue-documenteditor';
import { boot } from 'quasar/wrappers';
import { registerLicense } from '@syncfusion/ej2-base';
import { useEnvironment } from '../../../../netlify_functions';

export default boot(({ Vue }) => {
  registerLicense(useEnvironment().SYNCFUSION_LICENSE);
  Vue.use(DocumentEditorPlugin);
  Vue.use(DocumentEditorContainerPlugin);
});
