var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-card',{staticClass:"q-py-md q-px-lg",attrs:{"bordered":"","flat":""}},[_c('q-card-section',{staticClass:"q-pa-none"},[_c('div',{staticClass:"row items-center"},[_c('q-item-section',{staticClass:"col-12 col-sm"},[_c('q-item-label',{staticClass:"font-16 w-600 text-default"},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")]),(_vm.hasMembership)?_c('div',[_c('q-item-label',{staticClass:"q-pt-md"},[_c('span',{staticClass:"font-14 w-400 text-default",domProps:{"innerHTML":_vm._s(_vm.$t('account.plan.subtitle', { plan: _vm.membershipName }))}})]),_c('q-item-label',{staticClass:"q-pt-md text-subdued"},[(!_vm.downgradeDate)?_c('span',{staticClass:"font-14 w-400 text-default"},[_vm._v(" "+_vm._s(_vm.$t('account.plan.renew', { date: _vm.nextBillingDate }))+" ")]):_c('span',{staticClass:"font-14 w-400 text-default"},[_vm._v(" "+_vm._s(_vm.$t('account.plan.downgrade', { date: _vm.downgradeDate }))+" ")])])],1):_c('q-item-label',{staticClass:"q-pt-md font-14 w-400"},[_vm._v(" "+_vm._s(_vm.$t('account.plan.free'))+" ")])],1),_c('div',{class:{
          'q-mt-lg': _vm.$q.screen.xs,
          'col-12 col-sm-4': true
        }},[(!_vm.hasMembership)?_c('a-primary-btn',{attrs:{"label":_vm.$t('account.plan.upgrade')},on:{"click":function($event){return _vm.$emit('upgrade')}}}):_c('div',{staticClass:"full-width row"},[(!_vm.downgradeDate)?_c('a-secondary-btn',{staticClass:"col-12 col-md",attrs:{"bordered":"","no-caps":"","flat":"","label":_vm.$t('account.plan.cancel'),"text-color":"subdued"},on:{"click":function($event){return _vm.$emit('cancel')}}}):_vm._e(),_c('a-secondary-btn',{class:{
              'q-ml-md': !_vm.downgradeDate && _vm.$q.screen.gt.sm,
              'q-mt-sm': _vm.$q.screen.lt.md,
              'col-12 col-md': true
            },attrs:{"label":_vm.$t('account.plan.change'),"bordered":""},on:{"click":function($event){return _vm.$emit('upgrade')}}})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }