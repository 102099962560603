import { Notify } from 'quasar';

const CIRCLE_CHECK = 'img:/dashboard/icons/circle-check.svg';

export function useAfterActionNotification(
  message: string,
  body: any,
  icon = CIRCLE_CHECK
) {
  return Notify.create({
    ...body,
    icon: icon,
    message: message
  });
}
