import { Condition } from '@ligo/dashboard/customer/store';
import { useI18n, useStore } from '../../../composables';
import { useStoreValue } from '@ligo/shared/utils';
import { NotaryProduct } from '@ligo/bv-flow/store';

export function useTranslateNotaryProduct(conditions: Condition[]) {
  const translations = {};
  const store = useStore();
  const i18n = useI18n();
  const notaryProduct = useStoreValue<NotaryProduct>(store)(
    'notaryProduct/getNotaryProduct'
  );
  for (const condition of conditions) {
    for (const [key1, key2] of condition.translations) {
      if (key1 in translations) {
        translations[key1].push([condition.condition, key2]);
      } else {
        translations[key1] = [[condition.condition, key2]];
      }
    }
  }

  function tNPKey(key: string) {
    if (!(key in translations)) {
      return key;
    }
    for (const [condition, key2] of translations[key]) {
      if (condition(notaryProduct.value)) return key2;
    }
    return key;
  }

  function tNP(key: string) {
    return i18n.t(tNPKey(key));
  }

  return { tNP, tNPKey };
}
