





























































































































































































































import {
  defineComponent,
  PropType,
  watch,
  computed
} from '@vue/composition-api';
import EditionSidebar from './EditionSidebar.vue';
import { Event } from '@ligo/dashboard/customer/store';
import { useReminderManagerConfig } from './reminder-modals-config.hook';
import {
  ABtn,
  APrimaryBtn,
  ASecondaryBtn
} from '../../../components/base/app/buttons';
import { WYSIWYGEditor } from '@ligo/shared/components';
import { remindersTimeIntervals } from '../reminder-manager.hook';
import { useI18n } from '../../../composables';
import PaidTaskBanner from './PaidTaskBanner.vue';
import moment from 'moment';

const ASSISTANT = {
  title: 'Wendy Bogers',
  subtitle: 'Founder & CEO',
  image: '/dashboard/images/wendy.svg',
  size: '50px'
};

export default defineComponent({
  name: 'ESidebarUpdate',
  components: {
    WYSIWYGEditor,
    EditionSidebar,
    PaidTaskBanner,
    ABtn,
    APrimaryBtn,
    ASecondaryBtn
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    reminder: {
      type: Object as PropType<Event>,
      required: true
    }
  },
  setup(props) {
    const {
      reminderName,
      reminderDate,
      remindMeOn,
      reminderMessage,
      canCreate,
      getOptionDate
    } = useReminderManagerConfig();

    const i18n = useI18n();

    const timeIntervalOptions: {
      [key in keyof typeof remindersTimeIntervals]?: string;
    } = {
      month: 'contract.reminders.modals.time_interval.month',
      quarter: 'contract.reminders.modals.time_interval.quarter',
      year: 'contract.reminders.modals.time_interval.year'
    };

    function updateModal() {
      if (props.reminder) {
        reminderName.value =
          i18n.locale == 'nl'
            ? props.reminder.nl.event_name
            : props.reminder.en.event_name;
        reminderDate.value = props.reminder.event_date;
        remindMeOn.value = props.reminder.remind_me_on;
        reminderMessage.value =
          (i18n.locale == 'nl'
            ? props.reminder.nl.message
            : props.reminder.en.message) || '';
      }
    }

    function updateMessage(newValue: string) {
      reminderMessage.value = newValue;
    }

    function getFormattedDate(date: any) {
      if (moment(date).isValid()) return moment(date).format('DD-MM-YYYY');
      else return date;
    }

    const reminderEvent = computed(() => {
      return {
        id: props.reminder?.id,
        event_name: reminderName.value,
        event_date: reminderDate.value,
        message: reminderMessage.value,
        remind_me_on: remindMeOn.value
      };
    });

    watch(
      () => props.reminder,
      () => {
        updateModal();
      },
      {
        immediate: true
      }
    );

    const showAssistant = computed(() => !!props.reminder.event_type);

    const visibleTask = computed(() => props.reminder.visible);

    return {
      ASSISTANT,
      canCreate,
      reminderName,
      reminderDate,
      reminderEvent,
      reminderMessage,
      remindMeOn,
      timeIntervalOptions,
      showAssistant,
      visibleTask,
      getFormattedDate,
      updateMessage,
      updateModal,
      getOptionDate
    };
  }
});
