export default {
  subtotal: 'Subtotaal',
  btw: 'BTW',
  total: 'Totaal',
  pay: 'Ga naar betaling',
  message: 'Voor vragen kun je ons bellen',
  secure: '100% veilig',
  rating_banner: {
    customer: {
      text: 'TEVREDEN KLANTEN'
    },
    kyioh: {
      text: 'Kyioh beoordelingsscore',
      details: '9.2 Beoordelingen'
    },
    google: {
      text: 'Google beoordelingsscore',
      details: '4.4 Beoordelingen'
    }
  },

  stepper: {
    header: 'Plan wijzigen',
    select_step: 'Kies je nieuwe plan',
    billing_step: 'Voeg factuurgegevens toe',
    pay_step: 'Betaal en geniet ',
    enterprise_step: 'Boek je afspraak met ons team',
    cancel: 'Annuleren',
    error_message: 'Er is een fout opgetreden',
    types: {
      ExtraService: 'Vul informatie in'
    },
    dropin_header: {
      text: `Als je hieronder op betaling klikt, ga je akkoord met Ligo's {url} en die van haar partners.`,
      link: 'Algemene Voorwaarden'
    },
    coupon: {
      apply: 'Toepassen',
      generic: 'Ongeldige code',
      success: 'Kortingscode succesvol toegepast',
      remove: 'Kortingscode verwijderen',
      no_product: 'Ongeldige code'
    }
  },
  footer: {
    support: 'Hulp nodig?',
    support_link: 'Chat met ons',
    next: 'Volgende',
    next_tooltip: 'Je moet een plan kiezen om door te gaan'
  },
  switcher: {
    monthly: 'Maandelijks',
    yearly: 'Jaarlijks',
    save: 'Bespaar tot 40%'
  },
  plan: {
    header: 'Hi {name}, ik ben Lisa de support manager van Ligo.',
    subtitle: 'Je kan je plan in 1 minuut wijzigen.',
    choose_billing: 'Wanneer wilt u betalen?',
    choose_plan: 'Naar welk plan zou je willen upgraden?',
    current_plan: 'Huidig plan',
    change: 'Wijziging van dit plan', // TODO: AI generated
    upon_request: 'Op verzoek'
  },
  billing: {
    title: 'Wat zijn je factuurgegevens?',
    subtitle:
      'Je kan dit altijd later aanpassen in je instellingen als dat nodig is.'
  },
  checkout: {
    title: 'Laaste stap {name}! Hoe zou je willen betalen?',
    subtitle:
      'Als je je betaling hebt afgerond krijg je volledige toegang tot je plan 🙌',
    vat: 'BTW (21%)',
    total: 'Totaal'
  },
  enjoy: {
    title: 'Je bent helemaal klaar {name}!',
    subtitle: 'Je vindt je factuur onder je accountoverzicht',
    done: 'Afronden',
    close: 'Afsluiten'
  },
  enterprise_schedule: {
    title: 'Dat is Spannend, {name}!',
    subtitle: 'We kunnen niet wachten om meer met je te bespreken.',
    card:
      'Hieronder kan je een verzoek doen om te bellen met Matias, het Head of Product van Ligo. Jullie bespreken samen hoe het ondernemingsplan werkt en hoe het je kan helpen om je bedrijfsjuridische activiteiten naar een hoger niveau te tillen.',
    cta: 'Vraag een bel-gesprek aan met Matias',
    footer: 'Je ontvangt een e-mail van Matias binnen 24 uur.'
  },
  enterprise_contacted: {
    title: 'We zijn ermee bezig! Matias neemt contact met je op binnen 24 uur',
    subtitle:
      'Neem in de tussentijd gerust contact op met ons team via de chat als je hulp nodig hebt.',
    ok: 'Oke'
  },
  downgrade_warn: {
    title: 'Weet je zeker dat je wil downgraden naar {plan} plan?',
    subtitle:
      'Door {toPlan} plan te downgraden, verlies je toegang tot sommige functies die zijn opgenomen in je {fromPlan} plan. Weet je zeker dat je wil doorgaan?',
    cta: 'Ja, ga door'
  },
  cancel: {
    header: 'Annuleren en downgraden naar het gratis plan',
    warn: {
      title: 'Hey {name}, ik ben Lisa, de support manager van Ligo.',
      subtitle:
        'Downgraden van het GRATIS plan betekent dat je toegang tot sommige functies verliest, inclusief in je {planName} plan. Weet je zeker dat je door wil gaan?',
      cta: 'Ja, ga door'
    },
    goodbye: {
      title: 'We hopen om je terug te zien in de toekomst, {name}',
      subtitle:
        'Je hebt succesvol je  {plan} plan geannuleerd. Je behoudt toegang tot {date}, het einde van je facturingscyclus, en je wordt daarna gedowngraded tot het GRATIS plan',
      cta: 'Oke'
    },
    form: {
      title: 'Nog een snelle vraag voordat je klaar bent',
      subtitle:
        'Voordat je downgrade zouden wij het fijn vinden om feedback te krijgen hoe wij Ligo beter kunnen maken voor iedereen. Het was een genoegen om jou als klant te hebben, en we zouden het fijn vinden om van je te leren.',
      q1: '1. Wat is je reden om te downgraden?',
      a1_1: 'Missende functies',
      a1_1_placeholder: 'Laat ons weten welke functies je mist...',
      a1_2: 'Perongeluk geüpgraded',
      a1_3: 'Te moeilijk om te gebruiken',
      a1_4: 'Een alternatief gebruiken',
      a1_5: 'Veiligheids- of nalevingsvereisten',
      a1_6: 'Bedrijf stopt of bezuinigt',
      a1_7: 'Niet genoeg gebruik van maken',
      a1_8: 'Te duur',
      a1_9: 'Iets anders',
      a1_10_placeholder: 'Tell us more...',
      q2: '2. Welke tools gebruik je dan?',
      a2_1: 'DocuSign',
      a2_2: 'DocuSign CLM',
      a2_3: 'SignRequest',
      a2_4: 'Juro',
      a2_5: 'Oneflow',
      a2_6: 'Concord',
      a2_7: 'Contractbook',
      a2_8: 'Precisely',
      a2_9: 'Ironclad',
      a2_10: 'Other',
      a2_10_placeholder: 'Tell us more...',
      q3: '3. Wil je nog graag nog iets aan ons kwijt?',
      a3_placeholder:
        'Ons team zal jouw feedback bekijken om Ligo beter te maken...',
      cta: 'Annuleer en downgrade gratis'
    }
  },
  pay_contract: {
    header: 'Contract kopen',
    stepper: {
      billing: 'Factuurgegevens toevoegen',
      checkout: 'Betaal'
    },
    billing: {
      title:
        'Wat zijn de factuurgegevens die we voor je factuur kunnen gebruiken?',
      subtitle:
        'Je kan dit altijd later aanpassen in je instellingen als dat nodig is.'
    },
    checkout: {
      title: 'Hoe zou je willen betalen?',
      subtitle:
        'Zodra je jouw betaling heeft voltooid, krijgt je volledige toegang tot dit contract 🙌',
      success:
        'Jouw betaling is gelukt. Je kan dit contract nu openen en bewerken.'
    }
  },
  line_items: {
    yearly_discount: 'Yearly discount',
    new_subscription_discount: 'New subscription discount',
    new_membership_discount: 'Verrekening betaalde maanden'
  }
};
