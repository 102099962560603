import { DefaultUser, Membership } from '@ligo/shared/auth';
import { computed, ComputedRef } from '@vue/composition-api';
import moment from 'moment';
import { useStore, useRouter, useI18n } from '../../composables';
import { PlanItem } from '../../models';

const FREE_MEMBERSHIP_SLUG = 'free';
const localeMapper = { 'en-us': 'en', nl: 'nl' };

export function useMembershipInfo() {
  const store = useStore();
  const router = useRouter();
  const i18n = useI18n();
  const lang = localeMapper[i18n.locale];

  const user: ComputedRef<DefaultUser> = computed(
    () => store.getters['authentication/user']
  );

  const membership: ComputedRef<Membership> = computed(
    () => store.getters['authentication/getMembership']
  );

  const hasMembership = computed(
    () => membership.value?.membership_type.slug != FREE_MEMBERSHIP_SLUG
  );

  const availableSignatures = computed(
    () => membership.value?.available_signatures
  );
  const availableTemplates = computed(
    () => membership.value?.available_templates
  );
  const didReceiveContractForFree = computed(
    () => !!user.value?.did_receive_contract_for_free
  );
  const planSignatures = computed(
    () => membership.value?.membership_type.signatures
  );
  const planTemplates = computed(
    () => membership.value?.membership_type.templates
  );

  const membershipName = computed(() => {
    const membership_type = membership.value?.membership_type;
    if (membership_type?.description) {
      return (
        JSON.parse(membership_type?.description)[lang].name ||
        membership_type?.name
      );
    } else return '';
  });

  const membershipBillingInterval = computed(
    () => membership.value?.membership_type.billing_interval
  );

  const membershipPrice = computed(
    () => membership.value?.membership_type.price
  );

  const membershipFeatures = computed(() => {
    const data = JSON.parse(membership.value.membership_type.description)[lang];
    return data.features as PlanItem[];
  });

  const nextBillingDate = computed(() => {
    return moment(membership.value?.next_billing_date).format('MMM Do YYYY');
  });

  const downgradeDate = computed(() => {
    // TODO: we need to define a new action for reactivate the membership
    if (!user.value?.downgrade_date) return false;
    return moment(user.value?.downgrade_date).format('MMM Do YYYY');
  });

  function redirectToUpgrade() {
    router.push({ name: 'LegalPlans' });
  }

  return {
    membership,
    hasMembership,
    downgradeDate,
    redirectToUpgrade,
    availableSignatures,
    availableTemplates,
    planSignatures,
    planTemplates,
    membershipName,
    membershipFeatures,
    nextBillingDate,
    membershipBillingInterval,
    membershipPrice,
    didReceiveContractForFree
  };
}
