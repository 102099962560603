







































import { defineComponent } from '@vue/composition-api';
import { ASecondaryBtn } from '../../../../components/base/app/buttons';

const DOCUMENT_IMG = '/dashboard/images/blur_document.png';
const LOGO = '/dashboard/icons/ligo.svg';

export default defineComponent({
  name: 'ErrorLayout',
  components: {
    ASecondaryBtn
  },
  props: {
    title: {
      type: String
    },
    text: {
      type: String
    },
    icon: {
      type: String,
      default: 'mdi-close-circle'
    },
    iconColor: {
      type: String,
      default: 'red'
    }
  },
  setup() {
    const isLogged = !!localStorage.getItem('jwt');
    const hasLocale = !!localStorage.getItem('locale');

    const redirectTo = isLogged
      ? '/dashboard/contract'
      : hasLocale
      ? '/dashboard/login'
      : '/dashboard/register';

    return { DOCUMENT_IMG, LOGO, redirectTo };
  }
});
