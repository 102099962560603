import { useI18n } from '../../../composables';
import {
  SimpleField,
  SimpleFieldStatus
} from '../../base/SimpleForm/models/simpleForm.models';
import { NotaryProductSlug } from '@ligo/dashboard/store';

export const SEPARATOR_TOKEN = '---';

export const counterWords = (value: string) => {
  return !!value ? value.trim().split(' ').length : 0;
};

const hideFieldForAddr: SimpleFieldStatus = (_, formValue) => {
  return formValue['current_address_equals_home'] == null;
};

const disabledFieldForAddr: SimpleFieldStatus = (_, formValue) => {
  if (formValue) return !!formValue['current_address_equals_home'];
};

const valueDependencyForAddr = (key: string, secondKey: string) => ({
  keys: ['current_address_equals_home'],
  handler: (formValue, field) => {
    if (!!formValue['current_address_equals_home'])
      return {
        [field.key]:
          formValue[`${formValue['current_address_equals_home']}`][key] ||
          formValue[`${formValue['current_address_equals_home']}`][secondKey]
      };
    return {
      [field.key]: ''
    };
  }
});

export const useCompanyNameField = (slug: NotaryProductSlug) => {
  const needBvSuffix = (value: string) => {
    return (
      value &&
      slug != NotaryProductSlug.ANBI_STICHTING &&
      slug != NotaryProductSlug.STICHTING_OPRICHTEN
    );
  };

  const getCompanyName = (value: string) => {
    if (needBvSuffix(value))
      return `${value.replace(/(BV|B.V.)$/g, '').trim()} B.V.`;
    return value;
  };

  return {
    key: 'company_name',
    rules: ['required', 'bv_suffix'],
    suffix: needBvSuffix('BV') ? 'B.V.' : undefined,
    defaultValue(formValue) {
      const value = formValue['company_name'];
      if (needBvSuffix(value)) return value.replace(/(BV|B.V.)$/g, '').trim();
      return value;
    },
    reducer(formValue, fc) {
      const value = formValue[fc.key];
      return {
        [fc.key]: getCompanyName(value)
      };
    }
  } as SimpleField;
};

export const useAddressFields = () => {
  const i18n = useI18n();
  return [
    {
      key: 'current_address_equals_home',
      type: 'select',
      labelBuilder: (label, formValue) => {
        return `${label} ${formValue.company_name}`;
      },
      rules: ['required'],
      reducer: (formValue, fc) => {
        if (!!formValue[fc.key])
          return {
            [fc.key]: !!formValue[fc.key],
            ['selected_shareholder_for_address']: formValue[fc.key] || null
          };
        return {
          [fc.key]: !!formValue[fc.key]
        };
      },
      defaultValue: (formValue) => {
        const value = formValue['current_address_equals_home'];
        if (value) {
          const selected = formValue['selected_shareholder_for_address'];
          return selected;
        } else {
          return 0;
        }
      },
      selectable: {
        options: (formValue) => {
          return ['p4', 'p3', 'p2', 'p1']
            .filter((p) => Object.keys(formValue).indexOf(p) >= 0)
            .reduce(
              (prev, p) => {
                return [
                  {
                    id: p,
                    name: `${i18n.t('extra_questions.addr')} ${
                      formValue[p].firstname
                    } ${formValue[p].lastname}`
                  },
                  ...prev
                ];
              },
              [
                {
                  id: 0,
                  name: i18n.t('extra_questions.other')
                }
              ]
            );
        }
      }
    },
    {
      key: 'office_address',
      type: 'text',
      hideOn: hideFieldForAddr,
      disableOn: disabledFieldForAddr,
      valueDependency: valueDependencyForAddr('company_address', 'address'),
      rules: ['required']
    },
    {
      key: 'postal_code',
      type: 'text',
      hideOn: hideFieldForAddr,
      disableOn: disabledFieldForAddr,
      valueDependency: valueDependencyForAddr('company_zipcode', 'zip_code'),
      rules: ['required']
    },
    {
      key: 'place_of_business',
      type: 'text',
      hideOn: hideFieldForAddr,
      disableOn: disabledFieldForAddr,
      valueDependency: valueDependencyForAddr('company_location', 'location'),
      rules: ['required']
    }
  ] as SimpleField[];
};

export const activitiesField: SimpleField = {
  key: 'activities',
  type: 'textarea',
  counter: true,
  rules: ['min_length_80']
};
