import { Ref } from '@vue/composition-api';
import { signatureService } from '@ligo/dashboard/customer/store';
import { Signer } from '../../../../models';

const COLORS = [
  '#00BC98cd',
  '#37CFB1cd',
  '#39A1FFcd',
  '#2A3554cd',
  '#7839FFcd',
  '#FF4351cd',
  '#F26E00cd',
  '#F2BD00cd',
  '#2BB839cd',
  '#37CFB1',
  '#2A3554',
  '#FF4351',
  '#F26E00',
  '#F2BD00',
  '#39A1FF',
  '#7839FF',
  '#2BB839',
  '#00BC98'
];

export function addEmail(
  email: string,
  uuid: string,
  signers: Ref<Signer[]>,
  callback: () => void
) {
  if (!signers.value.find((s) => s.email === email)) {
    signatureService.post
      .addSigner(uuid, email)
      .then(() => callback())
      .catch((e) =>
        console.log(`There was an error adding the signer: ${email} ${e}`)
      );
  }
}

export function editEmail(
  contractUuid: string,
  uuid: string,
  signers: Ref<Signer[]>,
  newEmail: string,
  callback?: () => void
) {
  if (!signers.value.find((s) => s.email === newEmail)) {
    const oldSigner = signers.value.find((s) => s.uuid === uuid);
    removeSignerColor(oldSigner.email);
    oldSigner.email = newEmail;
    signatureService.patch.signer(contractUuid, oldSigner).then(() => {
      saveSignerColor(newEmail);
      callback && callback();
    });
  }
}

export function removeEmail(
  signerUuid: string,
  contractUuid: string,
  callback: () => void
) {
  signatureService.delete
    .signer(contractUuid, signerUuid)
    .then(() => {
      callback();
    })
    .catch((e) => console.log(`There was an error deleting signer : ${e}`));
}

export function signersColorSetup() {
  if (!localStorage.getItem('signers')) {
    localStorage.setItem('signers', '{}');
  }

  const signers = JSON.parse(localStorage.getItem('signers'));
  Object.values(signers).forEach((color: string) => {
    if (COLORS.includes(color)) {
      COLORS.splice(COLORS.indexOf(color), 1);
    }
  });
}

export function saveSignerColor(email: string) {
  let signers = JSON.parse(localStorage.getItem('signers'));
  if (!signers) {
    signers = {};
  }
  signers[email] = COLORS.pop();
  localStorage.setItem('signers', JSON.stringify(signers));
}

export function getSignerColor(email: string) {
  const signers = JSON.parse(localStorage.getItem('signers') || '{}');
  if (signers) return signers[email];
  return '#000000';
}

export function removeSignerColor(email: string) {
  const signers = JSON.parse(localStorage.getItem('signers') || '{}');
  COLORS.push(signers[email]);
  delete signers[email];
  localStorage.setItem('signers', JSON.stringify(signers));
}
