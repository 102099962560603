


































































import { defineComponent, ref } from '@vue/composition-api';
import { EXTERNAL_RESOURCES } from '@ligo/dashboard/customer/store';
import BasePage from '../components/base/BasePage.vue';
import LegalCheckupResult from '../components/LegalCheckups/LegalCheckupResult.vue';
import EmptyBanner from '../components/global/EmptyBanner.vue';
import { useLegalCheckup } from '../components/legal-checkups.hook';
import { ASecondaryBtn } from '../components/base/app/buttons';

export default defineComponent({
  components: {
    BasePage,
    LegalCheckupResult,
    EmptyBanner,
    ASecondaryBtn
  },
  setup() {
    const checkups = ref();
    const checkupTypes = ref();
    const loading = ref(true);
    const activeCheckups = ref([]);
    const tab = ref();

    const { load, getLastCheckupFromType } = useLegalCheckup();

    load().then((data) => {
      checkups.value = data.checkups;
      checkupTypes.value = data.checkupTypes;

      //Open by default in the first tab
      tab.value = checkupTypes.value[0].slug;

      checkupTypes.value.forEach((checkupType) => {
        const slug = checkupType.slug;
        activeCheckups.value[slug] = getLastCheckupFromType(slug);
      });
      loading.value = false;
    });

    function startCheckup(slug: string) {
      window.open(EXTERNAL_RESOURCES.MAIN_CHECKUP(slug), '_blank');
    }

    return {
      checkups,
      loading,
      activeCheckups,
      startCheckup,
      checkupTypes,
      tab
    };
  }
});
