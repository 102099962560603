








import { defineComponent, provide } from '@vue/composition-api';

export default defineComponent({
  name: 'AuthBrandLayout',
  props: {
    brand: {
      type: String,
      default: 'ligo'
    }
  },
  setup(props) {
    provide('brand', props.brand);
    return {};
  }
});
