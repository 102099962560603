import { Loading } from 'quasar';

export const awaitingProcess = {
  start() {
    Loading.show({
      spinnerColor: 'primary'
    });
  },
  stop() {
    Loading.hide();
  }
};
