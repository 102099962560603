/* eslint-disable @typescript-eslint/ban-types */
import { ComputedRef, ref, Ref } from '@vue/composition-api';
import { AxiosPromise, AxiosResponse } from 'axios';
import { useAsyncState } from './async-state.hook';

type Conditional<T, M> = M extends Object ? M : T;

const promiseToResults = <T, M>(
  total: Ref<number>,
  last: Ref<boolean>,
  transform?: (item: T) => M
) => (response: AxiosResponse<T[]>) => {
  total.value = parseInt(response.headers['x-total']);
  last.value = !response.headers['x-next-page'];
  return (transform
    ? response.data.map(transform)
    : response.data) as Conditional<T, M>[];
};

export function useAsyncResult<T, M = T>(
  promiseFactory: () => AxiosPromise<T[]>,
  dependencies: Array<Ref | ComputedRef> = [],
  transform?: (item: T) => M,
  defaultState: Conditional<T, M>[] = [],
  catchFn = (error: Error) => error,
  immediate = true
) {
  const total = ref(0);
  const last = ref(false);
  return {
    ...useAsyncState(
      () => promiseFactory().then(promiseToResults(total, last, transform)),
      defaultState,
      dependencies,
      catchFn,
      immediate
    ),
    total,
    last
  };
}

export function useSingleAsyncResult<T extends Object, M = T>(
  promiseFactory: () => AxiosPromise<T>,
  dependencies: Array<Ref | ComputedRef> = [],
  transform?: (item: T) => M,
  defaultState: Conditional<T, M> = {} as Conditional<T, M>,
  catchFn = (error: Error) => error,
  immediate = true
) {
  return useAsyncState(
    () =>
      promiseFactory().then((response) =>
        transform ? transform(response.data) : response.data
      ),
    defaultState,
    dependencies,
    catchFn,
    immediate
  );
}
