import { ContractService } from '@ligo/dashboard/customer/store';
import { computed, ref, watch } from '@vue/composition-api';
import { Notify } from 'quasar';
import { useI18n, useRouter } from '../../../composables';

const FORMATS = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'application/msword'
];
const MAX_SIZE = 15728640; // 15 MB

export function useAddDocument() {
  const i18n = useI18n();
  const router = useRouter();

  const newDocumentDialog = ref(false);
  const signedDocDialog = ref(false);
  const fileInput = ref();
  const fileForUpload = ref();
  const uploading = ref(false);

  const fileName = computed(() => {
    if (!fileForUpload.value) {
      return '';
    }
    return fileForUpload.value.name.split('.')[0];
  });

  function onAddFile() {
    newDocumentDialog.value = false;
    signedDocDialog.value = true;
  }
  function onSubmitFile(signed: boolean) {
    const formData = new FormData();
    formData.append('file', fileForUpload.value);
    formData.append('filename', fileName.value);
    formData.append('signed', JSON.stringify(signed));
    uploading.value = true;
    ContractService.create(formData)
      .then((response) => {
        router.push({
          name: 'ContractPreview',
          params: {
            uuid: response.data.uuid
          }
        });
      })
      .finally(() => {
        uploading.value = false;
      });
  }

  function onFileUpload() {
    fileInput.value.pickFiles();
  }

  function onRejectFile() {
    fileForUpload.value = undefined;
    Notify.create({
      type: 'negative',
      position: 'top',
      message: i18n.t('documents.upload.format').toString()
    });
  }

  watch(fileForUpload, (value: Blob) => {
    if (value) {
      const extensionIncluded = FORMATS.includes(value.type);
      const bigFile = value.size > MAX_SIZE;
      if (!extensionIncluded || bigFile) {
        onRejectFile();
        fileForUpload.value = null;
      } else onAddFile();
    }
  });

  return {
    newDocumentDialog,
    signedDocDialog,
    fileForUpload,
    uploading,
    fileName,
    onAddFile,
    onSubmitFile,
    onFileUpload,
    fileInput
  };
}
