





































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { PdfPage } from '@ligo/dashboard/customer/store';

const spinnerSize = '80px';

export default defineComponent({
  name: 'ContractImage',
  props: {
    pdfPages: {
      type: Array as PropType<PdfPage[]>
    },
    showBlur: {
      type: Boolean,
      default: false
    },
    generatingDocs: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const showSeparator = new Array(props.pdfPages.length).fill(ref(false));

    const loading = ref(true);
    function onLoad(index: number) {
      if (index == 0) {
        loading.value = false;
      }
      showSeparator[index].value = true;
    }

    return { spinnerSize, showSeparator, loading, onLoad };
  }
});
