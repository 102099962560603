import { boot } from 'quasar/wrappers';
import messages from '../i18n';
import Vue from 'vue';
import VueI18n, { DateTimeFormats, NumberFormats } from 'vue-i18n';

declare module 'vue/types/vue' {
  interface Vue {
    i18n: VueI18n;
  }
}

const numberFormats: NumberFormats = {
  'en-us': {
    currency: {
      style: 'currency',
      currency: 'EUR'
    }
  },
  nl: {
    currency: {
      style: 'currency',
      currency: 'EUR'
    }
  }
};

const dateTimeFormats: DateTimeFormats = {
  'en-us': {
    only_month: {
      month: 'long'
    },
    month_year: {
      month: 'long',
      year: 'numeric'
    }
  },
  nl: {
    only_month: {
      month: 'long'
    },
    month_year: {
      month: 'long',
      year: 'numeric'
    }
  }
};

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'en-us',
  fallbackLocale: 'en-us',
  messages,
  numberFormats,
  dateTimeFormats
});

Vue['i18n'] = i18n;

export default boot(({ app }) => {
  // Set i18n instance on app
  app.i18n = i18n;
});
