









import { defineComponent } from '@vue/composition-api';
import DocumentsLibrarySearchEngine from '../../components/documents/contracts-library/DocumentsLibrary.handler.vue';
import BasePage from '../../components/base/BasePage.vue';

export default defineComponent({
  name: 'DocumentsLibrary',
  components: { DocumentsLibrarySearchEngine, BasePage },
  props: {
    documentSlug: String
  }
});
