






import { defineComponent } from '@vue/composition-api';
import { BaseInput } from '.';

export default defineComponent({
  name: 'ESignBaseDate',
  components: { BaseInput },
  props: {
    value: {
      type: String,
      required: true
    }
  }
});
