

































import { defineComponent, onMounted } from '@vue/composition-api';
export default defineComponent({
  name: 'SQMainHeader',
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    icon: {
      type: String
    }
  },
  setup() {
    onMounted(() => {
      const actions = document.querySelector('.s-q-main-header-actions');
      if (actions && actions.children)
        for (let idx = 0; idx < actions.children.length; ++idx) {
          const child = actions.children[idx];
          child.classList.add('col-12');
        }
    });
    return {};
  }
});
