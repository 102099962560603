















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'NavItemTooltip',
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    }
  }
});
