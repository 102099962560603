export default {
  cta: {
    primary: 'Get service',
    secondary: 'Learn more'
  },
  list_page: {
    title: 'Business services',
    subtitle:
      'Arrange the services needed to run and protect your business from here.'
  },
  details_page: {
    go_back: 'Back to business services',
    title: 'DGA salary administration',
    empty_state: {
      title: 'No image available',
      subtitle: 'The team will soon upload an image for this service'
    }
  },
  breadcrumb: {
    business_services: 'Business services',
    dga: 'DGA',
    tax: 'TAX'
  }
};
