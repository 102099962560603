























import { defineComponent } from '@vue/composition-api';
import { APrimaryBtn } from '../base/app/buttons';

export default defineComponent({
  name: 'EmptyBanner',
  components: { APrimaryBtn },
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    btnLabel: {
      type: String
    },
    icon: {
      type: String,
      default: 'img:/dashboard/images/empty_services.svg'
    },
    avatarSize: {
      type: String,
      default: '200px'
    },
    iconSize: {
      type: String,
      default: '150px'
    }
  }
});
