














import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'ESignBaseInput',
  components: {},
  props: {
    placeholder: {
      type: String,
      default: 'Type text here ...'
    },
    value: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    }
  }
});
