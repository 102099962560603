import { NotaryProduct } from '@ligo/dashboard/store';
import { ActionTree } from 'vuex';
import { CustomerDashboardState } from '../state';
import { NotaryProductState } from './state';

const actions: ActionTree<NotaryProductState, CustomerDashboardState> = {
  select({ commit }, id) {
    if (id) commit('setNotaryProductId', id);
  },
  saveNotaryProduct({ commit }, notaryProduct: NotaryProduct) {
    commit('setNotaryProduct', notaryProduct);
  }
};

export default actions;
