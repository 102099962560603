






















import { defineComponent } from '@vue/composition-api';
import ABtn from './ABtn.vue';
export default defineComponent({
  name: 'APrimaryBtn',
  components: {
    ABtn
  },
  props: {
    label: String,
    leftIcon: String,
    rightIcon: String,
    leftIconColor: String,
    rightIconColor: String,
    padding: String
  }
});
