



















































































import { defineComponent, ref } from '@vue/composition-api';
import { useSettings, useChangePassword } from './user-settings.hook';
import BaseInput from '../../components/base/BaseInput.vue';
import { SimpleField } from '../base/SimpleForm/models/simpleForm.models';
import BaseSimpleField from '../base/SimpleForm/BaseSimpleField.vue';
import { APrimaryBtn } from '../base/app/buttons';

export default defineComponent({
  name: 'UserSettings',
  components: { BaseInput, BaseSimpleField, APrimaryBtn },
  setup() {
    const settingForm = ref();
    const passwordForm = ref();

    const settings = useSettings(settingForm);
    const password = useChangePassword(passwordForm);
    const phoneConfig: SimpleField = {
      key: 'phone_number',
      rules: ['required'],
      type: 'phone-number'
    };
    return {
      phoneConfig,
      password,
      settings,
      settingForm,
      passwordForm
    };
  }
});
