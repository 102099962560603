import { GetterTree } from 'vuex';
import { AssistantState } from './state';
import { CustomerDashboardState } from '../state';

const getters: GetterTree<AssistantState, CustomerDashboardState> = {
  getPrepareDocumentAssistantState: (state) => {
    return state.prepareDocumentAssistant.isActive;
  }
};

export default getters;
