




























import { MembershipService } from '@ligo/dashboard/customer/store';
import { defineComponent, ref } from '@vue/composition-api';
import { CancelPlanStep } from '../../../models';
import { useStore } from '../../../composables';

import PaymentModalStepper from '../modal-components/PaymentModalStepper.vue';
import CancelWarnStep from './CancelWarnStep.vue';
import FormStep from './FormStep.vue';
import SuccessStep from './SuccessStep.vue';
import { useMembershipInfo } from '../../utils/membership-info';
import { analyticCancelPlanEvent } from '../../../analytics';

export default defineComponent({
  name: 'CancelModal',
  components: {
    PaymentModalStepper,
    CancelWarnStep,
    FormStep,
    SuccessStep
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore();
    const step = ref(CancelPlanStep.WARN);

    const {
      membershipName,
      membershipBillingInterval,
      membershipPrice
    } = useMembershipInfo();

    function onCancelMemberhip(reasons) {
      MembershipService.cancelMembership().then(() => {
        store.dispatch('authentication/loadUserInfo');
        step.value = CancelPlanStep.SUCCESS;

        analyticCancelPlanEvent(store.getters['authentication/user'], {
          content: membershipName.value,
          type: membershipBillingInterval.value,
          revenue: membershipPrice.value,
          ...reasons
        });
      });
    }
    return { step, CancelPlanStep, onCancelMemberhip, membershipName };
  }
});
