import { computed, ref } from '@vue/composition-api';
import {
  SimpleStepperModel,
  StateValue
} from '../base/SimpleForm/models/simpleStepper.model';
import { onboardingFields } from './onboardingFields.hooks';
import { OnboardingPageInformation } from '../utils/useCustomerPageInformation.hooks';
import { Dictionary } from '@ligo/shared/utils';
import { SimpleForm } from '../base/SimpleForm/models/simpleForm.models';
import { simpleReducerForm } from '../base/SimpleForm/hooks/utils';

export type EmitOnboarding = (event: 'complete', ...args: any) => void;

export const useOnboardingRegister = (
  initialValue: Dictionary,
  emit: EmitOnboarding
) => {
  const state = ref<StateValue>({
    formValue: { ...initialValue },
    stack: [],
    step: 0
  });

  const { t, te, isReady, load } = OnboardingPageInformation;
  load();
  const fields = onboardingFields(t, te);
  const getConfigWithLayout = (
    layout: string[],
    customClass = 'col-md-7 col-sm-9 col-12'
  ): SimpleForm => {
    return {
      fields: fields,
      key: 'form',
      translateT: t,
      translateTe: te,
      design: {
        appClass: 'full-width row justify-center',
        transition: false,
        formClass: customClass
      },
      useLayout: [layout]
    };
  };
  const stepperConfig: SimpleStepperModel = {
    maxStep: 4,
    steps: [
      {
        key: 'welcome',
        step: 0,
        image: '/dashboard/images/welcome.svg',
        form: getConfigWithLayout([])
      },
      {
        key: 'company_structure',
        step: 1,
        form: getConfigWithLayout(['company_structure']),
        conditionalJump(state) {
          if (state.formValue['company_structure'] != 'existing_bv') return 2;
          return 1;
        }
      },
      {
        key: 'company_setup',
        step: 2,
        form: getConfigWithLayout([
          'company_name',
          'industry',
          'employee_count',
          'incorporated_time'
        ])
      },
      {
        key: 'use_ligo',
        step: 3,
        form: getConfigWithLayout(['use_ligo'])
      },
      {
        key: 'preferred_language',
        step: 4,
        form: getConfigWithLayout(
          ['preferred_language'],
          'col-md-5 col-sm-8 col-12'
        )
      },
      {
        key: 'finished',
        step: 0,
        form: getConfigWithLayout([])
      }
    ],
    t,
    te
  };

  const getCurrentStep = computed(() => {
    return stepperConfig.steps[state.value.step];
  });

  const onNext = () => {
    const current = getCurrentStep.value;

    const jump = current.conditionalJump
      ? current.conditionalJump(state.value)
      : 1;

    if (state.value.step + jump >= stepperConfig.steps.length) {
      emit('complete', simpleReducerForm(state.value.formValue, fields));
    } else {
      state.value.stack.push(state.value.step);

      state.value.step += jump;
    }
  };

  const onBack = () => {
    state.value.step = state.value.stack.pop();
  };

  const onUpdateFormValue = (formValue: Dictionary) => {
    state.value.formValue = formValue;
  };

  return {
    stepperConfig,
    state,
    getCurrentStep,
    t,
    te,
    isReady,
    onNext,
    onBack,
    onUpdateFormValue
  };
};
