

















































































































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { BaseSimpleForm } from '../../../base';
import { useSimpleFormConfig } from '../../../base/SimpleForm/hooks/simpleForm.hooks';
import { NotaryProductSlug } from '@ligo/bv-flow/store';
import { useFormConfig } from './form-configuration.hook';
import { NotaryProductService } from '@ligo/dashboard/customer/store';
import {
  activitiesField,
  counterWords
} from '../../hooks/common-field-definitions.hooks';
import { ExtraChangesPageInformation } from '../../../utils/useCustomerPageInformation.hooks';
import WarningModal from './WarningModal.vue';
import ErrorPaymentBanner from '../../../payment/checkout/ErrorPaymentBanner.vue';
import { APrimaryBtn, ASecondaryBtn } from '../../../base/app/buttons';

export default defineComponent({
  name: 'BusinessActivitiesCard',
  components: {
    BaseSimpleForm,
    WarningModal,
    ErrorPaymentBanner,
    APrimaryBtn,
    ASecondaryBtn
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    notaryProductUuid: {
      type: String,
      required: true
    },
    productType: {
      type: String as PropType<NotaryProductSlug>
    },
    productData: {
      type: Object
    }
  },
  setup(props, { emit }) {
    const { formConfig, onNext, onSendInfo } = useFormConfig(
      props.notaryProductUuid,
      [activitiesField],
      'business_activities'
    );

    formConfig.design.fieldContentClass = 'col-12';

    const showGPTDialog = ref(false);
    const forceLoading = ref(false);
    const gptContext = ref('');
    const gptError = ref(false);

    const confirmationModal = ref(false);

    const { formValue, getFormValueReducer } = useSimpleFormConfig(
      formConfig,
      props.productData
    );

    const onSave = async () => {
      const data = getFormValueReducer();
      await onNext(data);
      emit('submit', data);
      emit('input', false);
    };

    const close = () => {
      confirmationModal.value = false;
      gptError.value = false;
      emit('input', false);
    };

    const onAskGPT = () => {
      forceLoading.value = true;
      gptError.value = false;
      onSendInfo(getFormValueReducer()).then(() => {
        NotaryProductService.getDescriptionFromChatGPT({
          notary_product_uuid: props.notaryProductUuid,
          context: gptContext.value || ''
        })
          .then((resp) => {
            formValue.value['activities'] = resp.data.answer;
            showGPTDialog.value = false;
          })
          .catch(() => {
            gptError.value = true;
          })
          .finally(() => {
            forceLoading.value = false;
          });
      });
    };

    function onShowDialog() {
      const activities = formValue.value['activities'];
      if (activities) gptContext.value = activities;

      showGPTDialog.value = true;
    }

    const { t } = ExtraChangesPageInformation;

    return {
      formConfig,
      formValue,
      showGPTDialog,
      forceLoading,
      gptContext,
      onAskGPT,
      onNext,
      onSave,
      close,
      confirmationModal,
      getFormValueReducer,
      gptError,
      counterWords,
      t,
      onShowDialog
    };
  }
});
