import { AuthActionHandlers } from './../models/index';
import { Module } from 'vuex';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';

import { AuthResources } from '../models';
import { userServiceFactory } from '../services/user.service';

import { actionsFactory } from './actions';
import { gettersFactory } from './getters';
import state, { AuthState } from './state';
import mutations from './mutations';

export const authModuleFactory = <S>(resources: AuthResources, i18n: VueI18n, router: VueRouter, actionHandlers?: AuthActionHandlers, userService = userServiceFactory(router, resources, i18n)): Module<AuthState, S> => ({
  namespaced: true,
  actions: actionsFactory(userService, actionHandlers, resources, router, i18n),
  getters: gettersFactory(),
  mutations,
  state,
});
