











































import { defineComponent, ref, PropType } from '@vue/composition-api';
import { ColumnFormField } from './models/form';

export default defineComponent({
  name: 'ColumnForm',
  props: {
    label: String,
    value: { type: Object, required: true },
    fields: {
      type: (Array as unknown) as PropType<ColumnFormField[]>,
      default: () => []
    },
    actions: {
      type: (Array as unknown) as PropType<string[]>,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const form = ref();

    function validate() {
      return form.value.validate();
    }

    function reset() {
      form.value.reset();
    }

    function resetValidation() {
      return form.value.resetValidation();
    }

    function onInput($event: any, name: string) {
      emit('input', { ...props.value, [name]: $event });
    }

    return { form, validate, reset, resetValidation, onInput };
  }
});
