import { Ref, ref, computed, watch } from '@vue/composition-api';
import {
  ClickEvent,
  ESignField,
  FieldType,
  FieldTypes,
  Signer
} from '../../../models';
import Vue from 'vue';
import { resizeEventListener } from '@ligo/shared/utils';
import { signatureService } from '@ligo/dashboard/customer/store';
import { AxiosResponse } from 'axios';
import * as UserUtils from './utils/user';
import { useInteractions } from './utils/page-interactions/interactions';
import { awaitingProcess } from './utils/loaders';
import * as SignerUtil from './utils/signer';
import { useDocumentPreparationAssistant } from './utils/assistant';

const PAGE_REFERENCE = '#page-1';
const LIGO_APP_ID = 'ligo';

const FIELD_TYPES = Object.keys(FieldTypes).filter((type) =>
  isNaN(Number(type))
);

const successModal = {
  signer: {
    title: 'e_signature.prepare_document.modals.success.signer.title',
    description:
      'e_signature.prepare_document.modals.success.signer.description'
  },
  noSigner: {
    title: 'e_signature.prepare_document.modals.success.no_signer.title',
    description:
      'e_signature.prepare_document.modals.success.no_signer.description'
  }
};

export function useDocumentConfig(uuid: string) {
  const showingSuccessModal: Ref<boolean> = ref(false);
  const showingSendMailsModal: Ref<boolean> = ref(false);
  const showEditSignersModal: Ref<boolean> = ref(false);

  const showDrawer: Ref<boolean> = ref(false);

  const emailMessage: Ref<string> = ref('');

  const sendersCopys: Ref<string[]> = ref([]);

  const fieldsBoardReference = ref();

  const { user, loading: loadingUser } = UserUtils.getUser();

  const {
    showMenu,
    lastSignerUsedUuid,
    initInteractiveFields,
    removeTooltip,
    mouseMoveEvent: onMouseMoveEvent,
    loadMenu: onLoadMenu,
    addField,
    changeSigner: onChangeSigner,
    removeSignerField,
    copySignerField,
    showFieldMenu: onShowFieldMenu,
    closeFieldMenu: onCloseFieldMenu
  } = useInteractions();

  const {
    contract,
    loading: loadingContracts
  } = signatureService.get.contractPreview(uuid);

  const {
    signers,
    loading: loadingSigners,
    load: loadSigners
  } = signatureService.get.parsedSigners(uuid);

  SignerUtil.signersColorSetup();

  /* Assistant */

  const assistantConditions = {
    at_least_one_signer: computed(() => signers.value.length > 0),
    at_least_one_field: computed(() => atLeastOneField.value),
    show_menu: computed(() => showMenu.value),
    not_at_least_one_signer: computed(() => signers.value.length === 0),
    not_at_least_one_field_and_show_menu: computed(() => {
      return !atLeastOneField.value && showMenu.value;
    }),
    not_show_menu: computed(() => !showMenu.value)
  };

  const {
    assistantStates,
    isActive: isAssistantActive,
    hiddenAssistant,
    activateAssistant,
    finalizeAssistant,
    hideAssistant,
    handleNextClick: handleAssistantNextClick,
    handleDismissClick: handleAssistantDismissClick
  } = useDocumentPreparationAssistant(assistantConditions);

  const showAssistant = computed(
    () => isAssistantActive.value && !hiddenAssistant.value
  );

  /* ******************** */

  /* Computed */

  const successModalContent = computed(() => {
    return isMeOneOfSigners.value ? successModal.signer : successModal.noSigner;
  });

  const loading = computed(() => loadingContracts.value || loadingUser.value);

  const isOnlyOneSigner = computed(() => signers.value.length === 1);

  const isMeOneOfSigners = computed(() => {
    return signers.value.some((signer) => signer.email === user.value?.email);
  });

  const atLeastOneFieldPerSigner = computed(() => {
    return signers.value.every((signer) => signer.fields.length);
  });

  const atLeastOneField = computed(() => {
    return signers.value.some((signer) => signer.fields.length);
  });

  /* ******************** */

  /* Common methods */

  function onGoToField(field: ESignField) {
    const element = document.querySelector(`#field-${field.uuid}`);
    const header = document.querySelector('.q-header');
    const rec = element.getBoundingClientRect();
    const headerRec = header.getBoundingClientRect();
    window.scrollTo({
      behavior: 'smooth',
      top: rec.top + window.scrollY - headerRec.height
    });
  }

  async function updateFields(signer: Signer) {
    return signatureService.patch
      .signerWithInvertedCoordinates(uuid, signer)
      .catch((e) => {
        console.error(`There was an error updating the signer : ${e}`);
      });
  }

  async function updateAllSigners() {
    return Promise.all(
      signers.value.map(async (signer) => updateFields(signer))
    );
  }

  /* ******************** */

  /* Tooltip interactions */

  function onMouseMoveInPage(event) {
    onMouseMoveEvent(event, signers.value);
  }

  /* ******************** */

  /* Page interactions */

  function loadMenu(event: ClickEvent, index: number, pageSelector: string) {
    onLoadMenu(event, index, pageSelector, signers.value);
  }

  /* ******************** */

  /* Field interactions */

  function onAddField(signer: Signer, type: FieldType, required: boolean) {
    switch (type) {
      case FieldTypes.CHECKBOX:
        addField(
          signer,
          type,
          required,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        );
        break;
      default:
        addField(signer, type, required);
    }

    updateFields(signer);
  }

  function onRemoveField(signer: Signer, field: ESignField) {
    removeSignerField(signer, field);
    updateFields(signer);
  }

  async function changeSigner(signer: Signer, field: ESignField) {
    const currentOwner = signers.value.find(
      (s) => field.signerEmail === s.email
    );
    onChangeSigner(signer, field, signers.value);
    await updateFields(currentOwner);
    await updateFields(signer);
  }

  function showFieldMenu(field: ESignField) {
    onShowFieldMenu(field, signers.value);
  }

  function closeFieldMenu() {
    onCloseFieldMenu(signers.value);
  }

  function changeRequiredStatus(field: ESignField) {
    field.required = !field.required;
  }

  /* ******************** */

  /* Actions */

  function addSigner(newEmail: string) {
    SignerUtil.addEmail(newEmail, uuid, signers, () => {
      loadSigners();
      SignerUtil.saveSignerColor(newEmail);
    });
  }

  function addMyself() {
    addSigner(user.value.email);
  }

  function onEditSigner(singerUuid: string, newEmail: string) {
    SignerUtil.editEmail(contract.value.uuid, singerUuid, signers, newEmail);
  }

  function onDelete(uuid: string) {
    const signerEmail = signers.value.find((s) => s.uuid === uuid).email;
    SignerUtil.removeEmail(uuid, contract.value.uuid, () => {
      loadSigners();
      SignerUtil.removeSignerColor(signerEmail);
    });
  }

  function redirectToContractPreview() {
    signatureService.post
      .completeDocumentPreparation(
        uuid,
        emailMessage.value,
        sendersCopys.value,
        LIGO_APP_ID
      )
      .then(() => {
        Vue['Router'].push({
          name: 'ContractPreview',
          params: { uuid }
        });
      });
  }

  async function goToSign() {
    await updateAllSigners();
    awaitingProcess.start();
    signatureService.post
      .completeDocumentPreparation(
        uuid,
        emailMessage.value,
        sendersCopys.value,
        LIGO_APP_ID
      )
      .then((response: AxiosResponse<Signer[]>) => {
        awaitingProcess.stop();
        const signer: Signer = response.data.find(
          (s) => s.email === user.value.email
        );
        Vue['Router'].push({
          name: 'SigningDocument',
          params: {
            contractUuid: uuid,
            signerUuid: signer.uuid,
            sessionUuid: signer.signing_session.uuid
          },
          query: {
            origin: 'true'
          }
        });
      });
  }

  function goBack() {
    Vue['Router'].push({
      name: 'ContractPreview',
      params: { uuid }
    });
  }

  function sendEmails(message?: string, ccRecipients?: string[]) {
    showingSendMailsModal.value = false;
    showingSuccessModal.value = true;
    emailMessage.value = message;
    sendersCopys.value = ccRecipients || [];

    if (!isMeOneOfSigners.value) setTimeout(completePreparation, 3000);
  }

  function completePreparation() {
    showingSuccessModal.value = false;
    if (isMeOneOfSigners.value) {
      goToSign();
    } else {
      redirectToContractPreview();
    }
  }

  function onContinue() {
    if (isMeOneOfSigners.value && isOnlyOneSigner.value) {
      emailMessage.value = '';
      goToSign();
    } else {
      showingSendMailsModal.value = true;
    }
  }

  /* ******************** */

  /* Listeners */

  resizeEventListener(PAGE_REFERENCE, (page) => {
    const pageRect = page.getBoundingClientRect();
    signers.value.forEach((signer) => {
      signer.fields.forEach((field) => {
        const { width: oldWidth, height: oldHeight } = field.documentPageSize;
        const { width: newWidth, height: newHeight } = pageRect;
        const { x, y, width: fieldWidth, height: fieldHeight } = {
          ...field.dimensions,
          ...field.coordinates
        };

        field.coordinates.x = (x * newWidth) / oldWidth;
        field.coordinates.y = (y * newHeight) / oldHeight;
        field.dimensions.width = (fieldWidth * newWidth) / oldWidth;
        field.dimensions.height = (fieldHeight * newHeight) / oldHeight;
        field.documentPageSize.width = newWidth;
        field.documentPageSize.height = newHeight;
      });
    });
  });

  /* ******************** */

  watch(loadingSigners, () => {
    signers.value.forEach((signer) => {
      signer.fields.forEach((field) => {
        initInteractiveFields(field);
      });
    });
  });

  return {
    fieldsBoardReference,
    showMenu,
    contract,
    loading,
    signers,
    showingSendMailsModal,
    showingSuccessModal,
    successModalContent,
    atLeastOneFieldPerSigner,
    lastSignerUsedUuid,
    showEditSignersModal,
    user,
    FIELD_TYPES,
    showDrawer,
    assistantStates,
    isMeOneOfSigners,
    showAssistant,
    activateAssistant,
    finalizeAssistant,
    hideAssistant,
    handleAssistantNextClick,
    handleAssistantDismissClick,
    updateFields,
    addMyself,
    onEditSigner,
    onDelete,
    addSigner,
    removeTooltip,
    onMouseMoveInPage,
    goBack,
    loadMenu,
    onAddField,
    onContinue,
    changeSigner,
    onRemoveField,
    copySignerField,
    showFieldMenu,
    closeFieldMenu,
    sendEmails,
    completePreparation,
    changeRequiredStatus,
    onGoToField
  };
}
