































import { defineComponent, computed } from '@vue/composition-api';
import APrimaryBtn from '../base/app/buttons/APrimaryBtn.vue';

export default defineComponent({
  name: 'HomeActionCard',
  components: { APrimaryBtn },
  props: {
    title: String,
    subtitle: String,
    image: String,
    ctaLabel: String
  },
  setup(_, { root }) {
    const avatarSize = computed(() => {
      return root.$q.screen.lt.md
        ? { circle: '50px', icon: '20px' }
        : { circle: '60px', icon: '30px' };
    });
    return { avatarSize };
  }
});
