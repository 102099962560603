import { ref, computed, ComputedRef } from '@vue/composition-api';
import { useAsyncState } from '@ligo/shared/utils';
import {
  ExtraServiceTypesService,
  ExtraServiceType
} from '@ligo/dashboard/customer/store';
import { useStore, useI18n } from '../../composables';

export enum Kind {
  dga = 'dga',
  tax = 'tax'
}

export function breadcrumbLinks(kind: Kind) {
  if (kind === Kind.dga) return 'DgaServices';
  if (kind === Kind.tax) return 'TaxServices';
  return 'ServiceTypes';
}

export function useServiceTypes(kind?: Kind) {
  const selectedTypeSlug = ref('');
  const i18n = useI18n();
  const store = useStore();
  const user = computed(() => store.getters['authentication/user']);

  const {
    state: serviceTypesState,
    loading: loadingServiceTypes,
    load: loadServiceTypes
  } = useAsyncState<ExtraServiceType[], Promise<ExtraServiceType[]>>(
    () =>
      ExtraServiceTypesService.getServiceTypes().then(
        (response) => response.data
      ),
    [],
    undefined,
    undefined,
    false
  );

  const {
    state: serviceTypeState,
    loading: loadingServiceType,
    load: loadServiceType
  } = useAsyncState(
    () =>
      ExtraServiceTypesService.getServiceType(selectedTypeSlug.value).then(
        (response) => response.data
      ),
    undefined,
    undefined,
    undefined,
    false
  );

  const breadcrumbPath: ComputedRef<
    {
      name: string;
      link?: string;
      disabled?: boolean;
    }[]
  > = computed(() => {
    return [
      {
        name: i18n
          .t(`service_types.breadcrumb.${kind || 'business_services'}`)
          .toString(),
        link: breadcrumbLinks(kind)
      },
      {
        name: serviceType.value?.localeData.title,
        disabled: true
      }
    ];
  });

  const serviceTypes = computed(() => {
    return serviceTypesState.value.map((serviceType) =>
      mapServiceType(serviceType)
    );
  });

  const serviceType = computed(() => {
    return serviceTypeState.value && mapServiceType(serviceTypeState.value);
  });

  function mapServiceType(serviceType: ExtraServiceType): ExtraServiceType {
    return {
      ...serviceType,
      price: serviceType.price / 100,
      slug: serviceType.slug,
      preview:
        i18n.locale === 'nl' ? serviceType.preview_nl : serviceType.preview_en,
      localeData: JSON.parse(
        i18n.locale === 'nl' ? serviceType.nl : serviceType.en
      )
    };
  }

  function onLoadServiceType(slug: string) {
    selectedTypeSlug.value = slug;
    loadServiceType();
  }

  function trackServiceTypeAction(label: string, serviceType) {
    (window as any).analytics.track(`ServiceTypeEvent - ${label}`, {
      email: user.value.email,
      uuid: user.value.uuid,
      serviceTypeUuid: serviceType.uuid,
      serviceTypeSlug: serviceType.slug,
      serviceTypeName: serviceType.localeData.title,
      serviceTypePrice: serviceType.price,
      membership: user.value.membership.membership_type.slug
    });
  }

  function features(serviceType) {
    const items = serviceType.localeData?.features?.items;
    if (items?.length) return items;
  }

  return {
    serviceTypes,
    serviceType,
    loadingServiceTypes,
    loadingServiceType,
    breadcrumbPath,
    features,
    trackServiceTypeAction,
    loadServiceTypes,
    onLoadServiceType,
    mapServiceType
  };
}
