

































































import { defineComponent, PropType } from '@vue/composition-api';
import { useServiceTypes, Kind, breadcrumbLinks } from './service-types.hook';
import { WYSIWYGEditor } from '@ligo/shared/components';
import APrimaryBtn from '../base/app/buttons/APrimaryBtn.vue';
import { ExtraServiceService } from '@ligo/dashboard/customer/store';
import Teleport from 'vue2-teleport';

const IMAGE_UNAVAILABLE = '/dashboard/images/image_unavailable.svg';

export default defineComponent({
  name: 'ServiceTypesDetailsHandler',
  components: { WYSIWYGEditor, APrimaryBtn, Teleport },
  props: {
    slug: {
      type: String,
      required: true
    },
    kind: {
      type: String as PropType<Kind>
    }
  },
  setup(props) {
    const {
      serviceType,
      loadingServiceType,
      breadcrumbPath,
      onLoadServiceType
    } = useServiceTypes(props.kind);

    onLoadServiceType(props.slug);

    function gotoPayment() {
      ExtraServiceService.create(props.slug, {
        name: breadcrumbLinks(props.kind)
      });
    }

    return {
      serviceType,
      loadingServiceType,
      IMAGE_UNAVAILABLE,
      breadcrumbPath,
      gotoPayment
    };
  }
});
