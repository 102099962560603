import { MutationTree } from 'vuex';
import { LayoutState } from './state';

const mutations: MutationTree<LayoutState> = {
  setDashboardTitle(state, title: string) {
    state.dashboardTitle = title;
  },
  setDrawerBreakpoint(state, newBreakpoint: number) {
    state.drawerBreakpoint = newBreakpoint;
  },
  closePaymentModal(state) {
    state.openPaymentModal = false;
    state.paymentUuid = null;
  },
  openPaymentModal(state, paymentUuid: string) {
    state.paymentUuid = paymentUuid;
    state.openPaymentModal = true;
  }
};

export default mutations;
