import { Module } from 'vuex';
import { CustomerDashboardState } from '../state';
import state, { AssistantState } from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export const assistantStore: Module<AssistantState, CustomerDashboardState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
};
