import { Contract, ContractService } from '@ligo/dashboard/customer/store';
import { initWebSocket, getBaseSocketURL } from '@ligo/shared/utils';
import { computed, ref, Ref } from '@vue/composition-api';
import { AxiosResponse } from 'axios';
import { analyticGenericEditorEvent } from '../../analytics';
import { downloadContract, printPdf } from './contract-functions';
import { useRouter, useI18n, useStore } from '../../composables';
import moment from 'moment';
import Vue from 'vue';
import { EditorEvent } from '../../analytics/analytic.service';
import { analyticDownloadContract } from '../../analytics/events';

export function useContractDetail(uuid: string) {
  const router = useRouter();
  const i18n = useI18n();
  const store = useStore();

  const { url, channelIdentifier } = getBaseSocketURL(uuid, 'ContractsChannel');
  const { state, loading, load } = initWebSocket<
    Contract,
    Promise<AxiosResponse<Contract>>
  >(
    () => ContractService.getById(uuid),
    null,
    url,
    channelIdentifier,
    (state: Ref<Contract>, { data }) => {
      let currentDate: Date;
      let incommingDate: Date;
      if (state.value) {
        currentDate = new Date(state.value.updated_at);
        incommingDate = new Date(data.updated_at);
        if (currentDate.getTime() > incommingDate.getTime()) return;
      }

      state.value = data;
    }
  );

  store.dispatch('authentication/loadUserInfo');

  const showDelete = ref(false);
  const showRename = ref(false);
  const showRevoke = ref(false);

  async function onGoToSigning() {
    const contract = state.value;
    analyticGenericEditorEvent(
      EditorEvent.GO_SIGNING,
      contract,
      store.getters['authentication/user']
    );
    Vue['Router'].push({
      name: 'DocumentPreparation',
      params: { uuid }
    });
  }

  async function onGoToPayment() {
    let payment_uuid = state.value.payment?.uuid;
    if (!payment_uuid) {
      payment_uuid = (await ContractService.getPayment(state.value.uuid))
        .payment.uuid;
    }
    Vue['Router'].push({
      name: 'Billing',
      params: { paymentId: payment_uuid }
    });
  }

  function refreshContractAndMember() {
    store.dispatch('authentication/loadUserInfo');
    load();
  }

  async function onUseCredit() {
    await ContractService.payWithCredit(state.value.uuid);
    refreshContractAndMember();
  }

  async function onRevokeSigning() {
    await ContractService.revokeSigning(state.value.uuid);
    showRevoke.value = false;
    refreshContractAndMember();
  }

  function onDownloadDoc() {
    analyticDownloadContract(
      state.value,
      'word',
      store.getters['authentication/user']
    );
    downloadContract(state.value.last_revision.word_document, 'word');
  }

  function onDownloadPdf() {
    analyticDownloadContract(
      state.value,
      'pdf',
      store.getters['authentication/user']
    );
    downloadContract(state.value.last_revision.pdf_document, 'pdf');
  }
  function onDownloadSignedPdf() {
    analyticDownloadContract(
      state.value,
      'pdf-signed',
      store.getters['authentication/user']
    );
    downloadContract(state.value.last_revision.pdf_signed_document, 'pdf');
  }

  function onDownloadSigningLog() {
    analyticGenericEditorEvent(
      EditorEvent.DOWNLOAD_SIGNING_LOG,
      state.value,
      store.getters['authentication/user']
    );
    downloadContract(state.value.last_revision.signing_log, 'pdf');
  }

  function onPrint() {
    if (state.value.last_revision.pdf_signed_document) {
      printPdf(state.value.last_revision.pdf_signed_document);
    } else {
      printPdf(state.value.last_revision.pdf_document);
    }
  }

  async function onEditName(name: string) {
    await ContractService.update(state.value.uuid, {
      name
    });
    showRename.value = false;
    load();
  }

  async function onDelete() {
    await ContractService.delete(state.value.uuid);
    router.push({ name: 'ActiveContracts' });
  }

  function onShowRename() {
    showRename.value = true;
  }

  function onShowDelete() {
    showDelete.value = true;
  }

  function onShowRevoke() {
    showRevoke.value = true;
  }

  const updatedAtInfo = computed(() => {
    if (!state.value) return '';
    if (state.value.updated_at) {
      return `${i18n.t('contract.preview.updated')} ${moment(
        state.value.updated_at
      ).fromNow()}`;
    }
    return i18n.locale === 'nl'
      ? `${moment(state.value.created_at).fromNow()} ${i18n.t(
          'contract.preview.created'
        )}`
      : `${i18n.t('contract.preview.created')} ${moment(
          state.value.created_at
        ).fromNow()}`;
  });

  const generatingInitialDocs = computed(
    () => !state.value?.last_revision?.pdf_pages
  );
  const generatingSignedDocs = computed(
    () =>
      state.value?.signed &&
      state.value?.prepare_url &&
      !state.value?.last_revision.pdf_signed_document
  );

  const generatingDocs = computed(
    () => generatingInitialDocs.value || generatingSignedDocs.value
  );

  const contractName = computed(() => {
    if (!state.value) return '';
    return state.value.name;
  });

  const waitingForDocuments = computed(() => {
    if (!state.value) return false;
    return state.value.last_revision?.waiting_for_documents;
  });

  return {
    contract: state,
    load,
    loading,
    onGoToSigning,
    onGoToPayment,
    onDownloadDoc,
    onDownloadPdf,
    onDownloadSigningLog,
    onDownloadSignedPdf,
    onRevokeSigning,
    onPrint,
    onEditName,
    onShowRename,
    onDelete,
    onShowDelete,
    onShowRevoke,
    onUseCredit,
    showRevoke,
    showDelete,
    showRename,
    generatingDocs,
    updatedAtInfo,
    contractName,
    waitingForDocuments
  };
}
