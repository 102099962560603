






































































































































import { computed, defineComponent } from '@vue/composition-api';
import APrimaryBtn from '../base/app/buttons/APrimaryBtn.vue';
import ASecondaryBtn from '../base/app/buttons/ASecondaryBtn.vue';
import { useBankingAccount } from './banking.hook';

export default defineComponent({
  name: 'BankingRequestAnAccount',
  components: { APrimaryBtn, ASecondaryBtn },
  setup() {
    const {
      loading: notaryProductsLoading,
      notaryProducts,
      selectedNotaryProducts,
      stepsNumber,
      step,
      activeStep,
      stepsActions,
      termsAndConditions,
      t,
      isPageInformationReady,
      filter
    } = useBankingAccount();

    const currentStep = computed(() => stepsActions[activeStep.value - 1]);
    const disabled = computed(
      () =>
        currentStep.value.nextEnabled && !currentStep.value.nextEnabled.value
    );

    const termsAndConditionsObject = computed(() =>
      formatTermsAndConditions(t('account_request.step_2.terms_and_conditions'))
    );

    function stepBgColor(idx: number) {
      if (idx < activeStep.value) return step.checked.color;
      if (idx === activeStep.value) return step.current.color;
      return step.disabled.color;
    }

    function onNext() {
      if (activeStep.value <= stepsNumber) {
        activeStep.value += 1;
        if (currentStep.value.callback) {
          currentStep.value.callback();
        }
      }
    }

    function onBack() {
      if (activeStep.value > 1) {
        activeStep.value -= 1;
      }
    }

    function formatTermsAndConditions(text: string) {
      const [left, toFormat, right] = text.split('|');
      const [name, link] = toFormat.split('$');
      return { left, name, link, right };
    }

    return {
      stepsNumber,
      activeStep,
      step,
      notaryProductsLoading,
      notaryProducts,
      selectedNotaryProducts,
      stepsActions,
      termsAndConditions,
      currentStep,
      disabled,
      termsAndConditionsObject,
      formatTermsAndConditions,
      t,
      isPageInformationReady,
      onBack,
      filter,
      stepBgColor,
      onNext
    };
  }
});
