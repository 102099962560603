import { Ref, ref, computed } from '@vue/composition-api';
import { ModalCta } from 'libs/dashboard/customer/components/src/lib/models';

export function useModalCtas() {
  const cancelBtn: Ref<ModalCta> = ref({
    color: 'white',
    textColor: 'default',
    label: 'e_signature.ctas.cancel',
    emit: 'cancel',
    class: 'e-sign-bordered-btn',
    disabled: false
  });

  const saveBtn: Ref<ModalCta> = ref({
    color: 'primary',
    textColor: 'white',
    label: 'e_signature.ctas.save',
    emit: 'save',
    class: 'e-sign-accept-btn',
    disabled: false
  });

  const ctasList = computed(() => [cancelBtn.value, saveBtn.value]);

  return { cancelBtn, saveBtn, ctasList };
}
