























































import { defineComponent, PropType, computed } from '@vue/composition-api';
import { ASecondaryBtn } from '../../base/app/buttons';
import { BillingData } from '../../../models';

export default defineComponent({
  name: 'SavedBilling',
  components: { ASecondaryBtn },
  props: {
    billing: {
      type: Object as PropType<BillingData>
    }
  },
  setup(props) {
    const address = computed(() =>
      !props.billing.street &&
      !props.billing.house_number &&
      !props.billing.city &&
      !props.billing.zip
        ? ''
        : `${props.billing.street} ${props.billing.house_number},  ${props.billing.city}, ${props.billing.zip}`
    );
    return { address };
  }
});
