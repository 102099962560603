





































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'DocumentBanner',
  props: {
    title: String,
    subtitle: String,
    shadow: {
      type: Boolean,
      default: false
    },
    goBack: {
      type: Boolean,
      default: false
    }
  }
});
