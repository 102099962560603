var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"service-types"},[_c('s-q-main-header',{attrs:{"title":_vm.$t('service_types.list_page.title'),"subtitle":_vm.$t('service_types.list_page.subtitle'),"icon":"fas fa-wrench"}}),_c('div',{staticClass:"row justify-center q-mt-xl"},_vm._l((_vm.serviceTypes),function(service,i){return _c('div',{key:("service-" + i),class:{
        'col-12 col-sm-6': true,
        'q-mt-xl': (_vm.$q.screen.gt.xs && i >= 2) || (_vm.$q.screen.lt.sm && i >= 1),
        'q-pr-sm': i % 2 === 0 && _vm.$q.screen.gt.xs
      },attrs:{"id":("service-type-" + i)}},[_c('q-card',{staticClass:"q-pa-md full-height st-card"},[_c('div',{staticClass:"row justify-between items-center"},[_c('div',{staticClass:"font-16 w-600 card-title"},[_vm._v(" "+_vm._s(service.localeData.title)+" ")]),_c('div',{staticClass:"q-pa-sm text-np-primary-1100 font-24 w-600 card-price q-px-md"},[_vm._v(" €"+_vm._s(_vm.truncate(service.price))+" ")])]),(_vm.features(service))?_c('div',{staticClass:"q-mt-md"},_vm._l((_vm.features(service)),function(ref,j){
      var text = ref.text;
return _c('div',{key:("feature-" + j),staticClass:"q-mb-sm text-subdued row"},[_c('div',{staticClass:"q-px-sm"},[_vm._v("-")]),_c('div',{staticClass:"col"},[_vm._v(" "+_vm._s(text)+" ")])])}),0):_vm._e(),_c('div',{staticClass:"q-py-xl"}),_c('div',{staticClass:"absolute-bottom q-pa-md"},[_c('a-primary-btn',{staticClass:"q-mr-sm",attrs:{"label":service.localeData.cta && service.localeData.cta.primary
                ? service.localeData.cta.primary
                : _vm.$t('service_types.cta.primary')},on:{"click":function($event){return _vm.onGoToPayment(service)}}}),_c('a-secondary-btn',{attrs:{"label":service.localeData.cta && service.localeData.cta.secondary
                ? service.localeData.cta.secondary
                : _vm.$t('service_types.cta.secondary'),"right-icon":"far fa-arrow-up-right","right-icon-color":"default"},on:{"click":function($event){return _vm.onGoToDetails(service)}}})],1)])],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }