export default {
  title: 'Please review and approve the Final draft deed ',
  info:
    'The notary is currently preparing the final draft deed for you and it will be uploaded here as soon as it is finished. You will also receive an email notification once this happens.',
  reviewing: 'The notary is preparing the the final draft deed for you.',
  subtitle: 'Please read the following document carefully',
  download: 'Download',
  approve: 'Approve',
  accepted: 'Accepted',
  amend: 'Request to amend',
  requested: 'Amendments requested...',
  cancel_amendment_request: 'Cancel amendment request',

  confirm: {
    msg: 'Do you confirm the document is correct?',
    yes: 'Yes',
    no: 'No'
  },

  request: {
    title: 'Request amendments',
    subtitle: 'Describe what details need to be amended ',
    placeholder: 'I would like to...',
    yes: 'Send',
    no: 'Cancel',
    information: `Amendments due to typos in the information provided such as
  incorporators’ names, companies’ names and addresses can be
  requested here at no-extra cost. All other changes require the
  notary to review and execute compliance work again, thus involve
  extra costs. If you need to change your application, please use
  the ‘Edit application’ button on the top-right corner of your
  screen.`
  },
  move_next_step: 'Move to next step'
};
