export default {
  lawyers: {
    title: 'Bekijk de advocaten op ons platform',
    view: 'bekijk profiel',
    placeholder: 'Advocaat'
  },
  how_it_works: {
    title: 'Hoe het werkt',
    card_1_title: 'Leg je kwestie uit',
    card_1_text:
      'Beschrijf je zaak en vermeld het rechtsgebied waarop het betrekking heeft',
    card_2_title: 'Bespreek jouw kwestie',
    card_2_text:
      'Wij maken een voorselectie van de beste advocaten voor jouw zaak',
    card_3_title: 'Kies je advocaat',
    card_3_text: 'Kies de advocaat waarmee je wilt werken en handel je zaak af'
  },
  actions: {
    consult: 'Telefonisch consult aanvragen',
    quote: 'Gratis offerte aanvragen',
    doc_review: 'Document review aanvragen'
  },
  consult: {
    step_1: 'Stuur je vraag naar de <br /> advocaten',
    step_2: 'Ontvang een uitnodiging voor <br /> overleg binnen 48 uur',
    step_3: 'Neem deel aan de oproep en <br /> krijg antwoorden op je vragen',
    form: {
      title: 'Stel je vraag',
      text_placeholder: 'Schrijf je vraag',
      text_required: 'Schrijf uw vraag a.u.b',
      max_length: 'Maximaal aantal tekens: 1000',
      selection_required: 'Selecteer ten minste één optie',
      option_title: 'Kies de onderwerpen die bij je vraag passen',
      option_1: 'Arbeid',
      option_2: 'Onderneming',
      option_3: 'Fiscaal',
      option_4: 'Financieel',
      option_5: 'Intellectueel eigendom',
      submit: 'Dien uw vraag in',
      request_success: 'Uw vraag is succesvol ingediend',
      request_error: 'Er is een fout opgetreden bij het indienen van uw vraag'
    },
    kinds: {
      title: 'Wat voor vragen kun je stellen (Voorbeeld)?',
      establishment: {
        title: 'Onderneming',
        example_1:
          '"Mijn BV is nog niet helemaal opgericht, maar ik heb wel alvast kosten gemaakt. Ben ik voor de gemaakte kosten persoonlijk aansprakelijk en kan ik deze kosten bij de BV declareren?"',
        example_2:
          '"Ik wil uit de VOF stappen die ik samen met mijn zakenpartner heb opgericht en vervolgens mijn eigen BV oprichten. Kan ik klanten van de VOF naar de BV meenemen?"',
        example_3:
          '"Ik heb op dit moment een eenmanszaak, maar gelet op de groei daarvan zou ik de eenmanszaak graag willen omzetten in een BV. Wat komt hierbij kijken?"'
      },
      staff: {
        title: 'Arbeid',
        example_1:
          '"Ik ben ontevreden over de prestatie van mijn werknemer. Wanneer mag ik overgaan tot ontslag?"',
        example_2:
          '"Ik heb onlangs een BV opgericht en ik wil nu werknemers in dienst gaan nemen. Welke verzekeringen heb ik daarvoor nodig?"',
        example_3:
          '"Een ex-werknemer is sinds kort werkzaam bij een concurrent, terwijl in zijn arbeidsovereenkomst een concurrentiebeding is opgenomen. Wat kan ik hiertegen doen?"'
      },
      collection: {
        title: 'Incasso',
        example_1:
          '"Ik heb een klant die zijn openstaande facturen niet wil betalen. Wat kan ik doen om toch mijn geld te krijgen?"',
        example_2:
          '"De goederen die ik voor mijn onderneming heb besteld zijn niet geleverd terwijl ik wel al heb betaald. Ik wil de overeenkomst ontbinden en mijn geld terugvorderen. Hoe kan ik dit het beste aanpakken?"',
        example_3:
          '"Mijn onderneming verkeert in financiële problemen. Ik heb daardoor openstaande facturen die ik niet kan voldoen. Kan mijn onderneming uitstel van betaling krijgen?"'
      },
      privacy: {
        title: 'Privacy',
        example_1:
          '"Mijn onderneming moet binnenkort aan de eisen van de AVG voldoen. Welke gevolgen heeft dit voor de persoonsgegevens die ik in het verleden al heb verzameld?"',
        example_2:
          '"De computers van mijn bedrijf zijn onlangs gestolen. Moet ik mijn klanten op de hoogte stellen van een mogelijk datalek?"',
        example_3:
          '"Ik heb in het verleden met een derde partij een verwerkersovereenkomst gesloten, maar ik wil de overeenkomst nu gaan beëindigen. Kan dit?"'
      },
      trademark: {
        title: 'Merkbescherming',
        example_1:
          '"Sinds kort heeft een onderneming met bijna dezelfde handelsnaam zich gevestigd in de straat waar mijn onderneming ook is gevestigd. Dit zorgt voor verwarring onder de klanten. Wat kan ik hiertegen doen?"',
        example_2:
          '"Ik ben van mening een nieuw product te hebben uitgevonden. Graag wil ik dit product beschermen zodat anderen niet van mijn uitvinding kunnen genieten. Hoe kan ik van deze beschermingen genieten?"',
        example_3:
          '"Foto’s die ik op mijn website heb gepubliceerd, zijn zonder mijn toestemming door een andere website overgenomen. Welke juridische stappen kan ik hiertegen ondernemen?"'
      },
      other: {
        title: 'Overige',
        example_1:
          '"Mijn BV is gevestigd in Nederland, maar richt zich vooral op het verlenen van diensten in het buitenland. In welk land dient mijn BV belasting te betalen?"',
        example_2:
          '"Om te weten hoe mijn concurrenten te werk gaan, wil ik graag een mystery shopper inzetten. Mag ik dit doen?"',
        example_3:
          '"Ik verwacht dat ik mijn jaarrekening niet op tijd af zal krijgen. Wat voor gevolgen heeft dit?"'
      }
    }
  }
};
