export default {
  page: {
    title: 'Vraag een advocaat',
    subtitle:
      'Ons netwerk van goede advocaten staat klaar om je te helpen. Maak kennis door vrijblijvend contact met ze op te nemen.'
  },
  cards: {
    phone: 'Telefoon',
    email: 'Email',
    fee: 'Tarief',
    learn_more_cta: 'Lees meer'
  }
};
