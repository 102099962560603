













import { computed, defineComponent, ref } from '@vue/composition-api';
import { useNotaryProduct } from '../components/notary-product-detail.hook';

export default defineComponent({
  name: 'Map',
  props: {
    notaryProductId: {
      type: String,
      required: true
    }
  },
  setup(props, { root }) {
    const { notary } = useNotaryProduct(root, ref(props.notaryProductId));

    const coordinates = computed(() => {
      if (!notary.value) {
        return null;
      }
      return {
        lat: Number(notary.value.latitude),
        lng: Number(notary.value.longitude)
      };
    });

    return { coordinates };
  }
});
