export const baseText = {
  check_docs: {
    title: 'check_docs.title',
    info: 'check_docs.info_banner'
  },
  deed: {
    title: 'deed.title',
    info: 'deed.info'
  },
  meeting_step: {
    document_banner: 'meeting.read_and_print.banner.text',
    notary_banner: 'meeting.banner.text_contact'
  },
  finalized: {
    wait_title: 'finalized.title2',
    info: 'finalized.wait_info',
    title: 'finalized.title',
    subtitle: 'finalized.subtitle'
  },
  read_docs: {
    //** show if and only if enable_busy_banner */
    busy_notary: 'read_docs.busy_notary_banner',
    enable_busy_banner: false,

    //** show if info_omzetting_with_added_value_and_high_capitals (isOmzettingWithAddedValue(np) && hasHighTotalCapital(np))  */
    info_omzetting_with_added_value_and_high_capital:
      'read_docs.read.info_omzetting_with_added_value_and_high_capital',

    //** show if info_omzetting_with_added_value (isOmzettingWithAddedValue(np)) */
    info_omzetting_with_added_value:
      'read_docs.read.info_omzetting_with_added_value',

    //** show if high total capital (hasHighTotalCapital(np)) */
    info_when_high_capital: 'read_docs.read.info_when_high_capital',

    //** else show this */
    info: 'read_docs.read.info'
  },
  upload_docs: {
    /** show banner <=> notaryProduct.product_data.bv_type == BvType.PRIVATE_SAVINGS */
    spaar_info: 'read_docs.upload.spaar_info',

    /** show banner <=> NotaryProductNotaries.VG == notaryProduct.notary?.slug  */
    foreign_info: {
      /** show this copy <=> notaryProduct.product_data.current_address_equals_home; */
      residential_address: 'read_docs.upload.foreign_info.residential_address',

      /** else show this */
      next_step: 'read_docs.upload.foreign_info.next_step'
    },

    /** show banner <=> NotaryProductNotaries.VG == notaryProduct.notary?.slug */
    warning_foreign_info: {
      paragraph: 'read_docs.upload.warning_foreign_info.paragraph',
      question: 'read_docs.upload.warning_foreign_info.question',
      link: 'read_docs.upload.warning_foreign_info.link'
    },

    /** else show this (show <=> NotaryProductNotaries.VG != notaryProduct.notary?.slug)*/
    info: {
      all_docs_ok: 'read_docs.upload.info.all_docs_ok',
      residential_address: 'read_docs.upload.info.residential_address'
    }
  }
};
