


























































































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useGetReminders } from './reminders.hook';
import { SQTable, SQMainHeader } from '../../components/base';
import { useStore } from '../../composables';
import ESidebarUpdate from './modals/ESidebarUpdate.vue';
import ESidebarCreate from './modals/ESidebarCreate.vue';
import { AlertModal } from '../../components/base';
import { useReminderManager } from './reminder-manager.hook';
import { APrimaryBtn, ASecondaryBtn } from '../../components/base/app/buttons/';

const MENU_ICON = 'img:/dashboard/icons/three_dots_circle.svg';

export default defineComponent({
  name: 'ReminderList',
  components: {
    APrimaryBtn,
    ASecondaryBtn,
    AlertModal,
    ESidebarUpdate,
    ESidebarCreate,
    SQTable,
    SQMainHeader
  },
  props: {
    query: Object,
    externalLoading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { root, emit }) {
    const {
      totalReminders,
      pagination,
      loading,
      columns,
      rows,
      actions,
      filters,
      remindersList,
      showConfirmationDialog,
      selectedReminder,
      search,
      getReminders
    } = useGetReminders(props.query || {}, emit);

    const {
      createUnlinkedReminder,
      eventUpdate,
      eventDelete,
      eventComplete,
      remindMeOnCustomTime
    } = useReminderManager();

    const store = useStore();
    const user = computed(() => store.getters['authentication/user']);
    const currMembershipTypeLevel = computed(
      () => store.getters['authentication/getMembershipTypeLevel']
    );

    const isSelectedReminderRecurrent = computed(() => {
      return (
        selectedReminder.value?.event_type?.recurrence &&
        selectedReminder.value?.event_type?.recurrence != 'not_recurrent'
      );
    });

    const deleteReminderCallback = ref();

    const showESidebarUpdate = ref(false);
    const showESidebarCreate = ref(false);

    function trackTaskAction(label: string, reminder) {
      (window as any).analytics.track(`Reminder - ${label}`, {
        email: user.value.email,
        uuid: user.value.uuid,
        created_at: reminder.created_at,
        assignees: reminder.employees.length,
        event_date: reminder.event_date,
        remind_on: reminder.remind_me_on,
        membership: user.value.membership.membership_type.slug,
        event_type: reminder.event_type?.slug
      });
    }

    function onShowUpdateModal(reminderId: string) {
      const reminder = remindersList.value.find(
        (reminder) => reminder.id === reminderId
      );
      trackTaskAction('opened', reminder);
      selectedReminder.value = reminder;
      root.$nextTick(() => {
        showESidebarUpdate.value = true;
      });
    }

    function finishUpdateAction() {
      getReminders();
      showESidebarUpdate.value = false;
    }

    function finishCreateAction() {
      getReminders();
      showESidebarCreate.value = false;
    }

    function onToggleCompleteReminder(reminderId: string) {
      const reminder = remindersList.value.find(
        (reminder) => reminder.id === reminderId
      );
      eventComplete(reminder.id, getReminders);
    }

    function onDeleteReminder(reminderId: string) {
      const reminder = remindersList.value.find(
        (reminder) => reminder.id === reminderId
      );
      selectedReminder.value = reminder;
      showConfirmationDialog.value = true;
      deleteReminderCallback.value = () => {
        eventDelete(reminder.id, getReminders);
        showConfirmationDialog.value = false;
      };
    }

    function onUpdateReminder(reminder) {
      eventUpdate(reminder, finishUpdateAction);
    }

    function onRemindMeOnCustomTime(reminder, opt) {
      const onCloned = () => {
        trackTaskAction('cloned', reminder);
        finishUpdateAction();
      };
      remindMeOnCustomTime({ ...reminder }, opt, onCloned);
    }

    return {
      totalReminders,
      pagination,
      filters,
      actions,
      columns,
      rows,
      currMembershipTypeLevel,
      isSelectedReminderRecurrent,
      loading,
      MENU_ICON,
      deleteReminderCallback,
      selectedReminder,
      showConfirmationDialog,
      showESidebarCreate,
      showESidebarUpdate,
      onRemindMeOnCustomTime,
      search,
      onUpdateReminder,
      finishCreateAction,
      createUnlinkedReminder,
      finishUpdateAction,
      onShowUpdateModal,
      onToggleCompleteReminder,
      onDeleteReminder
    };
  }
});
