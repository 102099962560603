


















































































































































































































































































































import {
  Notary,
  NotaryProductDocumentKind,
  NotaryProductTypeSlug
} from '@ligo/dashboard/store';
import { defineComponent, PropType, ref, computed } from '@vue/composition-api';
import { useFinilizedProduct } from './finalized-product.hook';
import {
  onDownload,
  getLastUpdatedDocument,
  getDocumentsBy
} from '../documents.hooks';
import InfoBaloon from '../../global/InfoBaloon.vue';
import { useStoreValue } from '@ligo/shared/utils';
import { CustomerDashboardState } from '@ligo/dashboard/customer/store';
import DocumentsFolder from '../DocumentsFolder.vue';
import DocumentNameClickable from '../../global/DocumentNameClickable.vue';
import { APrimaryBtn } from '../../base/app/buttons';
import { useI18n } from '../../../composables';
import { useDynamicText } from '../hooks/dynamicText.hooks';
import { LottieAnimation } from '@ligo/shared/components';

const DOCUMENT_TYPES = [
  NotaryProductDocumentKind.USER_UPLOADED,
  NotaryProductDocumentKind.NOTARY_UPLOADED
];

const CONFETTI_LOTTIE =
  'https://lottie.host/8a1cdb82-5f67-4973-ac20-047493495092/lVozK3l2db.json';

export default defineComponent({
  name: 'FinalizedProduct',
  components: {
    InfoBaloon,
    DocumentNameClickable,
    DocumentsFolder,
    LottieAnimation,
    APrimaryBtn
  },
  props: {
    productType: {
      type: String as PropType<NotaryProductTypeSlug>
    },
    image: {
      type: String,
      default: '/dashboard/icons/finalized/fond.svg'
    },
    notary: {
      type: Object as PropType<Notary>
    },
    productData: {
      type: Object
    }
  },
  setup(props, { root, emit }) {
    emit('stop-loading');
    const notaryProductId = useStoreValue<string, CustomerDashboardState>(
      root.$store
    )('notaryProduct/getNotaryProductId');

    const { deeds, kvks, loading, items } = useFinilizedProduct(root);
    const showAnim = ref(true);
    const i18n = useI18n();
    const { nt } = useDynamicText(i18n, props.notary);

    const {
      items: uploadedDocuments,
      load: uploadedDocumentsLoad
    } = getDocumentsBy(notaryProductId, {
      kinds: DOCUMENT_TYPES
    });

    const lastUpdatedUploadedDocuments = computed(() => {
      if (!uploadedDocuments.value || uploadedDocuments.value.length === 0) {
        return '';
      }
      return getLastUpdatedDocument(uploadedDocuments.value);
    });

    const {
      items: othersUploadedDocuments,
      load: othersUploadedDocumentsLoad
    } = getDocumentsBy(notaryProductId, {
      kinds: DOCUMENT_TYPES
    });

    const lastUpdatedOtherUploadedDocuments = computed(() => {
      if (!uploadedDocuments.value || uploadedDocuments.value.length === 0) {
        return '';
      }
      return getLastUpdatedDocument(uploadedDocuments.value);
    });

    const STEPS = [
      {
        name: 'icorporate',
        done: true
      },
      {
        name: 'open_bank',
        done: false
      },
      {
        name: 'obligations',
        done: false
      },
      {
        name: 'more',
        done: false
      }
    ];

    function onCompleteAnimation() {
      showAnim.value = false;
    }

    return {
      deeds,
      kvks,
      loading,
      onDownload,
      items,
      NotaryProductTypeSlug,
      notaryProductId,
      STEPS,
      nt,
      showAnim,
      CONFETTI_LOTTIE,
      DOCUMENT_TYPES,
      uploadedDocuments,
      uploadedDocumentsLoad,
      lastUpdatedUploadedDocuments,
      othersUploadedDocuments,
      othersUploadedDocumentsLoad,
      lastUpdatedOtherUploadedDocuments,
      onCompleteAnimation
    };
  }
});
