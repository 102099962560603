














































import { defineComponent, computed } from '@vue/composition-api';
import UserMenu from './drawer-components/UserMenu.vue';
import CompaniesList from './drawer-components/CompaniesList.vue';
import NotificationList from './drawer-components/NotificationList.vue';
import { useStore } from '../composables';
import { useCompanies } from '../components/companies.hook';
import { ABtn } from '../components/base/app/buttons';
import CustomerLangSelect from './CustomerLangSelect.vue';

export default defineComponent({
  name: 'CustomerToolbar',
  components: {
    UserMenu,
    CompaniesList,
    NotificationList,
    ABtn,
    CustomerLangSelect
  },
  props: {
    mini: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore();

    const {
      list: companies,
      loading: loadingCompanies,
      company,
      onSelectCompany
    } = useCompanies();

    const hasMoreThanOneCompany = computed(
      () => store.getters['company/getCompaniesAmount'] > 1
    );

    return {
      hasMoreThanOneCompany,
      companies,
      loadingCompanies,
      company,
      onSelectCompany
    };
  }
});
