import { ActionTree } from 'vuex';
import { CustomerDashboardState } from '../state';
import { AssistantState } from './state';

const actions: ActionTree<AssistantState, CustomerDashboardState> = {
  activatePrepareDocumentAssistant({ commit }) {
    commit('setPrepareDocumentAssistantActive', true);
  },
  deactivatePrepareDocumentAssistant({ commit }) {
    commit('setPrepareDocumentAssistantActive', false);
  }
};

export default actions;
