import { Dictionary } from '@ligo/shared/utils';
import VueI18n, { TranslateResult } from 'vue-i18n';
import { useI18n } from '../../../../composables';

export const mappedRules = {
  required: (i18n: VueI18n) => (v) =>
    typeof v === 'number' ||
    typeof v === 'boolean' ||
    !!v ||
    i18n.t('validations.required'),
  bv_suffix: (i18n: VueI18n) => (v: any) => {
    return (!!v && !/(BV|B.V.)$/g.test(v)) || i18n.t('validations.bv_suffix');
  },
  non_negative: (i18n: VueI18n) => (v) =>
    (v && v >= 0) || i18n.t('validations.non_negative'),
  min_length_80: (i18n: VueI18n) => (v) => {
    return (
      (v && v.trim().split(' ').length >= 80) ||
      i18n.t('validations.min_length_80')
    );
  }
};

export type MappedRulesKey = keyof typeof mappedRules;

export type SimpleFieldType =
  | 'text'
  | 'textarea'
  | 'number'
  | 'select'
  | 'language'
  | 'phone-number';

export type SimpleFieldStatus = (
  fieldValue: any,
  formValue?: any,
  fieldConfig?: SimpleField
) => boolean;

export type SimpleFieldValidator = (
  formValue?: any,
  fieldConfig?: SimpleField
) => (value: any) => boolean | TranslateResult | string;

export type SimpleFieldReducer = (
  formValue: Dictionary,
  fieldConfig: SimpleField
) => Dictionary;

export interface SimpleBaseField {
  /** Parameter key of the field */
  key: string;

  /** Label for the field (default: [key])*/
  label?: string;

  /** Mask for the field */
  mask?: string;

  /** Function for create custom labels for the field */
  labelBuilder?: (
    label: string,
    formValue: Dictionary,
    field: SimpleField
  ) => string;

  /** Field type, by default: text */
  type?: SimpleFieldType;

  /** Basic rules functions link to the field, by default: [] */
  rules?: Array<SimpleFieldValidator | MappedRulesKey>;

  /** Material icon code */
  icon?: string;

  /** Suffix text */
  suffix?: string;

  /** Specify a max length of model  */
  maxlength?: number;

  /** Class for customize the field css */
  fieldClass?: string | ((v: any) => string);

  /** Class for customize the scope content of the field in the form */
  contentClass?: string | ((v: any) => string);

  /** Define the inicial value of the field */
  defaultValue?: any | ((formValue: Dictionary) => any);

  /** Show an automatic counter on bottom right */
  counter?: boolean;

  /** Dependency value definition for compute the dependency graph */
  valueDependency?: {
    keys: string[];
    handler: (formValue: Dictionary, field: SimpleField) => Dictionary;
  };

  /** Transform value when onInput is called */
  transform?: (value: any) => any;

  /**  Function that aggregates the field output to the request body */
  reducer?: SimpleFieldReducer;

  /** For hide the field */
  hideOn?: SimpleFieldStatus;

  /** For set the field on readonly mode*/
  readonlyOn?: SimpleFieldStatus;

  /** For set the field on disable mode*/
  disableOn?: SimpleFieldStatus;

  /** Checkpoint for handler when this field is complete */
  checkPoint?: (formValue: Dictionary, field: SimpleField) => Promise<any>;
}

export type SimpleSelectListPromise = (
  transform?: (obj: any) => any
) => Promise<any>;

export type SimpleSelectListType =
  | SimpleSelectListPromise
  | ((formValue: any) => any[]);

export interface SimpleSelectableOptions {
  /** Options for the field */
  options: SimpleSelectListType;

  /** Use as options by options visualize */
  useOptions?: boolean;

  /** Use as checkbox visualize */
  useCheckbox?: boolean;

  /** Option value for map-option, default: id */
  value?: string;

  /** Option label to show for map-option, default: name */
  label?: string | ((value: any) => string);

  /** Property of option which tells it's disabled;
   * The value of the property must be a Boolean;
   * If using a function then for best performance,
   * reference it from your scope and do not define it inline
   */
  disable?: string | ((value: any) => boolean);

  /** Dependency definition for reload the options */
  dependencyKeys?: string[];

  /** filter function for options depending on the formValue */
  filter?: (formValue: Dictionary, option: Dictionary) => boolean;

  /** for multiselect choice */
  multiple?: boolean;

  /** for use autocomplete mode */
  autocomplete?: {
    /** Filtering on more than {min} chars  */
    min: number;
  };
}

export interface SimpleSelectableField extends SimpleBaseField {
  type: 'select';
  selectable: SimpleSelectableOptions;
}

export const GetBoolSelectable = (): SimpleSelectableOptions => {
  const i18n = useI18n();
  return {
    useOptions: true,
    options: () => [
      {
        id: false,
        name: i18n.t('no')
      },
      {
        id: true,
        name: i18n.t('yes')
      }
    ]
  };
};

export type SimpleField = SimpleBaseField | SimpleSelectableField;

export const SimpleFormDesignDefault = {
  appClass: 'full-width row',
  titleClass:
    'q-pa-md full-width font-20 w-600 text-dark row justify-around items-center',
  labelClass: 'font-14 q-mb-sm text-dark',
  tooltipLabelClass: 'form-tooltip font-12',
  tooltipClass:
    'q-mb-md full-width text-text-subdued font-12 bg-surface-default',
  formClass: 'row text-dark full-width justify-start items-end',
  fieldClass: 'full-width row text-dark',
  fieldContentClass: 'col-12 q-pa-md',
  actionsClass: 'full-width row justify-end q-px-sm q-pt-md',
  transitionDuration: 1000,
  transition: true
};

export type SimpleFormDesignKey = keyof typeof SimpleFormDesignDefault;

export type SimpleForm = {
  /** Key for the form*/
  key: string;

  /** List of field inside the form */
  fields: SimpleField[];

  /** Design object for the form */
  design?: {
    [key in SimpleFormDesignKey]?: typeof SimpleFormDesignDefault[key];
  };

  /** Layout for add a stepper view concept */
  useLayout?: string[][];

  /** like $t */
  translateT: (key: string) => string;

  /** like $te */
  translateTe: (key: string) => boolean;

  /** For disable the next btn */
  disable?: (value: Dictionary) => boolean;

  /** Redefine how to create the payload to the api*/
  reducer?: (formValue: Dictionary) => Dictionary | FormData;
};
