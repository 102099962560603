export default {
  name: 'Name',
  street: 'Street',
  house_number: 'House Number',
  zip: 'Zip code',
  city: 'City',
  vat_number: 'VAT number (optional)',
  phone_number: 'Phone Number',
  message: 'In case you have any questions please give us a call',
  next: 'Next',
  rules: {
    required: 'This field is required'
  },
  address: 'Address',
  billing_details: 'Billing Details',
  edit: 'Edit',
  empty: 'No details added yet',
  save_success: 'Your billing address has been updated'
};
