






















import { defineComponent, ref } from '@vue/composition-api';
import AlertModal from '../../base/AlertModal.vue';
import BaseInput from '../../../base/BaseInput.vue';
import { APrimaryBtn, ASecondaryBtn } from '../../../base/app/buttons';

export default defineComponent({
  name: 'EditModal',
  components: {
    AlertModal,
    BaseInput,
    APrimaryBtn,
    ASecondaryBtn
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    name: {
      type: String
    }
  },
  setup(props, { emit }) {
    const newName = ref(props.name);
    function onCancel() {
      newName.value = props.name;
      emit('input', false);
    }
    return { newName, onCancel };
  }
});
