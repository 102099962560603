










import { defineComponent, inject } from '@vue/composition-api';
import { BrandedEmailConfirmation } from '@ligo/shared/auth';
import { brandInformation, sendConfirmationEmail, brandRouteName } from './auth';

export default defineComponent({
  name: 'Login',
  components: { BrandedEmailConfirmation },
  setup() {
    const brand = inject('brand', 'ligo')
    const brandData = brandInformation(brand)

    return {
      brand,
      brandData,
      brandRouteName,
      sendConfirmationEmail
    };
  }
});
