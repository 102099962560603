<template>
  <q-input
    ref="field"
    class="text-uppercase"
    outlined
    :dense="dense"
    :disable="disable"
    :label="label"
    :value="value"
    @click="$refs.proxy.show()"
    readonly
  >
    <template v-slot:prepend>
      <q-icon
        name="stop"
        :style="{ color: value }"
        class="cursor-pointer"
      >
        <q-popup-proxy
          ref="proxy"
          transition-show="scale"
          transition-hide="scale"
        >
          <q-color :value="value" @input="$emit('input', $event)" flat no-header no-footer>
          </q-color>
          <div class="row items-center justify-end">
            <q-btn
              v-close-popup
              :label="$t('close')"
              flat
            />
          </div>
        </q-popup-proxy>
      </q-icon>
      <q-separator class="q-mr-md" spaced inset vertical color="grey" />
    </template>
  </q-input>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    name: 'ColorPicker',
    props: {
        value: String,
        label: String,
        disable: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        }
    }
})
</script>
