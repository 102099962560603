var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.docsLoaded)?_c('div',[(_vm.busyNotaryBanner)?_c('busy-notary',{staticClass:"q-mb-lg",attrs:{"text":_vm.nt('read_docs.busy_notary'),"use-i18n":false},on:{"close":function($event){_vm.busyNotaryBanner = false}}}):_vm._e(),_c('div',{staticClass:"row justify-center"},[_c('div',{staticClass:"col"},[(
          !_vm.readDocsElements.loadingDocs && _vm.readDocsElements.docList.length
        )?_c('read-documents-handler',{staticClass:"q-mt-md",attrs:{"product-type":_vm.productType,"read-docs-attrs":_vm.readDocsElements,"notary":_vm.notary},on:{"completed":_vm.onAllDocsRead}}):_vm._e(),_c('upload-documents-handler',{staticClass:"q-mt-md",attrs:{"product-type":_vm.productType,"notary":_vm.notary},on:{"completed":_vm.onAllDocsUploaded}}),_c('div',{staticClass:"row justify-start q-mt-lg"},[_c('div',{staticClass:"col-xs-12 col-sm-5 col-md-3"},[(!!_vm.notaryId)?_c('a-primary-btn',{attrs:{"disable":_vm.cantMoveOn,"label":_vm.$t('read_docs.send_notary')},on:{"click":_vm.onSend}}):_vm._e(),(_vm.cantMoveOn)?_c('q-tooltip',{attrs:{"content-class":"bg-purple-1 text-purple font-13"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('read_docs.cant_send_message'))}})]):_vm._e()],1)]),(!_vm.notaryId)?_c('div',{staticClass:"full-width q-pa-md font-16 w-600 text-default"},[_vm._v(" "+_vm._s(_vm.t('extra_questions.fields.notary_id.label'))+" "),_c('q-icon',{staticClass:"q-mb-xs q-ml-xs cursor-pointer",attrs:{"name":"mdi-information","color":"subdued","size":"sm"},on:{"click":function($event){_vm.showToolip = !_vm.showToolip}}},[_c('q-tooltip',{attrs:{"content-class":"form-tooltip font-12"}},[_vm._v(" "+_vm._s(_vm.t('extra_questions.tooltip_info'))+" ")])],1)],1):_vm._e(),(_vm.showToolip)?_c('q-intersection',{staticClass:"full-width",attrs:{"transition":"scale"}},[_c('q-card',{staticClass:"q-mb-md full-width text-dark font-12 q-pa-xs bg-surface-subdued",attrs:{"flat":"","bordered":""}},[_c('q-item',{staticClass:"q-pa-md"},[_c('q-item-section',{staticClass:"q-mr-none",domProps:{"innerHTML":_vm._s(_vm.t('extra_questions.fields.notary_id.tooltip'))}}),_c('q-item-section',{staticClass:"q-ma-none",attrs:{"side":"","top":""}},[_c('q-btn',{attrs:{"tabindex":"-1","round":"","dense":"","flat":"","size":"sm","icon":"close"},on:{"click":function($event){_vm.showToolip = false}}})],1)],1)],1)],1):_vm._e(),(!_vm.notaryId)?_c('div',{class:{
          'full-width row shadow-1 rounded-borders bg-white q-pb-md q-pt-sm q-px-md': true,
          'action-table-row-cancelled': _vm.cantMoveOn
        }},[_c('div',{staticClass:"col-lg-6 col-xl-5 col-12 col-md-9 col-sm-10"},[_c('base-simple-form',{attrs:{"value":_vm.formValue,"force-loading":false,"config":_vm.formConfig},on:{"on-change-value":function($event){_vm.formValue = $event}},scopedSlots:_vm._u([{key:"form-header",fn:function(){return [_c('div')]},proxy:true},{key:"action-button",fn:function(){return [_c('div',{staticClass:"q-mr-sm"})]},proxy:true},{key:"next-button",fn:function(){return [_c('q-btn',{staticClass:"q-px-md",attrs:{"color":!_vm.cantMoveOn ? 'np-primary-1100' : 'grey-2',"no-caps":"","dense":"","disable":_vm.cantMoveOn || !_vm.formValue.notary_id},on:{"click":function($event){_vm.showConfirmDialog = true}}},[(
                    _vm.cantMoveOn &&
                    _vm.te('extra_questions.fields.notary_id.disable_text')
                  )?_c('q-tooltip',{attrs:{"content-class":"form-tooltip font-12 w-500"}},[_vm._v(" "+_vm._s(_vm.t('extra_questions.fields.notary_id.disable_text'))+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('read_docs.send_notary'))+" ")],1),_c('q-space')]},proxy:true},{key:"notary_id-default-item-listed",fn:function(scope){return [_c('q-item-section',[_vm._v(" "+_vm._s(scope.value.presentation_label)+" ")]),_c('q-item-section',{attrs:{"avatar":""}},[_c('q-badge',{class:("badge-availability-base badge-availability-" + (scope.value.capacity_status))},[_c('span',{staticClass:"font-12 w-500 text-dark"},[_vm._v(" "+_vm._s(_vm.getAvailabilityInfo[scope.value.capacity_status])+" ")])])],1)]}}],null,false,886834400)})],1)]):_vm._e()],1)]),(_vm.formValue.notary_id)?_c('alert-modal',{attrs:{"title":_vm.getDialogInfo.title,"subtitle":_vm.getDialogInfo.text,"modal-width":'new-version-width'},on:{"input":function($event){return _vm.$emit('input')}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('a-secondary-btn',{attrs:{"label":_vm.getDialogInfo.cancel},on:{"click":function($event){_vm.showConfirmDialog = false}}}),_c('a-primary-btn',{attrs:{"loading":_vm.loading,"label":_vm.getDialogInfo.next},on:{"click":_vm.onMoveNext}})]},proxy:true}],null,false,3234911030),model:{value:(_vm.showConfirmDialog),callback:function ($$v) {_vm.showConfirmDialog=$$v},expression:"showConfirmDialog"}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }