export function getPropertyClassBased(targetClass: string, property: string) {
  const tempElement = document.createElement('div');

  document.body.appendChild(tempElement);

  tempElement.classList.add(targetClass);

  const style = window.getComputedStyle(tempElement);
  const value = style[property];

  document.body.removeChild(tempElement);

  return value;
}
