

























import { defineComponent } from '@vue/composition-api';
import { APrimaryBtn } from '../../../../base/app/buttons';

const i18nContent = {
  signDocument: 'e_signature.prepare_document.modals.success.cta'
};

export default defineComponent({
  name: 'SuccessModal',
  components: { APrimaryBtn },
  props: {
    title: String,
    description: String,
    showActions: {
      type: Boolean,
      default: true
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    return { i18nContent };
  }
});
