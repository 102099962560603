






































































































































import {
  computed,
  defineComponent,
  ref,
  watch,
  onBeforeUnmount
} from '@vue/composition-api';
import { PropType } from 'vue';
import { SEPARATOR_TOKEN } from '../../../notary-product/extra-questions/extra-fields.hooks';
import { SimpleSelectableField } from '../models/simpleForm.models';
import { nully } from '@ligo/shared/utils';
import TrieSearch from 'trie-search';

export default defineComponent({
  name: 'BaseSimpleSelectField',
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {},
    rules: {
      type: Array
    },
    disableText: {
      type: String
    },
    tooltipClass: {
      type: String
    },
    placeholder: {
      type: String
    },
    readonly: {
      type: Boolean
    },
    disable: {
      type: Boolean
    },
    formValue: {
      type: Object
    },
    loading: {
      type: Boolean
    },
    hint: {
      type: String
    },
    config: {
      type: Object as PropType<SimpleSelectableField>,
      required: true
    },
    lastUpdateKey: {
      type: String
    }
  },
  setup(props, { emit }) {
    const optionsLoading = ref<boolean>(false);
    const options = ref<any[]>([]);
    const computedLastUpdateKey = computed(() => props.lastUpdateKey);
    const isPromise = (p: any): p is Promise<any> => !!p.then;
    const isFunction = (p: any): p is (value: any) => string =>
      typeof p === 'function';

    // filtering using the function filter that receive the formValue, for conditional formValue filter
    const filterWithFormValueOptions = (options: any[]) => {
      return options.filter((option) => {
        if (props.config.selectable.filter)
          return props.config.selectable.filter(props.formValue, option);
        return true;
      });
    };

    // using trie ds for optimize search inside options
    let optionsTrie;
    const filterTerm = ref('');
    const getFilteredOptions = computed(() => {
      if (filterTerm.value) {
        return filterWithFormValueOptions(optionsTrie.get(filterTerm.value));
      } else {
        return filterWithFormValueOptions(options.value);
      }
    });

    const onFilter = (val, update, abort) => {
      if (props.config.selectable.autocomplete) {
        if (val.length < props.config.selectable.autocomplete.min) {
          abort();
          return;
        }

        update(() => {
          filterTerm.value = val;
        });
      } else {
        update(() => void {});
      }
    };

    const loadOptionsForTrie = () => {
      if (props.config.selectable.autocomplete) {
        optionsTrie = new TrieSearch(props.config.selectable.label || 'name', {
          min: props.config.selectable.autocomplete.min
        });
        optionsTrie.addAll(options.value);
      }
    };

    const reload = () => {
      optionsLoading.value = true;
      const optionsLoad = props.config.selectable.options(props.formValue);
      if (isPromise(optionsLoad)) {
        optionsLoad
          .then((optionResponse) => {
            options.value = optionResponse;
            loadOptionsForTrie();
          })
          .finally(() => {
            optionsLoading.value = false;
          });
      } else {
        options.value = optionsLoad;
        loadOptionsForTrie();

        optionsLoading.value = false;
      }
    };

    const unwatch = watch(computedLastUpdateKey, () => {
      if (
        props.lastUpdateKey &&
        (props.config.selectable.dependencyKeys || []).includes(
          props.lastUpdateKey
        )
      ) {
        reload();
      }
    });

    reload();

    onBeforeUnmount(() => {
      unwatch();
    });

    const isOnValue = (option: any) => {
      const optionValue = option[props.config.selectable.value || 'id'];
      if (!props.value) return false;

      if (props.config.selectable.multiple) {
        return ((props.value as any[]) || []).includes(optionValue);
      }
      return props.value == optionValue;
    };

    const turnOnValue = (option: any) => {
      const optionValue = option[props.config.selectable.value || 'id'];

      const getEmitValue = () => {
        if (!props.value) {
          if (props.config.selectable.multiple) {
            return [optionValue];
          } else return optionValue;
        } else {
          if (props.config.selectable.multiple) {
            const clone = (props.value as any[]).map((x) => x);
            const idx = clone.findIndex((x) => x == optionValue);
            if (idx > -1) {
              clone.splice(idx, 1);
            } else {
              clone.push(optionValue);
            }
            return clone;
          } else {
            if (isOnValue(option)) {
              return null;
            } else {
              return optionValue;
            }
          }
        }
      };
      emit('input', getEmitValue());
    };

    const style = (option: any, i: number) => {
      const padding = i == getFilteredOptions.value.length - 1 ? '' : 'q-mr-sm';
      const optionValue = option[props.config.selectable.value || 'id'];
      if (typeof optionValue === 'boolean') {
        if (nully(props.value)) {
          return `text-grey-1 ${padding}`;
        }
        if (props.value && optionValue)
          return `text-np-primary-1100 border-np-primary-1100 w-600 ${padding}`;
        if (!props.value && !optionValue)
          return `text-bv-warning selected-option-negative ${padding}`;
      }
      const active = optionValue == props.value;
      return active
        ? `text-np-primary-1100 border-np-primary-1100 w-600 ${padding}`
        : `text-grey-1 ${padding}`;
    };

    return {
      optionsLoading,
      computedLastUpdateKey,
      SEPARATOR_TOKEN,
      getFilteredOptions,
      onFilter,
      isFunction,
      isOnValue,
      turnOnValue,
      style
    };
  }
});
