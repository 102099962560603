
























































































import { defineComponent } from '@vue/composition-api';
import { BaseInput } from '../../base';
import { ABtn } from '../../base/app/buttons';
import { useCancelFromQuestions } from './cancel-form.hook';
import StepHeader from '../modal-components/StepHeader.vue';
import NeedHelp from '../modal-components/NeedHelp.vue';
import { useI18n } from '../../../composables';

export default defineComponent({
  name: 'FormStep',
  components: { StepHeader, NeedHelp, BaseInput, ABtn },
  setup(_, { emit }) {
    const {
      q1,
      reasonsOptions,
      textInputs,
      q2,
      appsOptions
    } = useCancelFromQuestions();

    const i18 = useI18n();

    function onContinue() {
      emit('continue', {
        reasons_1: q1.value.map((item) => {
          return textInputs[item]
            ? `${i18.t(`payment.cancel.form.${item}`, 'en-us')}: ${
                textInputs[item].value
              }`
            : i18.t(`payment.cancel.form.${item}`, 'en-us');
        }),
        reasons_2: q2.value.map((item) => {
          return textInputs[item]
            ? `${i18.t(`payment.cancel.form.${item}`, 'en-us')}: ${
                textInputs[item].value
              }`
            : i18.t(`payment.cancel.form.${item}`, 'en-us');
        }),
        reasons_3: textInputs.feedback.value
      });
    }

    return { q1, reasonsOptions, textInputs, q2, appsOptions, onContinue };
  }
});
