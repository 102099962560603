import { ref, Ref } from '@vue/composition-api';
import { ApiService, RootContext } from '@ligo/shared/utils';
import { RESOURCES } from '@ligo/dashboard/customer/store';
import { BillingData } from '../../models';

export function useBillingData(root: RootContext) {
  const loading = ref(true);
  const form = ref(null);
  const billingData: Ref<BillingData> = ref(null);
  const formSections = [
    ['name'],
    ['street', 'house_number'],
    ['zip', 'city'],
    ['phone_number']
  ];
  const formRulesi18n = {
    required: 'billing.rules.required'
  };
  const customerService = {
    message: 'billing.message'
  };
  ApiService.get(RESOURCES.BILLING)
    .then(({ data }) => {
      billingData.value = (data || {}) as BillingData;
    })
    .catch((e) => {
      console.log('There was an error retriving the billing form data : ', e);
    })
    .finally(() => {
      loading.value = false;
    });
  async function next(paymentId: string) {
    if (await form.value.validate()) {
      ApiService.patch(RESOURCES.BILLING, {
        ...billingData.value,
        payment_uuid: paymentId
      })
        .then(() => {
          root.$router.push({
            name: 'Checkout',
            params: { paymentId: paymentId }
          });
        })
        .catch((e) => {
          console.log(
            'There was an error saving the billing data or redirecting to the payment : ',
            e
          );
        });
    }
  }
  return {
    loading,
    billingData,
    formSections,
    customerService,
    form,
    formRulesi18n,
    next
  };
}
