












































































































































import { defineComponent, watch } from '@vue/composition-api';
import {
  ESignBanner,
  ESignDrawer,
  ESignContent,
  ESignMenu
} from './components';
import { useDocumentConfig } from './prepare-document.hook';
import { AddFieldsBoard } from './components/boards';
import { SendMailsModal, SuccessModal } from './components/modals';
import { setupSigningInterceptors } from '@ligo/dashboard/customer/store';
import PageLoader from '../../global/PageLoader.vue';
import {
  SLigoAssistant,
  SLigoAssistantChatBalloon
} from '@ligo/shared/components';
import APrimaryBtn from '../../base/app/buttons/APrimaryBtn.vue';
import ASecondaryBtn from '../../base/app/buttons/ASecondaryBtn.vue';

const i18nContent = {
  board: {
    subtitle: 'e_signature.prepare_document.board.subtitle'
  }
};

export default defineComponent({
  name: 'PrepareDocument',
  components: {
    ESignBanner,
    ESignDrawer,
    ESignContent,
    ESignMenu,
    AddFieldsBoard,
    SendMailsModal,
    SuccessModal,
    PageLoader,
    SLigoAssistant,
    SLigoAssistantChatBalloon,
    APrimaryBtn,
    ASecondaryBtn
  },
  props: {
    uuid: {
      type: String,
      required: true
    },
    assistant: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    setupSigningInterceptors();

    const {
      fieldsBoardReference,
      loading,
      contract,
      showMenu,
      signers,
      showingSendMailsModal,
      showingSuccessModal,
      successModalContent,
      atLeastOneFieldPerSigner,
      lastSignerUsedUuid,
      showEditSignersModal,
      user,
      FIELD_TYPES,
      showDrawer,
      assistantStates,
      isMeOneOfSigners,
      showAssistant,
      activateAssistant,
      finalizeAssistant,
      hideAssistant,
      handleAssistantNextClick,
      handleAssistantDismissClick,
      onEditSigner,
      onDelete,
      onGoToField,
      addSigner,
      updateFields,
      addMyself,
      removeTooltip,
      onMouseMoveInPage,
      goBack,
      loadMenu,
      onAddField,
      onContinue,
      changeSigner,
      copySignerField,
      onRemoveField,
      showFieldMenu,
      closeFieldMenu,
      sendEmails,
      completePreparation,
      changeRequiredStatus
    } = useDocumentConfig(props.uuid);

    watch(
      () => props.assistant,
      (value) => {
        if (value) {
          activateAssistant();
        }
      },
      { immediate: true }
    );

    return {
      fieldsBoardReference,
      loading,
      contract,
      showMenu,
      signers,
      showingSendMailsModal,
      showingSuccessModal,
      atLeastOneFieldPerSigner,
      i18nContent,
      lastSignerUsedUuid,
      showEditSignersModal,
      user,
      FIELD_TYPES,
      showDrawer,
      successModalContent,
      assistantStates,
      isMeOneOfSigners,
      showAssistant,
      finalizeAssistant,
      hideAssistant,
      handleAssistantNextClick,
      handleAssistantDismissClick,
      updateFields,
      addMyself,
      removeTooltip,
      onMouseMoveInPage,
      goBack,
      loadMenu,
      onAddField,
      onContinue,
      changeSigner,
      copySignerField,
      onRemoveField,
      showFieldMenu,
      closeFieldMenu,
      sendEmails,
      completePreparation,
      changeRequiredStatus,
      onEditSigner,
      onDelete,
      addSigner,
      onGoToField
    };
  }
});
