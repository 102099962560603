type PaymentRating = {
  icon: string;
  text: string;
  rating: number;
  details: string;
};

type lineItems = { name: string; price: number };

export interface Payment {
  uuid: string;
  payable_name: string;
  payable_price: number;
  payment_amount: number;
  paymentAmount: number;
  line_items: lineItems[];
  payment_description: string;
  coupon_id: number;
}

export interface PaymentView {
  uuid: string;
  total: number;
  subtotal: number;
  btw: number;
  lineItems: lineItems[];
  upsellPackageName: string;
  description?: string;
  payableName?: string;
}

export interface PaymentRatingBanner {
  order: string[];
  customer: {
    icon: string;
    text: string;
    total: string;
  };
  kyioh: PaymentRating;
  google: PaymentRating;
}

// Contract Payment upgrade cards
export interface CardPlan {
  id: number;
  slug?: string;
  name: string;
  subtitle: string;
  price: number;
  items?: PlanItem[];
  title_description?: string;
  billing_description?: string;
  billingInterval?: string;
  title?: string;
  cta: string;
  border?: boolean;
}

export interface PlanItem {
  icon: string;
  text: string;
  tip?: string;
  hide?: boolean;
}

export enum BillingOption {
  YEARLY = 'yearly',
  MONTHLY = 'monthly'
}

export enum PaymentFlowSteps {
  BILLING = 'billing',
  CHECKOUT = 'checkout',
  SUCCESS_PURCHASE = 'success-purchase'
}

export enum CancelPlanStep {
  WARN = 'warn',
  FORM = 'form',
  SUCCESS = 'success'
}

export enum PayContractStep {
  BILLING = 'billing',
  CHECKOUT = 'checkout'
}

export enum PaymentModal {
  CONTRACT = 'contract',
  UPGRADE = 'upgrade'
}

export enum AdyenResponseCode {
  ERROR = 'Error',
  AUTHORISED = 'Authorised',
  REFUSED = 'Refused',
  CANCELLED = 'Cancelled',
  PENDING = 'Received'
}
