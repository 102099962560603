export default {
  title: 'Bekijk je finale concept akte en keur de inhoud goed',
  info:
    'De notaris maakt op dit moment de finale concept akte voor jou klaar. Deze akte verschijnt hier zodra de notaris deze heeft geüpload. Je zult hiervan een email-notificatie ontvangen.',
  reviewing:
    'De notaris is momenteel bezig met het voorbereiden van jouw finale concept akte',
  subtitle: 'Lees het volgende document aandachtig',
  download: 'Download',
  approve: 'Goedkeuren',
  accepted: 'Geaccepteerd',
  amend: 'Vraag een wijziging aan',
  requested: 'Wijzigingen aangevraagd...',
  cancel_amendment_request: 'Annuleer wijzigingsverzoek',
  information: `Amendments due to typos in the information provided such as
  incorporators’ names, companies’ names and addresses can be
  requested here at no-extra cost. All other changes require the
  notary to review and execute compliance work again, thus involve
  extra costs. If you need to change your application, please use
  the ‘Edit application’ button on the top-right corner of your
  screen.`,

  confirm: {
    msg: 'Bevestig dat de inhoud van de finale concept akte klopt',
    yes: 'Ja',
    no: 'Nee'
  },

  request: {
    title: 'Vraag wijzigingen aan in de akte',
    subtitle: 'Wat moet er gewijzigd worden?',
    placeholder: 'Licht de wijziging toe voor de notaris',
    yes: 'Verstuur je verzoek',
    no: 'Annuleer',
    information: `Amendments due to typos in the information provided such as
  incorporators’ names, companies’ names and addresses can be
  requested here at no-extra cost. All other changes require the
  notary to review and execute compliance work again, thus involve
  extra costs. If you need to change your application, please use
  the ‘Edit application’ button on the top-right corner of your
  screen.`
  },
  move_next_step: 'Bevestiging versturen'
};
