































import { computed, defineComponent, onMounted } from '@vue/composition-api';
import { useStore } from '../../composables';
import LawyerProfile from '../../components/lawyers/LawyerProfile.vue';
import BasePage from '../../components/base/BasePage.vue';
import { SQMainHeader } from '../../components/base';
import { useLawyers } from '../../components/lawyers/lawyers.hook';

export default defineComponent({
  name: 'LawyerDetails',
  components: { LawyerProfile, BasePage, SQMainHeader },
  props: {
    slug: String
  },
  setup(props) {
    const store = useStore();
    const user = computed(() => store.getters['authentication/user']);

    const {
      lawyerProfile,
      setLawyerSlug,
      breadcrumbPath,
      lawyerFullName
    } = useLawyers();
    setLawyerSlug(props.slug);

    function trackLoadedPage() {
      (window as any).analytics.track(`Lawyers - PageLoaded`, {
        email: user.value.email,
        uuid: user.value.uuid,
        slug: props.slug
      });
    }

    onMounted(() => {
      trackLoadedPage();
    });

    return { lawyerProfile, breadcrumbPath, lawyerFullName };
  }
});
