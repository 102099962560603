





















































import {
  defineComponent,
  ref,
  Ref,
  PropType,
  computed
} from '@vue/composition-api';
import { initCanvas, fitTextToWidth } from '@ligo/shared/utils';
import {
  Signer,
  Coordinates,
  Dimensions,
  FieldTypes
} from '../../../../models';
import { FieldMenu } from './menus';

const CLICK_DOWN_UP_DELAY = 200;

type FieldsRepresentation = {
  [key in FieldTypes]: {
    fontFamily: string;
    sample: string;
    icon?: string;
    fontSize?: number;
    justify?: string;
  };
};

export default defineComponent({
  name: 'ESignField',
  components: {
    FieldMenu
  },
  props: {
    color: {
      type: String,
      required: true
    },
    signerEmail: {
      type: String as PropType<string>,
      required: true
    },
    type: {
      type: String as PropType<string>,
      required: true
    },
    uuid: {
      type: String as PropType<string>,
      required: true
    },
    pageIndex: {
      type: Number as PropType<number>,
      required: true
    },
    showingMenu: {
      type: Boolean as PropType<boolean>
    },
    coordinates: {
      type: Object as PropType<Coordinates>,
      required: true
    },
    dimensions: {
      type: Object as PropType<Dimensions>,
      required: true
    },
    signers: {
      type: Array as PropType<Signer[]>,
      required: true
    },
    required: Boolean
  },
  setup(props, { root, emit }) {
    const stopWatch: Ref<number> = ref(0);
    const clickDown = ref(false);
    const requiredFonts = ['Arial', 'Brush Script MT'];
    const loadingFonts = ref(true);
    const fieldsRepresentation: FieldsRepresentation = {
      [FieldTypes.SIGNATURE]: {
        fontFamily: 'Brush Script MT',
        sample: root.$i18n
          .t('e_signature.prepare_document.fields.samples.signature')
          .toString()
      },
      [FieldTypes.TEXT]: {
        fontFamily: 'Arial',
        fontSize: 14,
        justify: 'start',
        sample: root.$i18n
          .t('e_signature.prepare_document.fields.samples.text')
          .toString()
      },
      [FieldTypes.DATE]: {
        fontFamily: 'Arial',
        sample: root.$i18n
          .t('e_signature.prepare_document.fields.samples.date')
          .toString()
      },
      [FieldTypes.CHECKBOX]: {
        fontFamily: 'Arial',
        icon: 'mdi-checkbox-marked-outline',
        sample: 'X'
      }
    };

    const getCurrentDimensions = computed(() => {
      const { width, height } = props.dimensions;
      return {
        width,
        height
      };
    });

    function getSampleFontSize() {
      if (fieldsRepresentation[props.type].fontSize)
        return fieldsRepresentation[props.type].fontSize;

      const paddingX = props.dimensions.width * 0.2;
      const paddingY = props.dimensions.height * 0.2;
      const { canvas, context } = initCanvas(
        props.dimensions.width - paddingX,
        props.dimensions.height - paddingY
      );
      const { fontFamily, sample } = fieldsRepresentation[props.type];
      context!.font = `100px ${fontFamily}`;
      const fontSize = fitTextToWidth(canvas, sample);
      return fontSize;
    }

    function initClick() {
      stopWatch.value = Date.now();
      clickDown.value = true;
    }

    function endClick() {
      if (Date.now() - stopWatch.value < CLICK_DOWN_UP_DELAY) {
        if (!props.showingMenu) {
          emit('show-menu');
        } else {
          emit('close-menu');
        }
      }
      clickDown.value = false;
      emit(
        'end-field-click',
        props.signers.find((signer) => signer.email === props.signerEmail)
      );
    }

    function getFieldStyle() {
      return `
        top: ${props.coordinates.y}px;
        left: ${props.coordinates.x}px;
        width: ${props.dimensions.width}px;
        height: ${props.dimensions.height}px;
        color: ${props.color};
      `;
    }

    function changeSigner(signer: Signer) {
      emit('change-signer', signer);
      emit('close-menu');
    }

    function deleteField(signer: Signer) {
      emit('delete-field', signer);
      emit('close-menu');
    }

    const menuPosition = computed(() => {
      return `
        top: ${props.coordinates.y}px;
        left: ${props.coordinates.x}px;
      `;
    });

    const sampleTextFontSize = computed(() => getSampleFontSize());

    Promise.all(
      requiredFonts.map((font) => (document as any).fonts.load(`24px ${font}`))
    ).then(() => {
      loadingFonts.value = false;
    });

    return {
      getFieldStyle,
      initClick,
      endClick,
      changeSigner,
      deleteField,
      menuPosition,
      sampleTextFontSize,
      loadingFonts,
      getCurrentDimensions,
      fieldsRepresentation
    };
  }
});
