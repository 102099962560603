






















import { defineComponent, PropType } from '@vue/composition-api';
import { MembershipSlug } from '@ligo/dashboard/customer/store';
import { CardPlan } from '../../../models';
import PlanCard from './PlanCard.vue';

const ENTERPRISE_PLAN_IDS = [22, 12];

export default defineComponent({
  name: 'PlanList',
  components: { PlanCard },
  props: {
    plans: {
      type: Array as PropType<CardPlan[]>
    },
    selectedPlan: {
      type: String
    },
    activePlan: {
      type: String
    },
    currentPlan: {
      type: String
    }
  },
  setup(props, { emit }) {
    function onSelect(desirePlan: MembershipSlug) {
      emit('select', desirePlan);
    }
    return { onSelect, ENTERPRISE_PLAN_IDS };
  }
});
