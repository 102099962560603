






















import { defineComponent, PropType } from '@vue/composition-api';
export default defineComponent({
  name: 'StepsBanner',
  components: {},
  props: {
    steps: {
      type: Array as PropType<
        { title: string; subtitle: string; icon: string }[]
      >,
      required: true
    }
  },
  setup() {
    return {};
  }
});
