import { ref, Ref } from '@vue/composition-api';
import {
  Signer,
  ESignField,
  PointerPage,
  FieldType,
  FieldTypes
} from '../../../../../models';
import { InteractableElement } from '@ligo/shared/utils';

type DefaultFields = {
  [key in FieldType]: {
    width: number;
    height: number;
    square?: boolean;
  };
};

const REFERENCE_SCREEN_WIDTH = 1920;
const REFERENCE_SCREEN_HEIGHT = 1080;

const FIELDS: DefaultFields = {
  SIGNATURE: {
    width: 280,
    height: 120
  },
  DATE: {
    width: 190,
    height: 40
  },
  TEXT: {
    width: 215,
    height: 60
  },
  CHECKBOX: {
    width: 45,
    height: 45,
    square: true
  }
};

function getProportionalSize(height: number, width: number) {
  const currentWidth = window.innerWidth;
  const currentHeight = window.innerHeight;
  const newWidth = (currentWidth * width) / REFERENCE_SCREEN_WIDTH;
  const newHeight = (currentHeight * height) / REFERENCE_SCREEN_HEIGHT;
  return { width: newWidth, height: newHeight };
}

export function useFieldsInteractions() {
  const pointerPage: Ref<PointerPage> = ref({
    page_number: 0,
    x: 0,
    y: 0
  });

  const lastSignerUsedUuid: Ref<string> = ref(null);

  const previousFieldMenu: Ref<ESignField> = ref(null);

  function initInteractiveFields(
    field: ESignField,
    squareAspectRatio?: boolean
  ) {
    const interactableElement = new InteractableElement(
      `[uuid="${field.uuid}"]`
    );
    interactableElement.makeResizable(
      (event) => {
        field.dimensions.width = event.rect.width;
        field.dimensions.height = event.rect.height;
        field.coordinates.x = parseFloat(event.target.style.left);
        field.coordinates.y = parseFloat(event.target.style.top);
      },
      squareAspectRatio,
      {
        left: `.left-edge`,
        right: `.right-edge`,
        bottom: `.bottom-edge`,
        top: `.top-edge`
      }
    );
    interactableElement.makeDraggable(`#page-${field.pageIndex}`, (event) => {
      field.coordinates.x = parseFloat(event.target.style.left);
      field.coordinates.y = parseFloat(event.target.style.top);
    });
  }

  function addField(
    signer: Signer,
    type: FieldType = 'SIGNATURE',
    required: boolean,
    x = pointerPage.value.x,
    y = pointerPage.value.y,
    width?: number,
    height?: number,
    pageIndex = pointerPage.value.page_number,
    squareField = false
  ) {
    let dimensions = { ...FIELDS[type] };
    const lastFieldWithSameType = signer.fields
      .filter((field) => field.type === type)
      .pop();
    if (width && height) {
      dimensions.width = width;
      dimensions.height = height;
    } else if (lastFieldWithSameType) {
      dimensions = { ...lastFieldWithSameType.dimensions };
    } else {
      const { width: newWidth, height: newHeight } = getProportionalSize(
        dimensions.height,
        dimensions.width
      );
      const minSide = Math.min(newWidth, newHeight);
      if (type == FieldTypes.CHECKBOX)
        dimensions = { width: minSide, height: minSide };
      else dimensions = { width: newWidth, height: newHeight };
    }
    lastSignerUsedUuid.value = signer.uuid;
    const container = document
      .querySelector(`#page-${pageIndex}`)
      .getBoundingClientRect();
    const uuid = Date.now();
    const newField = {
      uuid: uuid.toString(),
      signerEmail: signer.email,
      type,
      coordinates: {
        x,
        y
      },
      dimensions,
      documentPageSize: {
        width: container.width,
        height: container.height
      },
      pageIndex: pageIndex,
      required: required
    };
    signer.fields.push(newField as ESignField);
    initInteractiveFields(signer.fields.slice(-1)[0], squareField);
  }

  function removeField(fields, uuid) {
    fields.splice(
      fields.findIndex((field) => field.uuid === uuid),
      1
    );
  }

  function removeSignerField(signer: Signer, field: ESignField) {
    removeField(signer.fields, field.uuid);
  }

  function changeSigner(signer: Signer, field: ESignField, signers: Signer[]) {
    addField(
      signer,
      field.type,
      field.required || false,
      field.coordinates.x,
      field.coordinates.y,
      field.dimensions.width,
      field.dimensions.height,
      field.pageIndex
    );
    removeField(
      signers.find((s) => s.email === field.signerEmail).fields,
      field.uuid
    );
  }

  function copySignerField(signer: Signer, field: ESignField) {
    const x = Math.random() * field.dimensions.width + field.coordinates.x;
    const y = Math.random() * field.dimensions.height + field.coordinates.y;
    addField(
      signer,
      field.type,
      field.required || false,
      x,
      y,
      field.dimensions.width,
      field.dimensions.height,
      field.pageIndex
    );
  }

  function isFieldShowingMenu(signers: Signer[]) {
    return signers.some((s) => s.fields.some((f) => f.showingMenu));
  }

  function setFieldMenu(signers: Signer[], field?: ESignField) {
    if (previousFieldMenu.value) {
      previousFieldMenu.value.showingMenu = false;
    }
    if (field) {
      field.showingMenu = true;
      previousFieldMenu.value = field;
    }
    signers.splice(signers.length);
  }

  return {
    pointerPage,
    lastSignerUsedUuid,
    previousFieldMenu,
    removeField,
    initInteractiveFields,
    addField,
    removeSignerField,
    changeSigner,
    copySignerField,
    isFieldShowingMenu,
    setFieldMenu
  };
}
