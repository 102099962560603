import * as VueGoogleMaps from 'vue2-google-maps';
import {boot} from 'quasar/wrappers';
import {useEnvironment} from 'netlify_functions';

export default boot(({Vue}) => {
  Vue.use(VueGoogleMaps, {
    load: {
      key: useEnvironment().MAPS_API_KEY
    }
  });
});
