

























































































import { defineComponent, PropType } from '@vue/composition-api';
import { BoardHeader } from './base';
import { ESignField, fieldTypeIcons } from '../../../../../models';

const i18nContent = {
  board: {
    required_fields: 'e_signature.sign_document.board.required_fields',
    optional_fields: 'e_signature.sign_document.board.optional_fields',
    field: {
      types: {
        signature: 'e_signature.sign_document.board.fields.types.signature',
        text: 'e_signature.sign_document.board.fields.types.text',
        date: 'e_signature.sign_document.board.fields.types.date',
        checkbox: 'e_signature.sign_document.board.fields.types.checkbox'
      }
    },
    success: {
      title: 'e_signature.sign_document.board.success.title',
      text: 'e_signature.sign_document.board.success.text'
    }
  }
};

export default defineComponent({
  name: 'SignDocumentBoard',
  components: { BoardHeader },
  props: {
    title: String,
    subtitle: String,
    fields: {
      type: Array as PropType<ESignField[]>,
      default: () => [] as ESignField[]
    },
    success: Boolean,
    signerEmail: String
  },
  setup() {
    return { i18nContent, fieldTypeIcons };
  }
});
