





















































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { Signer, fieldTypeIcons } from '../../../../../models';
import { MenuSelect, MenuCta, RequiredOption } from './base';
import { APrimaryBtn } from '../../../../base/app/buttons';

const i18nContent = {
  title: 'e_signature.prepare_document.menu.title',
  signers_subtitle: 'e_signature.prepare_document.menu.signers_subtitle',
  fields_subtitle: 'e_signature.prepare_document.menu.fields_subtitle',
  field_types: {
    signature: 'e_signature.prepare_document.menu.field_types.signature',
    date: 'e_signature.prepare_document.menu.field_types.date',
    text: 'e_signature.prepare_document.menu.field_types.text',
    checkbox: 'e_signature.prepare_document.menu.field_types.checkbox'
  },
  add_cta: 'e_signature.prepare_document.menu.add_cta',
  required: 'e_signature.prepare_document.menu.required'
};

export default defineComponent({
  name: 'ESignMenu',
  components: {
    MenuSelect,
    MenuCta,
    RequiredOption,
    APrimaryBtn
  },
  props: {
    signers: {
      type: Array as PropType<Signer[]>,
      required: true
    },
    fieldTypes: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    currentSelectedSignerUuid: String
  },
  setup(props) {
    const selectedSigner = ref(
      props.currentSelectedSignerUuid
        ? props.signers.find((s) => s.uuid === props.currentSelectedSignerUuid)
        : undefined
    );

    const selectedType = ref(null);

    const requiredField = ref(true);

    return {
      selectedSigner,
      i18nContent,
      selectedType,
      fieldTypeIcons,
      requiredField
    };
  }
});
