import { ref, Ref } from '@vue/composition-api';
import moment from 'moment';

type SQTableItem = {
  value: string;
  textColor?: string;
  color?: string;
  radius?: number;
  customClass?: string;
  [key: string]: any;
};

type FilterOptions =
  | string[]
  | { value: any; label: string; [key: string]: any }[];

type FilterModel =
  | string
  | string[]
  | { value?: string; label?: string; [key: string]: string }
  | { value?: string; label?: string; [key: string]: string }[];

export type SearchEngine = (
  filters: SQTableFilter[],
  page: number,
  rowsPerPage: number,
  sortBy: string,
  descending: boolean
) => void;

export interface SQTableColumn {
  name: string;
  label: string;
  field: string | ((row) => string | SQTableItem);
  align?: 'left' | 'right' | 'center';
  sortable?: boolean;
}

export interface SQTableAction {
  label: string;
  icon?: string;
  color?: string;
  emit: string;
  conditional?: (row) => boolean;
}

export interface SQTableRow {
  [key: string]: string | boolean | number | SQTableItem;
  rowClass?: string;
}

export interface SQTableFilter {
  type: 'query' | 'single-select' | 'multi-select' | 'date-range';
  label?: string;
  labelSelected?: string;
  name: string;
  model: FilterModel;
  options?: FilterOptions;
  optionClass?: string | ((option: any) => string);
  selectedClass?: string | ((model: any) => string);
}

export interface SQTablePagination {
  sortBy: string;
  descending: boolean;
  page: number;
  rowsPerPage: number;
  rowsNumber?: number;
}

export interface SQTableEmptySate {
  title: string;
  subtitle: string;
  btnLabel: string;
  icon: string;
  iconSize?: number;
}

export interface SQTableNoResults {
  title: string;
  subtitle: string;
}

export function useSQTableConfig(
  filtersDefinitions: SQTableFilter[],
  searchEngine?: SearchEngine,
  pagination?: SQTablePagination
) {
  const filters = ref(
    filtersDefinitions.length
      ? filtersDefinitions.reduce((result, filter) => {
          result[filter.name] = { ...filter };
          return result;
        }, {})
      : ''
  );

  const initialPagination: Ref<SQTablePagination> = ref(
    pagination
      ? { ...pagination }
      : {
          sortBy: 'desc',
          descending: false,
          page: 1,
          rowsPerPage: 3
        }
  );

  const loading = ref(false);

  const MENU_ICON = 'img:/dashboard/icons/three_dots_circle.svg';

  function onRequest(props) {
    const { page, rowsPerPage, sortBy, descending } = props.pagination;
    const filter = props.filter;

    loading.value = true;

    if (searchEngine) {
      searchEngine(filter, page, rowsPerPage, sortBy, descending);
    }
  }

  function updatePagination(newPagination: SQTablePagination) {
    initialPagination.value = { ...newPagination };
  }

  function notEmptyDate(date: any) {
    return typeof date == 'string' || (date && date.from);
  }

  function formatDate(date: string) {
    return moment(date).format('D MMM YYYY');
  }

  function getValidActions(row: SQTableRow, actions: SQTableAction[]) {
    return actions.filter((action) => {
      if (action.conditional) {
        return action.conditional(row);
      }
      return true;
    });
  }

  function isModelEmpty(model: FilterModel) {
    if (Array.isArray(model) || typeof model == 'string') {
      return model.length == 0;
    }

    return !Boolean(model);
  }

  function reloadFilters(newDefinitions: SQTableFilter[]) {
    filters.value = newDefinitions.reduce((result, filter) => {
      result[filter.name] = { ...filter };
      return result;
    }, {});
  }

  return {
    filters,
    initialPagination,
    MENU_ICON,
    reloadFilters,
    isModelEmpty,
    getValidActions,
    formatDate,
    notEmptyDate,
    updatePagination,
    onRequest
  };
}
