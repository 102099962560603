















































import { defineComponent } from '@vue/composition-api';
import { APrimaryBtn } from '../../../../base/app/buttons';

const FEATURES = [
  'e_signature.sign_document.modals.success_without_account.features.store_all_documents',
  'e_signature.sign_document.modals.success_without_account.features.access_library',
  'e_signature.sign_document.modals.success_without_account.features.electronic_signature',
  'e_signature.sign_document.modals.success_without_account.features.digital_legally'
];

const REGISTER_PATH_NAME = 'Register';

const i18nContent = {
  title: 'e_signature.sign_document.modals.success_without_account.title',
  subtitle: 'e_signature.sign_document.modals.success_without_account.subtitle',
  create_account:
    'e_signature.sign_document.modals.success_without_account.create_account',
  cta: 'e_signature.sign_document.modals.success_without_account.cta',
  features: FEATURES
};

export default defineComponent({
  name: 'SuccessWithoutAccount',
  components: { APrimaryBtn },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    signerEmail: String
  },
  setup() {
    return { i18nContent, REGISTER_PATH_NAME };
  }
});
