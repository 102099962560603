









































































































import { computed, PropType } from '@vue/composition-api';

export default {
  name: 'UserNotifications',
  props: {
    notifications: {
      type: (Array as unknown) as PropType<any[]>
    },
    unseenCount: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    last: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: 300
    },
    maxHeight: {
      type: Number,
      default: 500
    },
    icon: {
      type: String,
      default: 'img:/icons/notification.svg'
    },
    emptyIcon: {
      type: String,
      default: 'img:/icons/no_notification.svg'
    }
  },
  setup(props) {
    const notificationCountMsg = computed(() =>
      props.unseenCount == 0 ? '' : `(${props.unseenCount})`
    );

    return {
      notificationCountMsg
    };
  }
};
