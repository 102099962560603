export type Dimensions = {
  width: number;
  height: number;
};

export type Coordinates = {
  x: number;
  y: number;
};

export enum FieldTypes {
  SIGNATURE = 'SIGNATURE',
  DATE = 'DATE',
  TEXT = 'TEXT',
  CHECKBOX = 'CHECKBOX'
}

type FieldTypeIcons = {
  [key in FieldTypes]: string;
};

export const fieldTypeIcons: FieldTypeIcons = {
  SIGNATURE: '/dashboard/icons/signature-icon.svg',
  DATE: '/dashboard/icons/date-icon.svg',
  TEXT: '/dashboard/icons/text-icon.svg',
  CHECKBOX: '/dashboard/icons/checkbox-icon.svg'
};

export enum CheckboxField {
  CHECKED = '/dashboard/images/checked.svg',
  UNCHECKED = '/dashboard/images/unchecked.svg'
}

export type FieldType = keyof typeof FieldTypes;

export interface ClickEvent {
  target: {
    getBoundingClientRect: () => DOMRect;
  };
  clientX: number;
  clientY: number;
  pageX: number;
  pageY: number;
  button: number;
  altKey: boolean;
}

export interface Signer {
  uuid: string;
  email: string;
  drawing_signatures: string[];
  fields: ESignField[];
  signing_session: {
    uuid: string;
  };
}

export enum SignerAASMState {
  OPEN = 'open',
  SIGNED = 'signed',
  DECLINED = 'declined'
}

export interface SignerSession {
  uuid: string;
  signer: Signer;
  expires_at: string;
}

export interface ESignField {
  uuid: string;
  signerEmail?: string;
  type: FieldType;
  coordinates: Coordinates;
  dimensions: Dimensions;
  documentPageSize: Dimensions;
  pageIndex: number;
  content?: string;
  auxData?: any;
  color?: string;
  showingMenu?: boolean;
  required: boolean;
}

interface Page {
  page_number: number;
}

export interface ESignPage extends Page {
  preview_image: string;
}

export interface PointerPage extends Page {
  x: number;
  y: number;
}

export type ModalCta = {
  label: string;
  color: string;
  textColor?: string;
  class?: string;
  disabled?: boolean;
  emit: string;
};
