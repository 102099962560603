export default {
  wait_info: 'The notary is preparing the Final Deed of Incorporation.',
  title: 'Congratulations!',
  title2: 'Congratulations',
  subtitle:
    'Now that your company is officially incorporated, you have to arrange a few legal matters to ensure that you comply with the rules of the tax authorities.',
  rate: 'Please rate your experience',
  documents: 'Incorporation documents of {name}',
  dashboard: 'Go to dashboard',
  finilized: 'Finalized',
  expected: 'Pending',
  final_deed: 'Deed of Incorporation',
  kvk: 'Extract of the chamber of commerce',
  document_folder: 'Documents you uploaded',
  upload_document: '{total} documents uploaded',
  steps: {
    icorporate: 'Incorporate my BV',
    open_bank: 'Open a bank account',
    obligations: 'Pay up shares',
    more: '...and more'
  },
  todo: 'See my legal to-dos',
  pending: 'This task is pending'
};
