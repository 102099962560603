export default {
  page: {
    title: 'Ask a lawyer',
    subtitle:
      'Our network of good lawyers is ready to help you. Get to know them by contacting them without obligation.'
  },
  cards: {
    phone: 'Phone',
    email: 'Email',
    fee: 'Fee',
    learn_more_cta: 'Learn more'
  }
};
