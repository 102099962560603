









































































import { defineComponent } from '@vue/composition-api';
import { useDgaServices, statusBadges } from './dga-services.hook';
import ServicesTypesList from './components/ServicesTypesList.vue';
import ServicesTable from './components/ServicesTable.vue';
import { DgaServicesPageInformation } from '../../components/utils/useCustomerPageInformation.hooks';
import { APrimaryBtn } from '../base/app/buttons';

export default defineComponent({
  name: 'DgaServicesHandler',
  components: { ServicesTypesList, ServicesTable, APrimaryBtn },
  setup() {
    const { t, isReady, load } = DgaServicesPageInformation;
    load();

    const {
      services,
      loadingServices,
      types,
      loadingTypes,
      tablePagination,
      search
    } = useDgaServices();

    return {
      services,
      loadingServices,
      types,
      loadingTypes,
      tablePagination,
      statusBadges,
      t,
      isReady,
      search
    };
  }
});
