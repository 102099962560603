import { ref, watch } from '@vue/composition-api';
import { ContractService } from '@ligo/dashboard/customer/store';
import { useRouter, useI18n } from '../../composables';
import { simpleNotification } from '@ligo/shared/utils';

const FORMATS = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'application/msword'
];

export function useSignatureHome() {
  const i18n = useI18n();
  const router = useRouter();
  const fileInput = ref();
  const selectedFiles = ref();

  function saveFiles(files: File[]) {
    const promises = files.map((file) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('filename', file.name);
      formData.append('signed', JSON.stringify(false));
      return ContractService.create(formData);
    });
    Promise.all(promises)
      .then((responses) => {
        if (files.length == 1) {
          const response = responses[0];
          router.push({
            name: 'ContractPreview',
            params: {
              uuid: response.data.uuid
            }
          });
        }
      })
      .catch((error) => {
        console.error('Error uploading files', error);
      })
      .finally(() => {
        if (files.length > 1) {
          router.push({
            name: 'ActiveContracts'
          });
        }
      });
  }

  function dropHandler(ev) {
    let files: File[] = [];
    if (ev.dataTransfer.items) {
      // Using DataTransferItemList interface to access the file(s)
      [...ev.dataTransfer.items].forEach((item, _) => {
        if (item.kind === 'file') {
          const file = item.getAsFile();
          files.push(file);
        }
      });
    } else {
      // Using DataTransfer interface to access the file(s)
      files = [...ev.dataTransfer.files];
    }
    processFiles(files);
  }

  function onUploadFiles() {
    fileInput.value.pickFiles();
  }

  function processFiles(files: File[]) {
    const selectedFiles = files.filter((file) => {
      const extensionIncluded = FORMATS.includes(file.type);
      if (!extensionIncluded) {
        console.error('Invalid file type', file.name);
        simpleNotification(
          undefined,
          `${i18n.t('e_signature.home_page.invalid_file')} ${file.name}`
        );
      } else {
        simpleNotification({
          message: `File uploaded ${file.name}`,
          timeout: 4000
        });
        console.log('File uploaded', file.name);
      }
      return extensionIncluded;
    });
    saveFiles(selectedFiles);
  }

  watch(selectedFiles, (files: File[]) => {
    if (files && files.length > 0) {
      processFiles(files);
    }
  });

  return { fileInput, selectedFiles, dropHandler, onUploadFiles };
}
