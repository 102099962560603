var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('s-q-main-header',{attrs:{"title":_vm.$t('contract.actives.title'),"subtitle":_vm.$t('nav.contracts_subtitle'),"icon":"fas fa-file-contract"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('panel',{on:{"upload-file":function($event){return _vm.$emit('upload-file')},"new-document":function($event){return _vm.$emit('new-document')}}})]},proxy:true}])}),_c('q-card',{staticClass:"row justify-between bg-white q-py-md q-px-lg rounded-borders q-mb-md",attrs:{"flat":"","bordered":""}},_vm._l((Object.values(_vm.signatureWidget)),function(item,i){return _c('div',{key:("item-" + i),class:{
        'row items-center': true,
        'col-12': _vm.$q.screen.lt.sm,
        'q-mt-lg': _vm.$q.screen.lt.sm && i > 0
      }},[_c('div',{class:("bg-surface-default-enhanced row items-center justify-center q-mr-" + (_vm.$q.screen.lt.sm ? 'md' : 'sm')),style:("width: 50px; height: 50px; border-radius: 50%;")},[_c('q-icon',{attrs:{"color":"black","size":"24px","name":item.icon}})],1),_c('div',[_c('div',{staticClass:"font-26 w-600 text-default"},[_vm._v(" "+_vm._s(item.total)+" ")]),_c('div',{staticClass:"font-10 w-600 text-subdued text-uppercase"},[_vm._v(" "+_vm._s(item.label)+" ")])])])}),0),_c('s-q-table',{attrs:{"rows":_vm.rows,"columns":_vm.columns,"loading":_vm.loading,"pagination":_vm.pagination,"search-engine":_vm.search,"filters-definitions":_vm.filters,"actions":_vm.actions,"total":_vm.itemsTotal,"empty":{
      title: _vm.$t('contract.empty_banner.title'),
      subtitle: _vm.$t('contract.empty_banner.subtitle'),
      icon: 'img:/dashboard/images/empty_docs.svg',
      btnLabel: _vm.$t('contract.empty_banner.btn_label')
    }},on:{"row-click":_vm.onItemClicked,"make-a-copy":function($event){return _vm.onShowMakeCopy($event.id)},"delete":function($event){return _vm.onShowDelete($event.id)},"empty-cta-click":function($event){return _vm.$emit('new-document')}},scopedSlots:_vm._u([{key:"mobile-row-data",fn:function(row){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10 font-16 w-500 text-default ellipsis"},[_vm._v(" "+_vm._s(row.name)+" ")])]),_c('div',{staticClass:"font-14 w-400 text-default q-mt-sm"},[_vm._v(" "+_vm._s(_vm.$t('contract.table.columns.due_date'))+" : "+_vm._s(row.due_date)+" ")]),_c('div',{staticClass:"row q-mt-sm"},[_c('div',{class:("bg-" + (row.status.color) + "  border-radius-" + (row.status.radius) + " text-" + (row.status.textColor) + " q-px-sm")},[_vm._v(" "+_vm._s(row.status.value)+" ")])]),_c('a-secondary-btn',{staticClass:"full-width q-mt-md",attrs:{"bordered":"","label":_vm.$t('contract.table.field.view_contract').toString()},on:{"click":function($event){return _vm.onItemClicked(row)}}})]}}])}),_c('confirmation-dialog',{attrs:{"message":"contract.actives.delete_confirmation"},on:{"confirm":_vm.onDelete},model:{value:(_vm.showDelete),callback:function ($$v) {_vm.showDelete=$$v},expression:"showDelete"}}),(_vm.contractToCopy)?_c('make-copy-modal',{attrs:{"contract":_vm.contractToCopy,"redirect-on-copy":false},on:{"exit-copy-dialog":function($event){_vm.showMakeCopy = false},"starting-copy":function($event){_vm.showMakeCopy = false},"copy-success":_vm.getContracts},model:{value:(_vm.showMakeCopy),callback:function ($$v) {_vm.showMakeCopy=$$v},expression:"showMakeCopy"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }