












































import { defineComponent } from '@vue/composition-api';
import { APrimaryBtn, ASecondaryBtn } from '../../../base/app/buttons';
const INFO_ICON = 'img:/dashboard/images/info_2.svg';

export default defineComponent({
  name: 'WarningModal',
  components: {
    APrimaryBtn,
    ASecondaryBtn
  },
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    value: {
      type: Boolean
    },
    labelCancel: {
      type: String,
      default: 'cancel'
    },
    hideCancel: {
      type: Boolean,
      default: false
    },
    labelConfirm: {
      type: String,
      default: 'yes'
    }
  },
  setup(_, { emit }) {
    const exit = () => {
      emit('confirm');
      emit('input', false);
    };
    return { exit, INFO_ICON };
  }
});
