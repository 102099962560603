import { ref, Ref } from '@vue/composition-api';
import { useAwaitDOMNode } from '@ligo/shared/utils';

export function resizeEventListener(
  selector: string,
  callback: (param) => void
) {
  const observer: Ref<ResizeObserver> = ref(null);
  const loading = ref(true);

  function start() {
    loading.value = true;
    useAwaitDOMNode([selector])
      .then(([node]) => {
        observer.value = new ResizeObserver(() => callback(node));
        callback(node);
        observer.value.observe(node);
      })
      .catch((e) => {
        `There was an error while waiting for the DOM node: ${e}`;
      })
      .finally(() => (loading.value = false));
  }

  function disconnect() {
    if (observer.value) {
      observer.value.disconnect();
    }
    loading.value = true;
  }

  start();

  return { start, disconnect, loading };
}
