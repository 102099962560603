

















import { defineComponent } from '@vue/composition-api';
import NotificationBanner from './NotificationBanner.vue';
export default defineComponent({
  name: 'WarningBanner',
  components: { NotificationBanner },
  props: {
    title: String,
    subtitle: String
  },
  setup() {
    return {};
  }
});
