






























import { defineComponent, PropType } from '@vue/composition-api';
import { BtnCard } from './models/BtnCard';

export default defineComponent({
  name: 'CardWithMultipleBtns',
  props: {
    btnList: {
      type: Array as PropType<Array<BtnCard>>
    }
  },
  setup(_, { root }) {
    function btnLabel(btnObject: BtnCard): string {
      if (root.$q.screen.gt.xs) return btnObject.label;
      else {
        return btnObject.shortLabel || btnObject.label;
      }
    }

    return { btnLabel };
  }
});
