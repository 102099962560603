import { RESOURCES } from '@ligo/dashboard/customer/store';
import { ApiService } from '@ligo/shared/utils';

export const MAGNEXUS_BRAND = 'magnexus';

export const loginFormLayout = [['email'], ['password']];

export const registerFormLayout = [
  ['firstname', 'lastname'],
  ['phone'],
  ['email'],
  ['password'],
  ['password_confirmation']
];

export const providerLinks = {
  google_oauth2: RESOURCES.GOOGLEOAUTH,
  linkedin: RESOURCES.LINKEDINOAUTH
};

const googleBtn = {
  icon: 'img:/dashboard/icons/google.svg',
  label: 'google',
  link: RESOURCES.GOOGLEOAUTH
};

const linkedinBtn = {
  icon: 'img:/dashboard/icons/linkedin.svg',
  label: 'linkedin',
  link: RESOURCES.LINKEDINOAUTH
};

export const socialBtns = [googleBtn, linkedinBtn];

export const brandSocialBtn = (brand: string) => {
  return brand == MAGNEXUS_BRAND ? [googleBtn] : [googleBtn, linkedinBtn];
};

export const socialAuth = (
  socialBtn: typeof googleBtn,
  redirectUrl?: string,
  legalCheckupUuid?: string
) => {
  let url = `${socialBtn.link}${
    window.location.host
  }/dashboard/token_auth&auth_redirect_url=${redirectUrl ?? '/dashboard'}`;
  if (legalCheckupUuid) url += `&legal_checkup_uuid=${legalCheckupUuid}`;
  window.open(url, '_self');
};

export const sendConfirmationEmail = (email: string) => {
  ApiService.post(RESOURCES.RETRY_CONFIRMATION, { email });
};

export const brandRouteName = (brand: string, route) => {
  return brand == MAGNEXUS_BRAND ? `Magnexus${route}` : route;
};

export const brandInformation = (brand: string) => {
  return brand == MAGNEXUS_BRAND
    ? {
        logo: '/dashboard/icons/magnexus_logo.svg',
        privacy:
          'https://s3.eu-west-3.amazonaws.com/getmagnexus.com/_2022+privacy+policy+Magnexus.pdf',
        terms:
          'https://s3.eu-west-3.amazonaws.com/getmagnexus.com/22_+Magnexus+Terms+%26+Conditions.pdf'
      }
    : {
        logo: '/dashboard/icons/ligo.svg',
        privacy: 'https://www.ligo.nl/privacy',
        terms: 'https://www.ligo.nl/terms'
      };
};
