








































import { defineComponent } from '@vue/composition-api';
import { useBillingData } from './billing.hooks';
import BaseButton from '../../components/global/BaseButton.vue';
import { Phone } from '@ligo/dashboard/components';

export default defineComponent({
  name: 'Billing',
  components: {
    BaseButton,
    Phone
  },
  props: {
    paymentId: {
      type: String
    }
  },
  setup(_, { root }) {
    const {
      billingData,
      formSections,
      loading,
      customerService,
      next,
      form,
      formRulesi18n
    } = useBillingData(root);

    return {
      billingData,
      formSections,
      loading,
      customerService,
      form,
      formRulesi18n,
      next
    };
  }
});
