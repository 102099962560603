export default {
  linkedin_register: 'Continue with LinkedIn',
  google_register: 'Continue with Google',
  email_register: 'Continue with email',
  linkedin: 'Log in with LinkedIn',
  google: 'Log in with Google',
  email: 'Log in with email',
  login: 'Log in',
  logout: 'Log out',
  signup: 'Sign up',
  register: 'Sign up',
  language_list: {
    en: 'English',
    nl: 'Dutch'
  },
  language_list_mini: {
    en: 'EN',
    nl: 'NL'
  },
  agree_sign_in: {
    prefix: 'By signing up, you agree to the',
    privacy: 'Privacy Policy',
    separator: 'and',
    terms: 'Terms and Conditions',
    suffix: 'of'
  },
  reset: {
    instructions: 'Send reset instructions',
    hint:
      'Ensure your email address is typed correctly and we will send you an email to reset your password.',
    explanation: {
      first:
        'If your email is associated with an existing account you will receive reset instructions at',
      second:
        'If you did not receive an email, check your spam folder and ensure your email address was entered correctly.'
    },
    finish: 'Back to login',
    start: 'Forgot your password?',
    here: 'here',
    end: 'to send you an email to reset it.',
    title: 'Reset your password',
    send: 'Get a reset link',
    nevermind: 'Nevermind!',
    back: 'Take me back to login screen',
    email: 'Enter the email address you use to sign in',
    error:
      'There was an error while resetting your password, please check that you have a Ligo account'
  },
  change_password: {
    new_password: 'New password',
    new_password_placeholder: 'Enter your new password',
    confirm_password: 'Confirm password',
    confirm_password_placeholder: 'Please confirm your new password',
    change: 'Change password',
    confirm_error: 'Passwords must match',
    error: 'There was an error while setting your new password'
  },
  check_mail: {
    check: 'Check your',
    email: 'email'
  },
  mail_error: "We can't find this email",
  confirmation_header: {
    title: ' Resend a confirmation email',
    subtitle:
      'Please confirm your email address before proceeding. You do this by clicking on the link in the email you received. This is necessary to maintain access to your account'
  },
  errorCodeMsg: {
    not_confirmed:
      'Authentication error - In case you have an account, you might not have confirmed it yet. Check your mailbox for a confirmation email and click on the activation link. Once confirmed, you can login.',
    different_provider:
      'Your e-mail address is already known to us. Continue with'
  },
  errorCodeLink: {
    not_confirmed: 'Resend confirmation email',
    different_provider: {
      google_oauth2: 'Google',
      linkedin: 'LinkedIn'
    }
  },
  registerFields: {
    email: { placeholder: 'Email', label: 'Email' },
    firstname: { placeholder: 'First Name', label: 'First Name' },
    lastname: { placeholder: 'Last Name', label: 'Last Name' },
    password: { placeholder: 'Password', label: 'Password' }
  },
  next: 'Next',
  send_confirmation: 'Send confirmation Email',
  confirmation_email_sent: 'Confirmation Email Sent',
  ligo: {
    terms_of_service: {
      prefix: 'I agree with the',
      terms: 'General Terms and Conditions',
      suffix: 'of Ligo BV'
    },
    register_header: {
      title: 'Create an account',
      subtitle:
        'The easiest way to incorporate your business in The Netherlands. 100% online and for the best price.'
    },
    next: 'Next',
    to_signin: 'Already have an account?',
    social_register: 'Or register with social media:',
    keep_posted: 'Keep me informed about legal tips and new features',
    register_checkmarks:
      'In order to proceed, you first need to accept the General Terms and Conditions.',
    login: 'Log in to your Ligo account',
    dont_account: 'Don’t have an account?'
  },
  magnexus: {
    terms_of_service: {
      prefix: 'I agree with the',
      terms: 'General Terms and Conditions',
      suffix: 'of Magnexus'
    },
    register_header: {
      title: 'Start taking control over your contracts',
      subtitle: 'Create, sign and manage all your legal documents in one place.'
    },
    next: 'Next',
    to_signin: 'Already have an account? ',
    social_register: 'Or continue with social media:',
    keep_posted: 'Keep me informed about tips and new features',
    register_checkmarks:
      'In order to proceed, you first need to accept the General Terms and Conditions.',
    login: 'Log in to your Magnexus account',
    dont_account: 'Don’t have an account?'
  }
};
