





















import { defineComponent } from '@vue/composition-api';
import { PropType } from 'vue';

export type ActionState = 'todo' | 'in_progress' | 'completed';

export default defineComponent({
  props: {
    value: {
      type: String as PropType<ActionState>
    }
  }
});
