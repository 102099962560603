











































































































import { defineComponent, ref, Ref } from '@vue/composition-api';
import {
  PaymentService,
  PaymentResponse
} from '@ligo/dashboard/customer/store';
import { useI18n } from '../../composables';
import {
  SQTableRow,
  SQTableColumn,
  SQTablePagination
} from '../../components/base/tables/s-q-table.hook';
import { SQTable } from '../base';
import { Payment } from '@ligo/dashboard/customer/store';
import { useAsyncResult } from '@ligo/shared/utils';
import { APrimaryBtn, ASecondaryBtn } from '../../components/base/app/buttons';

export default defineComponent({
  name: 'Payments',
  components: { SQTable, APrimaryBtn, ASecondaryBtn },
  setup() {
    const i18n = useI18n();

    const columns: SQTableColumn[] = [
      {
        name: 'paid_at',
        label: i18n.t('account.payment.date').toString(),
        align: 'left',
        field: (value) => {
          if (value.paid_at) return i18n.d(new Date(value.paid_at));
          return '-';
        }
      },
      {
        name: 'uuid',
        label: i18n.t('account.payment.name').toString(),
        align: 'left',
        field: 'description'
      },
      {
        name: 'total',
        label: i18n.t('account.payment.total').toString(),
        align: 'left',
        field: (value) => {
          return `€ ${value.total.toFixed(2)}`;
        }
      }
    ];

    const pagination: Ref<SQTablePagination> = ref({
      sortBy: 'desc',
      descending: false,
      page: 1,
      rowsPerPage: 50
    });

    const { state: paymentList, loading, total, load } = useAsyncResult(
      () =>
        PaymentService.list({
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage
        }),
      [pagination],
      (item: Payment): SQTableRow => ({
        paid_at: item.paid_at,
        uuid: item.uuid,
        description: item.merchantReference,
        total: item.payment_amount,
        status: item.status,
        statusBackgroundColor:
          item.status === PaymentResponse.AUTHORIZED
            ? 'green-light'
            : item.status === PaymentResponse.OPEN
            ? 'yellow-light'
            : 'red-light',
        statusTextColor:
          item.status === PaymentResponse.AUTHORIZED
            ? 'green'
            : item.status === PaymentResponse.OPEN
            ? 'yellow'
            : 'red',
        pdfInvoice: item.finalized_payment?.pdf_invoice
      })
    );

    function search(_filter, page, rowsPerPage, sortBy, descending) {
      pagination.value.sortBy = sortBy;
      pagination.value.descending = descending;
      pagination.value.page = page;
      pagination.value.rowsPerPage = rowsPerPage;

      load();
    }

    function onDownload(payment: any) {
      window.open(payment.pdfInvoice, '_blank');
    }

    return {
      PaymentResponse,
      paymentList,
      columns,
      loading,
      pagination,
      total,
      onDownload,
      search
    };
  }
});
