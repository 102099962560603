export default {
  documenteditorcontainer: {
    Download: 'Download',
    Undo: 'Undo',
    Redo: 'Redo',
    Image: 'Image',
    Link: 'Link',
    'Table of Contents': 'Table of Contents',
    Header: 'Header',
    Footer: 'Footer',
    'Page Setup': 'Page Setup',
    'Page Number': 'Page Number',
    Break: 'Break',
    'Insert Footnote': 'Insert Footnote',
    Find: 'Find',
    'Local Clipboard': 'Local Clipboard',
    'Form Fields': 'Form Fields',
    'Update Fields': 'Update Fields'
  }
};
