import { ApiService, Dictionary } from '@ligo/shared/utils';
import { RESOURCES } from '../resources';
import { AxiosPromise } from 'axios';
import { LawyerApi } from '../models/lawyer';

export const LawyerService = {
  lawyers(query: Dictionary = {}): AxiosPromise<LawyerApi[]> {
    return ApiService.get<LawyerApi[]>(RESOURCES.LAWYERS, {
      ...query
    });
  },
  lawyer(slug: string): AxiosPromise<LawyerApi> {
    return ApiService.get<LawyerApi>(RESOURCES.LAWYER(slug));
  }
};
