






































import { defineComponent, computed, ref } from '@vue/composition-api';
import { Toolbar } from '@syncfusion/ej2-vue-documenteditor';
import { setupEditor } from '../../components/documents/word-editor/word-editor.hook';
import {
  WordEditorBanner,
  DiscardModal
} from '../../components/documents/word-editor/';
import { EXTERNAL_RESOURCES } from '@ligo/dashboard/customer/store';

export default defineComponent({
  name: 'WordEditor',
  components: {
    WordEditorBanner,
    DiscardModal
  },
  provide: {
    DocumentEditorContainer: [Toolbar]
  },
  props: {
    contractUuid: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const bannerContainer = ref();
    const editorHeight = computed(() => {
      if (bannerContainer.value) {
        return `${
          window.innerHeight - bannerContainer.value.$el.offsetHeight
        }px`;
      }
      return '100vh';
    });

    const {
      items,
      editorComponent,
      documentChanged,
      showDiscardModal,
      locale,
      banner,
      contract,
      contractName,
      onToolbarClick,
      onSave,
      onDiscard,
      onContentChange,
      onDiscardConfirm
    } = setupEditor(props.contractUuid);

    return {
      items,
      editorComponent,
      documentChanged,
      showDiscardModal,
      locale,
      banner,
      editorHeight,
      bannerContainer,
      contract,
      serviceUrl: `${EXTERNAL_RESOURCES.WORD_EDITOR_SERVICE}/`,
      contractName,
      onToolbarClick,
      onSave,
      onDiscard,
      onContentChange,
      onDiscardConfirm
    };
  }
});
