





















import { defineComponent } from '@vue/composition-api';
import AlertModal from '../../base/AlertModal.vue';
import { ABtn, ASecondaryBtn } from '../../../base/app/buttons';

export default defineComponent({
  name: 'RevokeSignModal',
  components: { AlertModal, ABtn, ASecondaryBtn },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
});
