import { ref, watch, Ref, ComputedRef } from '@vue/composition-api';

export function useAsyncState<T, P extends Promise<any>>(
  promiseFactory: () => P,
  defaultState: T,
  dependencies: Array<Ref | ComputedRef> = [],
  catchFn = (error: Error) => error,
  immediate = true
) {
  const state = ref(defaultState) as Ref<T>;
  const loading = ref(false);

  function load(): void {
    loading.value = true;
    promiseFactory()
      .then((data) => {
        state.value = data;
        loading.value = false;
      })
      .catch(catchFn);
  }

  watch(
    dependencies,
    () => {
      load();
    },
    { immediate }
  );

  return { state, loading, load };
}
