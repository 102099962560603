export default {
  setting_page: 'Account',
  account_page: 'Billing & Payment',

  plan: {
    title: 'Your legal documents plan',
    title_magnexus: 'Your contract management plan',
    subtitle: 'You are currently subscribed to the <b> {plan} </b> plan',
    free: 'You currently have a Free Account',
    upgrade: 'Upgrade',
    cancel: 'Cancel plan',
    change: 'Change plan',
    renew: 'Renews {date}',
    downgrade: 'You cancelled this subscription on {date}'
  },
  billing: {
    title: 'Edit billing details',
    save: 'Save',
    cancel: 'Cancel'
  },
  payment: {
    title: 'Payments and invoices',
    name: 'Description',
    date: 'Date',
    total: 'Total',
    actions: {
      pay: 'Pay',
      done: 'Paid',
      view: 'View',
      error: 'Payment Error',
      open: 'Open',
      authorized: 'Authorized',
      refused: 'Refused',
      cancelled: 'Cancelled',
      pending: 'Pending',
      chargedback: 'Charged Back'
    }
  },
  profile: {
    title: 'Edit my profile',
    language: 'Choose language',

    phone_required: 'Phone number is required',
    firstname_required: 'First name is required',
    lastname_required: 'Last name is required',
    email: 'Email address',
    change_info: 'Change  information',
    change_password: 'Update password',
    current_password_placeholder: 'Enter your current password',
    new_password_placeholder: 'Enter your current password',
    confirm_placeholder: 'Repeat your new password',
    passwords_match: 'Passwords must match',
    info_success: 'Your account settings have been updated successfully',
    info_error: 'There was an error while updating your account settings',
    password_success: 'Your password has been updated successfully',
    password_error: 'There was an error while updating your password',

    personal_section: 'Personal Information',
    password_section: 'Account password',
    locale_section: 'Language',
    firstname: 'First name',
    lastname: 'Last name',
    phone: 'Phone number',
    save_info: 'Save changes',
    old_password: 'Current password',
    old_password_required: 'Current password is required',
    new_password: 'New password',
    new_password_required: 'New password is required',
    new_password_confirmation: 'Check new password',
    new_password_confirmation_required: 'New password confirmation is required',

    cancel_membership: {
      title_first_part: `You have cancelled your`,
      title_middle: 'plan on',
      title_second_part: `. Select other plan that may fit you best to keep enjoying all the features of Ligo!`
    }
  }
};
