















































import { defineComponent, ref, PropType } from '@vue/composition-api';
import { SimpleField } from '../models/simpleForm.models';
import { Dictionary } from '@ligo/shared/utils';

export default defineComponent({
  name: 'BaseSimplePhoneField',
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {},
    rules: {
      type: Array
    },
    loading: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    readonly: {
      type: Boolean
    },
    disable: {
      type: Boolean
    },
    hint: {
      type: String
    },
    formValue: {
      type: Object
    },
    config: {
      type: Object as PropType<SimpleField>
    }
  },
  setup(props, { emit }) {
    const phone = ref<Dictionary>({});
    const field_input = ref<any>(null);
    function phoneRule(value) {
      return !!value && !!phone.value && !!phone.value.valid;
    }
    function onValidate(value: Dictionary) {
      phone.value = value;
      if (!!props.value) field_input.value?.validate();
    }
    return {
      field_input,
      phone,
      onValidate,
      phoneRule,
      onInput(value: any) {
        if (props.config?.transform)
          emit('input', props.config?.transform(value));
        else emit('input', value);
      }
    };
  }
});
