



























import { defineComponent, ref, watch } from '@vue/composition-api';

export default defineComponent({
  name: 'SignerInputField',
  props: {
    value: {
      type: String,
      required: true
    },
    icon: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: false
    },
    inputClass: {
      type: String
    },
    clearAfterSave: {
      type: Boolean,
      default: false
    },
    placeholder: String
  },
  setup(props, { emit }) {
    const signerInput = ref(null);
    const currentValue = ref(props.value);
    const showIcon = ref(props.icon);
    const checkError = ref(true);

    function emailValidator(v: string) {
      return (
        v.length > 0 && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(v)
      );
    }

    function onSave() {
      if (
        signerInput.value?.validate() &&
        currentValue.value &&
        currentValue.value !== props.value
      ) {
        checkError.value = false;
        emit('on-save', currentValue.value);
        if (props.clearAfterSave) {
          currentValue.value = '';
        }
      }
      showIcon.value = true;
    }

    function onFocus() {
      showIcon.value = false;
      emit('on-focus');
    }

    function onBlur() {
      onSave();
      emit('on-blur');
    }

    watch(
      () => props.value,
      (value) => {
        currentValue.value = value;
      }
    );

    watch(currentValue, (value) => {
      if (checkError.value) emit('has-error', !emailValidator(value));
    });

    return {
      signerInput,
      currentValue,
      emailValidator,
      onSave,
      showIcon,
      onFocus,
      onBlur
    };
  }
});
