









import { defineComponent } from '@vue/composition-api';
import ErrorLayout from './ErrorLayout.vue';

const i18nContent = {
  title: 'e_signature.errors.completed.title',
  text: 'e_signature.errors.completed.text'
};

export default defineComponent({
  name: 'ErrorCompleted',
  components: {
    ErrorLayout
  },
  setup() {
    return {
      i18nContent
    };
  }
});
