








import { defineComponent, PropType } from '@vue/composition-api';
import NotaryProductList from '../../components/NotaryProductList.handler.vue';
import BasePage from '../../components/base/BasePage.vue';
import { RESOURCES } from '@ligo/dashboard/customer/store';
import { NPKinds } from '../../components/notary-product-table.hook';

export default defineComponent({
  name: 'NotaryProducts',
  components: { NotaryProductList, BasePage },
  props: {
    kind: {
      type: String as PropType<NPKinds>,
      default: NPKinds.INCORPORATION
    }
  },
  setup() {
    function gotoQuestionnaire() {
      window.location.assign(RESOURCES.QUESTIONNAIRE);
    }
    return { gotoQuestionnaire };
  }
});
