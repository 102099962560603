import { GetterTree } from 'vuex';
import { AuthState } from './state';

export const gettersFactory = <StateInterface>(): GetterTree<
  AuthState,
  StateInterface
> => ({
  loggedIn: (state) => {
    return state.accessToken ? true : false;
  },
  authenticationError: (state) => {
    return state.authenticationError;
  },
  authenticating: (state) => {
    return state.authenticating;
  },
  user: (state) => {
    return state.user;
  },
  avatar: (state) => {
    return state?.user?.profile_image;
  },
  username: (state) => {
    if (state.user) {
      return `${state.user.firstname} ${state.user.lastname}`;
    }
  },
  firstname: (state) => {
    if (state.user) {
      return state.user.firstname;
    }
  },
  getMembershipTypeId: (state) => {
    return state.user?.membership_type_id;
  },
  language: (state) => {
    return state.user?.locale;
  },
  token_as_query: (state) => {
    const token = state.accessToken;
    return `?access_token=${token.access_token}&expiry=${token.expiry}&client=${token.client}&uid=${token.uid}`;
  },
  getMembership: (state) => {
    return state.user?.membership;
  },
  getMembershipTypeLevel: (state) => {
    return state.user?.membership?.membership_type.level;
  },
  getMembershipTypeName: (state) => {
    return state.user?.membership?.membership_type.name;
  },
  getMembershipTypeSlug: (state) => {
    return state.user?.membership?.membership_type.slug;
  },
  isHybrid: (state) => {
    return state.user?.is_hybrid;
  },
  statusOfReceivedFreeContract: (state) => {
    return state.user?.did_receive_contract_for_free;
  },
  isNotaryFastIncorporation: (state) => {
    return state.user?.fast_incorporation;
  },
  userConfirmedAt: (state) => {
    return state.user?.confirmed_at;
  },
  userEmail: (state) => {
    return state.user?.email;
  }
});
