




















import { defineComponent, PropType } from '@vue/composition-api';
import { ESignPage, Signer } from '../../../../models';
import ESignPageComponent from './Page.vue';
import { getSignerColor } from '../utils/signer';

export default defineComponent({
  name: 'ESignContent',
  components: { ESignPageComponent },
  props: {
    pages: {
      type: Array as PropType<ESignPage[]>,
      required: true
    },
    signers: {
      type: Array as PropType<Signer[]>,
      required: true
    }
  },
  setup(props) {
    function getPageFields(pageIndex: number) {
      return props.signers
        .map((signer) =>
          signer.fields.filter((field) => {
            if (field && field.pageIndex === pageIndex) {
              field.color = getSignerColor(signer.email);
              return true;
            }
            return false;
          })
        )
        .flat();
    }
    return { getPageFields };
  }
});
