import { usePageInformation } from '@ligo/shared/utils';

export const ExtraQuestionPageInformation = usePageInformation(
  'extra_question'
);

export const ExtraChangesPageInformation = usePageInformation('extra-changes');

export const HomePageInformation = usePageInformation('customer-home');

export const OnboardingPageInformation = usePageInformation(
  'onboarding-register'
);

export const legalPlansPageInformation = usePageInformation('ligo-plans-page');

export const AssistantPageInformation = usePageInformation('ligo-assistant');

export const DgaServicesPageInformation = usePageInformation('dga-services');

export const TaxServicesPageInformation = usePageInformation('tax-services');

export const SignatureHomePageInformation = usePageInformation(
  'signature-page'
);

export const IncorporationsPageInformation = usePageInformation(
  'incorporation-page'
);

export const ModificationsPageInformation = usePageInformation(
  'modifications-page'
);

export const BankingPageInformation = usePageInformation('banking');
