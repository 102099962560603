import { Condition } from '@ligo/dashboard/customer/store';
import { NotaryProduct, NotaryProductTypeSlug } from '@ligo/dashboard/store';

function isOmzettingWithAddedValue(np: NotaryProduct) {
  return (
    np.notary_product_type.slug == NotaryProductTypeSlug.OMZETTING &&
    np.product_data?.added_value
  );
}

function hasHighTotalCapital(np: NotaryProduct) {
  const scopeProducts = [
    NotaryProductTypeSlug.DUTCH_BV,
    NotaryProductTypeSlug.ENGLISH_BV,
    NotaryProductTypeSlug.HOLDING,
    NotaryProductTypeSlug.OMZETTING
  ];
  const highCapital =
    np.product_data?.number_of_shares * np.product_data?.value_of_shares >=
    2000;

  return scopeProducts.includes(np.notary_product_type.slug) && highCapital;
}

export const conditionsReadDocs: Condition[] = [
  {
    condition: (np: NotaryProduct) =>
      isOmzettingWithAddedValue(np) && hasHighTotalCapital(np),
    translations: [
      [
        'read_docs.info',
        'read_docs.info_omzetting_with_added_value_and_high_capital'
      ]
    ]
  },
  {
    condition: isOmzettingWithAddedValue,
    translations: [
      ['read_docs.info', 'read_docs.info_omzetting_with_added_value']
    ]
  },
  {
    condition: hasHighTotalCapital,
    translations: [['read_docs.info', 'read_docs.info_when_high_capital']]
  }
];
