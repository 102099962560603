











































































































import { defineComponent, watch } from '@vue/composition-api';
import LibraryCategories from './LibraryCategories.vue';
import LibrarySearch from './LibrarySearch.vue';
import LibraryList from './LibraryList.vue';
import APrimaryBtn from '../../base/app/buttons/APrimaryBtn.vue';
import { WYSIWYGEditor } from '@ligo/shared/components';
import { useLibraryConfig } from './contract-library.hook';
import { SQMainHeader } from '../../base';
import Teleport from 'vue2-teleport';

const TEMPLATE_EMPTY = '/dashboard/images/template_empty.svg';

export default defineComponent({
  name: 'DocumentsLibrarySearchEngine',
  components: {
    LibraryCategories,
    LibrarySearch,
    LibraryList,
    APrimaryBtn,
    WYSIWYGEditor,
    SQMainHeader,
    Teleport
  },
  props: {
    documentSlug: String
  },
  setup(props) {
    const {
      header,
      loading,
      categoriesLabels,
      items,
      search,
      loadingDocumentPreview,
      documentPreview,
      documentPreviewSlug,
      breadcrumbPath,
      breadcrumbNavigation,
      onSlugChange,
      onNextPage,
      onChangeCategory,
      onStartContract,
      onContractClick
    } = useLibraryConfig(props.documentSlug);

    watch(
      () => props.documentSlug,
      (newValue) => {
        onSlugChange(newValue);
      },
      { immediate: true }
    );

    return {
      header,
      loading,
      categoriesLabels,
      items,
      search,
      loadingDocumentPreview,
      documentPreview,
      TEMPLATE_EMPTY,
      documentPreviewSlug,
      breadcrumbPath,
      breadcrumbNavigation,
      onNextPage,
      onChangeCategory,
      onStartContract,
      onContractClick
    };
  }
});
