var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('reminder-list-modal',{attrs:{"reminders":_vm.completeReminderList},on:{"toggle-complete":function($event){return _vm.onToggleCompleteReminder(_vm.contract.uuid, $event)}},model:{value:(_vm.showListDialog),callback:function ($$v) {_vm.showListDialog=$$v},expression:"showListDialog"}}),_c('create-modal',{attrs:{"contract-id":_vm.contract.uuid},on:{"close":_vm.onCloseCreateDialog,"create":function($event){return _vm.onCreateReminder(_vm.contract.uuid, $event)}},model:{value:(_vm.showCreateDialog),callback:function ($$v) {_vm.showCreateDialog=$$v},expression:"showCreateDialog"}}),(_vm.selectedReminder)?_c('update-modal',{attrs:{"contract-id":_vm.contract.uuid,"reminder":_vm.selectedReminder},on:{"close":_vm.onCloseUpdateDialog,"toggle-complete":function($event){return _vm.onToggleCompleteReminder(_vm.contract.uuid, $event)},"update":function($event){return _vm.onUpdateReminder(_vm.contract.uuid, $event)},"delete":_vm.onWrappDeleteReminder},model:{value:(_vm.showUpdateDoalog),callback:function ($$v) {_vm.showUpdateDoalog=$$v},expression:"showUpdateDoalog"}}):_vm._e(),_c('q-card-section',{staticClass:"q-pt-none q-pb-sm q-px-lg"},[_c('div',{staticClass:"row justify-between items-center"},[_c('div',[_c('q-icon',{class:_vm.noMembership && 'disabled-reminder',attrs:{"color":"subdued","name":"format_list_bulleted","size":"xs"}}),_c('span',{class:[
            'q-pl-sm text-subdued w-600 font-12',
            _vm.noMembership && 'disabled-reminder'
          ]},[_vm._v(" "+_vm._s(_vm.$t('contract.reminders.title'))+" ")]),(_vm.noMembership)?_c('q-icon',{staticClass:"q-pl-md q-pb-xs cursor-pointer",attrs:{"size":"xs","name":"lock","color":"subdued"},on:{"click":function($event){return _vm.$router.push({
              name: 'Account'
            })}}},[_c('q-tooltip',{attrs:{"content-class":"bg-dark text-white font-11 w-500"}},[_vm._v(" "+_vm._s(_vm.$t('contract.reminders.unlock'))+" ")])],1):_vm._e()],1),(_vm.completeReminderList.length && _vm.currMembershipTypeLevel)?_c('q-btn',{staticClass:"w-400 font-11 test-subdued cursor-pointer",attrs:{"flat":"","color":!_vm.noMembership ? 'subdued' : 'grey-1',"no-caps":"","disable":_vm.noMembership},on:{"click":function($event){_vm.showListDialog = !_vm.showListDialog}}},[_vm._v(" "+_vm._s(_vm.completeReminderList.length)+" "+_vm._s(_vm.$t('contract.reminders.complete_badge'))+" ")]):_vm._e()],1)]),_c('q-card-section',{staticClass:"q-px-none q-pt-none"},[(!_vm.loadingReminders)?_c('div',{staticClass:"q-px-lg"},[(_vm.reminderList.length)?_c('q-list',{staticClass:"q-pb-sm"},_vm._l((_vm.reminderList),function(item,index){return _c('div',{key:("rem-" + index)},[_c('q-item',{class:[
              'q-px-sm reminder-item',
              _vm.noMembership && 'disabled-reminder cursor-not-allowed'
            ],attrs:{"v-ripple":!_vm.noMembership,"clickable":!_vm.noMembership},on:{"click":function($event){return _vm.onShowUpdateDialog(item)}}},[_c('reminder',{attrs:{"reminder":item,"disable-state":_vm.noMembership}})],1)],1)}),0):_c('p',{class:[
          'text-subdued w-400 font-13 q-mb-sm',
          _vm.noMembership && 'disabled-reminder'
        ]},[_vm._v(" "+_vm._s(_vm.$t('contract.reminders.no_items'))+" ")]),_c('span',{class:[
          _vm.reminderList.length ? 'text-subdued' : 'text-primary',
          'font-13 w-400',
          _vm.noMembership
            ? 'cursor-not-allowed disabled-reminder'
            : ' cursor-pointer'
        ],on:{"click":function($event){!_vm.noMembership ? (_vm.showCreateDialog = true) : ''}}},[_c('q-icon',{staticClass:"q-pb-xs q-pr-xs",attrs:{"name":"add","size":"xs"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('contract.reminders.add'))+" ")])],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }