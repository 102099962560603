import { Dictionary } from '@ligo/shared/utils';
import { computed } from '@vue/composition-api';
import VueI18n from 'vue-i18n';
import {
  mappedRules,
  SimpleField,
  SimpleFieldValidator
} from '../models/simpleForm.models';

export const useSimpleField = (
  field: SimpleField,
  props: Dictionary,
  i18n: VueI18n
) => {
  const isSimpleFieldValidator = (key: any): key is SimpleFieldValidator => {
    return typeof key === 'function';
  };

  const computedRules = computed(() =>
    (field.rules || []).map((rule) => {
      if (!isSimpleFieldValidator(rule)) {
        return mappedRules[rule](i18n);
      } else {
        return rule(props.formValue, field);
      }
    })
  );

  const isDisabled = computed(() => {
    return (
      field.disableOn && field.disableOn(props.value, props.formValue, field)
    );
  });
  const isReadonly = computed(() => {
    return (
      field.readonlyOn && field.readonlyOn(props.value, props.formValue, field)
    );
  });
  return { computedRules, isDisabled, isReadonly };
};
