export enum DECISION {
  YES = 'yes',
  NO = 'no'
}
export function useCloseBanner(bannerKey: string) {
  function closeBanner() {
    localStorage.setItem(bannerKey, DECISION.YES);
  }

  function getShowBanner() {
    const decision = localStorage.getItem(bannerKey);
    return !decision || decision === DECISION.NO;
  }
  return { closeBanner, getShowBanner };
}
