export enum FilterFieldType {
  Select,
  MultipleSelect,
  Input,
  DateModified
}

export enum SearchTimeOptions {
  AnyTime = 'any_time',
  Today = 'today',
  Yesterday = 'yesterday',
  Last7Days = 'last_7_days',
  Last30Days = 'last_30_days',
  Last90Days = 'last_90_days',
  Custom = 'custom'
}

export interface Filter {
  options?: string[];
  value: any;
  valueRepresentation?: () => string;
  optionLabel?: (opt: string) => string;
  fieldType: FilterFieldType;
  [key: string]: any;
  placeholder?: string;
}
