











































import { defineComponent, ref } from '@vue/composition-api';
import DocumentBanner from '../base/DocumentBanner.vue';
import EditModal from '../contract-preview/modals/EditModal.vue';
import { APrimaryBtn, ASecondaryBtn } from '../../base/app/buttons';

export default defineComponent({
  name: 'WordEditorBanner',
  components: {
    DocumentBanner,
    EditModal,
    APrimaryBtn,
    ASecondaryBtn
  },
  props: {
    onChanges: {
      type: Boolean,
      default: false
    },
    title: String,
    subtitle: String,
    documentEditableName: {
      type: String
    }
  },
  setup(_, { emit }) {
    const showRenameModal = ref(false);
    function onEdit(name: string) {
      showRenameModal.value = false;
      emit('edit-name', name);
    }

    return { showRenameModal, onEdit };
  }
});
