import { ApiService } from '@ligo/shared/utils';
import { RESOURCES } from '../resources';
import { getFileName } from '@ligo/shared/utils';
import { NotaryProductDocument } from '@ligo/dashboard/store';

export const NotaryProductDocumentsService = {
  async get(notaryProductUuid: string, uuid: string) {
    return ApiService.get<NotaryProductDocument>(
      RESOURCES.NOTARY_PRODUCT_DOCUMENT_GET(notaryProductUuid, uuid)
    );
  },

  async download(doc: NotaryProductDocument) {
    const reloadedResponse = await this.get(doc.notary_product_id, doc.uuid);
    const blobResponse = await fetch(reloadedResponse.data.file_url, {
      method: 'GET'
    });
    const blob = await blobResponse.blob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = getFileName(
      blobResponse.headers.get('content-disposition')
    );
    link.click();
    URL.revokeObjectURL(link.href);
  }
};
