






import { defineComponent } from '@vue/composition-api';
import BankingRequestAnAccount from '../../components/banking/BankingRequestAnAccount.vue';
import BasePage from '../../components/base/BasePage.vue';

export default defineComponent({
  name: 'BankingAccountCreatePage',
  components: { BankingRequestAnAccount, BasePage },
  setup() {
    return {};
  }
});
