import { Payment, PaymentView } from '../../../models';

export function paymentToPaymentView(data: Payment) {
  return <PaymentView>{
    uuid: data.uuid,
    upsellPackageName: data.payable_name,
    lineItems: data.line_items.map((item) => ({
      ...item,
      price: item.price / 100.0
    })),
    total: data.payment_amount,
    subtotal: data.payable_price,
    description: data.payment_description,
    btw: data.payment_amount - data.payable_price,
    payableName: data.payable_name
  };
}
