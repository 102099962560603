


































































import { defineComponent, Ref, ref, watch } from '@vue/composition-api';
import {
  ABtn,
  ASecondaryBtn
} from '../../../../customer/components/src/lib/components/base/app/buttons';

export default defineComponent({
  name: 'RequestInfoDialogSplit',
  components: {
    ABtn,
    ASecondaryBtn
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    inputText: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String
    },
    extraSubtitle: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    extraPlaceholder: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: ''
    },
    cancelText: {
      type: String,
      default: 'cancel'
    },
    width: {
      type: String,
      default: '500px'
    },
    information: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit, root }) {
    const info = ref(props.inputText);
    const extraInfo = ref('');
    const form: Ref<any> = ref();

    const emptyRule = [
      (value: any) => !!value || root.$t('check_documents.reject_message')
    ];

    function closeDialog() {
      info.value = '';
      extraInfo.value = '';
      emit('input', false);
    }

    function confirm() {
      form.value.validate().then((valid) => {
        if (valid) {
          const result = `${info.value}<br>${extraInfo.value}`;
          emit('confirm', result);
          closeDialog();
        }
      });
    }

    watch(
      () => props.inputText,
      (newVal) => {
        info.value = newVal;
      }
    );

    return { closeDialog, confirm, info, extraInfo, form, emptyRule };
  }
});
