import { Contract } from '@ligo/dashboard/customer/store';
import { computed } from '@vue/composition-api';
import { useI18n, useStore } from '../../../../composables';
import { Footer } from '../../../../models';
import { useMembershipInfo } from '../../../utils/membership-info';

const INFINITE = -1;

export function useGetContract(contract: Contract) {
  const i18n = useI18n();
  const store = useStore();

  const {
    availableTemplates,
    membershipName,
    planTemplates
  } = useMembershipInfo();
  const username = computed(() => store.getters['authentication/firstname']);

  const upgradeCTA = {
    type: 'basic',
    label: i18n.t('contract.preview.upgrade'),
    emit: 'upgrade'
  };

  const useCreditCTA = {
    type: 'basic',
    label: i18n.t('contract.preview.use_credit'),
    emit: 'use-credit'
  };

  const getContractIcon = 'img:/dashboard/icons/sparkles_circled.svg';

  const footer: Footer = {
    title: i18n.t('contract.preview.card_footer.title'),
    subtitle: i18n.t('contract.preview.card_footer.subtitle'),
    ctaText: i18n.t('contract.preview.card_footer.cta', {
      price: Math.round(Number(contract.contract_type?.price))
    }),
    ctaEvent: 'buy'
  };

  const availableCredits = computed(() => ({
    topIcon: getContractIcon,
    title: `${availableTemplates.value} ${i18n.t(
      'contract.preview.contract_left.title'
    )}`,
    text: `${i18n.t('contract.preview.contract_left.subtitle_1')} ${
      availableTemplates.value
    } ${i18n.t('contract.preview.contract_left.subtitle_2')}`,
    ctas: [useCreditCTA]
  }));

  const creditExceeded = computed(() => ({
    topIcon: getContractIcon,
    title: i18n.t('contract.preview.credit_excedeed.title'),
    text: i18n.tc(
      'contract.preview.credit_excedeed.subtitle',
      planTemplates.value,
      {
        name: username.value,
        plan: membershipName.value,
        amount: planTemplates.value
      }
    ),
    ctas: [upgradeCTA],
    footer
  }));

  const unlimitedCredits = computed(() => ({
    topIcon: getContractIcon,
    title: `${i18n.t('contract.preview.contract_unlimited.title')}`,
    ctas: [useCreditCTA]
  }));

  const card = computed(() => {
    if (availableTemplates.value === INFINITE) return unlimitedCredits.value;
    else if (
      availableTemplates.value > 0 &&
      (useMembershipInfo().hasMembership.value ||
        !!contract.contract_type.allow_for_free)
    )
      return availableCredits.value;
    return creditExceeded.value;
  });
  return { card };
}
