


















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'UserPageSelector',
  props: {
    tab: {
      type: String
    }
  }
});
