


























































































































import { defineComponent, PropType, ref, computed } from '@vue/composition-api';
import { APrimaryBtn } from '../../../../base/app/buttons';
import { Signer } from '../../../../../models';

const DAYS_BEFORE_EXPIRATION = 21;
const i18nContent = {
  title: 'e_signature.prepare_document.modals.send_mails.title',
  signers: 'e_signature.prepare_document.modals.send_mails.signers',
  expiration: {
    title: 'e_signature.prepare_document.modals.send_mails.expiration.title',
    interval:
      'e_signature.prepare_document.modals.send_mails.expiration.interval',
    message: 'e_signature.prepare_document.modals.send_mails.expiration.message'
  },
  message: 'e_signature.prepare_document.modals.send_mails.message',
  send: 'e_signature.prepare_document.modals.send_mails.cta',
  copySenders: {
    title: 'e_signature.prepare_document.modals.send_mails.copy_senders.title',
    placeholder:
      'e_signature.prepare_document.modals.send_mails.copy_senders.placeholder',
    extraInfo:
      'e_signature.prepare_document.modals.send_mails.copy_senders.extra_info',
    mailRule:
      'e_signature.prepare_document.modals.send_mails.copy_senders.mail_rule',
    add: 'e_signature.prepare_document.modals.send_mails.copy_senders.add',
    description:
      'e_signature.prepare_document.modals.send_mails.copy_senders.description'
  }
};

interface Sender {
  id: number;
  mail: string;
}

export default defineComponent({
  name: 'SendMailsModal',
  components: { APrimaryBtn },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    signers: {
      type: Array as PropType<Signer[]>,
      required: true
    }
  },
  setup(props, { emit }) {
    const text = ref('');

    const sendersRef = ref();
    const newMail = ref('');
    const showError = ref(false);

    const expirationDate = computed(() => {
      const date = new Date();
      date.setDate(date.getDate() + DAYS_BEFORE_EXPIRATION);
      return {
        month: date.getMonth() + 1,
        day: date.getDate(),
        year: date.getFullYear()
      };
    });

    const senders = ref<Sender[]>([]);
    function onEnterMail() {
      if (newMail.value.length == 0) return;
      if (validateEmail(newMail.value)) {
        if (senders.value.length == 0) {
          senders.value.push({
            mail: newMail.value,
            id: 1
          });
        } else {
          senders.value.push({
            mail: newMail.value,
            id: senders.value[senders.value.length - 1].id + 1
          });
        }
        newMail.value = '';
        showError.value = false;
        sendersRef.value.updateInputValue('');
      } else {
        sendersRef.value.updateInputValue(newMail.value);
        showError.value = true;
      }
    }
    function removeItem(id: number) {
      senders.value = senders.value.filter((item) => item.id != id);
    }
    function onSendMails() {
      emit(
        'send-emails',
        text.value,
        senders.value.map((item) => item.mail)
      );
    }
    function validateEmail(mail: string) {
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(mail);
    }
    function catchTyped(val: string) {
      if (val) newMail.value = val;
      showError.value = false;
    }

    return {
      text,
      expirationDate,
      DAYS_BEFORE_EXPIRATION,
      i18nContent,
      catchTyped,
      onSendMails,
      removeItem,
      onEnterMail,
      sendersRef,
      newMail,
      showError,
      senders
    };
  }
});
