




































import { defineComponent, computed, ref } from '@vue/composition-api';
import { useMembershipInfo } from '../utils/membership-info';
import { useI18n, useStore } from '../../composables';
export default defineComponent({
  name: 'UpgradeNotification',
  setup(_, { root }) {
    const i18n = useI18n();
    const store = useStore();
    const dismiss = ref(false);
    const username = computed(() => store.getters['authentication/firstname']);
    const {
      availableSignatures,
      availableTemplates,
      membershipName,
      hasMembership
    } = useMembershipInfo();

    const welcome_msg = computed(() =>
      i18n.t('nav.upgrade_notification.msg', {
        name: username.value
      })
    );

    // TODO: this is the way, change this after vue3 migrations
    const currentRoute = computed(() => root.$route.name);

    const msg = computed(() => {
      if (currentRoute.value == 'Home') {
        return [
          i18n.t('nav.upgrade_notification.home', {
            name: username.value
          })
        ];
      }
      if (currentRoute.value == 'LegalCheckups') {
        return [
          i18n.t('nav.upgrade_notification.legal_checkup_page'),
          i18n.t('nav.upgrade_notification.legal_checkup_page_action')
        ];
      }
      if (availableSignatures.value == 0 && availableTemplates.value == 0) {
        const used_msg = i18n.t('nav.upgrade_notification.used_all', {
          plan: membershipName.value
        });
        const link = i18n.t('nav.upgrade_notification.all_link');
        return [`${welcome_msg.value} ${used_msg}`, link];
      } else if (availableSignatures.value == 0) {
        const used_msg = i18n.t('nav.upgrade_notification.used_signatures', {
          plan: membershipName.value
        });
        const link = i18n.t('nav.upgrade_notification.signatures_link');
        return [`${welcome_msg.value} ${used_msg}`, link];
      }
      const used_msg = i18n.t('nav.upgrade_notification.used_templates', {
        plan: membershipName.value
      });
      const link = i18n.t('nav.upgrade_notification.templates_link');
      return [`${welcome_msg.value} ${used_msg}`, link];
    });

    return {
      msg,
      dismiss,
      hasMembership
    };
  }
});
