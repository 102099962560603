













import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'BaseButton',
  props: {
    label: String,
    color: {
      type: String,
      default: 'primary'
    },
    event: {
      type: String,
      default: 'click'
    },
    outline: {
      type: Boolean,
      default: false
    },
    unelevated: {
      type: Boolean,
      default: false
    },
    paddingX: {
      type: String,
      default: 'md'
    },
    fontSize: {
      type: Number,
      default: 16,
      validator: (value: number) => {
        if (value % 2) {
          console.error('You need pass only even values for fontSize prop');
          return false;
        }
        return true;
      }
    }
  }
});
