



































import {
  NotaryProductDocument,
  NotaryProductTypeSlug
} from '@ligo/dashboard/store';
import { defineComponent, PropType } from '@vue/composition-api';
import DocumentNameClickable from '../../global/DocumentNameClickable.vue';

export default defineComponent({
  name: 'ItemAccepted',
  components: { DocumentNameClickable },
  props: {
    item: {
      type: Object as PropType<NotaryProductDocument>,
      required: true
    },
    productType: {
      type: String as PropType<NotaryProductTypeSlug>
    }
  }
});
