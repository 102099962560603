



































import { defineComponent, ref } from '@vue/composition-api';
import { BaseInput } from '../../base';
import { APrimaryBtn } from '../../base/app/buttons';
import { useBilling, formSections } from './billing.hook';
import { useI18n } from '../../../composables';
import SavedBilling from './SavedBilling.vue';

export default defineComponent({
  name: 'BillingForm',
  components: { BaseInput, SavedBilling, APrimaryBtn },
  props: {
    paymentUuid: {
      type: String
    }
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const { billingData, loading, saveBilling, showSaved } = useBilling();
    const form = ref();

    function required(v) {
      return !!v || i18n.t('billing.rules.required');
    }

    async function onNext() {
      if (!form.value) {
        emit('next');
      } else {
        const valid = await form.value.validate();
        if (valid) {
          saveBilling(props.paymentUuid).then(() => {
            emit('next');
          });
        }
      }
    }

    return {
      formSections,
      billingData,
      loading,
      showSaved,
      onNext,
      form,
      HTMLTableRowElement,
      required
    };
  }
});
