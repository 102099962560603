export default {
  title: 'Afspraak met de notaris',
  banner: {
    text_send_email_and_call_video:
      'Graag een email sturen naar de notaris om een afspraak in te plannen op een tijdstip dat jou goed uitkomt. De bijeenkomst met de notaris vindt plaats via een videogesprek. Graag zorgen dat je het legitimatiebewijs, die je voor de aanvraag gebruikt hebt, bij je hebt.',
    text_contact:
      'De notaris neemt telefonisch contact op om jouw gegevens te bevestigen en stuurt je na het telefoongesprek een e-mail om jouw identiteit te verifiëren en de volmacht te ondertekenen door middel van een Evidos, een digitale identificatiemiddel. Door het tekenen van de volmacht, machtig je notaris om namens jou jouw B.V, in te schrijven. <b>Vergeet niet om ook het tweede linkje te openen. Je maakt dan een foto van je identiteitsbewijs en jezelf om je te identificeren.</b>',
    text_send_email_and_go_notary:
      'Graag een email sturen naar de notaris om een afspraak in te plannen op een tijdstip dat jou goed uitkomt. De bijeenkomst met de notaris vindt plaats bij de notaris op kantoor. Als je voor de notaris in Rotterdam hebt gekozen, hoef je nu niets te doen, je ontvangt van hen een mail. Graag zorgen dat je het legitimatiebewijs, die je voor de aanvraag gebruikt hebt, bij je hebt.'
  },
  contact: 'Contactgegevens',
  email: 'E-mail',
  phone: 'Telefoonnummer',
  address: 'Adress',
  check_map: 'Bekijk het adres op de kaart',
  document_folder: 'Documenten map',
  read_and_print: {
    title: 'Print en breng deze documenten mee naar de afspraak.',
    banner: {
      text:
        'Graag de volmacht en de personal data card printen. Deze zal je gereed moeten hebben tijdens het gesprek met de notaris. De documenten mogen nog niet getekend worden. Je gaat deze documenten tekenen tijdens de afspraak met de notaris.'
    }
  },
  upload: 'Kies',
  change: 'Wijzig',
  documents: 'Al uw documenten',
  templates: 'Sjablonen'
};
