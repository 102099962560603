





























import { defineComponent, PropType } from '@vue/composition-api';
import { PreviewDocumentMenuItem } from '../../../models';

export default defineComponent({
  name: 'ContractMenu',
  props: {
    items: Array as PropType<PreviewDocumentMenuItem[]>
  }
});
