var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.externalLoading && _vm.currMembershipTypeLevel)?_c('div',[_vm._t("title",[_c('s-q-main-header',{attrs:{"title":_vm.$t('page.contract_reminders.title'),"subtitle":_vm.$t('page.contract_reminders.subtitle'),"icon":"fas fa-list-check"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('a-primary-btn',{attrs:{"label":_vm.$t('contract.reminders.new_reminder').toString()},on:{"click":function($event){_vm.showESidebarCreate = true}}})]},proxy:true}],null,false,1843339075)})]),_c('SQTable',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"actions":_vm.actions,"filters-definitions":_vm.filters,"search-engine":_vm.search,"loading":_vm.loading,"pagination":_vm.pagination,"empty":{
      title: _vm.$t('page.contract_reminders.empty_banner.title'),
      subtitle: _vm.$t('page.contract_reminders.empty_banner.subtitle'),
      icon: 'img:/dashboard/images/empty_tasks.svg',
      btnLabel: _vm.$t('page.contract_reminders.empty_banner.btn')
    },"total":_vm.totalReminders},on:{"complete":function($event){return _vm.onToggleCompleteReminder($event.id)},"delete":function($event){return _vm.onDeleteReminder($event.id)},"row-click":function($event){return _vm.onShowUpdateModal($event.id)},"empty-cta-click":function($event){_vm.showESidebarCreate = true}},scopedSlots:_vm._u([{key:"row-field",fn:function(item){return [_c('div',{staticClass:"row items-center no-wrap"},[(item.headerColumn)?_c('div',{staticClass:"q-mr-xs"},[_c('q-item',{staticClass:"q-pa-none q-mr-md border-radius-20 text-black",style:("min-height: auto !important"),attrs:{"clickable":""}},[_c('q-icon',{class:!item.visible && 'disabled',attrs:{"size":"sm","color":item.completed ? 'np-primary-1100' : 'np-gray-800',"name":("fa" + (item.completed ? 's' : 'r') + " fa-circle-check")},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();item.visible && _vm.onToggleCompleteReminder(item.id)}}})],1)],1):_vm._e(),_c('div',{class:("\n                task-table__field\n                text-" + (typeof item == 'object' && item.textColor) + "\n                bg-" + (typeof item == 'object' && item.color) + "\n                border-radius-" + (typeof item == 'object' && item.radius) + "\n                " + (typeof item == 'object' && item.customClass) + "\n              "),style:({
            padding: typeof item == 'object' && item.color && '2px 8px'
          })},[_vm._v(" "+_vm._s(item.value)+" ")])])]}},{key:"mobile-row-data",fn:function(row){return [_c('div',{staticClass:"font-16 w-500 text-default ellipsis"},[_vm._v(" "+_vm._s(row.name.value)+" ")]),_c('div',{staticClass:"font-14 w-400 text-default q-mt-sm"},[_vm._v(" "+_vm._s(_vm.$t('contract.reminders.table.columns.due_date'))+" : "+_vm._s(row.due_date)+" ")]),_c('div',{staticClass:"row q-mt-sm"},[_c('div',{class:("bg-" + (row.status.color) + "  border-radius-" + (row.status.radius) + " text-" + (row.status.textColor) + " q-px-sm")},[_vm._v(" "+_vm._s(row.status.value)+" ")])]),_c('a-secondary-btn',{staticClass:"full-width q-mt-lg",attrs:{"bordered":"","label":_vm.$t('contract.reminders.table.field.view_task').toString()},on:{"click":function($event){return _vm.onShowUpdateModal(row.id)}}})]}},{key:"table-top-header",fn:function(){return [_vm._t("table-top-header")]},proxy:true},{key:"table-top",fn:function(){return [_vm._t("table-top")]},proxy:true},{key:"table-no-data",fn:function(ref){
          var icon = ref.icon;
          var message = ref.message;
return [_vm._t("table-no-data",null,{"icon":icon,"message":message})]}},{key:"table-header",fn:function(props){return [_vm._t("table-header",null,null,props)]}}],null,true)}),_c('alert-modal',{attrs:{"title":("contract.reminders.modals.delete_confirmation." + (_vm.isSelectedReminderRecurrent ? 'title_recurrent' : 'title')),"subtitle":("contract.reminders.modals.delete_confirmation." + (_vm.isSelectedReminderRecurrent ? 'subtitle_recurrent' : 'subtitle')),"cancel-label":"contract.reminders.modals.delete_confirmation.cancel","confirm-label":"contract.reminders.modals.delete_confirmation.confirm"},on:{"confirm":function () { return _vm.deleteReminderCallback && _vm.deleteReminderCallback(); },"cancel":function($event){_vm.showConfirmationDialog = false}},model:{value:(_vm.showConfirmationDialog),callback:function ($$v) {_vm.showConfirmationDialog=$$v},expression:"showConfirmationDialog"}}),(_vm.selectedReminder)?_c('e-sidebar-update',{attrs:{"value":_vm.showESidebarUpdate,"reminder":_vm.selectedReminder},on:{"close":function($event){_vm.showESidebarUpdate = false},"delete":function () {
        _vm.onDeleteReminder(_vm.selectedReminder.id);
        _vm.showESidebarUpdate = false;
      },"toggle-complete":function () {
        _vm.onToggleCompleteReminder(_vm.selectedReminder.id);
        _vm.showESidebarUpdate = false;
      },"update":function($event){return _vm.onUpdateReminder($event)},"remind-me-next-year":function (_, opt) {
        _vm.onRemindMeOnCustomTime(_vm.selectedReminder, opt);
        _vm.showESidebarUpdate = false;
      }}}):_vm._e(),_c('e-sidebar-create',{attrs:{"value":_vm.showESidebarCreate},on:{"close":function($event){_vm.showESidebarCreate = false},"create":function($event){return _vm.createUnlinkedReminder($event, _vm.finishCreateAction)}}})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }