
























































































import { defineComponent, PropType, computed, ref } from '@vue/composition-api';
import { NotaryProductSlug } from '@ligo/bv-flow/store';
import { ExtraChangesPageInformation } from '../../../utils/useCustomerPageInformation.hooks';
import { useStore } from '../../../../composables';
import moment from 'moment';
import { NotaryProductService } from '@ligo/dashboard/customer/store';
import { notifySuccess } from './menu-list.hook';
import { APrimaryBtn, ASecondaryBtn } from '../../../base/app/buttons';

export default defineComponent({
  name: 'OtherChangesCard',
  components: { APrimaryBtn, ASecondaryBtn },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    notaryProductUuid: {
      type: String,
      required: true
    },
    productType: {
      type: String as PropType<NotaryProductSlug>
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const user = computed(() => store.getters['authentication/user']);

    const description = ref('');

    const { t } = ExtraChangesPageInformation;

    const onSave = async () => {
      await NotaryProductService.requestChange(props.notaryProductUuid, {
        request: description.value
      });
      notifySuccess(t('other.success').toString());
      description.value = '';
      emit('input', false);
    };

    return {
      onSave,
      user,
      moment,
      description,
      t
    };
  }
});
