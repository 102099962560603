export default {
  paid: 'Paid',
  unpaid: 'Draft',
  open: 'In progress',
  library: {
    search: 'Search',
    go_back: 'Back to library',
    use_template: 'Use this contract',
    empty: {
      title: 'We couldn’t load a preview of this document',
      subtitle:
        'You can still use and generate this contract by clicking on the green button.'
    }
  },
  template: {
    start_free: 'Get for free',
    start_en: 'Start',
    open_all: 'Open all the folders',
    free_dialog: {
      title: 'Good choice. This first contract is on us!',
      subtitle: 'Become a member and unlock 100+ contracts',
      btn_label: 'Create document'
    },
    all_contract: 'All documents',
    panel_subtitle:
      'Choose among 100+ contracts made by Ligo’s legal expert team to keep your business protected.',
    panel_title: 'Use a template FROM ligo’s contract library',
    lib_title: 'Templates',
    lib_subtitle:
      'Choose among 100+ contracts made by Ligo’s legal expert team to keep your business protected.',
    show_all: 'Contract templates'
  },
  actives: {
    title: 'Contracts',
    subtitle: 'Create, sign and store legal documents',
    create: 'New document',
    open: 'Open',
    intermediate: 'Intermediate',
    submitted: 'Submitted',
    delete_confirmation:
      'Are you sure you want to delete this contract? This action can not be undone and you will lost all your improvement',
    table: {
      name: 'Name',
      status: 'Status',
      last_modified: 'Last Modified',
      delete: 'Delete',
      copy: 'Make a copy'
    }
  },
  preview: {
    declined: 'Declined',
    sign_now: 'Sign now',
    download: 'Download',
    buy_contract: 'Buy contract',
    upgrade: 'Choose your new plan',
    sign_btn: 'Prepare and send for signature',
    revoke_sign: 'Cancel signing request',
    download_log: 'View signing log',
    pdf: 'PDF',
    pdf_signed: 'Signed PDF',
    word: 'Word',
    refresh_msg:
      'Your document is being generated. Reload the page if it does not show after 10 seconds.',
    upload: 'Upload new version',
    simple_upload: 'Upload',
    edit: 'Edit',
    use_credit: 'Create contract',
    signed: 'Signed',
    contract_unlimited: {
      title: 'You have unlimited credits now'
    },
    contract_left: {
      title: 'contract(s) left to create for free',
      subtitle_1: 'You can create',
      subtitle_2:
        'contract(s) from the Ligo library for free. Create the contract and get access.'
    },
    credit_excedeed: {
      title: 'Upgrade to access and sign this contact template',
      subtitle:
        '{name}, you ‘ve already used the one contract template that’s included in your current {plan} plan. | {name}, you ‘ve already used the {amount} contracts template that’s included in your current {plan} plan.'
    },
    signatures_exceeded: {
      title: 'Upgrade to sign this document',
      subtitle:
        "{name}, you've already used the {amount} digital signatures that are included in your {plan} plan."
    },
    signed_manually: {
      title: 'Sign digitally',
      subtitle:
        'You’ve manually marked this document as signed, which means you signed it outside of Ligo.'
    },
    sign_digitally: {
      title: 'SIGNATURE',
      subtitle: 'Prepare and send this document for signature in 3 easy steps.'
    },
    card_footer: {
      title: 'Don’t feel like upgrading?',
      subtitle: 'You can also buy this contract template individually.',
      cta: 'Buy contract template for €{price}'
    },

    signed_on: 'Signed on',
    not_signed: 'Didn’t sign before the expiration date',
    signed_yet: 'Hasn’t signed yet',
    out_for_signing: 'Out for signature',
    expired: 'Expired',
    rename_title: 'Rename document',
    delete: {
      title: 'Delete document',
      subtitle: 'Are you sure you want to delete this document?',
      cta: 'Delete'
    },
    revoke_modal: {
      title: 'Are you sure?',
      subtitle:
        'Are you sure you want to cancel the signing request for this document?',
      cta: 'Cancel signing request'
    },
    upload_version: {
      title: 'Edit document',
      subtitle:
        'This is a document in PDF format and PDF documents can’t be edited in your browser. You can either upload a new PDF version of this document with your edits, or upload .doc version of it and start editing it in your browser.',
      cta: 'Upload'
    },
    cancel: 'Cancel',
    save: 'Save',
    created: 'Created',
    updated: 'Last modified',
    expires_on: 'Request expires on',
    decline_on: 'Contract declined on',
    cant_edit:
      'You can’t edit this document in Ligo because it’s in <br> PDF format. Editing is only supported for .DOC files. <br> You can either edit it on your device and upload a <br> new version of it, or upload a .doc version and start <br> editing it in Ligo.',
    cant_edit_need_upgrade:
      'You need to have a Ligo plan or purchase <br> this contract to be able to edit',
    cant_edit_cancel_signing:
      'You need to cancel your signing <br> request in order to edit this document.',
    cant_edit_been_signed:
      'You can’t edit this document <br> because it has been signed.',
    cant_upload_need_upgrade:
      'You need to have a Ligo plan or purchase this contract <br> to be able to upload',
    cant_upload_cancel_signing:
      'You need to cancel your signing <br> request in order to upload a new <br> version.',
    cant_upload_been_signed:
      'You can’t upload a new version of this <br> document because it has been signed.'
  },
  upload: {
    title: 'Upload document',
    placeholder: 'Select a document',
    send: 'Send',
    success: 'The contract has been successfully uploaded',
    error: 'There has been an error while uploading the contract',
    required_file: 'Contract file is required',
    required_name: 'Contract name is required',
    format: 'Invalid contract. It should be pdf or word, no greater than 15 MB',
    name: 'Contract name',
    is_signed: 'This is a signed document',
    doc_name: 'Document name'
  },
  empty_banner: {
    title: 'Create, sign and manage your legal docments digitally',
    subtitle:
      'Start by choosing a template from Ligo’s contract library, by starting a document from scratch or simply by uploading your own document.',
    btn_label: 'New contract'
  },
  contract_menu: {
    download_as_doc: 'Download as .DOC',
    download_as_signed_pdf: 'Download signed PDF',
    download_as_unsigned_pdf: 'Download unsigned PDF',
    print: 'Print',
    rename: 'Rename',
    delete: 'Delete',
    make_copy: {
      title: 'Make a copy',
      required: 'Document name is required',
      document_name: 'Document name',
      new_name_title: 'How would you like to name this document?',
      created: 'Created {copyName}',
      next: 'Make copy',
      cancel: 'Cancel',
      copy: '{contractName} - copy',
      error: 'There has been an error',
      copying: 'Copying contract ...'
    }
  },
  reminders: {
    title: 'Tasks',
    unlock: 'Upgrade to unlock this feature',
    no_items:
      'Never miss a deadline or important obligation again. With Tasks, you can manage your to-dos with ease and focus on what really matters.',
    add: 'Add new',
    full_list_empty: 'There are no scheduled tasks.',
    overdue: 'Overdue',
    complete_badge: 'Completed',
    mark_as_incomplete: 'Mark as uncompleted',
    new_reminder: 'New task',
    paid: {
      title: 'Upgrade to get access',
      subtitle: 'This task with advice is accessible with a paid Legal Plan.',
      cta: 'Upgrade'
    },
    modals: {
      complete_title: 'Completed tasks',
      description_placeholder: 'Type a description',
      remind_on: 'Remind on',
      event_date: 'Event date',
      add_date: 'Add date',
      assignee: 'Assignee',
      add_assignee: 'Add assignee',
      create: 'Create',
      delete: 'Delete',
      cancel: 'Cancel',
      save: 'Save',
      complete: 'Mark as complete',
      done: 'Mark as done',
      success_create_reminder: 'Task created successfully',
      error_create_reminder: 'Error creating task',
      success_update_reminder: 'Task updated successfully',
      error_update_reminder: 'Error updating task',
      success_delete_reminder: 'Task deleted successfully',
      error_delete_reminder: 'Error deleting task',
      select_employee: 'Select a person from your workspace',
      close: 'Add',
      remind_me_next_year: 'Remind me next year',
      remind_me_again: 'Remind me again',
      time_interval: {
        month: 'next month',
        quarter: 'next quarter',
        year: 'next year'
      },
      delete_confirmation: {
        title: 'Are you sure you want remove this task?',
        title_recurrent: 'Are you sure you want to delete this repeating task?',
        subtitle: 'The task will be deleted from your dashboard.',
        subtitle_recurrent:
          'This is a repeating task you need to execute periodically. If you delete it, all following tasks in the future will also be deleted.',
        confirm: 'Remove',
        cancel: 'Cancel'
      }
    },
    table: {
      filters: {
        search: 'Search by name',
        status: 'Status',
        due_date: 'Due date'
      },
      columns: {
        name: 'Task name',
        status: 'Status',
        due_date: 'Due date',
        category: 'Category'
      },
      status: {
        pending: 'Pending',
        completed: 'Complete',
        due_date: 'Due date'
      },
      menu: {
        complete: 'Mark as done',
        delete: 'Delete'
      },
      field: {
        view_task: 'View task'
      }
    }
  },
  status: {
    all: 'All',
    editing: 'Ready for signing',
    awaiting_approval: 'Awaiting approval',
    approved: 'Approved',
    sent_for_signing: 'Sent for signing',
    active: 'Signed',
    expired: 'Expired',
    declined: 'Declined',
    draft: 'Questionnaire',
    create: 'Creating'
  },
  filters: {
    status: {
      name: 'Status'
    },
    date: {
      name: 'Last modified',
      any_time: 'Any time',
      today: 'Today',
      yesterday: 'Yesterday',
      last_7_days: 'Last 7 days',
      last_30_days: 'Last 30 days',
      last_90_days: 'Last 90 days',
      custom: 'Custom'
    }
  },
  table: {
    filters: {
      search: 'Search by name',
      status: 'Status',
      due_date: 'Last Modified'
    },
    columns: {
      name: 'Name',
      status: 'Status',
      due_date: 'Last Modified'
    },
    field: {
      view_contract: 'View contract'
    }
  },
  signature_widget: {
    preparing: 'Preparing',
    drafted: 'Drafted',
    sent: 'Sent',
    signed: 'Signed',
    declined: 'Declined'
  }
};
