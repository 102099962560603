import { computed, Ref, ref, watch } from '@vue/composition-api';
import { AxiosPromise } from 'axios';
import { Dictionary } from '../tools/types';
import { useAsyncResult } from './async-results.hook';

export function usePaginated<T>(
  list: (params?: Dictionary, arrayParams?: any[]) => AxiosPromise<T[]>,
  perPage: number,
  watchFor = [],
  search?: Ref<any>,
  arrayParams: any[] = [],
  watchAndRestart: any[] = []
) {
  const page = ref(1);
  const items: Ref<T[]> = ref([]);
  let done: () => void;

  const { state, last, loading, load } = useAsyncResult<T>(() => {
    const params: Dictionary = {
      per_page: perPage,
      page: page.value
    };
    if (search && search.value) {
      params.query = encodeURI(search.value);
    }
    return list(params, arrayParams);
  }, [page, ...watchFor]);

  // Cases when new data is loaded and needs to be added to the pool
  watch(state, (newVal) => {
    newVal.forEach((element) => {
      items.value.push(element);
    });
    if (done) {
      done();
    }
  });

  if (search) {
    watch(search, () => {
      restart();
    });
  }

  // Cases when a complete restart of the pool is needed
  if (watchAndRestart.length) {
    watch(watchAndRestart, () => {
      restart();
    });
  }

  const onNextPage = (
    event = () => {
      return;
    }
  ) => {
    if (!done) done = event;
    if (!last.value && state.value.length) {
      page.value += 1;
    } else {
      done();
    }
  };

  const restart = () => {
    items.value = [];
    if (page.value != 1) {
      page.value = 1;
    } else {
      load();
    }
  };

  const loadingAll = computed(() => loading.value && !items.value.length);
  const loadingMore = computed(() => loading.value && Boolean(items.value));

  return {
    items,
    loading,
    page,
    last,
    loadingAll,
    loadingMore,
    onNextPage,
    load,
    restart
  };
}
