import { basicResize } from './tools';
import { EdgeOptions } from '@interactjs/types/index';
import interact from 'interactjs';

export type Edges = {
  left: boolean;
  right: boolean;
  bottom: boolean;
  top: boolean;
};

type Size = {
  width: number;
  height: number;
};

export function toResizable(
  minSize: Size,
  callback?: (event: any) => void,
  edges: EdgeOptions = { left: true, right: true, bottom: true, top: true }
) {
  return {
    edges,
    listeners: {
      move(event: any) {
        basicResize(event);
        callback && callback(event);
      }
    },
    inertia: true,
    modifiers: [
      interact.modifiers.restrictSize({
        min: minSize
      })
    ]
  };
}
