import I18n from './i18n';
import ApiService from './api';
import CompositionApi from './composition-api';
import VueApexCharts from './apexcharts';
import GoogleMaps from './google-maps';
import HideDirective from './hide-directive';
import GTM from './gtm';
import Splides from './splides';
import WordEditor from './word-editor';
import { bugsnag, BugsnagStage } from '@ligo/shared/utils';
import { useEnvironment } from '../../../../netlify_functions';

const bugsnagStage = useEnvironment().APP_CONTEXT;

export const bootFiles = [
  bugsnag('f4f825cdfbfdbc83a33fc677be0b2908', bugsnagStage as BugsnagStage),
  I18n,
  CompositionApi,
  ApiService,
  VueApexCharts,
  GoogleMaps,
  GTM,
  WordEditor,
  HideDirective,
  Splides
];
