

































import { Contract } from '@ligo/dashboard/customer/store';
import {
  defineComponent,
  Ref,
  ref,
  PropType,
  watch
} from '@vue/composition-api';
import { useI18n, useRouter } from '../../../../composables';
import AlertModal from '../../base/AlertModal.vue';
import { ContractService } from '@ligo/dashboard/customer/store';
import { Notify } from 'quasar';
import { APrimaryBtn, ASecondaryBtn } from '../../../base/app/buttons';

export default defineComponent({
  name: 'MakeCopyModal',
  components: { AlertModal, APrimaryBtn, ASecondaryBtn },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    contract: {
      type: Object as PropType<Contract>,
      required: true
    },
    redirectOnCopy: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const i18 = useI18n();
    const router = useRouter();
    const loading = ref(false);

    const copyName = ref(
      i18.t('contract.contract_menu.make_copy.copy', {
        contractName: props.contract.name
      })
    );
    const form: Ref<any> = ref();

    const newNameRules = [
      (value) => !!value || i18.t('contract.contract_menu.make_copy.required')
    ];

    function clearCopyName() {
      copyName.value = i18.t('contract.contract_menu.make_copy.copy', {
        contractName: props.contract.name
      });
      emit('exit-copy-dialog');
    }

    function redirectToCopy(contract) {
      router
        .push({
          name: 'ContractPreview',
          params: { uuid: contract.uuid }
        })
        .then(() => {
          window.location.reload();
        });
    }

    function onMakeCopy() {
      form.value.validate().then((valid) => {
        if (valid) {
          loading.value = true;
          emit('starting-copy');
          const notify = Notify.create({
            group: false,
            message: i18.t(
              'contract.contract_menu.make_copy.copying'
            ) as string,
            color: 'dark',
            position: 'top',
            timeout: 0,
            spinner: true,
            classes: 'loading-notification'
          });

          ContractService.copy(props.contract.uuid, copyName.value as string)
            .then((response) => {
              notify({
                icon: 'img:/dashboard/icons/circle-check.svg',
                message: i18.t('contract.contract_menu.make_copy.created', {
                  copyName: copyName.value
                }) as string,
                color: 'dark',
                position: 'top',
                classes: 'basic-notification',
                spinner: false,
                timeout: 2000
              });
              if (props.redirectOnCopy) redirectToCopy(response.data);
            })
            .finally(() => {
              clearCopyName();
              loading.value = false;
              emit('copy-success');
            });
        }
      });
    }

    watch(
      () => props.contract,
      () => {
        copyName.value = i18.t('contract.contract_menu.make_copy.copy', {
          contractName: props.contract.name
        });
      }
    );

    return {
      copyName,
      clearCopyName,
      onMakeCopy,
      form,
      newNameRules,
      loading
    };
  }
});
