































import { defineComponent } from '@vue/composition-api';
import { usePanel } from './pannel.hook';
import ASecondaryBtn from '../../base/app/buttons/ASecondaryBtn.vue';
import APrimaryBtn from '../../base/app/buttons/APrimaryBtn.vue';

const SHOW_ALL_ICON = 'img:/icons/create-folder.svg';

export default defineComponent({
  name: 'Panel',
  components: {
    ASecondaryBtn,
    APrimaryBtn
  },
  setup() {
    const {
      contractsPreview,
      loading,
      headers,
      rows: items,
      open
    } = usePanel();

    return {
      contractsPreview,
      loading,
      headers,
      items,
      SHOW_ALL_ICON,
      open
    };
  }
});
