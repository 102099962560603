import {
  getLabelForStringOption,
  transformStringOptions
} from '../../base/SimpleForm/hooks/utils';
import {
  GetBoolSelectable,
  SimpleField,
  SimpleFieldStatus
} from '../../base/SimpleForm/models/simpleForm.models';
import {
  headquartersList,
  representationOptions,
  sellProductOptions,
  shareholderOptions,
  whereAreSoldOptions,
  offerTypeOptions
} from './extra-fields-option.model';
import { NotaryProductService } from '@ligo/dashboard/customer/store';
import {
  NotaryAvailability,
  NotaryCapacityStatus,
  NotaryProductSlug,
  AllNotaryProductSlug
} from '@ligo/dashboard/store';

import {
  useAddressFields,
  useCompanyNameField,
  activitiesField
} from '../hooks/common-field-definitions.hooks';
import {
  bvLayout,
  defaultFieldLayout,
  holdingLayout,
  stichtingLayout
} from './extra-fields-layout.model';

export const SEPARATOR_TOKEN = '---';

export const useGetNotarySelection = (
  uuid: string,
  notaryId: number | undefined,
  disable: () => boolean
) => {
  const loadNotaryOptions = () => {
    return NotaryProductService.getNotariesList(uuid).then((response) => {
      const options = response.data.map((item) => ({
        presentation_label: item.presentation_label || item.city,
        id: item.id,
        capacity_status: item.capacity_status
      }));
      const index = response.data.findIndex(
        (item) => item.availability === NotaryAvailability.OFFLINE
      );
      if (index > -1) {
        options.splice(index, 0, {
          id: 0,
          presentation_label: SEPARATOR_TOKEN,
          capacity_status: null
        });
      }
      return options;
    });
  };

  const notaryField: SimpleField = {
    key: 'notary_id',
    label: '',
    type: 'select',
    hideOn: () => {
      return !!notaryId;
    },
    disableOn: (_) => disable(),
    selectable: {
      options: loadNotaryOptions,
      disable: (value) => value.capacity_status === NotaryCapacityStatus.LOW,
      label: 'presentation_label'
    },
    rules: ['required']
  };
  return {
    loadNotaryOptions,
    notaryField
  };
};

export const useGetFields = (
  notaryId: number,
  t: (key: string) => string,
  slug: NotaryProductSlug
) => {
  const isForeignIncorporator: SimpleFieldStatus = (_, formValue) => {
    return (
      !!notaryId ||
      !!shareholderOptions
        .filter((p) => Object.keys(formValue).includes(p))
        .find((p) => {
          return !formValue[p].lives_in_netherlands;
        })
    );
  };

  const needBvSuffix = (value: string) => {
    return (
      value &&
      slug != NotaryProductSlug.ANBI_STICHTING &&
      slug != NotaryProductSlug.STICHTING_OPRICHTEN
    );
  };

  const getCompanyName = (value: string) => {
    if (needBvSuffix(value))
      return `${value.replace(/(BV|B.V.)$/g, '').trim()} B.V.`;
    return value;
  };

  const peopleFields: SimpleField[] = [
    // people
    {
      key: 'full_time_work',
      type: 'number',
      mask: 'integer',
      rules: ['required', 'non_negative']
    },
    {
      key: 'part_time_work',
      type: 'number',
      mask: 'integer',
      rules: ['required', 'non_negative']
    },
    {
      key: 'employees_loan',
      rules: ['required'],
      type: 'select',
      selectable: GetBoolSelectable()
    },
    {
      key: 'representation',
      rules: ['required'],
      type: 'select',
      selectable: {
        label: (value) =>
          getLabelForStringOption(
            'extra_questions',
            'representation',
            value,
            t
          ),
        options: () => transformStringOptions(representationOptions)
      }
    },
    {
      key: 'financial_year_end',
      rules: ['required'],
      type: 'select',
      selectable: {
        useOptions: true,
        options: () => {
          const date = new Date();
          const this_year = `31-12-${date.getFullYear().toString()}`;
          const next_year = `31-12-${(date.getFullYear() + 1).toString()}`;
          return [
            { id: 'this_year', name: this_year },
            { id: 'next_year', name: next_year }
          ];
        }
      }
    }
  ];

  const activitiesFields: SimpleField[] = [
    // activities
    {
      key: 'offer_type',
      rules: ['required'],
      type: 'select',
      selectable: {
        label: (value) =>
          getLabelForStringOption('extra_questions', 'offer_type', value, t),
        options: () => transformStringOptions(offerTypeOptions),
        multiple: true
      }
    },
    {
      key: 'export_products',
      rules: ['required'],
      type: 'select',
      selectable: GetBoolSelectable()
    },
    {
      key: 'import_products',
      rules: ['required'],
      type: 'select',
      selectable: GetBoolSelectable()
    },
    {
      key: 'sell_products',
      type: 'select',
      rules: ['required'],
      selectable: {
        label: (value) =>
          getLabelForStringOption('extra_questions', 'sell_products', value, t),
        options: () => transformStringOptions(sellProductOptions)
      }
    },
    {
      key: 'where_are_sold',
      type: 'select',
      rules: ['required'],
      selectable: {
        multiple: true,
        label: (value) =>
          getLabelForStringOption(
            'extra_questions',
            'where_are_sold',
            value,
            t
          ),
        options: () => transformStringOptions(whereAreSoldOptions)
      }
    },
    activitiesField
  ];

  const addressFields = useAddressFields();

  const defaultFields: SimpleField[] = [
    // address
    {
      key: 'intermediary',
      type: 'select',
      rules: ['required'],
      defaultValue: null,
      selectable: GetBoolSelectable()
    },
    {
      key: 'headquarters',
      type: 'select',
      rules: ['required'],
      selectable: {
        autocomplete: {
          min: 0
        },
        options: () => transformStringOptions(headquartersList)
      }
    },
    ...addressFields,
    {
      key: 'notary_city_id',
      type: 'select',
      label: 'preferred_notary_city',
      hideOn: isForeignIncorporator,
      selectable: {
        options: () =>
          NotaryProductService.getCitiesList().then((response) => response.data)
      },
      rules: ['required']
    }
  ];

  const productLayout: {
    [key in AllNotaryProductSlug]: string[][];
  } = {
    'anbi-stichting-oprichten': stichtingLayout,
    'bv-oprichten': bvLayout,
    'incorporate-dutch-bv': bvLayout,
    'incorporate-holding': holdingLayout,
    'omzetting-naar-bv': bvLayout,
    'persoonlijke-holding-oprichten': holdingLayout,
    'stichting-oprichten': stichtingLayout,
    'upsell-package-holding-oprichting': holdingLayout,
    'upsell-package-incorporate-holding': holdingLayout,
    aandelenoverdracht: defaultFieldLayout
  };
  const fields: SimpleField[] = [
    useCompanyNameField(slug),
    ...peopleFields,
    ...activitiesFields,
    ...defaultFields
  ];

  const useLayout = productLayout[slug] || defaultFieldLayout;

  return {
    fields,
    productLayout,
    getCompanyName,
    useLayout
  };
};
