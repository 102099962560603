



































































































import { computed, defineComponent, PropType } from '@vue/composition-api';
import { CardPlan } from '../../../models';
import APrimaryBtn from '../../base/app/buttons/APrimaryBtn.vue';
export default defineComponent({
  name: 'PlanCard',
  components: { APrimaryBtn },
  props: {
    planCard: {
      type: Object as PropType<CardPlan>
    },
    active: {
      type: Boolean,
      default: false
    },
    currentPlan: {
      type: Boolean,
      default: false
    },
    isEnterprise: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    function onClickCard() {
      if (!props.currentPlan) {
        emit('select', props.planCard.slug);
      }
    }
    const price = computed(() => Math.trunc(props.planCard.price));
    return { onClickCard, price };
  }
});
