



















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'BaseInput',
  props: {
    value: {
      type: String
    },
    label: {
      type: String
    },
    rules: {
      type: Array
    },
    type: {
      type: String
    },
    placeholder: {
      type: String
    },
    bgColor: {
      type: String
    }
  }
});
