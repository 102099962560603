import { ref } from '@vue/composition-api';
import { ApiService } from '@ligo/shared/utils';
import {
  NotaryProductService,
  RESOURCES
} from '@ligo/dashboard/customer/store';
import {
  NotaryProductTypeSlug,
  UpsellPackageNotaryProductSlug
} from '@ligo/dashboard/store';

export interface BannerInformation {
  hello: string;
  title: string;
  items: Array<(count: number) => string | string> | undefined;
  footer: string;
  footerLink: string;
  okCta: string;
}

const INCORPORATIONS = [
  NotaryProductTypeSlug.DUTCH_BV,
  NotaryProductTypeSlug.ENGLISH_BV,
  NotaryProductTypeSlug.ENGLISH_HOLDING,
  NotaryProductTypeSlug.OMZETTING,
  NotaryProductTypeSlug.STITCHING,
  NotaryProductTypeSlug.HOLDING,
  NotaryProductTypeSlug.ANBI_STITCHING,
  UpsellPackageNotaryProductSlug.UPSELL_HOLDING,
  UpsellPackageNotaryProductSlug.UPSELL_INCORPORATE_HOLDING
];

enum STATUS {
  PENDING = 'pending',
  COMPLETED = 'completed'
}

export function useHomeConfig() {
  const pendingTasks = ref(0);
  const runningIncorporations = ref(0);

  function getPendingReminders() {
    ApiService.get(RESOURCES.EVENTS, { status: STATUS.PENDING }).then(
      (response) => {
        pendingTasks.value = parseInt(response.headers['x-total']);
      }
    );
  }

  function getIncorporations() {
    NotaryProductService.list({
      notary_product_types: INCORPORATIONS
    }).then((response) => {
      runningIncorporations.value = parseInt(response.headers['x-total']);
    });
  }

  getPendingReminders();
  getIncorporations();

  return { pendingTasks, runningIncorporations };
}
