














import { defineComponent, computed } from '@vue/composition-api';
import { APrimaryBtn } from '../../base/app/buttons';
import { useStore } from '../../../composables';
import StepHeader from './StepHeader.vue';

export default defineComponent({
  name: 'EnjoyStep',
  components: { StepHeader, APrimaryBtn },
  setup() {
    const store = useStore();
    const username = computed(() => store.getters['authentication/firstname']);

    return { username };
  }
});
