




import { defineComponent } from '@vue/composition-api';
import PrepareDocument from '../../../components/documents/signing-document/PrepareDocument.handler.vue';

export default defineComponent({
  name: 'DocumentPreparation',
  components: { PrepareDocument },
  props: {
    uuid: {
      type: String,
      required: true
    },
    assistant: {
      type: Boolean,
      default: false
    }
  }
});
