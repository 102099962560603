export default {
  table: {
    columns: {
      service: 'Service',
      status: 'Status',
      year: 'Year',
      paid_on: 'Paid on'
    },
    status: {
      paid: 'Paid',
      open: 'Pending'
    },
    actions: {
      pay: 'Pay'
    },
    empty: {
      title: 'Your tax consultations will show up here',
      subtitle:
        'Get professional advice from our tax specialists and handle over all the work to ensure you optimize your tax.'
    }
  }
};
