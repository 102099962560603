import { computed, watch } from '@vue/composition-api';
import { ApiService, RootContext } from '@ligo/shared/utils';
import { Intercom, initIntercom } from '@ligo/shared/utils';
import { COMPANY_KEY } from '@ligo/dashboard/customer/store';
import { useAwaitDOMNode } from '@ligo/shared/utils';
import moment from 'moment';
import { useEnvironment } from '../../../../../../netlify_functions';
import { useStore } from './composables';
import Vue from 'vue';

const INTERCOM_CLASS = '.intercom-launcher';

export function loadCompanyFromStorage() {
  const companyId = localStorage.getItem(COMPANY_KEY);
  if (companyId) ApiService.setCustomHeader(COMPANY_KEY, companyId);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useInit(root: RootContext) {
  const store = useStore();

  function setCompanyHeader() {
    // The company selected by the user is store in Local Storage and passed in all api requests
    const companyId = store.getters['company/getCompanyId'];
    if (companyId) ApiService.setCustomHeader(COMPANY_KEY, companyId);
  }

  function loadIntercom() {
    useAwaitDOMNode([INTERCOM_CLASS]).then(() => {
      initIntercom(useEnvironment().INTERCOM_KEY);
      Intercom.identifyUser(root.$store.state.authentication.accessToken);
      Intercom.update();
    });
  }

  function setLanguage() {
    // Use the the locale property of the user to set the language on first load
    const lang = computed(() => store.getters['authentication/language']);
    watch(lang, (newLang) => {
      const localeMap = {
        en: 'en-us',
        nl: 'nl'
      };
      if (newLang != undefined) {
        root.$i18n.locale = localeMap[newLang];
        moment.locale(newLang);
        localStorage.setItem('locale', localeMap[newLang]);
      }
    });
  }

  function loadUserInfo() {
    if (!Vue['Router'].currentRoute.meta?.noUserInfoNeeded) {
      store.dispatch('authentication/loadUserInfo');
    }
  }

  function init() {
    setCompanyHeader();
    setLanguage();
    loadIntercom();
    loadUserInfo();
  }
  return { init };
}
