
























































import { defineComponent, PropType, ref, Ref } from '@vue/composition-api';
import { CategoryItem } from '../../../models';
import { ALL_CONTRACTS_CATEGORY_ID } from './contract-library.hook';

export default defineComponent({
  name: 'LibraryCategories',
  props: {
    categories: Array as PropType<CategoryItem[]>
  },
  setup() {
    const focusId = ref(null) as Ref<number>;
    return { ALL_CONTRACTS_CATEGORY_ID, focusId };
  }
});
