






































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { Company } from '@ligo/dashboard/store';
import { extractInitials } from '@ligo/shared/utils';

export default defineComponent({
  name: 'CompaniesList',
  props: {
    companies: {
      type: Array as PropType<Company[]>
    },
    selectedCompany: {
      type: Object as PropType<Company>
    },
    loading: {
      type: Boolean
    }
  },
  setup() {
    const showMenu = ref(false);
    return { showMenu, extractInitials };
  }
});
