
































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import {
  ApiService,
  extractInitials,
  titleize,
  Intercom
} from '@ligo/shared/utils';
import { EXTERNAL_RESOURCES, RESOURCES } from '@ligo/dashboard/customer/store';
import { useRouter, useStore } from '../../composables';
import { useMembershipInfo } from '../../components/utils/membership-info';
import {
  useUserMenuItems,
  TOKEN_FOR_SEPARATOR
} from '../customer-layout.hooks';
import { LinkType } from '../../models';

export default defineComponent({
  name: 'UserMenu',
  props: {
    mini: {
      type: Boolean,
      default: false
    }
  },
  setup(_, { emit }) {
    const store = useStore();
    const router = useRouter();
    const showMenu = ref(false);
    const user = computed(() => store.getters['authentication/user']);
    const userFirstName = computed(
      () => user.value && titleize(user.value.firstname)
    );
    const username = computed(() =>
      titleize(`${user.value.firstname} ${user.value.lastname}`)
    );
    const userInitials = computed(() =>
      user.value
        ? extractInitials([user.value.firstname, user.value.lastname])
        : ''
    );
    const {
      availableSignatures,
      availableTemplates,
      planSignatures,
      planTemplates,
      hasMembership
    } = useMembershipInfo();

    const { menuItems, logout } = useUserMenuItems();

    async function goOldDashboard() {
      const response = await ApiService.auth_redirection(
        EXTERNAL_RESOURCES.AUTHENTICATED_REDIRECTION(
          RESOURCES.OLD_CUSTOMER_DASHBOARD
        )
      );
      window.location.assign(response.request.responseURL);
    }

    function openIntercom() {
      Intercom.wakeUp();
    }

    function handleItemClick(type: LinkType, route?: string) {
      switch (type) {
        case LinkType.Route:
          router.push({ name: route });
          break;
        case LinkType.Logout:
          emit('logout');
          break;
        case LinkType.OldDashboard:
          goOldDashboard();
          break;
        case LinkType.OpenIntercom:
          openIntercom();
      }
    }

    function onShowMenu() {
      if (!showMenu.value && !user) return;
      showMenu.value = !showMenu.value;
    }

    return {
      user,
      username,
      userFirstName,
      menuItems,
      TOKEN_FOR_SEPARATOR,
      userInitials,
      showMenu,
      availableSignatures,
      availableTemplates,
      planSignatures,
      planTemplates,
      hasMembership,
      logout,
      extractInitials,
      handleItemClick,
      onShowMenu
    };
  }
});
