import { Dictionary } from '../tools/types';
import { ApiService } from '../../';

export const getFileName = (contentDisposition: string): string => {
  if (!contentDisposition) return 'file';
  let parser = require('content-disposition');
  let parsedContent = parser.parse(contentDisposition);

  if (!parsedContent.parameters.filename) return 'file';
  return parsedContent.parameters.filename;
};

export const downloadFile = (
  service: string,
  params?: Dictionary,
  options?: Dictionary
) => {
  ApiService.get(service, params, options).then((response) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(response.data);
    link.download = getFileName(response.headers['content-disposition']);
    link.click();
    URL.revokeObjectURL(link.href);
  });
};
