
































































import { defineComponent, ref, computed } from '@vue/composition-api';
import NavItemList from './drawer-components/NavItemList.vue';
import ABtn from '../components/base/app/buttons/ABtn.vue';
import { Screen } from 'quasar';

const BREAK_POINT = 750;

export default defineComponent({
  name: 'CustomerDrawer',
  components: { NavItemList, ABtn },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  setup(_, { emit }) {
    const mini = ref(false);

    const logoMini = 'img:/dashboard/icons/sidebar/logo_mini.svg';
    const logo = 'img:/dashboard/icons/sidebar/new_logo.svg';

    const onMobile = computed(() => {
      return Screen.width <= BREAK_POINT;
    });

    function changeState(state: boolean) {
      emit('change', state);
    }

    return {
      logo,
      logoMini,
      mini,
      onMobile,
      BREAK_POINT,
      changeState
    };
  }
});
