export const DEFAULT_DRAWER_BREAKPOINT = 950;

export interface LayoutState {
  dashboardTitle: string;
  openPaymentModal: boolean;
  paymentUuid?: string;
  drawerBreakpoint: number;
}

const state: LayoutState = {
  dashboardTitle: '',
  openPaymentModal: false,
  paymentUuid: undefined,
  drawerBreakpoint: DEFAULT_DRAWER_BREAKPOINT
};

export default state;
