export default {
  quote: {
    id: 'Quote ID : ',
    content: 'Your quote :',
    note: 'Answer to your quote',
    add_quote: 'Request new quote'
  },
  add_quote_banner: {
    card_1_title: 'Discuss your legal matter',
    card_1_text:
      'Tell us about your case and indicate the legal area to what it refers',
    card_2_title: 'Submit your request',
    card_2_text:
      'We will pre-select a list of attorneys that are the best for your case',
    card_3_title: 'Chose your attorney',
    card_3_text: 'Chose the attorney you want to work with and get things done'
  },
  submit_quote: 'Submit quote',
  upload_file: 'Upload a file',
  form: {
    title: 'Describe here the work to be done',
    textarea_placeholder:
      'e.g. : I need a lawyer to draw up general terms and conditions. We are a company that develops softwares for US costumers. In addition, we would like advice in any data protection regulations that we most take into account.',
    file_picker_title:
      'Please drag and drop your file in this area or click to upload a file'
  },
  msg: {
    load: {
      success: 'Quote loaded successfully',
      error: 'An error occurred while the quote was loading'
    },
    delete: {
      success: 'The quote was remove successfully',
      error: 'An error occurred while the quote was removing'
    }
  }
};
