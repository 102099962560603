



















































import { defineComponent, PropType } from '@vue/composition-api';
import { Navigation } from './models/navigation';
import UserMenu from './UserMenu.vue';
import UserNotifications from './UserNotifications.vue';

export default defineComponent({
  name: 'DashboardToolbar',
  components: { UserMenu, UserNotifications },
  props: {
    username: String,
    avatar: String,
    label: {
      type: String,
      default: ''
    },
    alias: String,
    links: {
      type: (Array as unknown) as PropType<Navigation[]>
    },
    notifications: {
      type: (Array as unknown) as PropType<any[]>
    },
    loadingNotifications: {
      type: Boolean,
      default: false
    },
    unseenCount: {
      type: Number,
      default: 0
    },
    lastNotifications: {
      type: Boolean,
      default: false
    },
    showMenu: {
      type: Boolean,
      default: false
    },
    showGoBack: {
      type: Boolean,
      default: false
    }
  }
});
