export default {
  title: 'Legal Scans',
  hover:
    'Do the legal health scan for your business and get recommendations on how to stay compliant, and protect your business and yourself.',
  empty: {
    title:
      'Start the Legal Checkup and uncover the legal gaps in your business',
    cta: 'Start your legal scan'
  },
  done: 'Done',
  score: 'score',
  progress: 'Progress to Legal Health',
  start: 'Start Now',
  start_new: 'Start New Legal Scan',
  banner: {
    in_progress: {
      title:
        'Continue with the Legal Checkup and find out in 5 minutes which legal gaps you need to cover',
      subtitle:
        'Keep your business legally compliant! We will analyse your legal gaps based on the information that you will provide in the Legal Checkup questionnaire. We will give you recommended solutions for protection of your business. A first contract is for free!',
      btn: 'Continue'
    },
    completed: {
      title:
        'Great job, you have completed al your recommendations to cover the legal gaps in your business!',
      subtitle:
        "Keep this up and make sure your business stays compliant. Keep your contracts up to date and stay on top of legal obligations. A great way to do this is by using Ligo's reminders."
    },
    negative: {
      title:
        'Oops, it appears from the Legal Scan that little has been arranged legally.',
      subtitle: `Thank you for taking the first step in keeping your business legally compliant!
        We have analyzed your legal gaps based on the information that
        you have provided in the Legal Scan questionnaire. Below you find the recommended solutions for protection of your business. A first contract is for free! Check from which ones you can choose under "Free" at the Contract Library.`
    },
    positive: {
      title:
        'You are doing well, but the Legal Scan still has points for improvement!',
      subtitle: `Thank you for taking the first step in keeping your business legally compliant!
      We have analyzed your legal gaps based on the information that you have provided in the Legal Scan questionnaire.
      Below you find the recommended solutions for protection of your business. A first contract is for free! Check from which ones you can choose under "Free" at the Contract Library.`
    },
    perfect: {
      title:
        'Perfect, you have everything in order according to the Legal Scan!',
      subtitle: `Thank you for taking the first step in keeping your business legally compliant!
      We have analyzed your legal gaps based on the information that
      you have provided in the Legal Scan questionnaire. Below you find the recommended solutions for protection of your business. A first contract is for free! Check from which ones you can choose under "Free" at the Contract Library.`
    }
  }
};
