export default {
  required: 'Dit veld is verplicht',
  email: 'Voer een geldig e-mailadres in',
  email_format: 'U moet een geldig e-mailadres opgeven',
  only_numbers: 'Dit veld mag alleen uit cijfers bestaan',
  password_length: 'Het wachtwoord moet minimaal 8 tekens lang zijn',
  before_today: 'Het document moet voor vandaag zijn afgegeven',
  after_today: 'Het document mag niet verlopen zijn',
  positive: 'Het moet een getal groter dan 0 zijn',
  non_negative: 'Het moet een getal zijn groter dan of gelijk aan 0',
  above1: 'It must be a number greater than 1',
  legal_age:
    'De aandeelhouder moet ouder zijn dan 18 jaar om een BV te kunnen oprichten',
  required_email: 'U moet een e-mailadres opgeven',
  required_password: 'U moet een wachtwoord opgeven',
  required_first_name: 'je moet een voornaam opgeven',
  required_last_name: 'je moet een achternaam opgeven',
  required_phone_number: 'U moet een telefoonnummer opgeven',
  phone_number: 'Je moet een geldig telefoonnummer opgeven',
  zip_code: 'u moet exact 4 tekens per spatie en 2 hoofdletters schrijven',
  bv_suffix:
    'We voegen automatisch "BV" toe aan het einde van de naam. Je hoeft zelf dus geen "BV" in te vullen',
  required_generic: 'Dit veld is verplicht',
  min_length_80: 'De beschrijving moet minimaal 80 woorden lang zijn'
};
