import { Notification } from '@ligo/dashboard/store';
export const NOTIFICATION_TYPES_NAVIGATABLE = [
  'NotaryProduct',
  'Contract',
  'Membership',
  'Note'
];

export function notificationWithClickableOption(
  notification: Notification
): Notification {
  return {
    clickable: NOTIFICATION_TYPES_NAVIGATABLE.includes(
      notification.notable_type
    ),
    ...notification
  };
}
