


































import { defineComponent, ref } from '@vue/composition-api';
import { PropType } from 'vue';
import { SimpleField } from '../models/simpleForm.models';
import { useBrandedLocale } from 'libs/shared/auth/src/lib/components/hooks/auth';

export default defineComponent({
  name: 'BaseLanguageSelect',
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {},
    rules: {
      type: Array
    },
    loading: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    readonly: {
      type: Boolean
    },
    disable: {
      type: Boolean
    },
    hint: {
      type: String
    },
    formValue: {
      type: Object
    },
    config: {
      type: Object as PropType<SimpleField>
    }
  },
  setup(props, { root, emit }) {
    const field = ref(null);
    const { current, locales, onChangeLanguage } = useBrandedLocale(root.$i18n);
    function onInput(value: string) {
      if (field.value?.hasError) {
        field.value?.resetValidation();
      }
      onChangeLanguage(value);
      if (props.config?.transform)
        emit('input', props.config?.transform(value));
      else emit('input', value);
    }
    function getLength(value: any) {
      return value?.toString().length || 0;
    }
    function onResetValidation() {
      field.value?.resetValidation();
    }
    return {
      field,
      current,
      locales,
      onInput,
      getLength,
      onResetValidation
    };
  }
});
