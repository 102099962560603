



















import { PropType, defineComponent } from '@vue/composition-api';
import { NotaryProductDocument } from '@ligo/dashboard/store';
import { NotaryProductDocumentsService } from '@ligo/dashboard/customer/store';

export default defineComponent({
  name: 'DocumentNameClickable',
  props: {
    document: {
      type: Object as PropType<NotaryProductDocument>,
      required: true
    }
  },
  setup(props) {
    function onDownload() {
      NotaryProductDocumentsService.download(props.document);
    }

    return { onDownload };
  }
});
