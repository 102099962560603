


















import { defineComponent } from '@vue/composition-api';
import APrimaryBtn from '../../base/app/buttons/APrimaryBtn.vue';

export default defineComponent({
  name: 'ServicesTypesCard',
  components: { APrimaryBtn },
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    ctaLabel: {
      type: String
    }
  }
});
