






import { defineComponent } from '@vue/composition-api';
import SignatureHomeHandler from '../../components/documents/SignatureHome.handler.vue';
import BasePage from '../../components/base/BasePage.vue';

export default defineComponent({
  name: 'SignatureHome',
  components: { SignatureHomeHandler, BasePage },
  setup() {
    return {};
  }
});
