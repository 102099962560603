export default {
  title: 'Juridische Scans',
  hover:
    'Doe de legal health scan voor je bedrijf en krijg aanbevelingen over hoe je compliant blijft, en hoe je jouw bedrijf en jezelf beschermt.',
  empty: {
    title:
      "Start de Legal Checkup en kom erachter welke juridische risico's je moet afdekken in je bedrijf",
    cta: 'Start je legal checkup'
  },
  done: 'Gedaan',
  score: 'score',
  start: 'Start Nu',
  start_new: 'Start Nieuwe Legal Scan',
  progress: 'Vooruitgang naar juridische gezondheid',
  banner: {
    in_progress: {
      title:
        "Ga verder met de Legal Checkup en weet binnen 5 minuten welke juridische risico's je moet afdekken",
      subtitle:
        "Voldoe aan belangrijke juridische regels! We analyseren je juridische risico's op basis van de informatie die je invult in de Legal Checkup vragenlijst. Hier vind je vervolgens de aanbevolen oplossingen voor de bescherming van je bedrijf. Een eerste contract is gratis!",
      btn: 'Doorgaan'
    },
    completed: {
      title:
        "Goed gedaan, je hebt al jouw verbeterpunten om risico's af te dekken afgerond!",
      subtitle:
        'Ga zo door en zorg ervoor dat je bedrijf compliant blijft. Houd je contracten up-to-date en blijf op de hoogte van wettelijke verplichtingen. Een geweldige manier om dit te doen is door de Reminders van Ligo te gebruiken.'
    },
    negative: {
      title:
        'Oei, je hebt aardig wat verbeterpunten dus ga snel aan de slag met je To Do lijst om je bedrijf te beschermen',
      subtitle: `Goed dat je de eerste stap hebt gezet om juridisch te voldoen aan de regels!
      We hebben je juridische risico's geanalyseerd op basis van de informatie die
      die je hebt ingevuld in de Legal Checkup vragenlijst. Hieronder vind je de aanbevolen oplossingen voor de bescherming van je bedrijf. Een eerste contract is gratis! Kijk uit welke je kan kiezen onder "Gratis" bij de Contractenbibliotheek.`
    },
    positive: {
      title:
        'Je bent aardig op weg, maar je hebt nog steeds verbeterpunten om je bedrijf te beschermen',
      subtitle: `Goed dat je de eerste stap hebt gezet om juridisch te voldoen aan de regels!
      We hebben je juridische risico's geanalyseerd op basis van de informatie die
      die je hebt ingevuld in de Legal Checkup vragenlijst. Hieronder vind je de aanbevolen oplossingen voor de bescherming van je bedrijf. Een eerste contract is gratis! Kijk uit welke je kan kiezen onder "Gratis" bij de Contractenbibliotheek.`
    },
    perfect: {
      title:
        'Geweldig, je hebt belangrijke zaken goed geregeld en geen verbeterpunten!',
      subtitle: `Goed dat je de eerste stap hebt gezet om juridisch te voldoen aan de regels!
      We hebben je juridische risico's geanalyseerd op basis van de informatie die
      die je hebt ingevuld in de Legal Checkup vragenlijst. Hieronder vind je de aanbevolen oplossingen voor de bescherming van je bedrijf. Een eerste contract is gratis! Kijk uit welke je kan kiezen onder "Gratis" bij de Contractenbibliotheek.`
    }
  }
};
