export default {
  offers: {
    page_title: 'Krijg de beste deals met onze partners',
    redeem: 'Krijg nu',
    redeemed: 'Verzilverd',
    upgrade: 'Upgrade om in te wisselen',
    disclaimer:
      "Als je klikt op 'Krijg Nu', geef je ons toestemming om jouw contactgegevens te delen met deze partner."
  },

  msg: {
    part_1: 'Gefeliciteerd!',
    part_2:
      'Gefeliciteerd! Onze partner neemt binnen één werkdag contact met je op, zodat je gebruik kunt maken van dit aanbod.'
  },
  ok: 'Ok',
  banner: {
    title: 'Krijg toegang tot exclusieve kortingen van onze partners',
    subtitle:
      "Vind een verzekering voor jouw bedrijf, een boekhouder voor al jouw loonadministratie en BTW-aangiftes en meer, tegen een scherpe prijs door gebruik te maken van Ligo's exclusieve kortingen.",
    btn: 'Kortingen bekijken'
  }
};
