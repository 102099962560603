






































import { Event } from '@ligo/dashboard/customer/store';
import Reminder from './Reminder.vue';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'ReminderListModal',
  components: { Reminder },
  props: {
    value: Boolean,
    reminders: Array as PropType<Event[]>,
    useEventable: Boolean
  },
  setup(props, { emit }) {
    function onToggleComplete(event: Event) {
      if (props.reminders.length == 1) {
        emit('input', false);
      }
      emit('toggle-complete', {
        id: event.id
      });
    }
    return {
      onToggleComplete
    };
  }
});
