import { NotaryProduct } from '@ligo/dashboard/store';
import { MutationTree } from 'vuex';
import { NotaryProductState, NOTARY_PRODUCT_KEY } from './state';

const mutation: MutationTree<NotaryProductState> = {
  setNotaryProductId(state, id: string) {
    localStorage.setItem(NOTARY_PRODUCT_KEY, id);
    state.selectedId = id;
  },
  setNotaryProduct(state, notaryProduct: NotaryProduct) {
    state.notaryProduct = notaryProduct;
  }
};

export default mutation;
