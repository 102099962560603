






import { defineComponent } from '@vue/composition-api';
import BasePage from '../../components/base/BasePage.vue';
import RemindersList from './Reminders.handler.vue';
export default defineComponent({
  name: 'ContractReminders',
  components: { BasePage, RemindersList }
});
