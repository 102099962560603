















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'LibrarySearch',
  props: {
    placeholder: String,
    value: String
  }
});
