import { ref } from '@vue/composition-api';
import { CancelOption } from '../../../models';

export function useCancelFromQuestions() {
  const textInputs = {
    a1_1: ref(''),
    a1_9: ref(''),
    a2_10: ref(''),
    feedback: ref('')
  };
  const q1 = ref([]);
  const reasonsOptions: CancelOption[] = [
    {
      value: 'a1_1',
      label: 'payment.cancel.form.a1_1',
      input: true,
      input_placeholder: 'payment.cancel.form.a1_1_placeholder'
    },
    {
      value: 'a1_2',
      label: 'payment.cancel.form.a1_2'
    },
    {
      value: 'a1_3',
      label: 'payment.cancel.form.a1_3'
    },
    {
      value: 'a1_4',
      label: 'payment.cancel.form.a1_4'
    },
    {
      value: 'a1_5',
      label: 'payment.cancel.form.a1_5'
    },
    {
      value: 'a1_7',
      label: 'payment.cancel.form.a1_7'
    },
    {
      value: 'a1_8',
      label: 'payment.cancel.form.a1_8'
    },
    {
      value: 'a1_9',
      label: 'payment.cancel.form.a1_9',
      input: true,
      input_placeholder: 'payment.cancel.form.a1_9_placeholder'
    }
  ];
  const q2 = ref([]);
  const appsOptions: CancelOption[] = [
    {
      value: 'a2_1',
      label: 'payment.cancel.form.a2_1'
    },
    {
      value: 'a2_2',
      label: 'payment.cancel.form.a2_2'
    },
    {
      value: 'a2_3',
      label: 'payment.cancel.form.a2_3'
    },
    {
      value: 'a2_4',
      label: 'payment.cancel.form.a2_4'
    },
    {
      value: 'a2_5',
      label: 'payment.cancel.form.a2_5'
    },
    {
      value: 'a2_7',
      label: 'payment.cancel.form.a1_2'
    },
    {
      value: 'a2_8',
      label: 'payment.cancel.form.a2_8'
    },
    {
      value: 'a2_9',
      label: 'payment.cancel.form.a2_9'
    },
    {
      value: 'a2_10',
      label: 'payment.cancel.form.a2_10',
      input: true,
      input_placeholder: 'payment.cancel.form.a2_10_placeholder'
    }
  ];

  return { q1, reasonsOptions, textInputs, q2, appsOptions };
}
