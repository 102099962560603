
















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'LabelValue',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: false
    },
    font: {
      type: String,
      default: '16'
    },
    isClickable: Boolean
  }
});
