export const industryTypeOptions = [
  'business_services',
  'construction',
  'health',
  'retail',
  'culture_sport_recreation',
  'ict_media',
  'wholesale',
  'agriculture',
  'manufacturing',
  'logistics',
  'hospitality',
  'financial_services',
  'energy_water_environment',
  'other'
];

export const companyStructureOptions = [
  'existing_bv',
  'soon_to_incorporate',
  'uninterested',
  'other'
];

export const employeeCountOptions = ['only_me', '1-5', '6-10', '11-25', '25+'];

export const incorporatedTimeOptions = ['-1', '1-3', '4-10', '11-15', '+15'];

export const useLigoOptions = [
  'notarial',
  'contracts',
  'signature',
  'investment',
  'legal_advice',
  'protect',
  'bookeeping'
];
