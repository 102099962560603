export default {
  dashboard: 'Dashboard',
  account: 'Plan en Facturen',
  settings: 'Account',
  upgrade: 'Upgrade and save',
  logout: 'Uitloggen',
  discounts: 'Zakelijke diensten',
  discounts_subtitle:
    'Regel hier zaken die nodig zijn om jouw bedrijf te runnen en te beschermen',
  help: 'Krijg hulp van ons team',
  old_dashboard: 'Old Dashboard',
  services_title: 'Notarieel',
  services_subtitle: 'Een BV oprichten, je aandelen overdragen en meer',
  contracts_title: 'Contracten',
  contracts_subtitle: 'Maak, onderteken en bewaar juridische documenten',
  templates_title: 'Contracten bibliotheek',
  templates_subtitle:
    'Toegang tot 100+ modelcontracten om jouw BV te runnen en te laten groeien',
  ask_lawyer_title: 'Vraag een advocaat',
  ask_lawyer_subtitle: 'Zoek een advocaat uit ons netwerk en stel jouw vragen',
  unlimited_templates: 'Onbeperkt Templates contracten ',
  unlimited_signatures: 'Onbeperkte handtekeningen',
  signatures_used: '{used} van {total} E-signatures gebruikt',
  templates_used: '{used} van {total} contract templates gebruikt',
  upgrade_notification: {
    home:
      'Hi {name}! Je hebt momenteel een gratis account. Upgrade om toegang te krijgen tot alle voordelen.',
    legal_checkup_page:
      "Upgrade en bespaar honderden euro's met het regelen van al je juridische To Do's.",
    legal_checkup_page_action: ' Bekijk de voordelen!',
    msg: 'Hi {name}!',
    used_all: 'Je hebt momenteel een gratis account.',
    used_templates:
      'Je hebt al je contract templates van je {plan} Plan gebruikt.',
    used_signatures:
      'Je hebt al je digitale handtekeningen van je {plan} Plan gebruikt.',
    all_link: 'Upgrade nu om gebruik te maken van alle voordelen.',
    signatures_link: 'Upgrade nu voor meer.',
    templates_link: 'Upgrade nu om toegang te krijgen tot meer.'
  },
  upgrade_section: {
    link: 'Upgrade',
    content: 'Alle juridische zaken van je bedrijf online geregeld'
  },
  incorporations: {
    title: 'Oprichtingen',
    subtitle: 'Richt een BV, holdingstructuur of stichting op'
  },
  modifications: {
    title: 'Wijzigingen',
    subtitle: 'Overdracht van aandelen en statutenwijziging'
  },
  contract_reminders: 'Taken',
  home: 'Home',
  documents_library: 'Templates',
  dga_services: 'DGA diensten',
  tax_services: 'Boekhouding',
  signature_home: 'eSignatures',
  new_navigation_item: 'New',
  banking: 'Bankieren',
  lawyers: 'Advocaten',
  lawyers_subtitle:
    'Ons netwerk van goede advocaten staat klaar om je te helpen.'
};
