import { ContractService } from '@ligo/dashboard/customer/store';
import { ref, Ref } from '@vue/composition-api';
import { useRouter } from '../../../composables';
import { getDefinitions } from './definitions';

export function useCleanEditor(editor: Ref<any>) {
  const router = useRouter();

  const { items } = getDefinitions();
  const fileName = ref('untitled document');
  const documentChanged = ref(false);

  async function onCreateDocument() {
    const blob = await editor.value.ej2Instances.documentEditor.saveAsBlob(
      'Docx'
    );

    const formData = new FormData();
    formData.append('file', blob);
    formData.append('filename', fileName.value);
    formData.append('signed', JSON.stringify(false));
    const contract = (await ContractService.create(formData)).data;
    router.push({
      name: 'ContractPreview',
      params: {
        uuid: contract.uuid
      }
    });
  }

  function onDiscard() {
    router.push({ name: 'ActiveContracts' });
  }

  function onDocumentChanged() {
    documentChanged.value = true;
  }

  return {
    onCreateDocument,
    fileName,
    onDiscard,
    toolbarItems: items,
    documentChanged,
    onDocumentChanged
  };
}
