
























import { defineComponent } from '@vue/composition-api';
import LawyerCard from '../../components/lawyers/LawyerCard.vue';
import { SQMainHeader } from '../../components/base';
import BasePage from '../../components/base/BasePage.vue';
import { useLawyers } from '../../components/lawyers/lawyers.hook';

export default defineComponent({
  name: 'LawyersList',
  components: { LawyerCard, BasePage, SQMainHeader },
  setup() {
    const { lawyersCards, loadLawyersCards } = useLawyers();
    loadLawyersCards();

    return { lawyersCards };
  }
});
