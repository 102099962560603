

















import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'MenuCta',
  props: {
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  }
});
