















































































import { PropType, defineComponent } from '@vue/composition-api';
import HeaderStepper from './OnboardingHeaderStepper.vue';
import OnboardingStep from './OnboardingStep.vue';
import {
  SimpleStepModel,
  SimpleStepperModel,
  StateValue
} from '../base/SimpleForm/models/simpleStepper.model';
import { APrimaryBtn, ASecondaryBtn } from '../base/app/buttons';
import BaseSimpleForm from '../base/SimpleForm/BaseSimpleForm.vue';
export default defineComponent({
  name: 'OnboardingStepper',
  components: {
    HeaderStepper,
    OnboardingStep,
    BaseSimpleForm,
    APrimaryBtn,
    ASecondaryBtn
  },
  props: {
    stepperConfig: {
      type: Object as PropType<SimpleStepperModel>,
      required: true
    },
    step: {
      type: Object as PropType<SimpleStepModel>,
      required: true
    },
    state: {
      type: Object as PropType<StateValue>,
      required: true
    },
    loading: Boolean
  }
});
