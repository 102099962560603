export default {
  popular: 'BV Services',
  services_card: 'Services for my company',
  documents_card: 'Contracts for my company',
  create_document: 'Create Document',
  holding: 'Incorporate a holding',
  bv: 'Incorporate BV',
  all_services: 'See all services',

  incorporate: 'Incorporate BV',
  ask_lawyer: 'Ask a lawyer',
  transfer_shares: 'Transfer of shares',
  quote_request: 'Quotes requests',
  workflow: 'My workflows',

  name: 'Name',
  service_type: 'Service type',
  status: 'Status',
  update_date: 'Update date',
  last_viewed: 'Last viewed',

  checkup: {
    header_avg: 'AVG Privacy Scan',
    header_legal: 'Legal Compliance Scan',
    msg: 'Here you will know how your B.V. is doing regarding legal matters.',
    btn: 'Perform new checkup',
    free: 'Free',
    comming: 'Coming'
  },

  information: {
    title: 'Welcome to the new Dashboard Beta!',
    text: `We're excited to show you our new and refreshed design.`,
    btn: 'Go to the old dashboard'
  },

  in_progress: 'In progress',
  open_mix: 'Questionnaire',
  extra_questions: 'Extra questions',
  submitted_mix: 'Uploading documents',
  checking_documents: 'Checking documents',
  legal_support_checking_documents: 'Checking documents',
  uploading_fca: 'Approval of FCA',
  scheduling_meeting: 'Identity verification',
  executing_deed: 'Final step',
  extracting_kvk: 'Generating KVK number',
  on_hold: 'On hold',
  waiting_payment: 'Waiting for payment',
  finalized: 'Finalized',
  canceled: 'Cancelled',
  submitted: 'Submitted',
  step: 'Step',
  paid: 'Paid',
  add_new_service: 'Add new Service'
};
