


































































































































































import { BaseModal } from '.';
import { defineComponent, ref, computed } from '@vue/composition-api';
import Vue from 'vue';
import { APrimaryBtn, ASecondaryBtn } from '../../../../base/app/buttons';
enum TabKeys {
  NEW = 'new',
  USED = 'used'
}
const CHECK_ICON_WIDTH = 32;
const HEIGHT = 200;
const WIDTH = 474;
const MOBILE_WIDTH = 300;
const REMOVE_ICON_WIDTH = 20;
const SELECTED_ICON = '/dashboard/icons/selected_point.png';
const UNSELECTED_ICON = '/dashboard/icons/unselected_point.png';

const i18nContent = {
  title: 'e_signature.sign_document.pad.title',
  description: 'e_signature.sign_document.pad.description',
  draw_new_label: 'e_signature.sign_document.pad.draw_new_label',
  draw_new_action: 'e_signature.sign_document.pad.draw_new_action',
  tabs: 'e_signature.sign_document.pad.tabs',
  cta: {
    clear: 'e_signature.sign_document.pad.cta.clear',
    save: 'e_signature.sign_document.pad.cta.save',
    cancel: 'e_signature.ctas.cancel',
    remove: 'e_signature.ctas.remove'
  },
  remove_dialog: {
    title: 'e_signature.sign_document.pad.remove_title',
    message: 'e_signature.sign_document.pad.remove_message'
  }
};

export default defineComponent({
  name: 'SignModal',
  components: { BaseModal, APrimaryBtn, ASecondaryBtn },
  props: {
    value: {
      type: Boolean,
      require: true
    },
    usedSignatures: {
      type: Array,
      require: true
    }
  },
  setup(props, { emit }) {
    const tab = ref<TabKeys>(TabKeys.NEW);

    const deletedSignedSelected = ref(-1);
    const showRemoveDialog = ref(false);
    const RemoveCtas = [
      {
        label: i18nContent.cta.cancel,
        textColor: 'default',
        class: 'e-sign-bordered-btn',
        color: 'white',
        emit: 'cancel'
      },
      {
        label: i18nContent.cta.remove,
        color: 'negative',
        emit: 'remove'
      }
    ];

    const selectedSignature = ref(-1);
    const drawOn = ref(false);

    const disableSign = computed(() => {
      return (
        (tab.value == TabKeys.USED && selectedSignature.value == -1) ||
        (tab.value == TabKeys.NEW && !drawOn.value)
      );
    });

    function onSign() {
      if (tab.value == 'used') {
        emit('sign', props.usedSignatures[selectedSignature.value]);
      } else emit('sign');
      drawOn.value = false;
    }

    function onPointerPad() {
      drawOn.value = true;
    }

    function onClear() {
      drawOn.value = false;
      emit('clear');
    }

    function onStart() {
      drawOn.value = false;
      tab.value = TabKeys.NEW;
      Vue.nextTick(() => {
        if (props.usedSignatures.length > 0) tab.value = TabKeys.USED;
      });
    }

    function onDeleteSignature(signatureIndex: number) {
      if (props.usedSignatures.length == 1) {
        tab.value = TabKeys.NEW;
      }
      if (selectedSignature.value == signatureIndex)
        selectedSignature.value = -1;
      if (selectedSignature.value > signatureIndex) selectedSignature.value--;
      emit('delete', props.usedSignatures[signatureIndex], signatureIndex);
      showRemoveDialog.value = false;
    }

    return {
      tab,
      drawOn,
      HEIGHT,
      WIDTH,
      REMOVE_ICON_WIDTH,
      CHECK_ICON_WIDTH,
      SELECTED_ICON,
      UNSELECTED_ICON,
      deletedSignedSelected,
      showRemoveDialog,
      RemoveCtas,
      i18nContent,
      TabKeys,
      disableSign,
      selectedSignature,
      onDeleteSignature,
      onSign,
      onClear,
      onStart,
      onPointerPad,
      MOBILE_WIDTH
    };
  }
});
