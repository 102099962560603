





































import { computed, defineComponent } from '@vue/composition-api';

import PlansList from '../../components/payment/plans/PlansList.vue';
import { useStore } from '../../composables';
import { usePlans } from '../../components/payment/plans/plans.hook';
import { MembershipService } from '@ligo/dashboard/customer/store';
import { HeroBanner } from '../../components/base/banners';
import { legalPlansPageInformation } from '../../components/utils/useCustomerPageInformation.hooks';
import BasePage from '../../components/base/BasePage.vue';

const PLATFORM_AWARD = '/dashboard/images/ligo_shield.svg';
const TAX_AWARD = '/dashboard/images/award_winning.png';

export default defineComponent({
  name: 'PackagesPage',
  components: { PlansList, HeroBanner, BasePage },
  setup() {
    const store = useStore();

    const {
      visiblePlans,
      currentPlan,
      activePlan,
      loading,
      showLegacyBanner
    } = usePlans();

    const { t, isReady, load } = legalPlansPageInformation;

    load();

    function onPay(plan: string) {
      activePlan.value = plan;

      const selectedPlan = visiblePlans.value.find(
        (plan) => plan.slug === activePlan.value
      );
      async function onPlanSelected() {
        const data = await (
          await MembershipService.changeMembership(selectedPlan.id)
        ).data;
        store.dispatch('layout/openPaymentModal', {
          paymentUuid: data.payment.uuid
        });
      }
      onPlanSelected();
    }

    const bannersAwards = computed(() => [
      {
        text: t('tax_award'),
        img: TAX_AWARD
      },
      {
        text: t('platform_award'),
        img: PLATFORM_AWARD
      }
    ]);

    return {
      visiblePlans,
      currentPlan,
      activePlan,
      onPay,
      isReady,
      t,
      showLegacyBanner,
      loading,
      bannersAwards
    };
  }
});
