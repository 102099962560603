import { Dictionary, nully } from '@ligo/shared/utils';
import {
  SimpleField,
  SimpleFieldReducer,
  SimpleFieldType
} from '../models/simpleForm.models';

export const DEFAULT_BY_TYPE: {
  [key in SimpleFieldType]: any;
} = {
  text: '',
  textarea: '',
  number: 0,
  select: null,
  language: 'nl',
  'phone-number': ''
};

const SimpleBasicReducer: SimpleFieldReducer = (formValue, fieldConfig) => {
  return {
    [fieldConfig.key]: formValue[fieldConfig.key]
  };
};

export const createInitialFormValue = (
  formValue: Dictionary,
  fields: SimpleField[]
) => {
  return fields.reduce((prev, current) => {
    const value = current.defaultValue
      ? typeof current.defaultValue === 'function'
        ? current.defaultValue(formValue)
        : current.defaultValue
      : typeof formValue[current.key] == 'boolean'
      ? formValue[current.key]
      : formValue[current.key] || DEFAULT_BY_TYPE[current.key];
    return {
      ...prev,
      [current.key]: value
    };
  }, formValue);
};

export const simpleReducerForm = (
  formValue: Dictionary,
  fields: SimpleField[]
) => {
  return fields.reduce((prev, current) => {
    const value = current.reducer
      ? current.reducer(formValue, current)
      : SimpleBasicReducer(formValue, current);
    if (nully(value)) {
      return {
        ...prev
      };
    }
    return {
      ...prev,
      ...value
    };
  }, {});
};

export const useGraph = () => {
  const G: Dictionary<string[]> = {};

  const addEdge = (a: string, b: string) => {
    if (!G[a]) G[a] = [];
    G[a].push(b);
  };

  const isAcyclic = () => {
    const used: Dictionary<boolean> = {};

    const dfs_acyclic = (root: string) => {
      used[root] = true;
      if (G[root])
        G[root].forEach((vnode) => {
          if (used[vnode]) return true;
          if (dfs_acyclic(vnode)) return true;
        });
      return false;
    };

    Object.keys(G).forEach((node) => {
      if (!used[node] && dfs_acyclic(node)) return true;
    });

    return false;
  };

  const dfsHandler = (root: string, handlerFunc: (key: string) => void) => {
    const used: Dictionary<boolean> = {};

    const dfs = (root: string) => {
      used[root] = true;
      if (G[root])
        G[root].forEach((vnode) => {
          if (!used[vnode]) {
            handlerFunc(vnode);
            dfs(vnode);
          }
        });
    };

    dfs(root);
  };

  return {
    addEdge,
    isAcyclic,
    dfsHandler
  };
};

export const getLabelForStringOption = (
  formKey: string,
  key: string,
  value: any,
  t: (key: string) => string
) => t(`${formKey}.fields.${key}.options.${value.id}`);

export const getLabelOptionalForStringOption = (
  formKey: string,
  key: string,
  value: any,
  t: (key: string) => string,
  te: (key: string) => boolean
) => {
  const tkey = `${formKey}.fields.${key}.options.${value.id}`;
  if (te(tkey)) return t(tkey);
  return value.name;
};

export const transformStringOptions = (options: string[]) => {
  return options.map((value) => ({
    id: value,
    name: value
  }));
};

export const transformStringCheckboxOptions = (
  options: string[],
  formKey: string,
  key: string,
  t: (key: string) => string
) => {
  return options.map((value) => ({
    id: value,
    name: t(`${formKey}.fields.${key}.options.${value}.label`),
    subtitle: t(`${formKey}.fields.${key}.options.${value}.subtitle`)
  }));
};
