export default {
  linkedin_register: 'Ga verder met LinkedIn',
  google_register: 'Ga verder met google',
  email_register: 'Ga verder met email',
  linkedin: 'Log in met LinkedIn',
  google: 'Log in met Google',
  email: 'Log in met email',
  login: 'Inloggen',
  logout: 'Uitloggen',
  signup: 'Inschrijven',
  register: 'Aanmelden',
  language_list: {
    en: 'Engels',
    nl: 'Nederlands'
  },
  language_list_mini: {
    en: 'EN',
    nl: 'NL'
  },
  agree_sign_in: {
    prefix: 'Door je aan te melden, ga je akkoord met het',
    privacy: 'privacybeleid',
    separator: 'en de',
    terms: 'algemene voorwaarden',
    suffix: 'van'
  },
  reset: {
    instructions: 'Stuur reset instructies',
    hint:
      'Zorg ervoor dat jouw e-mailadres correct en we sturen je een e-mail om je wachtwoord opnieuw in te stellen.',
    explanation: {
      first:
        'Als je e-mailadres is gekoppeld aan een bestaand account, ontvang je reset-instructies op',
      second:
        'Als je geen e-mail hebt ontvangen, controleer dan je spammap en zorg ervoor dat het e-mailadres correct is ingevoerd.'
    },
    finish: 'Terug naar inloggen',
    start: 'Wachtwoord vergeten?',
    here: 'hier',
    end: 'om u een e-mail te sturen om het te resetten.',
    title: 'Stel je wachtwoord opnieuw in',
    send: 'Ontvang een reset-link',
    nevermind: 'Laat maar!',
    back: 'Breng me terug naar het inlogscherm',
    email: 'Voer het e-mailadres in dat u gebruikt om in te loggen',
    error:
      'Er is een fout opgetreden bij het opnieuw instellen van uw wachtwoord. Controleer of u een Ligo-account heeft'
  },
  change_password: {
    new_password: 'Nieuw paswoord',
    new_password_placeholder: 'Typ uw nieuwe wachtwoord',
    confirm_password: 'Bevestig wachtwoord',
    confirm_password_placeholder: 'Bevestig uw nieuwe wachtwoord',
    change: 'Wachtwoord wijzigen',
    confirm_error: 'Wachtwoorden moeten overeenkomen',
    error:
      'Er is een fout opgetreden bij het instellen van uw nieuwe wachtwoord'
  },
  check_mail: {
    check: 'Controleer je',
    email: 'email'
  },
  mail_error: 'We kunnen deze e-mail niet vinden',
  confirmation_header: {
    title: 'Bevestigingsmail opnieuw versturen',
    subtitle:
      'Bevestig je e-mailadres voordat je verder gaat. Dit doe je door op de link the klikken in de email die je hebt ontvangen. Dit is noodzakelijk om de toegang tot je account te behouden'
  },
  errorCodeMsg: {
    not_confirmed:
      'Authenticatiefout - Als je een account hebt, is deze wellicht nog niet bevestigd. Controleer je mailbox voor een bevestigingsmail en klik op de activatielink. Daarna kan je inloggen. \n',
    different_provider: 'Je e-mail is al bij ons bekend. Je kan inloggen met'
  },
  errorCodeLink: {
    not_confirmed: 'Stuur bevestigingsemail opnieuw',
    different_provider: {
      google_oauth2: 'Google',
      linkedin: 'LinkedIn'
    }
  },
  registerFields: {
    email: { placeholder: 'E-mailadres', label: 'E-mailadres' },
    firstname: { placeholder: 'Voornaam', label: 'Voornaam' },
    lastname: { placeholder: 'Achternaam', label: 'Achternaam' },
    password: { placeholder: 'Wachtwoord', label: 'Wachtwoord' }
  },
  next: 'Verder',
  ligo: {
    next: 'Verder',
    confirmation_email_sent: 'Confirmation Email Sent',
    register_header: {
      title: 'Maak een account',
      subtitle:
        'De eenvoudigste manier om jouw BV in Nederland op te oprichten. 100% online en voor de beste prijs.'
    },
    to_signin: 'Heb je al een account?',
    social_register: 'Of registreer via social media:',
    terms_of_service: {
      prefix: 'Ik ga akkoord met de',
      terms: 'Algemene voorwaarden',
      suffix: 'van Ligo BV'
    },
    keep_posted: 'Houd me op de hoogte van juridische tips en nieuwe features',
    register_checkmarks:
      'In order to proceed, you first need to accept the General Terms and Conditions.',
    login: 'Log in op je Ligo account',
    dont_account: ' Heb je geen account?'
  },
  magnexus: {
    next: 'Verder',
    confirmation_email_sent: 'Confirmation Email Sent',
    register_header: {
      title: 'Magnexus gratis ontdekken',
      subtitle:
        'Maak, onderteken en beheer al uw juridische documenten op één plek.'
    },
    to_signin: 'Heb je al een account?',
    social_register: 'Of registreer via social media:',
    terms_of_service: {
      prefix: 'Ik ga akkoord met de',
      terms: 'Algemene voorwaarden',
      suffix: 'van Ligo BV'
    },
    keep_posted: 'Houd me op de hoogte van juridische tips en nieuwe features',
    register_checkmarks:
      'In order to proceed, you first need to accept the General Terms and Conditions.',
    login: 'Log in op je Magnexus account',
    dont_account: ' Heb je geen account?',
    send_confirmation: 'Bevestigingsmail verzenden'
  }
};
