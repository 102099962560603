


















import { PropType, defineComponent } from '@vue/composition-api';

import StepHeader from '../payment/modal-components/StepHeader.vue';
import {
  SimpleStepModel,
  SimpleStepperModel
} from '../base/SimpleForm/models/simpleStepper.model';

const ICON = '/dashboard/images/wendy.svg';

export default defineComponent({
  name: 'OnboardingStep',
  components: { StepHeader },
  props: {
    stepperConfig: {
      type: Object as PropType<SimpleStepperModel>,
      required: true
    },
    step: {
      type: Object as PropType<SimpleStepModel>,
      required: true
    }
  },
  setup() {
    return { ICON };
  }
});
