







import { Contract } from '@ligo/dashboard/customer/store';
import ReminderList from './ReminderList.vue';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'WrappReminder',
  components: { ReminderList },
  props: {
    contract: {
      type: Object as PropType<Contract>,
      required: true
    }
  }
});
