











import { defineComponent } from '@vue/composition-api';
import AlertModal from '../base/AlertModal.vue';
import { AlertModalBtn } from '../../../models';

export default defineComponent({
  name: 'DiscardModal',
  components: { AlertModal },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const title = 'documents.editor.discardModal.title';
    const subtitle = 'documents.editor.discardModal.subtitle';

    const btns: AlertModalBtn[] = [
      {
        label: 'documents.editor.discardModal.buttonContinue',
        emit: 'close'
      },
      {
        label: 'documents.editor.discardModal.buttonDiscard',
        emit: 'discard-confirm',
        class: 'q-ml-sm'
      }
    ];

    return { btns, title, subtitle };
  }
});
