


























import { defineComponent, PropType } from '@vue/composition-api';
import { ContractType } from '@ligo/dashboard/customer/store';
import ScrollArea from '../../global/ScrollArea.vue';

export default defineComponent({
  name: 'LibraryList',
  components: {
    ScrollArea
  },
  props: {
    templates: Array as PropType<ContractType[]>
  }
});
