

















































import { defineComponent } from '@vue/composition-api';
import { APrimaryBtn } from 'libs/dashboard/customer/components/src/lib/components/base/app/buttons';

export default defineComponent({
  name: 'DashboardDialog',
  components: {
    APrimaryBtn
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    message: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    },
    actionLabel: {
      type: String,
      default: 'next'
    },
    actionDisable: {
      type: Boolean,
      default: false
    },
    actionLoading: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '400px'
    },
    cardSubtitle: {
      type: String,
      default: ''
    },
    sectionPadding: {
      type: String,
      default: ''
    },
    customClass: {
      type: String,
      default: ''
    },
    rightBtnPosition: {
      type: Boolean,
      default: false
    },
    showActions: {
      type: Boolean,
      default: true
    }
  },
  setup(_, { emit }) {
    function onCloseDialog() {
      emit('input', false);
    }

    return { onCloseDialog };
  }
});
