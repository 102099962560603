












import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'BoardHeader',
  props: {
    title: String,
    subtitle: String
  }
});
