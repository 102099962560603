import { ExtraServiceType } from '../models/extra-service';
import { ApiService } from '@ligo/shared/utils';
import { RESOURCES } from '../resources';

export const ExtraServiceTypesService = {
  async getServiceTypes(categories = []) {
    return ApiService.get<Array<ExtraServiceType>>(
      RESOURCES.EXTRA_SERVICE_TYPES,
      { categories }
    );
  },
  async getServiceType(slug: string) {
    return ApiService.get<ExtraServiceType>(
      `${RESOURCES.EXTRA_SERVICE_TYPES}/${slug}`
    );
  }
};
