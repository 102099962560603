import { ref } from '@vue/composition-api';
import { NotaryProduct } from '@ligo/dashboard/store';
import { useI18n } from '../../../composables';

const SCHEDULE_MEETING_NOTARY_KEY = 'meeting_step';
const DOCUMENT_BANNER_KEY = 'document_banner';
const NOTARY_BANNER_KEY = 'notary_banner';

const AVAILABLE_LOCALES = {
  'en-us': 'english_information',
  nl: 'dutch_information'
};

export function extractMeetingStepCopies(np: NotaryProduct) {
  const i18n = useI18n();

  /* Default values section */

  const bannerTitle = ref();
  const documentBannerTitle = ref('meeting.read_and_print.banner.text');

  if (np.notary.allow_video_calls)
    bannerTitle.value = 'meeting.banner.text_send_email_and_call_video';
  else bannerTitle.value = 'meeting.banner.text_send_email_and_go_notary';

  /* End default values section */

  const scheduleMeetingInfo =
    np.notary[AVAILABLE_LOCALES[i18n.locale]][SCHEDULE_MEETING_NOTARY_KEY];

  if (scheduleMeetingInfo && scheduleMeetingInfo[NOTARY_BANNER_KEY])
    bannerTitle.value = scheduleMeetingInfo[NOTARY_BANNER_KEY];
  if (scheduleMeetingInfo && scheduleMeetingInfo[DOCUMENT_BANNER_KEY])
    documentBannerTitle.value = scheduleMeetingInfo[DOCUMENT_BANNER_KEY];

  return {
    bannerTitle,
    documentBannerTitle
  };
}
