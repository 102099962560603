

















import { defineComponent, computed } from '@vue/composition-api';
import BasePage from '../../components/base/BasePage.vue';
import TaxServicesHandler from '../../components/services/TaxServices.handler.vue';
import { TaxServicesPageInformation } from '../../components/utils/useCustomerPageInformation.hooks';
import { HeroBanner } from '../../components/base/banners';

const LIGO_SHIELD = '/dashboard/images/ligo_shield.svg';
const PLATFORM_AWARD = '/dashboard/images/award_winning.png';

export default defineComponent({
  name: 'TaxService',
  components: { BasePage, TaxServicesHandler, HeroBanner },
  setup() {
    const { t, isReady, load } = TaxServicesPageInformation;
    load();

    const bannersAwards = computed(() => [
      {
        text: t('tax_award'),
        img: LIGO_SHIELD
      },
      {
        text: t('platform_award'),
        img: PLATFORM_AWARD
      }
    ]);
    return { bannersAwards, t, isReady };
  }
});
