<template>
  <q-card :dark="dark">
    <q-list padding class="scroll">
      <q-scroll-area
        :thumb-style="thumbStyle"
        :style="`height: ${height}px;`"
        class="min-width-250"
        :bar-style="barStyle"
      >
        <q-infinite-scroll class="q-pt-sm" :offset="45" @load="onLoad">
          <div v-for="(item, i) in items" :key="i">
            <slot name="item" :item="item" :index="i">
              <q-item class="q-pa-lg">
                <q-item-section>
                  <q-item-label class="font-16 w-600">
                    {{ item.title }}
                  </q-item-label>
                  <q-item-label class="font-14" caption>
                    {{ item.label }}
                  </q-item-label>
                </q-item-section>
              </q-item>
            </slot>
          </div>
          <template v-if="!done || loading" #loading>
            <div class="row justify-center q-my-md">
              <q-spinner-dots color="primary" class="font-30" />
            </div>
          </template>
          <div class="col self-center">
            <slot v-if="!items.length && done" name="empty">
              <div />
            </slot>
          </div>
        </q-infinite-scroll>
      </q-scroll-area>
    </q-list>
  </q-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'InfiniteScrollList',
  props: {
    items: {
      type: Array
    },
    done: {
      type: Boolean
    },
    loading: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 125
    },
    thumbStyle: {
      type: Object,
      default: () => ({
        right: '4px',
        borderRadius: '5px',
        backgroundColor: '#C1C1C1',
        width: '5px',
        opacity: 0.75
      })
    },
    barStyle: {
      type: Object,
      default: () => ({
        right: '2px',
        borderRadius: '9px',
        backgroundColor: '#F1F1F1',
        width: '9px',
        opacity: 0.2
      })
    }
  },
  setup(props, { emit }) {
    function onLoad(index, done) {
      if (!props.done) emit('scroll', done);
    }
    return { onLoad };
  }
});
</script>
