






























import { computed, defineComponent } from '@vue/composition-api';
import { APrimaryBtn } from 'libs/dashboard/customer/components/src/lib/components/base/app/buttons';

export default defineComponent({
  name: 'ReadonlyAmendmentDialog',
  components: {
    APrimaryBtn
  },
  props: {
    value: {
      type: Boolean
    },
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    okBtnText: {
      type: String,
      default: 'ok'
    }
  },
  setup(props) {
    const splitText = computed(() => {
      if (props.message) {
        return props.message.split('<br>');
      }
      return ['', ''];
    });
    return { splitText };
  }
});
