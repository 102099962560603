







import { defineComponent } from '@vue/composition-api';
import UserSettingsHandler from '../../components/account/UserSettings.handler.vue';
import BasePage from '../../components/base/BasePage.vue';
import UserPageSelector from './UserPageSelector.vue';

export default defineComponent({
  name: 'Settings',
  components: { UserSettingsHandler, BasePage, UserPageSelector }
});
