

















































import { Event } from '@ligo/dashboard/customer/store';
import { defineComponent, PropType, ref } from '@vue/composition-api';
import moment from 'moment';

const CALENDAR_ICON = 'img:/dashboard/icons/calendar.svg';
const CALENDAR_PASSED_ICON = 'img:/dashboard/icons/calendar-passed.svg';
const USER_ICON = 'img:/dashboard/icons/user_filled.svg';
const DOC_ICON = 'img:/dashboard/icons/doc_filled.svg';

export default defineComponent({
  name: 'Reminder',
  props: {
    reminder: {
      type: Object as PropType<Event>,
      required: true
    },
    useEventable: Boolean,
    disableState: Boolean
  },
  setup(props) {
    const DEFAULT_LINK_COLOR = 'subdued';
    const HOVER_LINK_COLOR = 'np-secondary-1000';
    const linkColor = ref(DEFAULT_LINK_COLOR);
    function getEmployeeName() {
      return `${props.reminder.employees[0].firstname} ${props.reminder.employees[0].lastname}`;
    }

    function passedReminder() {
      const reminderDate = new Date(props.reminder.event_date);
      const currentDate = new Date();
      if (reminderDate.getTime() < currentDate.getTime()) return true;
      return false;
    }

    function formatDate(date: string) {
      return moment(date).format('DD-MM-YYYY');
    }

    return {
      getEmployeeName,
      passedReminder,
      formatDate,
      linkColor,
      DEFAULT_LINK_COLOR,
      HOVER_LINK_COLOR,
      CALENDAR_ICON,
      CALENDAR_PASSED_ICON,
      USER_ICON,
      DOC_ICON
    };
  }
});
