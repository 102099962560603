












import { defineComponent, computed } from '@vue/composition-api';
import { useStore } from '../../../../../composables';
import { BaseModal } from './';

const i18nContent = {
  title: 'e_signature.sign_document.modals.confirmation.title',
  text: 'e_signature.sign_document.modals.confirmation.text'
};

const ctas = [
  {
    label: 'e_signature.sign_document.modals.confirmation.cancel',
    textColor: 'default',
    class: 'e-sign-bordered-btn',
    color: 'white',
    emit: 'cancel'
  },
  {
    label: 'e_signature.sign_document.modals.confirmation.ok',
    color: 'primary',
    emit: 'ok'
  }
];

export default defineComponent({
  name: 'ConfirmationModal',
  components: { BaseModal },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    signerEmail: String
  },
  setup() {
    const store = useStore();
    const username = computed(() => store.getters['authentication/userEmail']);
    return { ctas, i18nContent, username };
  }
});
