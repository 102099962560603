export default {
  wait_info:
    'De notaris is momenteel bezig met het ondertekenen van je akte en de inschrijving bij de KvK.',
  title: 'Gefeliciteerd!',
  title2: 'Gefeliciteerd',
  subtitle:
    'Nu je bedrijf officieel is opgericht, zijn er belangrijke juridische zaken die je moet regelen. Het ondernemersavontuur begint, en wij zorgen ervoor dat je voldoet aan de wettelijke regels en eisen van de Belastingdienst.',
  rate: 'Beoordeel je ervaring',
  documents: 'Oprichtingsdocumenten van {name}',
  dashboard: 'Ga naar dashboard',
  finilized: 'Afgerond',
  expected: 'In afwachting',
  final_deed: 'Akte van oprichting',
  kvk: 'Uittreksel van de Kamer van Koophandel',
  document_folder: 'Documenten door jou geüpload',
  upload_document: '{total} documents uploaded',
  steps: {
    icorporate: 'Richt mijn BV op',
    open_bank: 'Open een bankrekening',
    obligations: 'Aandelen volstorten',
    more: '...en meer'
  },
  todo: "Bekijk mijn legal To Do's",
  pending: 'Deze taak is nog niet gedaan'
};
