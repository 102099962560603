










import { defineComponent } from '@vue/composition-api';
import SigningDocument from '../../../components/documents/signing-document/SigningDocument.handler.vue';

export default defineComponent({
  name: 'SigningDocumentPage',
  components: { SigningDocument },
  props: {
    contractUuid: {
      type: String,
      required: true
    },
    signerUuid: {
      type: String,
      required: true
    },
    sessionUuid: {
      type: String,
      required: true
    }
  }
});
