









































import { defineComponent, PropType } from '@vue/composition-api';
import { AlertModalBtn } from '../../../models';
import { ABtn, ASecondaryBtn } from '../../base/app/buttons';

export default defineComponent({
  name: 'AlertModal',
  components: { ABtn, ASecondaryBtn },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: String,
    subtitle: String,
    buttons: Array as PropType<AlertModalBtn[]>,
    maxWidth: {
      type: String,
      default: '60vw'
    }
  }
});
