













import { defineComponent, ref } from '@vue/composition-api';
import { BaseModal } from './';
import { BaseDate } from '../base';
import { useModalCtas } from './base';
import moment from 'moment';

export default defineComponent({
  name: 'FillDateModal',
  components: { BaseModal, BaseDate },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: String
  },
  setup() {
    const date = ref('');

    const { saveBtn, ctasList: ctas } = useModalCtas();
    saveBtn.value.disabled = true;

    function changeDate(newValue: string) {
      date.value = newValue;
      saveBtn.value.disabled = !Boolean(newValue);
    }

    return { date, ctas, changeDate, moment };
  }
});
