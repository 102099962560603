import { computed, Ref } from '@vue/composition-api';

export function getMaxHeightFromCurrentTop(el: Ref<HTMLElement>) {
  const height = computed(() => {
    if (el.value) {
      const dimensions = el.value.getBoundingClientRect();
      const start = dimensions.top % window.innerHeight;
      return Math.abs(window.innerHeight - start);
    }
    return window.innerHeight;
  });
  return { height };
}
