
































































import { computed, defineComponent, PropType } from '@vue/composition-api';
import DocumentBanner from '../base/DocumentBanner.vue';
import { PreviewDocumentMenuItem } from '../../../models';
import ContractMenu from './ContractMenu.vue';
import { Contract } from '@ligo/dashboard/customer/store';
import { UploadVersionModal } from './modals';
import { useContractPropsForHeaders } from './contract-banner.hook';
import CustomerLangSelect from '../../../layouts/CustomerLangSelect.vue';
import { ASecondaryBtn } from '../../base/app/buttons';

const EDIT_ICON = 'img:/dashboard/icons/pen-to-square.svg';
const EDIT_ICON_DISABLED = 'img:/dashboard/icons/pen-to-square-disabled.svg';

const UPLOAD_ICON = 'img:/dashboard/icons/upload-new.svg';
const UPLOAD_ICON_DISABLED = 'img:/dashboard/icons/upload-new-disabled.svg';

const MENU_ICON = 'img:/dashboard/icons/menu_dots.svg';

export default defineComponent({
  name: 'ContractBanner',
  components: {
    DocumentBanner,
    ContractMenu,
    UploadVersionModal,
    CustomerLangSelect,
    ASecondaryBtn
  },
  props: {
    title: String,
    subtitle: String,
    loading: Boolean,
    menuItems: Array as PropType<PreviewDocumentMenuItem[]>,
    contract: {
      type: Object as PropType<Contract>
    }
  },
  setup(props) {
    const {
      onEdit,
      showEdit,
      showUpload,
      uploadTooltip,
      editTooltip
    } = useContractPropsForHeaders(props.contract);

    const previewEditDisabled = computed(() => {
      return (
        !showEdit ||
        !props.contract.last_revision.word_document ||
        props.loading
      );
    });

    const previewUploadDisabled = computed(() => {
      return !showEdit || props.loading;
    });

    return {
      EDIT_ICON,
      MENU_ICON,
      showEdit,
      onEdit,
      showUpload,
      UPLOAD_ICON,
      uploadTooltip,
      editTooltip,
      UPLOAD_ICON_DISABLED,
      EDIT_ICON_DISABLED,
      previewEditDisabled,
      previewUploadDisabled
    };
  }
});
