import { ref, computed, Ref } from '@vue/composition-api';
import { useAsyncResult } from '@ligo/shared/utils';
import {
  ExtraServiceService,
  ExtraServiceTypesService
} from '@ligo/dashboard/customer/store';
import { useI18n } from '../../composables';
import {
  ExtraService,
  ExtraServiceType
} from '../../../../../store/src/lib/models/extra-service';
import {
  SQTableColumn,
  SQTableRow,
  SQTablePagination
} from '../base/tables/s-q-table.hook';
import moment from 'moment';
import { Kind, useServiceTypes } from '../service-types/service-types.hook';

export const statusBadges = {
  authorized: {
    color: 'green-light',
    textColor: 'green',
    text: 'tax.table.status.paid',
    tag: 'authorized'
  },
  open: {
    color: 'yellow-light',
    textColor: 'yellow',
    text: 'tax.table.status.open',
    tag: 'open'
  }
};

export function useTaxTable() {
  const i18n = useI18n();
  const columns: SQTableColumn[] = [
    {
      name: 'service',
      label: i18n.t('tax.table.columns.service').toString(),
      align: 'left',
      field: 'name'
    },
    {
      name: 'status',
      label: i18n.t('tax.table.columns.status').toString(),
      align: 'left',
      field: 'status'
    },
    {
      name: 'year',
      label: i18n.t('tax.table.columns.year').toString(),
      align: 'left',
      field: 'year'
    },
    {
      name: 'paid_on',
      label: i18n.t('tax.table.columns.paid_on').toString(),
      align: 'left',
      field: 'paid_at'
    }
  ];
  return { columns };
}

export function useTaxesServices() {
  const i18n = useI18n();
  const { mapServiceType } = useServiceTypes();

  function getServiceStatus(item: ExtraService) {
    const badge = item.paid_at ? statusBadges.authorized : statusBadges.open;
    return {
      value: i18n.t(badge.text).toString(),
      textColor: badge.textColor,
      color: badge.color,
      radius: 10,
      tag: badge.tag
    };
  }

  const pagination: Ref<SQTablePagination> = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 5,
    rowsNumber: 10
  });

  const { state: types, loading: loadingTypes } = useAsyncResult(
    () => ExtraServiceTypesService.getServiceTypes([Kind.tax]),
    undefined,
    (item: ExtraServiceType): ExtraServiceType => mapServiceType(item)
  );

  const {
    state: services,
    loading: loadingServices,
    load: loadServices,
    total: servicesTotal
  } = useAsyncResult<ExtraService, SQTableRow>(
    () => ExtraServiceService.list([Kind.tax], pagination.value),
    undefined,
    (item: ExtraService): SQTableRow => {
      return {
        id: item.uuid,
        name: {
          value: item.name
        },
        status: getServiceStatus(item),
        paid_at: item.paid_at ? moment(item.paid_at).format('D MMM YYYY') : '-',
        year: moment(item.created_at).format('YYYY'),
        paymentUuid: item.payment.uuid
      };
    }
  );

  const tablePagination = computed(() => {
    return {
      ...pagination.value,
      rowsNumber: servicesTotal.value
    };
  });

  function search(_, page, rowsPerPage, sortBy, descending) {
    pagination.value.sortBy = sortBy;
    pagination.value.descending = descending;
    pagination.value.page = page;
    pagination.value.rowsPerPage = rowsPerPage;

    loadServices();
  }

  return {
    types,
    services,
    loadingTypes,
    loadingServices,
    tablePagination,
    statusBadges,
    search
  };
}
