export default {
  read: {
    title: 'Read documents',
    loading_docs_to_read:
      'We are generating your documents. This can take up to 1 minute.',
    info:
      'Please read the document requirements carefully and indicate that you have read them by checking the boxes. This also indicates that you agree with the content.Do you have any questions? Feel free to contact our team on 020 - 303 1043.',
    subtitle: 'Please read the following documents carefully',
    checkbox: 'I have read it',
    download: 'Download',
    info_omzetting_with_added_value:
      'You chose a so-called “geruisloze” or “ruisende” conversion of a sole proprietorship or partnership to a BV. Because of this the activities of the notary will take longer. On average, this can take up to 3 days extra.\
     <br><br> Please read the document requirements below carefully and indicate that you read them. This also indicates that you agree with the content. Do you have any questions? Feel free to contact our team on 020 - 303 1043.',
    info_when_high_capital:
      'With a high share capital, the notary has to perform more research. Therefore, take into account an average of 3 days extra. Note that a notary has a statutoty obligation to investigate the origin of the capital in case the share capital exceeds €10,000. The notary could charge extra costs. For more information, please contact us via 020-3031043. \
     <br><br> Please read the document requirements below carefully and indicate that you read them. This also indicates that you agree with the content. Do you have any questions? Feel free to contact our team on 020 - 303 1043.',
    info_omzetting_with_added_value_and_high_capital:
      'You chose a so-called “geruisloze” or “ruisende” conversion of a sole proprietorship or partnership to a BV. Because of this the activities of the notary will take longer. On average, this can take up to 3 days extra.\
    <br><br> With a high share capital, the notary has to perform more research. Therefore, take into account an average of 3 days extra. Note that a notary has a statutoty obligation to investigate the origin of the capital in case the share capital exceeds €10,000. The notary could charge extra costs. For more information, please contact us via 020-3031043\
    <br><br> Please read the document requirements below carefully and indicate that you read them. This also indicates that you agree with the content. Do you have any questions? Feel free to contact our team on 020 - 303 1043.'
  },
  upload: {
    title: 'Upload documents',
    label: 'Upload',
    spaar_info: `The notary who will incorporate your company is based in <strong>{notary_location}</strong>. During the process it is important to legalize some of your documents. You will have two different options for doing this: <br><br> 1. You can go to <strong>{notary_location}</strong> in person and sign your documents directly at the notary. <br><br> 2. You can bring your documents to a notary near you and have them legalized there. You can then send these documents to our notary by post. <br><br> Please contact our team for more information and step-by-step guidance on this.`,
    info: {
      all_docs_ok:
        'Please make sure the documents are legible and filled out correctly to avoid delays. Only use .docx and pdf documents that are no larger than 15 Mb. The notary will check the documents and may request additional information.',
      residential_address:
        'If you selected the residential address of one of the shareholders as the company address, then you can upload a blank page with the text ‘Settlement address is the same as residential address’ in the lease agreement section.'
    },
    foreign_info: {
      residential_address:
        'If you selected the residential address of one of the Dutch shareholders (if any) as the company address, then you can upload a blank page with the text ‘Settlement address is the same as residential address’ in the lease agreement section.',
      next_step:
        'In the next step, the notary will review your documents. Do you have any \
      questions? Send us a message via chat for immediate support.'
    },
    warning_foreign_info: {
      paragraph: `Some documents of the foreign shareholder(s) involved in the incorporation need to be legalised and/or apostilled. Please contact our team via the chat for immediate guidance and support. <br><br> Once approved by our notary, please send the original copy of these documents by post to the postal address of the notary, and upload the scans here in your dashboard`,
      question: 'Not familiar with legalisations or apostilles?',
      link: 'Please read this article.'
    },
    uploaded: 'Uploaded',
    change: 'Change',
    success: 'The document has been successfully uploaded',
    error: 'There has been an error while uploading the document'
  },
  send_notary: 'Send to the notary',
  cant_send_message: `This button will activate once all <br> required documents are uploaded`,
  busy_notary_banner:
    'At this moment, all notarial offices in The Netherlands are very busy, so it could take a bit longer to complete your case.',
  watch_video: 'Watch video',
  process_video: 'What is the process?'
};
