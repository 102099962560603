








































import { defineComponent, PropType, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'PaymentModalStepper',
  props: {
    title: {
      type: String,
      default: 'payment.stepper.header'
    },
    curStep: {
      type: String
    },
    steps: {
      type: Array as PropType<string[]>
    },
    hideSteps: {
      type: Boolean,
      default: false
    },
    isLastStep: {
      type: Boolean,
      default: false
    },
    cancelText: {
      type: String,
      default: 'payment.stepper.cancel'
    },
    usei18n: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const curStepIdx = computed(() =>
      props.steps.findIndex((s) => s == props.curStep)
    );
    return { curStepIdx };
  }
});
