

























import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'OnboardingHeaderStepper',
  props: {
    steps: {
      type: Number,
      required: true
    },
    current: {
      type: Number,
      required: true
    },
    size: {
      type: String,
      default: 'sm'
    }
  },
  setup(props) {
    function stepStatus(idx: number) {
      return props.current <= idx ? idx : '✓';
    }
    return { stepStatus };
  }
});
