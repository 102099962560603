









import { defineComponent, PropType } from '@vue/composition-api';
import ServiceTypeDetailsHandler from '../../components/service-types/ServiceTypeDetails.handler.vue';
import BasePage from '../../components/base/BasePage.vue';
import { Kind } from '../../components/service-types/service-types.hook';

export default defineComponent({
  name: 'ServiceTypeDetails',
  components: { BasePage, ServiceTypeDetailsHandler },
  props: {
    slug: {
      type: String,
      required: true
    },
    kind: {
      type: String as PropType<Kind>
    }
  }
});
