






















































import { NotaryProductDocument } from '@ligo/dashboard/store';

import { defineComponent, ref, PropType } from '@vue/composition-api';
import { onDownload } from './documents.hooks';
import UploadDialog from '../global/UploadDialog.vue';
import DocumentNameClickable from '../global/DocumentNameClickable.vue';

export default defineComponent({
  name: 'DocumentsFolder',
  components: { UploadDialog, DocumentNameClickable },
  props: {
    notaryProductId: {
      type: String
    },
    upload: {
      type: Boolean,
      default: true
    },
    itemsClass: {
      type: Array as PropType<Array<string>>,
      default: () => ['q-mx-lg']
    },
    items: {
      type: Array as PropType<Array<NotaryProductDocument>>,
      default: () => []
    }
  },
  setup() {
    const showUploadDialog = ref(false);

    return { onDownload, showUploadDialog };
  }
});
