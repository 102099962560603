export default {
  rows_per_page: 'Records per page',
  pagination: '{from}-{to} of {total}',
  no_data: 'No matching records found',
  date: {
    from: 'From {date}'
  },
  no_results: {
    title: 'No matching results',
    subtitle:
      'Try another search, or use search filters to find a file by status, last modified date and more'
  },
  loading: 'Loading results...'
};
