import { ContractService, EventService } from '@ligo/dashboard/customer/store';
import {
  Employee,
  ReminderObject,
  Event
} from '@ligo/dashboard/customer/store';
import { ref, Ref } from '@vue/composition-api';
import { useAfterActionNotification } from '../../components/notifications-hook';
import { useI18n } from '../../composables';
import moment from 'moment';
import {
  incrementYear,
  incrementMonth,
  incrementWeek
} from '@ligo/shared/utils';

export enum remindersTimeIntervals {
  week = 'week',
  month = 'month',
  quarter = 'quarter',
  year = 'year'
}

const timeAlgebra = {
  [remindersTimeIntervals.week]: incrementWeek,
  [remindersTimeIntervals.month]: incrementMonth,
  [remindersTimeIntervals.quarter]: (date: string) => incrementMonth(date, 3),
  [remindersTimeIntervals.year]: incrementYear
};

export function useReminderManager() {
  const employeeList: Ref<Employee[]> = ref([]);
  const reminderList = ref<Event[]>([]);
  const completeReminderList = ref<Event[]>([]);
  const loadingReminders = ref(true);

  const i18n = useI18n();
  const ERROR_ICON = 'warning';
  const POSITIVE_NOTIFICATION = {
    color: 'dark',
    position: 'top',
    timeout: 2000
  };
  const NEGATIVE_NOTIFICATION = {
    color: 'negative',
    position: 'top',
    timeout: 2000
  };

  function loadReminders(contractUuid: string) {
    loadingReminders.value = true;
    ContractService.reminders(contractUuid).then((response) => {
      loadingReminders.value = false;
      reminderList.value = [];
      completeReminderList.value = [];
      response.data.forEach((event) => {
        if (event.completed_at) {
          completeReminderList.value?.push(event);
        } else {
          reminderList.value.push(event);
        }
      });
    });
  }

  function createReminder(contractUuid: string, reminder: ReminderObject) {
    ContractService.createReminder(contractUuid, reminder)
      .then(() => {
        useAfterActionNotification(
          i18n
            .t('contract.reminders.modals.success_create_reminder')
            .toString(),
          POSITIVE_NOTIFICATION
        );
        loadReminders(contractUuid);
      })
      .catch((error) => {
        useAfterActionNotification(
          i18n.t('contract.reminders.modals.error_create_reminder').toString(),
          NEGATIVE_NOTIFICATION,
          ERROR_ICON
        );
        console.log(error);
      });
  }

  function createUnlinkedReminder(
    reminder: ReminderObject,
    callback?: () => void
  ) {
    EventService.create(reminder)
      .then(() => {
        useAfterActionNotification(
          i18n
            .t('contract.reminders.modals.success_create_reminder')
            .toString(),
          POSITIVE_NOTIFICATION
        );
        if (callback) callback();
      })
      .catch((error) => {
        useAfterActionNotification(
          i18n.t('contract.reminders.modals.error_create_reminder').toString(),
          NEGATIVE_NOTIFICATION,
          ERROR_ICON
        );
        console.log(error);
      });
  }

  function cloneReminder(
    reminderId: number,
    remindMeOn: string,
    eventDate: string,
    callback?: () => void
  ) {
    EventService.clone(reminderId, remindMeOn, eventDate)
      .then(() => {
        useAfterActionNotification(
          i18n
            .t('contract.reminders.modals.success_create_reminder')
            .toString(),
          POSITIVE_NOTIFICATION
        );
        if (callback) callback();
      })
      .catch((error) => {
        useAfterActionNotification(
          i18n.t('contract.reminders.modals.error_create_reminder').toString(),
          NEGATIVE_NOTIFICATION,
          ERROR_ICON
        );
        console.log(error);
      });
  }

  function remindMeOnCustomTime(
    reminder: ReminderObject,
    opt: remindersTimeIntervals,
    callback?: () => void
  ): void {
    if (reminder.remind_me_on)
      reminder.remind_me_on = moment(
        timeAlgebra[opt](reminder.remind_me_on)
      ).format('YYYY-MM-DD');
    if (reminder.event_date)
      reminder.event_date = moment(
        timeAlgebra[opt](reminder.event_date)
      ).format('YYYY-MM-DD');
    cloneReminder(
      reminder.id,
      reminder.remind_me_on,
      reminder.event_date,
      callback
    );
  }

  function eventUpdate(reminder: ReminderObject, callback?: () => void) {
    EventService.update(reminder)
      .then(() => {
        useAfterActionNotification(
          i18n
            .t('contract.reminders.modals.success_update_reminder')
            .toString(),
          POSITIVE_NOTIFICATION
        );
        if (callback) callback();
      })
      .catch((error) => {
        useAfterActionNotification(
          i18n.t('contract.reminders.modals.error_update_reminder').toString(),
          NEGATIVE_NOTIFICATION,
          ERROR_ICON
        );
        console.log(error);
      });
  }

  function eventComplete(reminder_id: number, callback?: () => void) {
    EventService.toggleComplete(reminder_id)
      .then(() => {
        useAfterActionNotification(
          i18n
            .t('contract.reminders.modals.success_update_reminder')
            .toString(),
          POSITIVE_NOTIFICATION
        );
        if (callback) callback();
      })
      .catch((error: any) => {
        useAfterActionNotification(
          i18n.t('contract.reminders.modals.error_update_reminder').toString(),
          NEGATIVE_NOTIFICATION,
          ERROR_ICON
        );
        console.log(error);
      });
  }

  function eventDelete(reminder_id: number, callback?: () => void) {
    EventService.delete(reminder_id)
      .then(() => {
        useAfterActionNotification(
          i18n
            .t('contract.reminders.modals.success_delete_reminder')
            .toString(),
          POSITIVE_NOTIFICATION
        );
        if (callback) callback();
      })
      .catch((error) => {
        useAfterActionNotification(
          i18n.t('contract.reminders.modals.error_delete_reminder').toString(),
          NEGATIVE_NOTIFICATION,
          ERROR_ICON
        );
        console.log(error);
      });
  }

  return {
    employeeList,
    reminderList,
    completeReminderList,
    loadingReminders,
    remindMeOnCustomTime,
    eventComplete,
    eventDelete,
    eventUpdate,
    createUnlinkedReminder,
    loadReminders,
    createReminder
  };
}
