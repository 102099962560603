export default {
  rows_per_page: 'Resultaten per pagina',
  pagination: '{from}-{to} van {total}',
  no_data: 'Geen resultaten gevonden',
  date: {
    from: 'Vanaf {date}'
  },
  no_results: {
    title: 'Geen resultaten gevonden',
    subtitle:
      'Probeer een andere naam of verbeter je zoekopdracht door te filteren op status, laatst gewijzigd of andere opties'
  },
  loading: 'Bezig met laden...'
};
