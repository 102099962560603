





















































































import { defineComponent, ref, PropType } from '@vue/composition-api';
import { useContractDetail } from '../../components/documents/contract-detail.hook';
import ContractPaymentMessage from './ContractPaymentMessage.vue';
import {
  ContractBanner,
  ContractImage
} from '../../components/documents/contract-preview';
import { useContractMenu } from '../..//components/documents/contract-preview/contract-menu.hook';
import ContractDrawer from '../../components/documents/contract-preview/ContractDrawer.vue';
import {
  EditModal,
  DeleteModal,
  RevokeSignModal
} from '../../components/documents/contract-preview/modals';
import { useRouter, useI18n, useStore } from '../../composables';
import { PaymentQuery } from '@ligo/shared/models';
import MakeCopyModal from '../../components/documents/contract-preview/modals/MakeCopyModal.vue';
import PageLoader from '../../components/global/PageLoader.vue';
import { GlobalPaymentModal } from '../../components/payment';
import { Notify } from 'quasar';
import { ContractService } from '@ligo/dashboard/customer/store';

export default defineComponent({
  name: 'ContractPreview',
  components: {
    ContractPaymentMessage,
    ContractImage,
    ContractBanner,
    ContractDrawer,
    EditModal,
    DeleteModal,
    RevokeSignModal,
    MakeCopyModal,
    GlobalPaymentModal,
    PageLoader
  },
  props: {
    uuid: {
      type: String,
      required: true
    },
    dialog: {
      type: String
    },
    paymentUuid: {
      type: String
    },
    paymentStep: {
      type: String
    },
    adyenData: {
      type: Object as PropType<PaymentQuery>
    }
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const i18n = useI18n();
    const contract = useContractDetail(props.uuid);
    const { menu } = useContractMenu(contract.contract);
    const showDrawer = ref(true);
    const showMakeCopy = ref(false);
    const showPayContract = ref(props.paymentUuid ? true : false);
    const actualPaymentUuid = ref(props.paymentUuid);

    function closePaymentModal() {
      showPayContract.value = false;
      actualPaymentUuid.value = null;
      store.dispatch('layout/closePaymentModal');
    }

    function onPaymentSuccess() {
      closePaymentModal();
      Notify.create({
        message: i18n.t('payment.pay_contract.checkout.success').toString(),
        color: 'dark',
        position: 'top',
        icon: 'img:/dashboard/icons/circle-check.svg'
      });
      router.replace({ query: null });
      contract.load();
    }

    async function openPayment() {
      if (!actualPaymentUuid.value) {
        ContractService.getPayment(props.uuid).then((contract) => {
          actualPaymentUuid.value = contract.payment?.uuid;
          showPayContract.value = true;
        });
      } else {
        showPayContract.value = true;
      }
    }

    return {
      showDrawer,
      contract,
      menu,
      showMakeCopy,
      showPayContract,
      actualPaymentUuid,
      onPaymentSuccess,
      closePaymentModal,
      openPayment
    };
  }
});
