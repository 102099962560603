import { ActionTree } from 'vuex';
import { CustomerDashboardState } from '../state';
import { LayoutState, DEFAULT_DRAWER_BREAKPOINT } from './state';

const actions: ActionTree<LayoutState, CustomerDashboardState> = {
  cleanDashboardTitle({ commit }) {
    commit('setDashboardTitle', '');
  },
  setDrawerBreakpoint({ commit }, newBreakpoint) {
    commit('setDrawerBreakpoint', newBreakpoint);
  },
  restoreDrawerBreakpoint({ commit }) {
    commit('setDrawerBreakpoint', DEFAULT_DRAWER_BREAKPOINT);
  },
  openPaymentModal({ commit }, { paymentUuid }) {
    commit('openPaymentModal', paymentUuid);
  },
  closePaymentModal({ commit }) {
    commit('closePaymentModal');
  }
};

export default actions;
