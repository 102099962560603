import { Dictionary } from '@ligo/shared/utils';
import { Ref, ref } from '@vue/composition-api';

import {
  SimpleField,
  SimpleForm,
  SimpleFormDesignDefault
} from '../models/simpleForm.models';

import { createInitialFormValue, simpleReducerForm, useGraph } from './utils';

export const useSimpleForm = (config: SimpleForm) => {
  const formRef: Ref<any> = ref();

  const fieldsMap: Dictionary<SimpleField> = config.fields.reduce(
    (prev, field) => ({
      ...prev,
      [field.key]: field
    }),
    {}
  );

  const useLayout = config.useLayout
    ? config.useLayout.map((list) => list.filter((key) => !!fieldsMap[key]))
    : [config.fields.map((field) => field.key)];

  const step = ref(0);
  const stepperSize = useLayout.length;

  const { addEdge, dfsHandler, isAcyclic } = useGraph();

  function reset() {
    return formRef.value.reset();
  }

  function t(key: string) {
    return config.translateT(`${config.key}.${key}`);
  }
  function te(key: string) {
    return config.translateTe(`${config.key}.${key}`);
  }

  const formDesign = {
    ...SimpleFormDesignDefault,
    ...(config.design || {})
  };

  const handlerDependency: Dictionary<
    (formValue: Dictionary, field: SimpleField) => Dictionary
  > = {};

  config.fields
    .filter((field) => !!field.valueDependency)
    .forEach((field) => {
      handlerDependency[field.key] = field.valueDependency.handler;
      field.valueDependency.keys.forEach((dependencyKey) => {
        addEdge(dependencyKey, field.key);
      });
    });

  if (isAcyclic()) {
    throw 'The dependency graph is cyclic';
  }

  const updateDependencyGraph = (key: string, formValue: Dictionary) => {
    const updateTarget = (key: string) => {
      formValue = handlerDependency[key]
        ? {
            ...formValue,
            ...handlerDependency[key](formValue, fieldsMap[key])
          }
        : formValue;
    };
    dfsHandler(key, updateTarget);
    return formValue;
  };

  return {
    formRef,
    reset,
    t,
    te,
    step,
    stepperSize,
    formDesign,
    fieldsMap,
    updateDependencyGraph,
    useLayout
  };
};

export const useSimpleFormConfig = (
  formConfig: SimpleForm,
  initialData: Dictionary
) => {
  const formValue = ref<Dictionary>({});
  const fields = formConfig.fields;

  function initializeFormValue(initialValue: Dictionary) {
    formValue.value = createInitialFormValue(initialValue, formConfig.fields);
  }

  function getFormValueReducer(partialUpdate: string[] = []) {
    if (partialUpdate.length > 0) {
      simpleReducerForm(
        formValue.value,
        formConfig.fields.filter((value) => partialUpdate.includes(value.key))
      );
    }
    return simpleReducerForm(formValue.value, formConfig.fields);
  }

  initializeFormValue(initialData);

  return {
    fields,
    formValue,
    getFormValueReducer
  };
};
