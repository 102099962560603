import VueCompositionApi from '@vue/composition-api';
import { boot } from 'quasar/wrappers';
import { install } from '@ligo/shared/utils';
import * as baseComponents from '@ligo/shared/components';
import * as dashboardComponents from '@ligo/dashboard/components';

export default boot(({ Vue }) => {
  Vue.use(VueCompositionApi);
  install(Vue, Object.values({ ...baseComponents, ...dashboardComponents }));
});
