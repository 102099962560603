



















import { defineComponent } from '@vue/composition-api';

const i18nContent = {
  title: 'e_signature.sign_document.modals.success.title',
  text: 'e_signature.sign_document.modals.success.text'
};

export default defineComponent({
  name: 'SignSuccessModal',
  props: {
    value: {
      type: Boolean,
      default: true
    },
    signerEmail: String
  },
  setup() {
    return { i18nContent };
  }
});
