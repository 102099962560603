







































import { defineComponent, ref } from '@vue/composition-api';
import BankingAccountsHandler from '../../components/banking/BankingAccounts.handler.vue';
import BasePage from '../../components/base/BasePage.vue';
import {
  HeroBanner,
  InfoBanner,
  WarningBanner
} from '../../components/base/banners';
import { BankingPageInformation } from '../../components/utils/useCustomerPageInformation.hooks';
import ASecondaryBtn from '../../components/base/app/buttons/ASecondaryBtn.vue';

export default defineComponent({
  name: 'BankingLanding',
  components: {
    BankingAccountsHandler,
    BasePage,
    HeroBanner,
    InfoBanner,
    WarningBanner,
    ASecondaryBtn
  },
  props: {
    successAccountCreated: Boolean
  },
  setup(props) {
    const infoBanner = ref(false);
    const warningBanner = ref(false);
    const { t, isReady, load } = BankingPageInformation;
    load();

    if (props.successAccountCreated) {
      infoBanner.value = true;
      warningBanner.value = true;
    }

    return { infoBanner, warningBanner, t, isReady };
  }
});
