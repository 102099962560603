












import { defineComponent } from '@vue/composition-api';
import OnboardingStepper from './OnboardingStepper.vue';
import { useOnboardingRegister } from './onboardingStepper.hooks';

export default defineComponent({
  name: 'OnboardingPage',
  components: { OnboardingStepper },
  props: {
    initialValue: Object,
    loading: Boolean
  },
  setup(props, { emit }) {
    return useOnboardingRegister(props.initialValue || {}, emit);
  }
});
