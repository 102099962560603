export default {
  home_page: {
    invalid_file: 'Invalid document. It must be in .doc .docx or .pdf format.'
  },
  global: {
    drawer: {
      actions: {
        continue: 'Send for signature',
        back: 'Back',
        decline: 'Decline'
      }
    }
  },
  ctas: {
    save: 'Save',
    cancel: 'Cancel',
    remove: 'Delete'
  },
  base_input: {
    placeholder: 'Type text here ...'
  },
  add_signers: {
    board: {
      title: '',
      subtitle: 'Add signers',
      actions: {
        continue: 'Continue',
        cancel: 'Cancel'
      },
      CRUD: {
        add: 'Add | Add signer | Add another signer | Add another signer',
        add_yourself: 'Add yourself',
        input: {
          placeholder: 'Type an email address'
        }
      }
    }
  },
  errors: {
    expired: {
      title: 'The signing session has expired',
      text: 'Please contact the sender to get a new link'
    },
    completed: {
      title: 'The signing process was completed',
      text: 'You have already signed this document'
    },
    sent_for_signing: {
      title: 'This document was sent for signing',
      text:
        'This document is already prepared and sent for signing. You cannot edit it anymore.'
    },
    declined: {
      title:
        'This document got declined by some of its signers and is not valid anymore',
      text:
        'You can contact the person that asked you to sign it for more information.'
    },
    back_to_home: 'Back to home'
  },
  prepare_document: {
    board: {
      title: 'Add signers',
      subtitle: 'Add fields and send for signature',
      click_field_hint: 'Click to scroll to field',
      signers: {
        add: 'Add another signer',
        mail_placeholder: 'Add email'
      },
      no_fields: {
        paragraph_1:
          'Click anywhere on the document to add signers and fields.',
        paragraph_2:
          'These are the fields where all signers will fill-in their details when you send them the document for signature.',
        message: 'You have to add at least one signature field for each signer.'
      },
      fields: {
        description: `Fields you've added`,
        edit_signer: 'Edit signers',
        types: {
          signature: 'Signature',
          date: 'Date',
          text: 'Text',
          checkbox: 'Checkbox'
        },
        add_myself: 'Add myself'
      },
      actions: {
        continue: 'Next',
        cancel: 'Cancel',
        add: 'Add',
        edition: {
          save: 'Save',
          cancel: 'Cancel',
          delete: 'Remove signer',
          remove: 'Remove',
          valid_email: 'Email is not valid'
        }
      }
    },
    page: {
      tooltip: 'Click to add a field'
    },
    modals: {
      delete: {
        title: 'Are you sure?',
        subtitle:
          'Are you sure you want to remove the signer and all of their associated fields?'
      },
      send_mails: {
        title: 'Review and send for signature',
        signers: 'Signers',
        expiration: {
          title: 'Expiration date',
          interval:
            'Signature request expires in {amountDays} days, on {finalDate}',
          message:
            'Multiple reminders will be sent to all signers as the expiration date approaches, to make sure everybody will all sign on time.'
        },
        message: 'Personal message (optional)',
        cta: 'Send',
        copy_senders: {
          title:
            'Who else should receive a copy of the signed document? (optional)',
          placeholder: 'Type an email address...',
          extra_info:
            'These people won’t sign the document but will receive a copy of the signing log and the finalized signed document.',
          mail_rule: 'Please enter a valid email address',
          add: 'Add',
          description:
            'These people won’t sign the document but will receive a copy of the signing log and the finalized signed document.'
        }
      },
      success: {
        signer: {
          title: 'Sent for signature. Now it’s your turn to sign.',
          description:
            'All signers will receive an email attachment of the completed document once everyone has signed.'
        },
        no_signer: {
          title: 'Sent for signature.',
          description:
            'All signers will receive an email attachment of the completed document once everyone has signed.'
        },
        cta: 'Sign document'
      },
      edit: {
        title: 'Edit signers'
      }
    },
    menu: {
      title: 'Add Field',
      signers_subtitle: 'Assigned to',
      fields_subtitle: 'Pick a field type',
      field_types: {
        signature: 'Signature',
        date: 'Date',
        text: 'Text',
        checkbox: 'Checkbox'
      },
      required: 'Required',
      add_cta: 'Add'
    },
    edit_menu: {
      assigned: 'Assigned to',
      actions: {
        title: 'Actions',
        copy: 'Copy',
        delete: 'Delete'
      }
    },
    fields: {
      samples: {
        signature: 'Signature',
        text: 'text ...',
        date: 'dd/mm/yyyy'
      }
    }
  },
  sign_document: {
    toolbar: {
      one_remaining: 'field left',
      many_remaining: 'fields left',
      next: 'Next',
      submit: 'Submit',
      decline: 'Decline'
    },
    board: {
      title: '',
      subtitle: 'Sign document',
      required_fields: 'Required Fields',
      optional_fields: 'Optional fields',
      fields: {
        types: {
          signature: 'Signature',
          date: 'Date',
          text: 'Text',
          checkbox: 'Checkbox'
        }
      },
      actions: {
        continue: 'Finish signing',
        cancel: 'Cancel'
      },
      success: {
        title: 'You successfuly signed this document',
        text:
          'You’ll receive a copy of the signed document at {signerEmail} once everybody signs it. You can close this window for now.'
      },
      decline: {
        title: 'You have declined this document',
        text: 'You can’t sign this document anymore because you declined it.'
      }
    },
    pad: {
      title: 'Add your signature',
      description: 'Draw it in',
      draw_new_label: 'Want to use a different signature ?',
      draw_new_action: 'Draw a new one',
      remove_title: 'Are you sure?',
      remove_message: 'Are you sure do you want to delete this signature ?',
      tabs: {
        new: 'Draw',
        used: 'Saved'
      },
      cta: {
        clear: 'Clear',
        save: 'Save',
        remove: 'Remove'
      }
    },
    modals: {
      confirmation: {
        title: 'Ready to submit?',
        text:
          'We’ll email you a copy of the signed document at {signerEmail} once everybody signs it.',
        ok: 'Submit',
        cancel: 'Cancel'
      },
      success: {
        title: 'You successfuly signed this document',
        text:
          'You’ll receive a copy of the signed document at {signerEmail} once everybody signs it. You can close this window for now.'
      },
      success_without_account: {
        title: 'You successfully signed this document.',
        subtitle:
          'You’ll receive a copy of the signed document at {signerEmail} once everybody signs it.',
        create_account:
          'Need to sign and manage more documents? Create your FREE Ligo account today.',
        features: {
          store_all_documents: 'Store all your documents in one place',
          access_library: 'Access a library of 100+ contract templates',
          electronic_signature: 'Sign documents electronically',
          digital_legally: 'Get digital legally binding signatures from others'
        },
        cta: 'Sign up'
      },
      checkbox: {
        title: 'Check this box ?',
        yes: 'Yes',
        no: 'No'
      },
      decline: {
        title: 'Are you sure?',
        text: 'Confirm that you want to decline this document.',
        decline: 'Decline',
        cancel: 'Cancel'
      },
      fill_text: {
        title: 'Add text'
      },
      fill_date: {
        title: 'Add date'
      }
    },
    fields: {
      samples: {
        signature: 'Signature',
        text: 'Text',
        date: 'Date'
      }
    }
  }
};
