
































import { defineComponent, computed } from '@vue/composition-api';
import { useLocale } from '../components/account/user-settings.hook';

export default defineComponent({
  name: 'CustomerLangSelect',
  setup() {
    const locale = useLocale(false);

    const netherlandsFlag = '/dashboard/icons/sidebar/netherlands_flag.webp';
    const englandFlag = '/dashboard/icons/sidebar/england_flag.png';
    const EN = computed(() => locale?.locales.value[0]?.value);
    const NL = computed(() => locale?.locales.value[1]?.value);
    const currentLanguage = computed(() => locale?.current?.value);

    function changeLocale(newLocale = 'nl') {
      locale.current.value = newLocale;
    }

    return {
      netherlandsFlag,
      englandFlag,
      EN,
      NL,
      currentLanguage,
      changeLocale
    };
  }
});
