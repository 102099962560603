




import { defineComponent } from '@vue/composition-api';
import ErrorLayout from './ErrorLayout.vue';

const i18nContent = {
  title: 'e_signature.errors.sent_for_signing.title',
  text: 'e_signature.errors.sent_for_signing.text'
};

export default defineComponent({
  name: 'ErrorSentForSigning',
  components: {
    ErrorLayout
  },
  setup() {
    return {
      i18nContent
    };
  }
});
