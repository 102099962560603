





























































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { PropType } from 'vue';
import { SimpleField, SimpleForm } from './models/simpleForm.models';
import BaseSimpleField from './BaseSimpleField.vue';
import { APrimaryBtn, ASecondaryBtn } from '../app/buttons';
import { useSimpleForm } from './hooks/simpleForm.hooks';

export default defineComponent({
  name: 'BaseSimpleForm',
  components: {
    BaseSimpleField,
    APrimaryBtn,
    ASecondaryBtn
  },
  props: {
    value: {
      type: Object
    },
    config: {
      type: Object as PropType<SimpleForm>,
      required: true
    },
    forceLoading: Boolean
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const lastUpdateKey = ref<string>('');

    const {
      formDesign,
      formRef,
      fieldsMap,
      step,
      stepperSize,
      useLayout,
      reset,
      t,
      te,
      updateDependencyGraph
    } = useSimpleForm(props.config);

    const visibleFields = computed(() =>
      useLayout[step.value]
        .map((key) => fieldsMap[key])
        .filter(
          (field) =>
            !field.hideOn || !field.hideOn(props.value[field.key], props.value)
        )
    );

    const isDisable = computed(
      () =>
        step.value == stepperSize - 1 &&
        props.config.disable &&
        props.config.disable(props.value)
    );

    return {
      isDisable,
      visibleFields,
      step,
      stepperSize,
      onStep(i: number) {
        if (i < step.value) {
          step.value = i;
        }
      },
      onBack() {
        if (step.value > 0) {
          step.value--;
        }
      },
      onNext() {
        formRef.value.validate().then((res: boolean) => {
          if (res) {
            if (step.value == stepperSize - 1) emit('on-next');
            else step.value++;
          }
        });
      },
      onChangeValue(key: string, value: any, field: SimpleField) {
        lastUpdateKey.value = '';
        const newPayload = {
          ...props.value,
          [key]: value
        };
        const action = () => {
          lastUpdateKey.value = key;
          const result = updateDependencyGraph(key, newPayload);
          emit('on-change-value', {
            ...result
          });
          emit('on-change-key-field', {
            key: key,
            value: value
          });
        };
        if (field.checkPoint) {
          loading.value = true;
          field
            .checkPoint(newPayload, field)
            .then(() => {
              action();
            })
            .finally(() => {
              loading.value = false;
            });
        } else action();
      },
      t,
      te,
      reset,
      formRef,
      loading,
      lastUpdateKey,
      formDesign
    };
  }
});
