import { ref, Ref } from '@vue/composition-api';
import {
  Signer,
  ESignField,
  ClickEvent,
  FieldType
} from '../../../../../models';
import { useTooltipsInteractions } from './tooltips';
import { useFieldsInteractions } from './fields';
import Vue from 'vue';

export function useInteractions() {
  const showMenu: Ref<boolean> = ref(false);

  const {
    mouseMoveEvent: onMouseMoveEvent,
    removeTooltip
  } = useTooltipsInteractions();

  const {
    pointerPage,
    lastSignerUsedUuid,
    previousFieldMenu,
    removeField,
    initInteractiveFields,
    addField: onAddField,
    removeSignerField,
    changeSigner,
    copySignerField,
    isFieldShowingMenu,
    setFieldMenu
  } = useFieldsInteractions();

  function mouseMoveEvent(event, signers: Signer[]) {
    onMouseMoveEvent(event, isFieldShowingMenu(signers), showMenu.value);
  }

  function addField(
    signer: Signer,
    type?: FieldType,
    required?: boolean,
    x?: number,
    y?: number,
    width?: number,
    height?: number,
    pageIndex?: number,
    squareField?: boolean
  ) {
    showMenu.value = false;
    onAddField(
      signer,
      type,
      required || false,
      x,
      y,
      width,
      height,
      pageIndex,
      squareField
    );
  }

  function loadMenu(
    event: ClickEvent,
    index: number,
    pageSelector: string,
    signers: Signer[]
  ): void {
    if (!isFieldShowingMenu(signers) && !showMenu.value) {
      removeTooltip();
      const page = document.querySelector(pageSelector);
      const pageRect = page.getBoundingClientRect();
      pointerPage.value.page_number = index;
      pointerPage.value.x = event.clientX - pageRect.left;
      pointerPage.value.y = event.clientY - pageRect.top;
      showMenu.value = true;
      Vue.nextTick(() => {
        const menu = document.getElementById('e-sign-menu');
        menu.style.top = `${event.pageY}px`;
        menu.style.left = `${event.pageX}px`;
      });
    } else {
      showMenu.value = false;
      closeFieldMenu(signers);
    }
  }

  function showFieldMenu(field: ESignField, signers: Signer[]) {
    removeTooltip();
    showMenu.value = false;
    setFieldMenu(signers, field);
  }

  function closeFieldMenu(signers: Signer[]) {
    setFieldMenu(signers);
  }

  return {
    showMenu,
    lastSignerUsedUuid,
    previousFieldMenu,
    initInteractiveFields,
    addField,
    removeField,
    removeSignerField,
    changeSigner,
    copySignerField,
    isFieldShowingMenu,
    setFieldMenu,
    loadMenu,
    showFieldMenu,
    closeFieldMenu,
    removeTooltip,
    mouseMoveEvent
  };
}
