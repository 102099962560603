































































































import {
  ContractService,
  EXTERNAL_RESOURCES,
  LegalCheckup,
  LegalCheckupsService,
  RESOURCES
} from '@ligo/dashboard/customer/store';
import {
  Ref,
  computed,
  defineComponent,
  onBeforeMount,
  ref
} from '@vue/composition-api';
import { ApiService } from '@ligo/shared/utils';
import { APrimaryBtn } from '../base/app/buttons';
import {
  NotaryProductQuestionnairePath,
  NotaryProductSlug
} from '@ligo/dashboard/store';
import { analyticLegalCheckupContinueEvent } from '../../analytics/events';
import { useStore, useI18n } from '../../composables';

enum headers {
  IN_PROGRESS = 'in_progress',
  PERFECT = 'perfect',
  COMPLETED = 'completed',
  POSITIVE = 'positive',
  NEGATIVE = 'negative'
}

export default defineComponent({
  name: 'LegalCheckupResult',
  components: { APrimaryBtn },
  props: {
    uuid: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const checkUp: Ref<LegalCheckup> = ref();
    const currentAction = ref(0);
    const totalScore = ref(0);
    const actionsCount = ref(0);
    const loading = ref(true);
    const totalActions = ref(0);
    const store = useStore();
    const i18n = useI18n();

    const doneActions = computed(() => {
      return checkUp.value.legal_checkup_actions.filter(
        (action) => action.status == 'done'
      ).length;
    });
    const progress = computed(() =>
      totalActions.value ? doneActions.value / totalActions.value : 0
    );

    function getDescription(action) {
      return (
        action.actionable?.translated_fields?.find(
          (item) => item.locale == i18n.locale
        )?.text || action.actionable.description
      );
    }

    const header = computed(() => {
      if (checkUp.value.status == 'open') {
        return headers.IN_PROGRESS;
      } else if (doneActions.value == totalActions.value) {
        return headers.COMPLETED;
      } else {
        if (
          totalScore.value > 0 &&
          checkUp.value.score / totalScore.value <= 0.5
        ) {
          return headers.NEGATIVE;
        } else if (checkUp.value.score == totalScore.value)
          return headers.PERFECT;
        else return headers.POSITIVE;
      }
    });

    function continueCheckup() {
      analyticLegalCheckupContinueEvent(
        store.getters['authentication/user'],
        checkUp.value.legal_checkup_type.slug
      );
      window.open(
        `${EXTERNAL_RESOURCES.MAIN_CHECKUP(
          checkUp.value.legal_checkup_type.slug
        )}/${checkUp.value.uuid}`,
        '_blank'
      );
    }

    function load() {
      LegalCheckupsService.getById(props.uuid).then(({ data }) => {
        checkUp.value = data;
        checkUp.value.legal_checkup_actions.sort((a, b) =>
          a.actionable.id > b.actionable.id ? 1 : -1
        );
        actionsCount.value = data.legal_checkup_actions.length;
        totalScore.value = data.scored_questions;
        totalActions.value = data.legal_checkup_actions.length;
        loading.value = false;
      });
    }
    onBeforeMount(load);

    async function startContract(action) {
      const newContract = await ContractService.createFromTemplate(
        action.actionable.id
      );
      const url = EXTERNAL_RESOURCES.CONTRACTS(
        newContract.contract_type.slug,
        newContract.uuid
      );
      const path = EXTERNAL_RESOURCES.AUTHENTICATED_REDIRECTION(url);
      const response = await ApiService.auth_redirection(path);
      window.location.assign(response.request.responseURL);
    }

    async function onStart(action) {
      if (action.actionable_type == 'NotaryProductType') {
        if (Object.keys(NotaryProductSlug).includes(action.actionable.slug)) {
          window.location.assign(
            `${RESOURCES.QUESTIONNAIRE}${
              NotaryProductQuestionnairePath[action.actionable.slug]
            }`
          );
        } else {
          const response = await ApiService.post(
            EXTERNAL_RESOURCES.NOTARY_PRODUCT_CREATE(action.actionable.slug),
            {}
          );
          const redirectTo = EXTERNAL_RESOURCES.AUTHENTICATED_REDIRECTION(
            response.request.responseURL
          );
          const redirectionResponse = await ApiService.auth_redirection(
            redirectTo
          );
          window.open(redirectionResponse.request.responseURL, '_blank');
        }
      } else if (action.actionable_type == 'ContractType') {
        startContract(action);
      }
    }

    function updateStatus(action) {
      LegalCheckupsService.updateStatus(action.uuid, action.status).then(() => {
        load();
      });
    }

    return {
      checkUp,
      progress,
      header,
      currentAction,
      totalScore,
      actionsCount,
      loading,
      load,
      continueCheckup,
      onStart,
      updateStatus,
      getDescription
    };
  }
});
