import { onUnmounted, ref, Ref } from '@vue/composition-api';
import { Store } from 'vuex';

export const getReactiveObject = <T, S>(getState: () => T, store: Store<S>) => {
  const data = ref(getState()) as Ref<T>;
  const unwatch = store.watch<T>(getState, (newVal: T) => {
    data.value = newVal;
  });
  onUnmounted(() => {
    unwatch();
  });
  return data;
};

export const useStoreValue = <T, S = any>(store: Store<S>) => (
  state: (() => T) | string
) => {
  const getState =
    typeof state === 'string' ? () => store.getters[state] : state;
  return ref(getReactiveObject<T, S>(getState, store));
};
