






import {
  defineComponent,
  onMounted,
  PropType,
  ref
} from '@vue/composition-api';
import { PaymentAdyenSessions, PaymentQuery } from '@ligo/shared/models';
import { RESOURCES, sendGTMPaymentEvent } from '@ligo/dashboard/customer/store';
import { ApiService } from '@ligo/shared/utils';
import { useEnvironment } from 'netlify_functions/environment-tools';
import AdyenCheckout from '@adyen/adyen-web';
import { AdyenResponseCode } from '../../../models';
import { useI18n } from '../../../composables';

export default defineComponent({
  name: 'AdyenPayment',
  props: {
    uuid: {
      type: String,
      required: true
    },
    originUrl: {
      type: String,
      required: true
    },
    query: Object as PropType<PaymentQuery>
  },
  setup(props, { emit }) {
    const dropinContainer = ref();
    const container = ref();
    const checkout = ref();
    const i18n = useI18n();

    async function onPaymentCompleted(paymentResponse) {
      if (paymentResponse.resultCode == AdyenResponseCode.AUTHORISED) {
        container.value.setStatus('success');
        await sendGTMPaymentEvent(props.uuid);
        setTimeout(() => {
          emit('payment-success');
        }, 3000);
      } else emit('payment-error', paymentResponse.resultCode);
    }

    function onError(error, component) {
      emit('payment-error', error);
      console.error(error.name, error.message, error.stack, component);
    }

    function getConfig(session: any) {
      return {
        environment: useEnvironment().ADYEN.environment,
        clientKey: useEnvironment().ADYEN.clientKey,
        session,
        onPaymentCompleted: onPaymentCompleted,
        onError: onError,
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true
          }
        },
        locale: i18n.locale == 'nl' ? 'nl_NL' : 'en_US'
      };
    }

    async function loadDropin() {
      let session;
      if (props.query?.sessionId) {
        session = {
          id: props.query.sessionId
        };
      } else {
        const { data } = await ApiService.post<PaymentAdyenSessions>(
          RESOURCES.ADYEN_PAYMENT(props.uuid),
          { origin_url: props.originUrl }
        );
        session = data.session;
      }

      const config = getConfig(session);

      checkout.value = await AdyenCheckout(config);
      container.value = checkout.value
        .create('dropin')
        .mount(dropinContainer.value);
      if (props.query.redirectResult) {
        checkout.value.submitDetails({
          details: { redirectResult: props.query.redirectResult }
        });
      }
    }
    onMounted(loadDropin);

    return { dropinContainer };
  }
});
