import Vue from 'vue';
import { Store } from 'vuex';
import VueRouter from 'vue-router';
import { route } from 'quasar/wrappers';
import { CustomerDashboardState } from '@ligo/dashboard/customer/store';
import routes from './routes';
import { scroll } from 'quasar';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation
 */

Vue.use(VueRouter);

const Router = new VueRouter({
  scrollBehavior: function (to, _from, _savedPosition) {
    if (to.hash) {
      const { getScrollTarget, setScrollPosition } = scroll;
      const target: HTMLElement = document.querySelector(to.hash);
      if (target) {
        const scrollTarget = getScrollTarget(target);
        const offset = target.offsetTop;
        setScrollPosition(scrollTarget, offset, 2000);
      }
    } else return { x: 0, y: 0 };
  },
  routes,
  // Leave these as is and change from quasar.conf.js instead!
  // quasar.conf.js -> build -> vueRouterMode
  // quasar.conf.js -> build -> publicPath
  mode: 'history'
});

export default route<Store<CustomerDashboardState>>(function () {
  return Router;
});

export { Router };

Router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt') == null) {
      next({
        name: 'Login',
        query: { redirect_url: window.location.pathname }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
