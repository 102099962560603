var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"drawer-navigation"},_vm._l((_vm.navItems),function(navItem,idx){return _c('q-card',{key:("item-" + idx),class:("nav-item q-mt-" + (idx != 0 && 'sm') + " cursor-pointer"),attrs:{"flat":""},on:{"click":function($event){return _vm.onMainItemClick(navItem)},"mouseover":function($event){_vm.itemHovered = idx},"mouseout":function($event){_vm.itemHovered = _vm.INDEX_NO_HOVERED}}},[(_vm.useTooltips)?_c('nav-item-tooltip',{attrs:{"title":navItem.title,"subtitle":navItem.subtitle}}):_vm._e(),_c('div',{class:{
        'full-width row items-center': !_vm.mini,
        'row justify-center': _vm.mini
      }},[_c('div',{class:("rounded-borders row items-center bg-" + (_vm.itemHovered == idx ? _vm.BG_HOVER_COLOR : 'transparent')),style:(("padding: 8px; " + (_vm.isActivePage(navItem) && "border: 1.5px solid #2A3554")))},[_c('q-icon',{attrs:{"color":_vm.ICON_COLOR,"size":((_vm.isActivePage(navItem) ? 13 : 16) + "px"),"name":navItem.icon}})],1),(!_vm.mini)?_c('div',{staticClass:"font-14 w-400 q-pl-sm col row justify-between items-center"},[_vm._v(" "+_vm._s(_vm.$t(navItem.title))+" "),(!_vm.mini && _vm.hasChildren(navItem))?_c('div',[_c('q-icon',{class:((_vm.getItemIcon(navItem).value.class) + " text-default"),attrs:{"size":"12px","name":_vm.getItemIcon(navItem).value.name}})],1):_vm._e()]):_vm._e()]),(_vm.showChildren(navItem))?_c('div',{staticClass:"nested"},_vm._l((navItem.children),function(child,i){return _c('div',{key:("child-" + i),staticClass:"relative-position",on:{"mouseover":function($event){_vm.childHovered = i},"mouseout":function($event){_vm.childHovered = _vm.INDEX_NO_HOVERED},"click":function($event){$event.stopPropagation();return _vm.goRoute(child.route)}}},[_c('div',{class:{
            'row items-center child-item': true,
            active: _vm.childHovered == i,
            selected: _vm.activeItem(child)
          }},[(child.icon)?_c('q-icon',{staticClass:"q-mr-sm",attrs:{"name":child.icon}}):_vm._e(),_c('div',{staticClass:"font-14 w-400 text-default"},[_vm._v(" "+_vm._s(_vm.$t(child.title))+" ")]),(child.newItem)?_c('div',{staticClass:"q-ml-sm new-badge"},[_c('div',{staticClass:"text-np-secondary-1200 font-12 w-500"},[_vm._v(" "+_vm._s(_vm.$t('nav.new_navigation_item'))+" ")])]):_vm._e()],1)])}),0):_vm._e(),(navItem.badgeNumber && navItem.badgeNumber > 0)?_c('q-badge',{staticClass:"q-mt-sm",attrs:{"floating":"","color":"primary","round":""}},[_vm._v(" "+_vm._s(navItem.badgeNumber)+" ")]):_vm._e()],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }