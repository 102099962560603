








import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ErrorPaymentBanner',
  props: {
    text: {
      type: String,
      required: true
    },
    textColor: {
      type: String,
      default: 'text-dark'
    },
    icon: {
      type: String,
      default: 'img:/dashboard/icons/circle-exclamation.svg'
    },
    bgColor: {
      type: String,
      default: 'primary'
    },
    contentClass: String
  }
});
