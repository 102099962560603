































































































import { defineComponent, ref, computed, PropType } from '@vue/composition-api';
import { BaseSimpleForm } from '../../base';
import { APrimaryBtn, ASecondaryBtn } from '../../base/app/buttons';
import { useSimpleFormConfig } from '../../base/SimpleForm/hooks/simpleForm.hooks';
import AlertModal from '../../documents/base/AlertModal.vue';
import { counterWords } from '../hooks/common-field-definitions.hooks';
import { NotaryProductService } from '@ligo/dashboard/customer/store';

import { SEPARATOR_TOKEN } from './extra-fields.hooks';
import {
  DIALOG_WITH_NOTARY_KEY,
  DialogPageInformation,
  useCustomerExtraQuestions
} from './extra-questions.hooks';
import { NotaryProductSlug } from '@ligo/bv-flow/store';
import ErrorPaymentBanner from '../../payment/checkout/ErrorPaymentBanner.vue';
export default defineComponent({
  name: 'ExtraQuestionHandler',
  components: {
    AlertModal,
    BaseSimpleForm,
    ErrorPaymentBanner,
    APrimaryBtn,
    ASecondaryBtn
  },
  props: {
    notaryProductUuid: {
      type: String,
      required: true
    },
    productType: {
      type: String as PropType<NotaryProductSlug>
    },
    productData: {
      type: Object
    },
    notaryId: {
      type: Number
    }
  },
  setup(props, { emit }) {
    const {
      loading,
      formConfig,
      getCompanyName,
      onSendInfo,
      loadPageInformation,
      t,
      onNext
    } = useCustomerExtraQuestions(
      props.notaryProductUuid,
      props.productType,
      emit,
      props.notaryId
    );

    loadPageInformation().then(() => {
      emit('stop-loading');
    });

    const { formValue, getFormValueReducer } = useSimpleFormConfig(
      formConfig,
      props.productData
    );

    const showConfirmDialog = ref(false);
    const showGPTDialog = ref(false);
    const forceLoading = ref(false);
    const gptContext = ref('');
    const gptError = ref(false);

    const getDialogInfo = computed(() => {
      return t<DialogPageInformation>(DIALOG_WITH_NOTARY_KEY);
    });

    function onShowAI(scope: any) {
      const activitiesContext = formValue.value['activities'];
      if (activitiesContext) gptContext.value = activitiesContext;

      showGPTDialog.value = true;
      gptError.value = false;
      scope.baseField?.onResetValidation();
    }

    function onAskGPT() {
      forceLoading.value = true;
      gptError.value = false;
      onSendInfo(getFormValueReducer()).then(() => {
        NotaryProductService.getDescriptionFromChatGPT({
          notary_product_uuid: props.notaryProductUuid,
          context: gptContext.value || ''
        })
          .then((resp) => {
            showGPTDialog.value = false;
            formValue.value['activities'] = resp.data.answer;
          })
          .catch(() => {
            gptError.value = true;
          })
          .finally(() => {
            forceLoading.value = false;
          });
      });
    }

    function onMoveNext() {
      const asda = getFormValueReducer();
      console.log('onMoveNext', asda);
      onNext(asda);
    }

    function onChangeKeyField(change: any) {
      if (change.key === 'company_name') {
        emit('on-rename', getCompanyName(change.value));
      }
    }

    return {
      loading,
      showConfirmDialog,
      SEPARATOR_TOKEN,
      formConfig,
      formValue,
      getDialogInfo,
      showGPTDialog,
      forceLoading,
      gptContext,
      gptError,
      onShowAI,
      counterWords,
      onAskGPT,
      onMoveNext,
      onChangeKeyField
    };
  }
});
