
































import { defineComponent, ref } from '@vue/composition-api';

import { BtnCard } from '@ligo/dashboard/components';
import { useAddDocument } from '../../components/documents/panel/add-document.hook';
import { useMembershipInfo } from '../../components/utils/membership-info';

import ActiveContractsList from '../../components/documents/ActiveContractsList.vue';
import BasePage from '../../components/base/BasePage.vue';
import AlertModal from '../../components/base/modals/AlertModal.vue';

const BTN_DIALOG_SIZE = 'btn-width-135';

export default defineComponent({
  name: 'ActiveContracts',
  components: {
    ActiveContractsList,
    BasePage,
    AlertModal
  },
  setup() {
    const btns = ref<Array<BtnCard>>([
      {
        label: 'contract.actives.create',
        emitKey: 'contract-create'
      }
    ]);

    const {
      hasMembership,
      membership,
      availableTemplates,
      planTemplates
    } = useMembershipInfo();

    const {
      newDocumentDialog,
      signedDocDialog,
      fileInput,
      fileForUpload,
      uploading,
      fileName,
      onFileUpload,
      onSubmitFile
    } = useAddDocument();

    return {
      btns,
      newDocumentDialog,
      signedDocDialog,
      onFileUpload,
      onSubmitFile,
      fileForUpload,
      fileInput,
      uploading,
      fileName,
      BTN_DIALOG_SIZE,
      hasMembership,
      membership,
      availableTemplates,
      planTemplates
    };
  }
});
