import { GetterTree } from 'vuex';
import { NotaryProductState } from './state';
import { CustomerDashboardState } from '../state';

const getters: GetterTree<NotaryProductState, CustomerDashboardState> = {
  getNotaryProductId(state) {
    return state.selectedId;
  },
  getNotaryProduct(state) {
    return state.notaryProduct;
  }
};

export default getters;
