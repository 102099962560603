import {
  NotaryProductService,
  NotaryProductDocumentsService
} from '@ligo/dashboard/customer/store';
import { Ref } from '@vue/composition-api';
import { NotaryProductDocument } from '@ligo/dashboard/store';
import {
  initWebSocket,
  getBaseSocketURL,
  useAsyncResult
} from '@ligo/shared/utils';
import { AxiosPromise } from 'axios';
import moment from 'moment';
import { useI18n } from '../../composables';

function useWebSocket(
  notaryProductId: Ref<string>,
  queryParams?: {
    kinds?: string[];
    document_types?: string[];
    per_page?: number;
  }
) {
  const { url, channelIdentifier } = getBaseSocketURL(
    notaryProductId.value,
    'NotaryProducts::NotaryProductDocumentsChannel'
  );

  return initWebSocket<
    NotaryProductDocument[],
    AxiosPromise<NotaryProductDocument[]>
  >(
    () => NotaryProductService.documents(notaryProductId.value, queryParams),
    [] as NotaryProductDocument[],
    url,
    channelIdentifier,
    (state, response) => {
      state.value = response.data.filter((document) => {
        const kinds = queryParams.kinds || [document.kind];
        const document_types = queryParams.document_types || [
          document.document_type
        ];
        return (
          kinds.includes(document.kind) &&
          document_types.includes(document.document_type)
        );
      });
    }
  );
}

function useRequest(
  notaryProductId: Ref<string>,
  queryParams?: {
    kinds?: string[];
    document_types?: string[];
    per_page?: number;
  }
) {
  return useAsyncResult(() =>
    NotaryProductService.documents(notaryProductId.value, queryParams)
  );
}

// Common functions to all hooks
/*********************************************** */
export function onDownload(doc: NotaryProductDocument): void {
  NotaryProductDocumentsService.download(doc);
}

export function getDocumentsBy(
  notaryProductId: Ref<string>,
  queryParams?: {
    kinds?: string[];
    document_types?: string[];
    per_page?: number;
  },
  listenWebSocket?: boolean
): {
  items: Ref<NotaryProductDocument[]>;
  loading: Ref<boolean>;
  load: () => void;
} {
  const { state, load, loading } = listenWebSocket
    ? useWebSocket(notaryProductId, queryParams)
    : useRequest(notaryProductId, queryParams);

  return { items: state, loading, load };
}

export function getLastUpdatedDocument(documents: NotaryProductDocument[]) {
  if (!documents.length) return undefined;
  const i18n = useI18n();
  const result = documents.reduce((maxi, doc) => {
    const currentDate = new Date(doc.updated_at);
    const maxDate = new Date(maxi);
    return currentDate > maxDate ? doc.updated_at : maxi;
  }, documents[0].updated_at);
  return i18n.t('documents.last_updated', {
    date: moment(result).format('DD MMM YYYY')
  });
}
/*********************************************** */
