





















































import { defineComponent, ref, computed } from '@vue/composition-api';
import SignerInputField from './SignerInputField.vue';

export default defineComponent({
  name: 'SignerBox',
  components: {
    SignerInputField
  },
  props: {
    email: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    addMyselfLabel: String,
    showRemove: {
      type: Boolean,
      default: true
    },
    showAddMyself: {
      type: Boolean,
      default: true
    },
    clearAfterSave: {
      type: Boolean,
      default: true
    },
    iconColor: String
  },
  setup() {
    const focus = ref(false);
    const hasError = ref(false);
    const enter = ref(false);
    const showError = computed(() => {
      return hasError.value && (!focus.value || enter.value);
    });

    function getFocus() {
      focus.value = true;
    }

    function loseFocus() {
      focus.value = false;
    }

    function onKeyDown(key: string) {
      if (key === 'Enter') {
        enter.value = true;
      } else {
        enter.value = false;
      }
    }

    return {
      focus,
      hasError,
      showError,
      onKeyDown,
      getFocus,
      loseFocus
    };
  }
});
