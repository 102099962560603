


































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'InactivityModal',
  props: {
    image: {
      type: String,
      default: '/dashboard/images/inactivity_modal_image.svg'
    },
    modalAppearanceTime: {
      type: Number,
      required: true
    },
    showModal: {
      type: Boolean,
      required: true
    }
  }
});
