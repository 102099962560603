import { ApiService } from '@ligo/shared/utils';
import { RESOURCES } from '../resources';

export const EventService = {
  async create(data) {
    return ApiService.post(RESOURCES.EVENTS, data);
  },
  async update(data) {
    const { id, ...rest } = data;
    return ApiService.patch(`${RESOURCES.EVENTS}/${id}`, rest);
  },
  async delete(id: number) {
    return ApiService.delete(`${RESOURCES.EVENTS}/${id}`);
  },
  async toggleComplete(id: number) {
    return ApiService.patch(`${RESOURCES.EVENTS}/${id}/toggle_complete`, {});
  },
  async clone(id: number, remindMeOn: string, eventDate: string) {
    return ApiService.post(`${RESOURCES.EVENTS}/${id}/clone`, {
      remind_me_on: remindMeOn,
      event_date: eventDate
    });
  }
};
