








import { Payment, RESOURCES } from '@ligo/dashboard/customer/store';
import { ApiService } from '@ligo/shared/utils';
import { defineComponent, onMounted } from '@vue/composition-api';
import { useRouter } from '../../composables';
export default defineComponent({
  name: 'PaymentRedirect',
  props: {
    uuid: {
      type: String,
      required: true
    },
    sessionId: String,
    redirectResult: String
  },
  setup(props) {
    const router = useRouter();

    async function loadAdyenConfig() {
      const data = (
        await ApiService.get<Payment>(RESOURCES.PAYMENT(props.uuid))
      ).data;

      const query = {
        paymentUuid: props.uuid,
        paymentStep: 'checkout',
        sessionId: props.sessionId,
        redirectResult: props.redirectResult
      };

      if (data.origin_url) {
        router.push({
          path: data.origin_url,
          query: query
        });
      } else {
        router.push({
          name: 'Home',
          query: { ...query, paymentStep: 'billing' }
        });
      }
    }

    onMounted(loadAdyenConfig);
  }
});
